import React, { useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'

import 'assets/scss/style.scss'
import SatisfactionIcon from 'assets/img/satisfaction-icon.svg'
import CuriousIcon from 'assets/img/curious-icon.svg'

import { searchEvaluationByJobPostingCompanyId, getUserEvaluations } from 'services/evaluation'
import { EvaluationThread, EvaluationNav } from 'components'

function Evaluations({ jobPostingId, userId }) {
  const { t } = useTranslation()
  const stats = useSelector((state) => state.evaluation.evaluationStats || [])
  const jobPostingDetails = useSelector((state) => state.evaluation.jobPostingDetails || [])
  const [active, setActive] = useState('all')
  const dispatch = useDispatch()
  const [isProfilePage, setIsProfilePage] = useState(false)

  useEffect(() => {
    if (typeof jobPostingId !== 'undefined') {
      dispatch(searchEvaluationByJobPostingCompanyId(jobPostingId))
    } else if (typeof userId !== 'undefined') {
      dispatch(
        getUserEvaluations(userId, {
          page: 1,
          limit: 10,
          sort: 'desc',
          sortBy: 'created_at',
          job_evaluation_filter: active === 'all' ? null : active,
        })
      )
    }
  }, [dispatch, jobPostingId, userId, active])

  useEffect(() => {
    setItems((oldItems) => oldItems.map((oldItem) => ({ ...oldItem, number: stats[oldItem.id] })))
  }, [stats])

  useEffect(() => {
    if (window.location.pathname === '/profile') {
      setIsProfilePage(true)
    }
  }, [])

  const [items, setItems] = useState([
    {
      id: 'all',
      icon: '',
      label: t('evaluation.tabs.all'),
      number: 1,
    },
    {
      id: 'like',
      icon: <img src={SatisfactionIcon} alt="altSVGImage" width="20" height="auto" />,
      label: t('evaluation.tabs.like'),
      number: 10,
    },
    {
      id: 'dislike',
      icon: <img src={CuriousIcon} alt="altSVGImage" width="20" height="auto" />,
      label: t('evaluation.tabs.dislike'),
      number: 20,
    },
  ])

  const handleClick = (id) => {
    setActive(id)
  }

  return (
    <div className="evaluation__leftCont">
      <div
        className={clsx({
          [`evaluation__leftMain`]: !isProfilePage,
          [`cm__evaWrap`]: isProfilePage,
        })}
      >
        {!isProfilePage ? (
          <>
            <h2 className="evaluation__leftMainTitle">
              {jobPostingDetails.companyName
                ? jobPostingDetails.companyName + t('evaluation.leftMainTitle')
                : t('evaluation.leftMainTitle1')}
            </h2>
            <p className="evaluation__leftMainTitleSup">{t('evaluation.leftMainTitleSup')}</p>
          </>
        ) : null}
        <div className="evaluation__leftEva">
          <EvaluationNav
            items={items}
            active={active}
            onClick={handleClick}
            preset="evaluation"
            profilePage={isProfilePage}
          />
          <ul className="evaluation__leftEvaUl">
            <EvaluationThread activeTab={active} userId={userId} />
          </ul>
        </div>
      </div>
    </div>
  )
}

Evaluations.propTypes = {
  jobPostingId: PropTypes.number,
  userId: PropTypes.number,
}

export default Evaluations

import React from 'react'

import PropTypes from 'prop-types'

let compId = 0

function NotificationSwitch({ id, checked, onChange }) {
  const theId = id ?? `notification-switch-${compId++}`

  return (
    <div className="notification__listSwitch">
      <input type="checkbox" checked={checked} onChange={onChange} id={theId} />
      <label htmlFor={theId}>
        <span></span>
      </label>
      <div className="notification__listSwitchImg"></div>
    </div>
  )
}

NotificationSwitch.propTypes = {
  id: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
}

export default NotificationSwitch

import { useState, useEffect } from 'react'

const isMobile = () => {
  const [windowWith, setWindowWith] = useState(window.innerWidth)

  useEffect(() => {
    const handleWindowSizeChange = () => setWindowWith(window.innerWidth)

    window.addEventListener('resize', handleWindowSizeChange)
    return () => window.removeEventListener('resize', handleWindowSizeChange)
  }, [])

  return windowWith <= 768
}

export default isMobile

import axios from 'axios'
import store from 'store/config'

import { showNotification } from 'store/notification/actionCreators'
import { signOut } from 'store/auth/actionCreators'

// create new instance
const Http = axios.create()

// set default config
Http.defaults.baseURL = process.env.REACT_APP_API_URL
Http.defaults.headers.common.Accept = 'application/json'

/**
 * intercept the response so we can handle the
 * expected exceptions from the API
 */
Http.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    /**
     * This could be a CORS issue or
     * a dropped internet connection.
     */
    const isCancel = error instanceof axios.Cancel
    const silenceErrors = !!error?.config.silenceErrors

    if (typeof error.response === 'undefined') {
      // Suppress if error is cancel
      if (!isCancel && !silenceErrors) {
        alert('A network error occurred.')
      }

      const error = new Error('A network error occurred.')
      error.networkError = true
      // Return a rejected promise to make it consistent for consumers
      return Promise.reject(error)
    }

    switch (error.response.status) {
      // The following case clauses are wrapped into blocks using brackets to avoid eslint no-case-declarations
      case 401: {
        const state = store.getState()

        if (state.auth.isAuthenticated) {
          store.dispatch(signOut())
        }

        break
      }
      case 500:
      case 562:
      case 563:
      case 567:
      case 568:
      case 570: {
        const message = error?.response?.data?.error || 'Unknown Error'

        !silenceErrors && store.dispatch(showNotification(message, { type: 'danger' }))
        break
      }
      default:
        break
    }

    return Promise.reject(error)
  }
)

export default Http

import React, { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Modal from '../Modal'
import PropTypes from 'prop-types'
import identity from 'lodash/identity'
import EllipsisIcon from 'assets/img/threedot.svg'
import { useTranslation } from 'react-i18next'
import { Input, Label, Button, BlockEl } from 'components/atoms'
import { ReactComponent as EditIcon } from 'assets/img/edit-icon2.svg'
import { ReactComponent as GarbageIcon } from 'assets/img/garbage-icon3.svg'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import { MODAL_SEARCH_TYPE } from 'utils/constants'
import { fetchJobPostingFilterSearch, fetchJobPostingFilter, deleteJobPostingFilter } from 'services/jobFilter'
import { showNotification } from 'store/notification/actionCreators'
import { selectEducationalBackgrounds } from 'store/educational-background/selectors'
import find from 'lodash/find'
import { selectChoice } from 'store/choices/selectors'
import { selectFlattenedMedium } from 'store/aboutTheMedium/selectors'
import SearchCriteriaItemModal from './SearchCriteriaItemModal'

const SectionItems = ({ title, items = [] }) => {
  const theItems = items.filter((item) => !!item)
  const { t } = useTranslation()

  if (theItems.length <= 0) return null

  return (
    <dl className="search__cm001LiLiTag">
      <dt className="search__cm001LiLiTagDt">{title}</dt>
      <dd className="search__cm001LiLiTagDd">
        {theItems.slice(0, 3).map((item, index) => {
          return (index ? ', ' : '') + item
        })}
        {theItems.length > 3
          ? `,${t('searchCriteria.moreItems', {
              count: theItems.length - 3,
            })}`
          : ''}
      </dd>
    </dl>
  )
}

SectionItems.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array,
}

function SearchGroupConditionItem({ title, items }) {
  const theItems = items.filter((item) => !!item)

  return (
    <li className="search__cm001LiLi">
      <p className="search__cm001LiLiTitle">{title}</p>
      <BlockEl blockElClass="search__cm001LiLiTagArea">
        {theItems.map((item, i) => (
          <SectionItems {...item} key={i} />
        ))}
      </BlockEl>
    </li>
  )
}

SearchGroupConditionItem.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape(SectionItems.propTypes)),
}

const SearchCriteriaModal = ({
  open,
  onCloseCriteriaModal,
  onEditClick,
  onSelectCriteria,
  handleFilterCloseIconClick,
}) => {
  const { t } = useTranslation()
  const [freeWord, setFreeWord] = useState('')
  const [loading, setLoading] = useState(false)
  const [selectedSearchCriteria, setSelectedSearchCriteria] = useState({})
  const [criteriaToDelete, setCriteriaToDelete] = useState()
  const [searchCriteriaItemModalOpen, setSearchCriteriaItemModalOpen] = useState(false)
  const deleteDialogArea = useRef()
  const educationBackgrounds = useSelector(selectEducationalBackgrounds)
  const chineseLevels = useSelector((state) => state.chineseLevel.items)
  const englishLevels = useSelector((state) => state.englishLevel.items)
  const postingRecruitmentMedia = useSelector((state) => selectChoice(state, 'posting_recruitment_media') || {})
  const sendingScoutEmail = useSelector((state) => selectChoice(state, 'sending_scout_email') || {})
  const aboutTheMedium = useSelector(selectFlattenedMedium)
  const dispatch = useDispatch()

  const modalFilterData = {
    [MODAL_SEARCH_TYPE.industries]: useSelector((state) => state.industries.items),
    [MODAL_SEARCH_TYPE.occupations]: useSelector((state) => state.occupations.items),
    [MODAL_SEARCH_TYPE.workLocations]: useSelector((state) => state.common.prefectureslist),
    [MODAL_SEARCH_TYPE.workPoints]: useSelector((state) => state.workpoints.items),
  }

  const jobFilterOptions = useSelector((state) => state.jobFilter.items)

  const [criteriaList, setCriteriaList] = useState([])

  useEffect(() => {
    if (open) {
      document.addEventListener('click', handleClickOutside)
      setCriteriaList([])
      dispatch(fetchJobPostingFilter())
    } else {
      resetDataAndListeners()
    }
  }, [open])

  const resetDataAndListeners = () => {
    setFreeWord('')
    clearEventListeners()
  }

  const getCriteriaList = () => {
    let data = jobFilterOptions.map((item) => {
      let dateCreated = new Date(item.created_at)
      let dateUpdated = new Date(item.updated_at)
      let registrationDate =
        dateCreated.getFullYear() +
        '/' +
        addLeadingZeros(Number(dateCreated.getMonth()) + 1) +
        '/' +
        addLeadingZeros(dateCreated.getDate())
      let updateDate =
        dateUpdated.getFullYear() +
        '/' +
        addLeadingZeros(Number(dateUpdated.getMonth()) + 1) +
        '/' +
        addLeadingZeros(dateUpdated.getDate())
      const ensureParseJson = (str) => {
        try {
          return JSON.parse(str || '[]') || []
        } catch (e) {
          return []
        }
      }

      let initObject = {
        id: item.id,
        savedSearchCriteria: item.search_criteria,
        registrationDate: registrationDate,
        updateDate: updateDate,
        searchByBasicConditions: cleanEmptyObjects({
          freeWord: item?.keyword,
          availablePosition: getActualNames(item.available_position ?? '{}', MODAL_SEARCH_TYPE.occupations),
          jobIndustry: getActualNames(item.job_industry ?? '{}', MODAL_SEARCH_TYPE.industries),
          workLocations: getActualNames(item.work_locations, MODAL_SEARCH_TYPE.workLocations),
          annualSalary: item?.annual_salary,
          maxEstimatedAnnualSalary: item?.max_estimated_annual_salary,
          minEstimatedAnnualSalary: item?.min_estimated_annual_salary,
          advertisingBudget: ensureParseJson(item.advertising_budget),
        }),
        searchByCharacteristics: cleanEmptyObjects({
          workPoints: getActualNames(item.working_points, MODAL_SEARCH_TYPE.workPoints),
          postPeriod: ensureParseJson(item.post_period),
          employmentForm: JSON.parse(item?.characteristic_employment_status),
          numberOfHires: JSON.parse(item?.number_of_positions),
          adoptHopePeriod: JSON.parse(item?.desired_period_of_employment),
        }),
        searchByDetailedConditions: cleanEmptyObjects({
          employmentForm: JSON.parse(item?.detailed_employment_status),
          numberOfEmployees: JSON.parse(item?.number_of_employees),
          workingStyles: JSON.parse(item?.type_of_work ?? '[]') || [],
          minAcceptableAge: item?.min_acceptable_age,
          maxAcceptableAge: item?.max_acceptable_age,
          changedJobCount: item?.changed_job_count,
          educationalBackground: JSON.parse(item?.education_background) || [],
          assumedGender: JSON.parse(item?.assumed_gender),
          englishLevel: JSON.parse(item?.english_level),
          chineseLevel: JSON.parse(item?.chinese_level),
          japaneseLevel: JSON.parse(item?.japanese_level),
          yearEstablished: ensureParseJson(item?.year_established),
          closingMonth: ensureParseJson(item?.closing_month),
        }),
        searchByPublicInformation: cleanEmptyObjects({
          postingRecruitmentMedia: JSON.parse(item?.posting_recruitment_media),
          sendingScoutEmail: JSON.parse(item?.sending_scout_email),
        }),
        searchByPublicationMedia: cleanEmptyObjects({
          currentMedia: ensureParseJson(item?.current_media),
          pastPublicationMedia: ensureParseJson(item?.past_publication_media),
          mediaOfInterest: ensureParseJson(item?.media_of_interest),
          presenceAbsenceAuthorizer: item?.presence_absence_authorizer,
        }),
      }
      return initObject
    })
    setCriteriaList(data)
    setLoading(false)
  }

  useEffect(() => {
    cmSerarchFuncEve()
    cmSearchFuncEve2()
  }, [criteriaList])

  useEffect(() => {
    getCriteriaList()
  }, [jobFilterOptions])

  //deletes objects that have empty children for rendering purposes
  const cleanEmptyObjects = (obj) => {
    for (var propName in obj) {
      if (Array.isArray(obj[propName])) {
        if (obj[propName].length === 0) delete obj[propName]
      } else if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName]
      }
    }
    return Object.keys(obj).length === 0 ? null : obj
  }

  const addLeadingZeros = (n) => {
    if (n <= 9) {
      return '0' + n
    }
    return n
  }

  const handleSearch = () => {
    setLoading(true)
    setCriteriaList([])
    dispatch(fetchJobPostingFilterSearch(freeWord)).catch((err) => {
      console.log(err)
      dispatch(fetchJobPostingFilter())
    })
    handleFilterCloseIconClick()
  }

  const handleDeleteJobFilter = (id) => {
    setLoading(true)
    dispatch(deleteJobPostingFilter(id)).then((res) => {
      if (res?.error?.code > 200) {
        dispatch(
          showNotification(res.error?.error ? res.error?.error : t('searchCriteria.deleteCriteriaFail'), {
            type: 'danger',
          })
        )
        setLoading(false)
      } else {
        dispatch(showNotification(t('searchCriteria.deleteCriteriaSuccess'), { type: 'success' }))
        handleSearch()
      }
      setCriteriaToDelete()
      setSearchCriteriaItemModalOpen(false)
    })
  }

  const deleteCriteriaPrompt = (criteriaId) => {
    setCriteriaToDelete(criteriaId)
  }

  const cmSerarchFuncEve = () => {
    const searchCm001Li = document.querySelectorAll('.search__cm001Li')
    if (searchCm001Li.length !== 0) {
      searchCm001Li.forEach((item) => {
        const searchCm001LiLi = item.querySelectorAll('.search__cm001LiLi')

        if (searchCm001LiLi.length > 1) {
          const searchCm001DropMenuWrap = item.getElementsByClassName('search__cm001DropMenuWrap')[0]
          searchCm001DropMenuWrap.style.display = 'block'

          const ulHeight = searchCm001LiLi[0].clientHeight
          const searchCm001LiUl = item.getElementsByClassName('search__cm001LiUl')[0]
          searchCm001LiUl.style.maxHeight = `${ulHeight}px`
          searchCm001DropMenuWrap.setAttribute('data-h', ulHeight)

          // tag数え
          let tags = 0
          searchCm001LiLi.forEach((list, index) => {
            if (index !== 0) {
              const searchCm001LiLiTag = list.querySelectorAll('.search__cm001LiLiTag')
              searchCm001LiLiTag.forEach(() => {
                tags++
              })
            }
          })

          searchCm001DropMenuWrap.getElementsByClassName('search__cm001DropMenu')[0].classList.remove('open')
          searchCm001DropMenuWrap.getElementsByClassName('search__cm001DropMenu')[0].textContent = t(
            'searchCriteria.seeAllTags',
            { tags }
          )
          searchCm001DropMenuWrap.setAttribute('data-num', tags)
        }
      })
    }
  }

  const clearEventListeners = () => {
    document.removeEventListener('click', handleClickOutside)
    const searchCm001DropMenu = document.querySelectorAll('.search__cm001DropMenu')
    if (searchCm001DropMenu.length !== 0) {
      searchCm001DropMenu.forEach((btn) => {
        btn.removeEventListener('click', () => {})
      })
    }
  }

  const cmSearchFuncEve2 = () => {
    const searchCm001DropMenu = document.querySelectorAll('.search__cm001DropMenu')
    if (searchCm001DropMenu.length !== 0) {
      searchCm001DropMenu.forEach((btn) => {
        btn.addEventListener('click', (e) => {
          if (e.currentTarget.classList.contains('open')) {
            e.currentTarget.classList.remove('open')
            const thisH = e.currentTarget.parentNode.getAttribute('data-h')
            const thisNum = e.currentTarget.parentNode.getAttribute('data-num')
            e.currentTarget.parentNode.previousElementSibling.style.maxHeight = `${thisH}px`
            const target = e.currentTarget
            setTimeout(() => {
              target.textContent = t('searchCriteria.seeAllTags', { tags: thisNum })
            }, 200)
          } else {
            e.currentTarget.classList.add('open')
            e.currentTarget.parentNode.previousElementSibling.style.maxHeight = '5000px'
            e.currentTarget.textContent = t('candidateSlideout.close')
          }
        })
      })
    }
  }

  const handleClickOutside = (event) => {
    const path = event.path || (event.composedPath && event.composedPath())

    if (!path.includes(deleteDialogArea.current)) {
      setCriteriaToDelete()
    }
  }

  const getActualNames = (values, typeName) => {
    let mergedChildren = []
    const actualNames = []

    const parsedValues = Object.keys(JSON.parse(values))
    const parsedChildrenValues = Object.values(JSON.parse(values))?.flat()
    if (typeName === MODAL_SEARCH_TYPE.workLocations) {
      modalFilterData[typeName].map((item) => {
        if (parsedChildrenValues.includes(item.id)) {
          actualNames.push(item.prefecture)
        }
      })
    } else {
      const parent = modalFilterData[typeName].filter((item) => {
        if (parsedValues.includes(item.id.toString())) {
          return item
        }
      })

      if (typeName === MODAL_SEARCH_TYPE.workPoints) {
        parent.map((item) => {
          mergedChildren = [...mergedChildren, item.descendants]
        })
      } else {
        parent.map((item) => {
          mergedChildren = [...mergedChildren, item.children]
        })
      }

      Object.values(mergedChildren)
        .flat()
        .map((item) => {
          if (parsedChildrenValues.includes(item.id)) {
            actualNames.push(item.name)
          }
        })
    }

    return actualNames
  }

  const adoptHopePeriodToLabel = (key) => t(`jobPosting.search.${key}`)
  const isNumeric = (value) => !isNaN(value - parseFloat(value))
  const listAboutTheMedium = (ids) => (ids || []).map((id) => find(aboutTheMedium, { id: +id })?.name)

  const searchCriteriaItemOptionClick = (searchCriteria) => {
    setSelectedSearchCriteria(searchCriteria)
    setSearchCriteriaItemModalOpen(true)
  }

  const handleSearchCriteriaItemModalClose = () => {
    setSearchCriteriaItemModalOpen(false)
  }

  const handleEditClick = (searchCriteria) => {
    setSearchCriteriaItemModalOpen(false)
    onEditClick(searchCriteria)
  }

  return (
    <>
      <Modal
        containerClass="modal__contSlideCont-spUp modal__contSlideCont-full modalCont"
        containerType="contSlideCont2"
        innerProps={{ modifier: 'lt' }}
        open={open}
        onClose={() => onCloseCriteriaModal()}
      >
        <Modal.Header modifier="fixed" className="pc">
          <Modal.TitleWrap>
            <Modal.Title modifier="mgb0">
              <span className="modal__contTitle-mgr">{t('searchCriteria.savedSearchCriteria')}</span>
              <span className="modal__contTitle-num">{criteriaList.length}</span>
              <span className="modal__contTitle-mini">{t('candidateSlideout.item')}</span>
            </Modal.Title>
          </Modal.TitleWrap>
          <Modal.CloseButton onClick={() => onCloseCriteriaModal()} />
        </Modal.Header>
        <Modal.Header modifier="fixed pd" className="sp sp-flex">
          <a href="" className="spHeader__title spHeader__title-link modalClose" onClick={() => onCloseCriteriaModal()}>
            {t('searchCriteria.savedSearchCriteria')}&emsp;{criteriaList.length}
            {t('suffixes.subject')}
          </a>
        </Modal.Header>
        <BlockEl blockElClass="search__cm001SeachArea">
          <Label blockElClass="search__cm001SeachLabel">{t('searchCriteria.freeWord')}</Label>
          <Input
            type="text"
            blockElClass="search__cm001SeachInput"
            placeholder={t('jobPosting.search.searchFor')}
            value={freeWord}
            onChange={(e) => setFreeWord(e.target.value)}
          />
          <Button
            className={clsx('btn__shadowDissableBtn', {
              'btn__shadowDissableBtn-active': !loading,
            })}
            variant="shadowDissable"
            onClick={handleSearch}
            disabled={loading}
          >
            {t('jobPosting.search.searchFor')}
          </Button>
        </BlockEl>
        <ul className="search__cm001Ul">
          {criteriaList.map((item, index) => (
            <li className="search__cm001Li" key={index}>
              <BlockEl blockElClass="search__cm001LiHeader">
                <p className="search__cm001LiTitle">{item?.savedSearchCriteria}</p>
                <BlockEl blockElClass="search__cm001LiHeaderRight">
                  <p className="search__cm001LiHeaderDay search__cm001LiHeaderDay-top">
                    {t('jobPosting.createdAt')}&#65306;{item.registrationDate}
                  </p>
                  <p className="search__cm001LiHeaderDay">
                    {t('jobPosting.updatedAt')}&#65306;{item.updateDate}
                  </p>
                </BlockEl>
              </BlockEl>
              <ul className="search__cm001LiUl">
                {item.searchByBasicConditions && (
                  <SearchGroupConditionItem
                    title={t('jobPosting.search.basicCondition')}
                    items={[
                      {
                        title: t('searchCriteria.freeWord'),
                        items: [item?.searchByBasicConditions?.freeWord],
                      },
                      {
                        title: t('jobPosting.search.jobIndustry'),
                        items: item?.searchByBasicConditions?.jobIndustry,
                      },
                      {
                        title: t('jobPosting.search.availablePosition'),
                        items: item?.searchByBasicConditions?.availablePosition,
                      },
                      {
                        title: t('jobPosting.search.workLocations'),
                        items: item?.searchByBasicConditions?.workLocations,
                      },
                      {
                        title: t('jobPosting.search.estimatedAnnualIncome'),
                        items: [
                          isNumeric(item?.searchByBasicConditions?.annualSalary)
                            ? item?.searchByBasicConditions?.annualSalary
                            : [
                                isNumeric(item?.searchByBasicConditions?.minEstimatedAnnualSalary),
                                isNumeric(item?.searchByBasicConditions?.maxEstimatedAnnualSalary),
                              ].every(identity) &&
                              t('jobPosting.card.min_max_yen', {
                                min: item?.searchByBasicConditions?.minEstimatedAnnualSalary,
                                max: item?.searchByBasicConditions?.maxEstimatedAnnualSalary,
                              }),
                        ],
                      },
                      {
                        title: t('jobVacancies.basicInformation.advertisingBudget'),
                        items: (item.searchByBasicConditions.advertisingBudget || []).map((item) =>
                          t(`jobPosting.search.${item}`)
                        ),
                      },
                    ]}
                  />
                )}
                {item.searchByPublicationMedia && (
                  <SearchGroupConditionItem
                    title={t('jobPosting.search.publicationMedium')}
                    items={[
                      {
                        title: t('newJobPostProposal.step1.currentPostMedia'),
                        items: listAboutTheMedium(item.searchByPublicationMedia?.currentMedia),
                      },
                      {
                        title: t('jobPosting.search.pastPublicationMedium'),
                        items: listAboutTheMedium(item.searchByPublicationMedia?.pastPublicationMedia),
                      },
                      {
                        title: t('jobPosting.search.mediaOfInterest'),
                        items: listAboutTheMedium(item.searchByPublicationMedia?.mediaOfInterest),
                      },
                      {
                        title: t('jobPosting.search.presenceOrAbsenceOfApprover'),
                        // Empty string to display without list values
                        items: item.searchByPublicationMedia.presenceAbsenceAuthorizer ? [' '] : [],
                      },
                    ]}
                  />
                )}
                {item.searchByCharacteristics && (
                  <SearchGroupConditionItem
                    title={t('jobPosting.search.searchByCharacteristics')}
                    items={[
                      {
                        title: t('jobPosting.search.postPeriod'),
                        items: (item.searchByCharacteristics?.postPeriod || []).map((key) =>
                          t(`jobPosting.search.${key}`)
                        ),
                      },
                      {
                        title: t('jobPosting.search.pointOfWorkingStyle'),
                        items: item.searchByCharacteristics.workPoints,
                      },
                      {
                        title: t('jobPosting.search.employmentStatus'),
                        items: item.searchByCharacteristics.employmentForm,
                      },
                      {
                        title: t('jobPosting.search.noOfHires'),
                        items: item.searchByCharacteristics.numberOfHires,
                      },
                      {
                        title: t('jobPosting.search.desiredTimeToHire'),
                        items: (item.searchByCharacteristics.adoptHopePeriod || []).map(adoptHopePeriodToLabel),
                      },
                    ]}
                  />
                )}
                {item.searchByDetailedConditions && (
                  <SearchGroupConditionItem
                    title={t('searchCriteria.detailedConditions')}
                    items={[
                      {
                        title: t('jobPosting.search.employmentStatus'),
                        items: item.searchByDetailedConditions.employmentForm,
                      },
                      {
                        title: t('jobPosting.search.companySize'),
                        items: item.searchByDetailedConditions.numberOfEmployees,
                      },
                      {
                        title: t('jobPosting.search.workingStyle'),
                        items: item.searchByDetailedConditions.workingStyles,
                      },
                      {
                        title: t('jobPosting.search.assumedAge'),
                        items: [
                          item.searchByDetailedConditions.maxAcceptableAge &&
                            t('jobPosting.card.yearOld', {
                              min: item.searchByDetailedConditions.minAcceptableAge,
                              max: item.searchByDetailedConditions.maxAcceptableAge,
                            }),
                        ],
                      },
                      {
                        title: t('jobPosting.search.assumedGender'),
                        items: [
                          item.searchByDetailedConditions.changedJobCount &&
                            t('jobPosting.card.changedJobCount', {
                              value: item.searchByDetailedConditions.changedJobCount,
                            }),
                        ],
                      },
                      {
                        title: t('jobPosting.search.educationalBackground'),
                        items: (item.searchByDetailedConditions.educationalBackground || []).map(
                          (id) => find(educationBackgrounds, { id: +id })?.name
                        ),
                      },
                      {
                        title: t('jobPosting.search.assumedGender'),
                        items: item.searchByDetailedConditions.assumedGender,
                      },
                      {
                        title: t('jobPosting.search.englishLevel'),
                        items: (item.searchByDetailedConditions.englishLevel || []).map(
                          (id) => find(englishLevels, { id: +id })?.name
                        ),
                      },
                      {
                        title: t('jobPosting.search.chineseLevel'),
                        items: (item.searchByDetailedConditions.chineseLevel || []).map(
                          (id) => find(chineseLevels, { id: +id })?.name
                        ),
                      },
                      {
                        title: t('jobPosting.search.yearsEstablished'),
                        items: (item.searchByDetailedConditions.yearEstablished || []).map((key) =>
                          t(`jobPosting.search.${key}`)
                        ),
                      },
                      {
                        title: t('jobPosting.search.yearsEstablished'),
                        items: (item.searchByDetailedConditions.closingMonth || []).map((value) =>
                          t(`corporateInfo.closingMonthVal`, { months: value })
                        ),
                      },
                    ]}
                  />
                )}
                {item?.searchByPublicInformation && (
                  <SearchGroupConditionItem
                    title={t('jobPosting.search.publicInformation')}
                    items={[
                      {
                        title: t('jobPosting.search.postingOnMedia'),
                        items: (item.searchByPublicInformation.postingRecruitmentMedia || []).map(
                          (key) => (postingRecruitmentMedia && postingRecruitmentMedia[key]) || ''
                        ),
                      },
                      {
                        title: t('jobPosting.search.sendScoutEmail'),
                        items: (item.searchByPublicInformation.sendingScoutEmail || []).map(
                          (key) => (sendingScoutEmail && sendingScoutEmail[key]) || ''
                        ),
                      },
                    ]}
                  />
                )}
              </ul>
              <BlockEl blockElClass="search__cm001DropMenuWrap">
                <p className="search__cm001DropMenu"></p>
              </BlockEl>
              <BlockEl blockElClass="search__cm001LiLiBtnArea">
                <p
                  className="search__mainContFooterDot popModal noModlaClose"
                  data-modal="modal2"
                  data-modalcont="sakujoModal"
                  onClick={() => searchCriteriaItemOptionClick(item)}
                >
                  <img src={EllipsisIcon} alt="" className="search__mainContFooterDotImg" width="20" height="8" />
                </p>
                <Button
                  onClick={(e) => {
                    e.stopPropagation()
                    deleteCriteriaPrompt(item.id)
                  }}
                  modifier="mgr"
                  variant="text"
                  variantMod="red"
                  disabled={loading}
                  className="searchCm001DelBtn pc"
                >
                  <span className="btn__btnIcon">
                    <GarbageIcon />
                  </span>
                  {t('common.delete')}
                  <div
                    ref={deleteDialogArea}
                    className={clsx('candidate__modalTabMemoDelArea', {
                      'candidate__modalTabMemoDelArea-open': criteriaToDelete === item.id,
                    })}
                  >
                    <p className="candidate__modalTabMemoText">{t('common.confirmDeleteQuestion')}</p>
                    <Link
                      to="#"
                      onClick={(e) => {
                        e.preventDefault()
                        if (!loading) handleDeleteJobFilter(item.id, e)
                      }}
                      className="candidate__modalTabMemoDelLink"
                    >
                      {t('common.delete')}
                    </Link>
                  </div>
                </Button>
                <Button modifier="iconL mgr" variant="outline" onClick={() => handleEditClick(item)} className="pc">
                  <span className="btn__btnIcon">
                    <EditIcon />
                  </span>
                  {t('common.edit')}
                </Button>
                <Button
                  modifier="sph32"
                  className={clsx('btn__shadowDissableBtn', {
                    'btn__shadowDissableBtn-active': !loading,
                  })}
                  onClick={() => {
                    onSelectCriteria(item.savedSearchCriteria)
                    onCloseCriteriaModal()
                  }}
                  variant="shadowDissable"
                  disabled={loading}
                >
                  {t('searchCriteria.SearchByThisCondition')}
                </Button>
              </BlockEl>
            </li>
          ))}
        </ul>
      </Modal>
      <SearchCriteriaItemModal
        searchCriteria={selectedSearchCriteria}
        open={searchCriteriaItemModalOpen}
        onClose={handleSearchCriteriaItemModalClose}
        onEdit={handleEditClick}
        onDelete={handleDeleteJobFilter}
      />
    </>
  )
}

SearchCriteriaModal.propTypes = {
  open: PropTypes.bool,
  onCloseCriteriaModal: PropTypes.func,
  onEditClick: PropTypes.func,
  onSelectCriteria: PropTypes.func,
  handleFilterCloseIconClick: PropTypes.func,
}

export default SearchCriteriaModal

import produce from 'immer'
import { AUTH_SIGNOUT } from 'store/auth/actionTypes'
import * as ActionTypes from './actionTypes'

const initialState = {
  fetching: false,
  items: [],
  search: {
    page: 1,
    limit: 10,
  },
  pagination: {
    total: 0,
    currentPage: 1,
    lastPage: 1,
  },
  controls: {
    filterModal: false,
  },
}

export default produce((draft, { type, payload }) => {
  switch (type) {
    case ActionTypes.PATCH_USER_LOGS_SEARCH_CRITERIA:
      draft.search = { ...draft.search, ...payload }
      return draft
    case ActionTypes.LOAD_USER_LOGS_REQUEST:
      draft.fetching = true
      return draft
    case ActionTypes.LOAD_USER_LOGS_SUCCESS:
      draft.fetching = false
      draft.items = payload.data
      draft.pagination = {
        total: +payload.meta.total,
        lastPage: +payload.meta.lastPage,
        currentPage: +payload.meta.currentPage,
      }
      return draft
    case ActionTypes.LOAD_USER_LOGS_FAILURE:
      draft.fetching = false
      return draft
    case ActionTypes.SET_USER_LOGS_FILTER:
      draft.controls.filterModal = payload
      break
    case AUTH_SIGNOUT:
      return initialState
    default:
      return draft
  }
}, initialState)

import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { BlockEl, Button, TitleRow } from 'components'

const FeaturedArticles = ({ articles }) => {
  const { t } = useTranslation()
  const handleSeemoreClick = () => {}
  const titleCharLimit = 30
  const sentenceCharLimit = 64

  const Article = ({ article }) => {
    const { id, title, lead_sentence } = article
    return (
      <Button blockElClass="fArticle__list__item" component="a" href={`/article/${id}`}>
        <BlockEl blockElClass="fArticle__list__item-title">
          {title.length > titleCharLimit ? `${title.slice(0, titleCharLimit)}...` : title}
        </BlockEl>
        {lead_sentence.length > sentenceCharLimit ? (
          <BlockEl component={'p'} blockElClass="fArticle__list__item-text">
            {lead_sentence.slice(0, sentenceCharLimit)}
            <br />
            {lead_sentence.slice(sentenceCharLimit).slice(0, titleCharLimit)}...
          </BlockEl>
        ) : (
          <BlockEl component={'p'} blockElClass="fArticle__list__item-text">
            {lead_sentence}
          </BlockEl>
        )}
      </Button>
    )
  }
  Article.propTypes = {
    article: PropTypes.object,
  }

  return (
    <>
      <TitleRow
        title={t('dashboard.featuredArticles.title')}
        handleSeemoreClick={handleSeemoreClick}
        seeMoreFlag={true}
      />
      <BlockEl blockElClass="fArticle__list">
        {articles.map((article, index) => (
          <Article article={article} key={index} />
        ))}
      </BlockEl>
    </>
  )
}

FeaturedArticles.propTypes = {
  articles: PropTypes.array,
}

export default FeaturedArticles

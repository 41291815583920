import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Modal, FormGroupCheck } from 'components/molecules'
import { Checkbox, Button, BlockEl } from 'components/atoms'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'

const ApplicantsModal = (props) => {
  const { register } = useFormContext()
  const {
    handleOnClose,
    open,
    onHandleCheckAll,
    onHandleCheck,
    applicantsList,
    onIsChecked,
    selectedApplicants,
    onDecideHandle,
    handleClearSelection,
  } = props

  const { t } = useTranslation()

  return (
    <Modal
      onClose={handleOnClose}
      open={open}
      containerBlockElClass="modal__adCont-w331 modal__cont modal__adCont"
      containerType="adCont"
    >
      <BlockEl blockElClass="modal__choiceContInner" className="choiceModal">
        <Modal.Header blockElClass="modal__contHeader modal__contHeader-pd">
          <Modal.Title>{t('tableHeaders.candidateName')}</Modal.Title>
          <Modal.CloseButton onClick={handleOnClose} blockElClass="selectionModal__close" />
        </Modal.Header>
        <BlockEl blockElClass="modal__contMain">
          <BlockEl blockElClass="modal__contMainRight" className="modal__contMainRight1" modifier="sp">
            <BlockEl
              blockElClass="modal__contMainRightCont"
              modifier="active"
              noBlockEl
              style={{ paddingBottom: 15 + 'px' }}
            >
              <FormGroupCheck.Item
                Input={Checkbox}
                inputProps={{
                  value: 0,
                  onChange: onHandleCheckAll,
                  blockElClass: 'form__checkItemModal',
                  className: clsx({ checked: applicantsList.length === selectedApplicants.length }),
                }}
                label={t(`jobPosting.search.modal.selectAll`)}
                type="square"
              />
              <BlockEl blockElClass="modal__contMainRightInner">
                {applicantsList?.map((row, index) => (
                  <FormGroupCheck.Item
                    key={index}
                    Input={Checkbox}
                    className="candidate__conditonCheckItem"
                    inputProps={{
                      ...register('applicant'),
                      value: row.id,
                      onChange: onHandleCheck,
                      blockElClass: `form__checkItemModal`,
                      className: clsx({ checked: onIsChecked(row.id) }),
                    }}
                    label={`${row.lastname} ${row.firstname}`}
                    type="square"
                  />
                ))}
              </BlockEl>
            </BlockEl>
          </BlockEl>
        </BlockEl>
        <Modal.Footer blockElClass="modal__adContFooter">
          <Button type="button" variant="outline" onClick={handleClearSelection} className="btn-mgr">
            {t('jobPosting.search.modal.clear')}
          </Button>
          <Button onClick={onDecideHandle} type="submit" variant="contained" className="btn btn__shadowBtn btn-spW100">
            {t('jobPosting.search.modal.decide')}
          </Button>
        </Modal.Footer>
      </BlockEl>
    </Modal>
  )
}

ApplicantsModal.propTypes = {
  handleOnClose: PropTypes.func,
  onHandleCheckAll: PropTypes.func,
  onHandleCheck: PropTypes.func,
  open: PropTypes.bool,
  applicantsList: PropTypes.array,
  selectedApplicants: PropTypes.array,
  onIsChecked: PropTypes.func,
  onDecideHandle: PropTypes.func,
  handleClearSelection: PropTypes.func,
}

export default ApplicantsModal

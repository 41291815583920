import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useForm, FormProvider } from 'react-hook-form'
import { Modal } from 'components'
import { Button } from 'components/atoms'
import FormFields from './FormFields'
import PrefectureModal from './PrefectureModal'
import JobSearchModalTab from '../JobSearchModalTab'
import CircularProgress from '@mui/material/CircularProgress'
import { MODAL_SEARCH_TYPE } from 'utils/constants'
import { fetchJobPostingFilter, createJobPostingFilter } from 'services/jobFilter'
import { actionUpdateJobSearchFilters } from 'store/job-postings/actionCreators'
import { actionAddJobFilterCriteria } from 'store/job-filter/actionCreators'
import { showNotification } from 'store/notification/actionCreators'
import { useIsMobile } from 'utils/hooks'

function JobPostingModal(props) {
  const { open, onClose, modalData, onClickList, activeListKey, onSaveSearchCriteria, onSubmitSearch, onClear } = props
  const methods = useForm({ mode: 'onChange' })
  const { title, isTabListSearch, name } = modalData
  const jobFilters = useSelector((state) => state.jobPostings.jobPostingSearch)
  const activeModal = useSelector((state) => state.jobPostings.jobPostingSearch.activeModal)
  const { filterCriteria } = useSelector((state) => state.jobFilter)
  const [disableSubmit, setDisableSubmit] = useState(false)
  const modalFilterData = {
    [MODAL_SEARCH_TYPE.industries]: useSelector((state) => state.industries.items),
    [MODAL_SEARCH_TYPE.occupations]: useSelector((state) => state.occupations.items),
    [MODAL_SEARCH_TYPE.workLocations]: useSelector((state) => state.common.prefectureslist),
    [MODAL_SEARCH_TYPE.workPoints]: useSelector((state) => state.workpoints.items),
  }
  const {
    formState: { isSubmitting, isDirty, isValid },
    handleSubmit,
    setError,
    reset,
  } = methods
  const isMobile = useIsMobile()

  useEffect(() => {
    setDisableSubmit(false)
  }, [activeModal])

  useEffect(() => {
    setDisableSubmit(false)
  }, [])

  const { t } = useTranslation()

  const submitSearchCriteria = async (searchCriteria) => {
    onSaveSearchCriteria(searchCriteria)
    const filter = Object.assign({}, filterCriteria)
    filter['search_criteria'] = searchCriteria
    setDisableSubmit(true)
    const { error, data } = await dispatch(createJobPostingFilter({ ...filter }))
    if (data && data.code === 200) {
      const filterCriterias = data.data
      dispatch(fetchJobPostingFilter())
      dispatch(actionAddJobFilterCriteria({ ...filterCriterias }))
      dispatch(actionUpdateJobSearchFilters({ filterCriterias }))
      onClose()
      reset()
      setDisableSubmit(false)
    } else {
      setDisableSubmit(false)
    }

    if (error) {
      if (error.code === 422) {
        setError('searchCriteria', { type: 'custom', message: error.error['search_criteria'][0] })
        return
      }

      dispatch(showNotification(error.error, { type: 'danger' }))
    }
  }
  const onSubmit = async (data) => {
    if (data.searchCriteria) {
      await submitSearchCriteria(data.searchCriteria)
    } else {
      onSubmitSearch()
      onClose()
    }
  }

  const handleOnClear = async (typeName) => {
    //hard copy
    let tempFilter = JSON.stringify(jobFilters)
    let defaultFilter = JSON.parse(tempFilter)
    defaultFilter.activeModal = ''
    delete defaultFilter?.activeFilterModal[typeName]
    defaultFilter.filters[typeName] = []
    dispatch(actionUpdateJobSearchFilters(defaultFilter))
    setDisableSubmit(false)
    onSubmitSearch()
    onClose()
    onClear(typeName)
  }

  const handleOnClose = () => {
    onClose()
  }

  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [isValidBtn, setIsValidBtn] = useState(false)
  useEffect(() => {
    switch (name) {
      case MODAL_SEARCH_TYPE.industries:
        setLoading(true)
        setLoading(!modalFilterData[MODAL_SEARCH_TYPE.industries].length > 0)
        break
      case MODAL_SEARCH_TYPE.occupations:
        setLoading(true)
        setLoading(!modalFilterData[MODAL_SEARCH_TYPE.occupations].length > 0)
        break
      case MODAL_SEARCH_TYPE.workLocations:
        setLoading(true)
        if (modalFilterData[MODAL_SEARCH_TYPE.workLocations] !== null) {
          setLoading(!Object.keys(modalFilterData[MODAL_SEARCH_TYPE.workLocations]).length > 0)
        }
        break
      case MODAL_SEARCH_TYPE.workPoints:
        setLoading(true)
        setLoading(!modalFilterData[MODAL_SEARCH_TYPE.workPoints].length > 0)
        break
      default:
        setLoading(true)
        return
    }
  }, [name, modalFilterData])

  useEffect(() => {
    if (activeModal !== MODAL_SEARCH_TYPE.workLocations) {
      setIsValidBtn(jobFilters.filters[activeModal] && Object.keys(jobFilters.filters[activeModal]).length > 0)
    } else {
      setIsValidBtn(jobFilters.filters[activeModal] && jobFilters.filters[activeModal].length > 0)
    }
  }, [jobFilters])

  const handleModalSearchCheckOptions = (e) => {
    const filterParentIndex = e.target.dataset.index
    const filterChildrenIndex = parseInt(e.target.value)
    const modalTypeName = e.target.name

    const filters = Object.assign(
      {},
      typeof jobFilters.filters === 'object' ? jobFilters.filters : JSON.parse(jobFilters.filters)
    )

    const filterList = Object.assign(
      {},
      typeof filters[modalTypeName] === 'object' ? filters[modalTypeName] : JSON.parse(filters[modalTypeName])
    )

    let selectedItem = {}
    if (filterParentIndex in filterList) {
      if (filterList[filterParentIndex].includes(filterChildrenIndex)) {
        const insertIndex = filterList[filterParentIndex].filter((item) => item !== filterChildrenIndex)
        selectedItem[filterParentIndex] = insertIndex
      } else {
        selectedItem[filterParentIndex] = [...filterList[filterParentIndex], filterChildrenIndex]
      }
    } else {
      selectedItem[filterParentIndex] = [filterChildrenIndex]
    }
    filters[modalTypeName] = { ...filters[modalTypeName], ...selectedItem }
    dispatch(actionUpdateJobSearchFilters({ filters }))
  }

  const handleCheckAll = (e) => {
    const selected = parseInt(e.target.value)
    const isChecked = !!e.target.checked
    const filters = Object.assign({}, jobFilters.filters)
    let selectedItem = {}
    const selectOptions = modalFilterData[name].filter((items) => items.id === selected)
    let selectAllOption =
      name === MODAL_SEARCH_TYPE.workPoints ? selectOptions[0].descendants : selectOptions[0].children
    const selectAll = selectAllOption.map((item) => item.id)
    if (isChecked) {
      selectedItem[selected] = selectAll
    } else {
      selectedItem[selected] = []
    }
    filters[name] = { ...filters[name], ...selectedItem }
    dispatch(actionUpdateJobSearchFilters({ filters }))
  }

  const isChecked = (index, value) => {
    let isChecked = false
    isChecked =
      typeof jobFilters.filters[name] === 'object'
        ? Object.keys(jobFilters.filters[name]).length > 0 &&
          jobFilters.filters[name][index] &&
          jobFilters.filters[name][index].includes(value)
        : Object.keys(JSON.parse(jobFilters.filters[name])).length > 0 &&
          JSON.parse(jobFilters.filters[name])[index] &&
          JSON.parse(jobFilters.filters[name])[index].includes(value)

    return isChecked
  }

  const ModalPc = () => (
    <div className="modal__choiceContInner1 choiceModal">
      <Modal.Header blockElClass="modal__contHeader modal__contHeader-pd">
        <Modal.Title>{title}</Modal.Title>
        <Modal.CloseButton onClick={handleOnClose} blockElClass="selectionModal__close" />
      </Modal.Header>
      <FormProvider {...methods}>
        {Object.keys(modalFilterData).map((modal, index) => {
          if (modal !== MODAL_SEARCH_TYPE.workLocations) {
            return (
              activeModal === modal && (
                <JobSearchModalTab
                  key={index}
                  lists={modalFilterData[modal]}
                  onHandleActiveList={onClickList}
                  activeList={activeListKey}
                  onHandleCheck={handleModalSearchCheckOptions}
                  onIsChecked={isChecked}
                  onHandleCheckAll={handleCheckAll}
                />
              )
            )
          } else {
            return activeModal === modal && <PrefectureModal key={index} name={modal} lists={modalFilterData[modal]} />
          }
        })}
      </FormProvider>
      <Modal.Footer blockElClass="modal__adContFooter">
        <Button type="button" variant="outline" onClick={() => handleSubmit(handleOnClear(modalData?.name))}>
          {t('jobPosting.search.modal.clear')}
        </Button>
        <Button
          disabled={!isValidBtn || isSubmitting || disableSubmit}
          type="submit"
          modifier="mgl16"
          variant={!isValidBtn || isSubmitting || disableSubmit ? 'yellowDessable' : 'shadow'}
        >
          {t('jobPosting.search.modal.decide')}
        </Button>
      </Modal.Footer>
    </div>
  )

  const ModalSp = () => (
    <div className="modal__choiceContInner choiceModal">
      <Modal.Header blockElClass="modal__contHeader modal__contHeader-pd">
        <Button
          component="a"
          className="spHeader__title spHeader__title-link sp sp-flex modalClose"
          onClick={handleOnClose}
        >
          {title}
        </Button>
      </Modal.Header>
      <div className="modal__contMain modal__contMain-flex">
        <FormProvider {...methods}>
          {Object.keys(modalFilterData).map((modal, index) => {
            if (modal !== MODAL_SEARCH_TYPE.workLocations) {
              return (
                activeModal === modal && (
                  <JobSearchModalTab
                    key={index}
                    lists={modalFilterData[modal]}
                    onHandleActiveList={onClickList}
                    activeList={activeListKey}
                    onHandleCheck={handleModalSearchCheckOptions}
                    onIsChecked={isChecked}
                    onHandleCheckAll={handleCheckAll}
                  />
                )
              )
            } else {
              return (
                activeModal === modal && <PrefectureModal key={index} name={modal} lists={modalFilterData[modal]} />
              )
            }
          })}
        </FormProvider>
      </div>
      <Modal.Footer blockElClass="modal__adContFooter">
        <Button
          type="button"
          className="btn checkClear numClear"
          modifier="miniPd mgr"
          variant="outline"
          onClick={() => handleOnClear(modalData?.name)}
        >
          {t('jobPosting.search.modal.clear')}
        </Button>
        <Button
          disabled={!isValidBtn || isSubmitting || disableSubmit}
          type="submit"
          className="modalClose checkDecide"
          modifier="spW100"
          variant={!isValidBtn || isSubmitting || disableSubmit ? 'yellowDessable' : 'shadow'}
        >
          {t('jobPosting.search.modal.decide')}
        </Button>
      </Modal.Footer>
    </div>
  )

  const ModalContent = () => (isMobile ? <ModalSp /> : <ModalPc />)

  return (
    <Modal
      onClose={handleOnClose}
      open={open}
      containerType={isTabListSearch ? 'adCont' : 'contC'}
      className="modal__contC-w474"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        {!isTabListSearch ? (
          <>
            <Modal.Body>
              <Modal.Title>{title}</Modal.Title>
              <Modal.Text>{modalData.note}</Modal.Text>
              <FormProvider {...methods}>
                <FormFields />
              </FormProvider>
            </Modal.Body>
            <Modal.Footer>
              <Button type="button" variant="outline" onClick={handleOnClose}>
                {t('jobPosting.search.modal.' + (isTabListSearch ? 'clear' : 'cancel'))}
              </Button>
              <Button
                disabled={(!isDirty && !isValid) || isSubmitting || disableSubmit}
                type="submit"
                modifier="mgl16"
                variant={(!isDirty && !isValid) || isSubmitting || disableSubmit ? 'yellowDessable' : 'shadow'}
              >
                {t('jobPosting.search.modal.' + (isTabListSearch ? 'decide' : 'save'))}
              </Button>
            </Modal.Footer>
          </>
        ) : (
          <>
            {!loading ? (
              <ModalContent />
            ) : (
              <div className="modal__choiceContInner1 choiceModal">
                <div className="circularProgress">
                  <CircularProgress disableShrink />
                </div>
              </div>
            )}
          </>
        )}
      </form>
    </Modal>
  )
}

JobPostingModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  modalData: PropTypes.object,
  onClickList: PropTypes.func,
  activeListKey: PropTypes.number,
  onSubmitModalSearch: PropTypes.func,
  onSaveSearchCriteria: PropTypes.func,
  onSubmitSearch: PropTypes.func,
  onClear: PropTypes.func,
}

export default JobPostingModal

import React, { memo, useMemo, useState } from 'react'

import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import groupBy from 'lodash/groupBy'
import dayjs from 'dayjs'

import { Avatar, BlockEl, NewlineText } from 'components/atoms'
import { replaceFileName, isJson } from 'utils/helper'

function Diff({ diff }) {
  if (typeof diff === 'string') {
    return <NewlineText text={`${diff} `} />
  }

  const deleted = Array.isArray(diff.d) ? diff.d.join(' ') : ''
  const inserted = Array.isArray(diff.i) ? diff.i.join(' ') : ''

  return (
    <>
      {deleted && (
        <BlockEl component="span" blockElClass="selectionModal__recomText" modifier="before">
          <NewlineText text={`${deleted} `} />
        </BlockEl>
      )}
      {inserted && (
        <BlockEl component="span" blockElClass="selectionModal__recomText" modifier="after">
          <NewlineText text={`${inserted} `} />
        </BlockEl>
      )}
    </>
  )
}

Diff.propTypes = {
  diff: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

const MemoidDiff = memo(Diff)

function ChangeHistoryItem({ item }) {
  const { t } = useTranslation('translation', { keyPrefix: 'editHistory' })
  const [expanded, setExpanded] = useState(false)
  const handleClick = () => setExpanded((old) => !old)

  return item.edited_item !== 'other_docs_url' ? (
    item.edited_item !== 'other_docs_pdf' && (
      <li className="selectionModal__recomInLi">
        <p className="selectionModal__recomInLiTime">{dayjs(item.created_at).format('HH:mm')}</p>
        <div className="selectionModal__recomInLiRight">
          <p className="selectionModal__recomInLiImgArea">
            <Avatar className="selectionModal__recomInLiImg" src={item.editor?.avatar} width="24" height="24" />
          </p>
          <p className="selectionModal__recomInLiCatch">
            {t('titleCatch', { field: item.edited_item_text, editor: item.editor?.full_name })}
          </p>
          <BlockEl
            component="p"
            onClick={handleClick}
            blockElClass="selectionModal__recomDdTitle"
            modifier={{ open: expanded }}
          >
            {t('seeDetails')}
          </BlockEl>
          <BlockEl component="div" blockElClass="selectionModal__recomTextArea" modifier={{ open: expanded }}>
            {item.diff !== null ? (
              <p className="selectionModal__recomText">
                {item.diff.map((diff, index) => (
                  <MemoidDiff key={index} diff={diff} />
                ))}
              </p>
            ) : (
              <dl>
                <p className="selectionModal__recomText">
                  {t('before')}: <span>{replaceFileName(item.value_before_editing)}</span>
                </p>
                <p className="selectionModal__recomText">
                  {t('after')}: <span>{replaceFileName(item.value_after_editing)}</span>
                </p>
              </dl>
            )}
          </BlockEl>
        </div>
      </li>
    )
  ) : (
    <li className="selectionModal__recomInLi">
      <p className="selectionModal__recomInLiTime">{dayjs(item.created_at).format('HH:mm')}</p>
      <div className="selectionModal__recomInLiRight">
        <p className="selectionModal__recomInLiImgArea">
          <Avatar className="selectionModal__recomInLiImg" src={item.editor?.avatar} width="24" height="24" />
        </p>
        <p className="selectionModal__recomInLiCatch">
          {t('titleCatch', { field: item.edited_item_text, editor: item.editor?.full_name })}
        </p>
        <BlockEl
          component="p"
          onClick={handleClick}
          blockElClass="selectionModal__recomDdTitle"
          modifier={{ open: expanded }}
        >
          {t('seeDetails')}
        </BlockEl>
        <BlockEl component="div" blockElClass="selectionModal__recomTextArea" modifier={{ open: expanded }}>
          {item.diff !== null ? (
            <p className="selectionModal__recomText">
              {item.diff.map((diff, index) => (
                <MemoidDiff key={index} diff={diff} />
              ))}
            </p>
          ) : (
            <dl>
              <p className="selectionModal__recomText">
                {t('before')}:
                {!isJson(item.value_before_editing) ? (
                  <span>{replaceFileName(item.value_before_editing)}</span>
                ) : (
                  JSON.parse(item.value_before_editing).map((value, key) => (
                    <>
                      <span key={key}>{replaceFileName(value.option === 'url' ? value.url : value.pdf)}</span>
                      <br />
                    </>
                  ))
                )}
                <p className="selectionModal__recomText">
                  {t('after')}:
                  {!isJson(item.value_after_editing) ? (
                    <span>{replaceFileName(item.value_after_editing)}</span>
                  ) : (
                    JSON.parse(item.value_after_editing).map((value, key) => (
                      <>
                        <span key={key}>{replaceFileName(value.option === 'url' ? value.url : value.pdf)}</span>
                        <br />
                      </>
                    ))
                  )}
                </p>
              </p>
            </dl>
          )}
        </BlockEl>
      </div>
    </li>
  )
}

ChangeHistoryItem.propTypes = {
  item: PropTypes.object.isRequired,
}

function ChangeHistoryList({ items }) {
  const itemList = useMemo(() => groupBy(items, (item) => dayjs(item.created_at).format('MM/DD(dddd)')), [items])
  const itemKeyList = Object.keys(itemList)

  return (
    <>
      <BlockEl
        component="div"
        blockElClass={`selectionModal__recomHistory`}
        modifier={itemKeyList.length > 0 ? 'active' : ''}
      >
        <ul className="selectionModal__recomUl">
          {itemKeyList.map((key) => (
            <li key={key} className="selectionModal__recomLi">
              <p className="selectionModal__recomDate">{key}</p>
              <ul className="selectionModal__recomInUl">
                {itemList[key].map((item) => (
                  <ChangeHistoryItem key={item.id} item={item} />
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </BlockEl>
    </>
  )
}

ChangeHistoryList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default ChangeHistoryList

import React, { useState } from 'react'
import { BlockEl, FormInterlock, FormItemLabel } from 'components'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const EmergencyContact = (props) => {
  const { t } = useTranslation()
  const [keyword, setKeyword] = useState('')

  const {
    successContact,
    adPersonInCharge,
    adInchargeList,
    editmode,
    handleShowAdDropdown,
    showAdDropdown,
    showContactText,
    handleOnChange,
    selectIncharge,
    candidateSelected,
    data,
    errors,
  } = props
  const [showAdList, setShowAdList] = useState(false)
  const { register } = useForm({})

  const selectAdIncharge = (e, row) => {
    e.preventDefault()
    setShowAdList(false)
    selectIncharge(e, row)
  }

  const handleShowAdList = () => {
    setShowAdList(!showAdList)
  }

  const handleContactSearch = (e) => {
    e.preventDefault()
    const { value } = e.target
    handleOnChange(e)
    setKeyword(value)
    setShowAdList(true)
  }

  const handleClose = () => {
    setShowAdList(false)
  }

  return (
    <div
      className={
        editmode
          ? `form__group form__group-mgb0 form__group-mgt0 required ${successContact}`
          : `form__group form__group-mgb0 form__group-mgt0`
      }
    >
      <FormItemLabel label={t('fieldLabels.emergencyContact')} />
      {editmode && !candidateSelected ? (
        <>
          <BlockEl blockElClass="form__groupCheckArea">
            <BlockEl blockElClass="form__groupCheck">
              <input
                type="radio"
                {...register('emergency_contact')}
                id="emergency-contact1"
                defaultChecked={showAdDropdown}
                data-interlock="interlock2"
                className="form__checkItem formRadio boolCheck interlock"
                value="ad_person_incharge"
                onClick={handleShowAdDropdown}
              />
              <label htmlFor="emergency-contact1" className="form__checkLabel">
                {t('hints.selectFromList')}
              </label>
            </BlockEl>
            <FormInterlock active={showAdDropdown}>
              <BlockEl blockElClass="form__groupFlex" className="form__group-mgt">
                <BlockEl blockElClass="form__dropWrap">
                  {showAdDropdown && (
                    <BlockEl blockElClass="form__dropInputWrap" onClick={handleShowAdList}>
                      <input
                        type="text"
                        {...register('ad_person_incharge')}
                        value={`${adPersonInCharge?.full_name}/${adPersonInCharge?.contact_number}`}
                        placeholder={t('hints.selectAD')}
                        className="form__dropText form__dropWrap-w240px inputCheck boolCheck"
                        id="formTelText"
                        readOnly
                      />
                    </BlockEl>
                  )}

                  {showAdList ? (
                    <BlockEl blockElClass="form__dropInputArea form__dropInputArea-active" id="telAreaWrap">
                      <BlockEl blockElClass="form__dropInputAreaBack" onClick={handleClose}></BlockEl>
                      <BlockEl blockElClass="form__dropInputAreaInner" id="telArea">
                        <BlockEl blockElClass="form__dropSearchArea">
                          <p className="form__dropSearchText">{t('fieldLabels.personInchargeNumber')}</p>
                          <input
                            type="text"
                            {...register('contact_search')}
                            id="telSearch"
                            placeholder={t('hints.narrowDown')}
                            className="form__dropSearch"
                            onChange={handleContactSearch}
                          />
                        </BlockEl>
                        <ul className="form__dropUl">
                          {keyword !== ''
                            ? adInchargeList
                                ?.filter((contact) => {
                                  return keyword.trim().length === 0 || contact.full_name.includes(keyword)
                                })
                                .map((row, index) => {
                                  return (
                                    <li className="form__dropLi form__dropLi-top" key={index}>
                                      <p className="form__dropName">{row.full_name}</p>
                                      <p className="form__dropTelNum">{row.contact_number}</p>
                                      <div className="form__dropBtnWrap">
                                        <button
                                          className="btn btn__shadowBtn form__dropBtn"
                                          data-nametel={row.contact_number}
                                          onClick={(e) => selectAdIncharge(e, row)}
                                        >
                                          {t('buttonLabels.select')}
                                        </button>
                                      </div>
                                    </li>
                                  )
                                })
                            : adInchargeList?.map((row, index) => {
                                return (
                                  <li className="form__dropLi form__dropLi-top" key={index}>
                                    <p className="form__dropName">{row.full_name}</p>
                                    <p className="form__dropTelNum">{row.contact_number}</p>
                                    <div className="form__dropBtnWrap">
                                      <button
                                        className="btn btn__shadowBtn form__dropBtn"
                                        data-nametel={row.contact_number}
                                        onClick={(e) => selectAdIncharge(e, row)}
                                      >
                                        {t('buttonLabels.select')}
                                      </button>
                                    </div>
                                  </li>
                                )
                              })}
                        </ul>
                      </BlockEl>
                    </BlockEl>
                  ) : null}
                </BlockEl>
              </BlockEl>
              <BlockEl blockElClass="form__supArea form__supArea-flex">
                <p className="form__alert">{t('hints.enter1')} </p>
                <p className="form__alert">{errors?.ad_person_incharge}</p>
              </BlockEl>
            </FormInterlock>
            <BlockEl blockElClass="form__groupCheck">
              <input
                type="radio"
                {...register('emergency_contact')}
                id="emergency-contact2"
                data-interlock="interlock3"
                className="form__checkItem formRadio boolCheck interlock"
                value="contactNumber"
                defaultChecked={showContactText}
                onClick={handleShowAdDropdown}
              />
              <label htmlFor="emergency-contact2" className="form__checkLabel">
                {t('hints.enterDirectly')}
              </label>
            </BlockEl>
            <FormInterlock active={showContactText}>
              <BlockEl blockElClass="form__groupFlex" className="form__group-mgt">
                <input
                  type="text"
                  {...register('contact_number')}
                  name="contact_number"
                  defaultValue={data?.contact_number}
                  onChange={handleOnChange}
                  className="form__inputText inputCheck boolCheck"
                />
              </BlockEl>
              <BlockEl blockElClass="form__supArea form__supArea-flex">
                <p className="form__alert">{t('hints.enter2')}</p>
              </BlockEl>
            </FormInterlock>
            <BlockEl blockElClass="form__supArea form__supArea-flex">
              <p className="form__alert">{errors?.contact_number}</p>
            </BlockEl>
          </BlockEl>
        </>
      ) : (
        <BlockEl blockElClass="form__groupFlex form_handleFileInput_group-mgt form__groupMultiCheck">
          <p className="recommendation__dt">
            {data?.ad_person_incharge?.full_name}/
            {data?.contact_number ? data?.contact_number : data?.ad_person_incharge?.contact_number}
          </p>
        </BlockEl>
      )}
    </div>
  )
}

EmergencyContact.propTypes = {
  successContact: PropTypes.string,
  adPersonInCharge: PropTypes.object,
  adInchargeList: PropTypes.array,
  editmode: PropTypes.bool,
  handleShowAdDropdown: PropTypes.func,
  showAdDropdown: PropTypes.bool,
  showContactText: PropTypes.bool,
  handleOnChange: PropTypes.func,
  selectIncharge: PropTypes.func,
  candidateSelected: PropTypes.bool,
  data: PropTypes.object,
  errors: PropTypes.object,
}

export default EmergencyContact

import userMainGroup from './guards/userMainGroup'
import userIsSetupInvitation from './guards/userIsSetupInvitation'
import isCompanySetupInvitation from './guards/userCompanySetupInvitation'
import isAccountSetupInvitation from './guards/userAccountSetupInvitation'
import isAccountSetupInvitationDone from './guards/userAccountSetupInvitationDone'
import isCompanySetupInvitationDone from './guards/userCompanySetupInvitationDone'
import isNeedDefaultPassChanged from './guards/userHasChangeDefaultPass'
import parentAccountOnly from './guards/parentAccountOnly'
import restrictedUserStatus from './guards/restrictedUserStatus'

export default [
  {
    path: '/',
    component: 'views/dashboard',
    auth: true,
  },
  {
    path: '/users',
    component: 'views/users',
    auth: true,
    guards: [isCompanySetupInvitationDone(), isAccountSetupInvitationDone(), parentAccountOnly()],
  },
  {
    path: '/job-posting/recommendation/:id',
    component: 'views/ad_1/recommend',
    auth: true,
    guards: [userMainGroup('AD1'), restrictedUserStatus()],
  },
  {
    path: '/recommendation_confirmation',
    component: 'views/ad_1/recommendation-confirmation',
    auth: true,
    guards: [userMainGroup('AD1'), restrictedUserStatus()],
  },
  {
    path: '/profile',
    component: 'views/profile/view-profile',
    auth: true,
    guards: [isCompanySetupInvitationDone(), isAccountSetupInvitationDone()],
  },
  {
    path: '/profile/edit',
    component: 'views/profile/edit-profile',
    auth: true,
    layout: 'preview',
    guards: [isCompanySetupInvitationDone(), isAccountSetupInvitationDone()],
  },
  {
    path: '/sign-in',
    component: 'views/sign-in',
    layout: 'guest',
  },
  {
    path: '/sign-up',
    component: 'views/sign-up',
    layout: 'minimal',
  },
  {
    path: '/forgot-password',
    component: 'views/forgot-password',
    layout: 'guest',
  },
  {
    path: '/invitation/password/reset',
    component: 'views/reset-password',
    layout: 'guest',
    auth: true,
    guards: [isNeedDefaultPassChanged()],
  },
  {
    path: '/password/reset',
    component: 'views/reset-password',
    layout: 'guest',
  },
  {
    path: '/admin/users',
    component: 'views/admin-users',
    auth: true,
  },
  {
    path: '/applicant/:type/:file',
    component: 'views/applicant-file',
    layout: 'preview',
  },
  {
    path: '/admin/user-logs',
    component: 'views/user-logs',
    auth: true,
  },
  {
    path: '/chat/:id',
    component: 'views/chat',
  },
  {
    path: '/account/activate',
    component: 'views/invitation/activate',
    layout: 'guest',
    auth: false,
  },
  {
    path: '/google/oauth',
    component: 'views/google-redirect',
    layout: 'guest',
    auth: false,
  },
  {
    auth: true,
    path: '/questions',
    component: 'views/questions',
    guards: [isCompanySetupInvitationDone(), isAccountSetupInvitationDone(), restrictedUserStatus()],
  },
  {
    path: '/new-job-post/step4',
    component: 'views/hr/new-job-post/step4',
    auth: true,
    layout: 'new-job-post',
    guards: [isCompanySetupInvitationDone(), isAccountSetupInvitationDone(), restrictedUserStatus()],
  },
  {
    path: '/article/:id',
    component: 'views/article',
    auth: true,
    guards: [userMainGroup(['AD1', 'HR', 'ADMIN']), restrictedUserStatus()],
  },
  {
    auth: true,
    path: '/job-postings/:id/:tab?',
    component: 'views/job-postings/details',
    guards: [isCompanySetupInvitationDone({ bypassAdmin: true }), isAccountSetupInvitationDone({ bypassAdmin: true })],
  },
  {
    path: '/mock/',
    component: 'views/mock',
  },
  {
    auth: true,
    path: '/evaluations/:id',
    component: 'views/evaluation',
    guards: [isCompanySetupInvitationDone(), isAccountSetupInvitationDone()],
  },
  {
    path: '/selection-management',
    component: 'views/selection-management',
    auth: true,
    guards: [
      isCompanySetupInvitationDone(),
      isAccountSetupInvitationDone(),
      userMainGroup(['AD1', 'HR']),
      restrictedUserStatus(),
    ],
  },
  {
    path: '/suggestion-management',
    component: 'views/suggestion-management',
    auth: true,
    guards: [
      isCompanySetupInvitationDone(),
      isAccountSetupInvitationDone(),
      userMainGroup(['AD2', 'HR']),
      restrictedUserStatus(),
    ],
  },
  {
    path: '/jobSearch',
    component: 'views/job-postings/list',
    auth: true,
    guards: [isCompanySetupInvitationDone(), isAccountSetupInvitationDone(), userMainGroup(['AD2', 'AD1'])],
  },
  {
    path: '/job_postings/pdf/:id?',
    component: 'views/preview-file',
    layout: 'preview',
  },
  {
    path: '/favorite',
    component: 'views/favorite/list',
    auth: true,
    guards: [isCompanySetupInvitationDone(), isAccountSetupInvitationDone()],
  },
  {
    path: '/new-job-post/step1',
    component: 'views/hr/new-job-post/step1.js',
    layout: 'new-job-post',
    auth: true,
    guards: [userMainGroup('HR'), restrictedUserStatus()],
  },
  {
    path: '/new-job-post/step2',
    component: 'views/hr/new-job-post/step2',
    auth: true,
    layout: 'new-job-post',
    guards: [userMainGroup('HR'), restrictedUserStatus()],
  },
  {
    path: '/new-job-post/step3',
    component: 'views/hr/new-job-post/step3',
    auth: true,
    layout: 'new-job-post',
    guards: [userMainGroup('HR'), restrictedUserStatus()],
  },
  {
    path: '/new-job-post/step-conf',
    component: 'views/hr/new-job-post/step-conf',
    auth: true,
    layout: 'new-job-post/step-conf',
    guards: [userMainGroup('HR'), restrictedUserStatus()],
  },
  {
    path: '/new-job-post-proposal/step-conf',
    component: 'views/hr/new-job-post/step-conf',
    auth: true,
    layout: 'new-job-post/step-conf',
    guards: [userMainGroup('HR'), restrictedUserStatus()],
  },
  {
    path: '/applicants',
    component: 'views/ad_1/applicants/list',
    auth: true,
    guards: [isCompanySetupInvitationDone(), isAccountSetupInvitationDone(), restrictedUserStatus()],
  },
  {
    path: '/invitation',
    component: 'views/invitation',
    auth: true,
    guards: [userIsSetupInvitation],
  },
  {
    path: '/invitation/company-account/create',
    component: 'views/invitation/company-account/create',
    auth: true,
    guards: [isCompanySetupInvitation()],
  },
  {
    path: '/invitation/company-account/confirm',
    component: 'views/invitation/company-account/confirm',
    auth: true,
    guards: [isCompanySetupInvitation()],
  },
  {
    path: '/user/create',
    component: 'views/user-account/create',
    auth: true,
    layout: 'empty',
    guards: [isCompanySetupInvitationDone()],
  },
  {
    path: '/user/confirm',
    component: 'views/user-account/confirm',
    auth: true,
    layout: 'empty',
    guards: [isCompanySetupInvitationDone()],
  },
  {
    path: '/user/edit',
    component: 'views/user-account/create',
    auth: true,
    // layout: 'empty',
    guards: [isCompanySetupInvitationDone()],
  },
  {
    path: '/new-job-post-proposal/step1',
    component: 'views/hr/new-job-post/step1.js',
    layout: 'new-job-post',
    auth: true,
    guards: [userMainGroup('HR')],
  },
  {
    path: '/applicants/add-applicant',
    component: 'views/ad_1/applicants/AddApplicant',
    auth: true,
    layout: 'preview',
  },
  {
    path: '/applicants/edit-applicant',
    component: 'views/ad_1/applicants/AddApplicant',
    auth: true,
    layout: 'preview',
  },
  {
    path: '/applicants/confirmation',
    component: 'views/ad_1/applicants/AddApplicantConfirmation',
    auth: true,
    layout: 'preview',
  },
  {
    path: '/new-job-post-proposal/step2',
    component: 'views/hr/new-job-post/step3.js',
    auth: true,
    layout: 'new-job-post',
    guards: [userMainGroup('HR')],
  },
  {
    path: '/new-job-post-proposal/step3',
    component: 'views/hr/new-job-post/step4.js',
    auth: true,
    layout: 'new-job-post',
    guards: [userMainGroup('HR'), restrictedUserStatus()],
  },
  {
    path: '/admin/company-management',
    component: 'views/admin/company-management',
    auth: true,
    guards: [userMainGroup('ADMIN')],
  },
  {
    path: '/admin/company-management-create',
    component: 'views/admin/company-management/create',
    auth: true,
    guards: [userMainGroup('ADMIN')],
  },
  {
    path: '/admin/company-management/invited-ad-conf',
    component: 'views/admin/company-management/invited-ad-conf/invited-ad-conf.js',
    auth: true,
    guards: [userMainGroup('ADMIN')],
  },
  {
    path: '/admin/company-management/invited-hr-conf',
    component: 'views/admin/company-management/invited-hr-conf',
    auth: true,
    guards: [userMainGroup('ADMIN')],
  },
  {
    path: '/admin/account-management/:id',
    component: 'views/admin/users/account-management',
    auth: true,
    guards: [userMainGroup(['ADMIN'])],
  },
  {
    path: '/admin/article/create',
    component: 'views/admin/featured-article/create',
    auth: true,
    guards: [userMainGroup(['ADMIN'])],
  },
  {
    path: '/admin/article/create/:id',
    component: 'views/admin/featured-article/create',
    auth: true,
    guards: [userMainGroup(['ADMIN'])],
  },
  {
    path: '/job-management',
    component: 'views/hr/job-management',
    auth: true,
    guards: [userMainGroup('HR')],
  },
  {
    path: '/invitation/user-profile/create',
    component: 'views/invitation/user-profile/create',
    auth: true,
    guards: [isAccountSetupInvitation()],
  },
  {
    path: '/invitation/user-profile/confirm',
    component: 'views/invitation/user-profile/confirm',
    auth: true,
    guards: [isAccountSetupInvitation()],
  },
  {
    path: '/admin/log',
    component: 'views/admin/log',
    auth: true,
    guards: [userMainGroup('ADMIN')],
  },
  {
    path: '/admin/evaluation',
    component: 'views/admin/evaluation',
    auth: true,
    guards: [userMainGroup('ADMIN')],
  },
  {
    path: '/profile/:id',
    component: 'views/profile/view-profile',
    auth: true,
    guards: [isCompanySetupInvitationDone(), isAccountSetupInvitationDone()],
  },
  {
    path: '/about-us',
    component: 'views/about-us/company-info',
    auth: true,
    guards: [userMainGroup(['AD1', 'HR', 'AD2'])],
  },

  {
    path: '/views/about-us/company-edit',
    component: 'views/about-us/company-edit-info',
    auth: true,
    guards: [parentAccountOnly()],
  },
  {
    path: '/user-settings',
    component: 'views/user-settings',
    auth: true,
  },
  {
    path: '/admin/article',
    component: 'views/article/special-feature',
    auth: true,
    guards: [userMainGroup('ADMIN')],
  },
]

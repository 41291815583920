import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as FunnelIcon } from 'assets/img/funnel.svg'
import { useTranslation } from 'react-i18next'

const FilterHeader = ({ title, onFilter }) => {
  const { t } = useTranslation()

  return (
    <div className="spHeader">
      <div className="spHeader__inner border">
        <div className="spHeader__left">
          <h2 className="spHeader__title">{title}</h2>
        </div>
        <div className="spHeader__right">
          <a href="" className="spHeader__btn" id="spSearchBtn" onClick={onFilter}>
            <span className="btn__btnIcon">
              <FunnelIcon />
            </span>
            {t('formLabels.search')}
          </a>
        </div>
      </div>
    </div>
  )
}

FilterHeader.propTypes = {
  title: PropTypes.string.isRequired,
  onFilter: PropTypes.func.isRequired,
}

export default FilterHeader

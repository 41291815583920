import React from 'react'
import PropTypes from 'prop-types'
import { HeadPropTypes } from './Head'
import CircularProgress from '@mui/material/CircularProgress'
import { useTranslation } from 'react-i18next'
import { objectFlip } from 'utils/helper'
import { DATE_FORMAT_TIME, USER_STATUSES, DATE_FORMAT } from 'utils/constants'
import dayjs from 'dayjs'
import AccountManagementActionCell from '../AccountManagementActionCell'
import { Pagination } from 'components/molecules'
import clsx from 'clsx'

TableList.propTypes = {
  title: PropTypes.string.isRequired,
  headCells: HeadPropTypes.headCells,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    })
  ).isRequired,
  pageSize: PropTypes.number,
  currentPage: PropTypes.number,
  sort: PropTypes.oneOf(['asc', 'desc']),
  sortBy: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pageSizes: PropTypes.array,
  totalCount: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  handlePageChange: PropTypes.func.isRequired,
  handlePageSizeChange: PropTypes.func,
  handleSort: PropTypes.func,
  handleDelete: PropTypes.func,
  handleEdit: PropTypes.func,
  showCheckbox: PropTypes.bool,
  selectableRow: PropTypes.bool,
  lastPage: PropTypes.number,
}

TableList.defaultProps = {
  showCheckbox: false,
  selectableRow: false,
}

TableList.defaultProps = {
  pageSize: 10,
  currentPage: 1,
  pageSizes: [5, 10, 50],
  sort: 'asc',
}

function TableList(props) {
  const { t } = useTranslation()

  const { data, currentPage, handlePageChange, isLoading, lastPage } = props
  const handleChangePage = (newPage) => {
    handlePageChange(newPage)
  }

  const flipped = objectFlip(USER_STATUSES)
  const dateToday = dayjs(new Date().toLocaleString()).format(DATE_FORMAT)

  return (
    <>
      <ul className="member__mainUl">
        <li className="member__mainLi member__mainLi-gray">
          <div className="member__mainLiInner">
            <div className="member__mainName member__mainName-ais member-pdb0">名前</div>
            <div className="member__mainJobMail member-pdb0">メールアドレス</div>
            <div className="member__mainAuthority member-pdb0">権限</div>
            <div className="member__mainStatus member-pdb0">ステータス</div>
            <div className="member__mainCertification member-pdb0">アカウント認証</div>
          </div>
        </li>

        {!isLoading &&
          data.map((row) => {
            const statusId = row.status.id
            const statusKey = statusId === USER_STATUSES.pending ? 'pending' : flipped[statusId]
            let expiredDate = new Date(row.created_at)
            expiredDate.setDate(expiredDate.getDate() + 1)

            return (
              <li key={row.id} className="member__mainLi">
                <div className="member__mainLiInner">
                  <div className="member__mainName">{row?.full_name !== ' ' ? row?.full_name : '----'}</div>
                  <div className="member__mainJobMail memberSpTitle" data-type="メールアドレス">
                    {row?.email}
                  </div>
                  <div className="member__mainAuthority memberSpTitle" data-type="権限">
                    {row.groups[0]?.is_parent ? t('userPermission.parent') : t('userPermission.child')}
                  </div>

                  <div
                    className={clsx('member__mainStatus memberSpTitle', statusId >= 4 ? 'member__mainStatus-col' : '')}
                    data-type="ステータス"
                  >
                    {statusId >= 4 ? (
                      <div>
                        <span
                          className={clsx(
                            statusId === 4 && dateToday <= dayjs(expiredDate).format(DATE_FORMAT)
                              ? 'member__mainStatus-invite'
                              : 'member__mainStatus-expired'
                          )}
                        >
                          {dateToday <= dayjs(expiredDate).format(DATE_FORMAT)
                            ? t(`userStatus.${statusKey}`)
                            : t('userAccount.statusExpired')}
                        </span>
                        <span className="member__mainStatus-gray">
                          {t(`userStatus.pendingInviter1`, { inviter_name: row.invited_by?.full_name })}
                        </span>
                      </div>
                    ) : (
                      <span
                        className={clsx(
                          statusId === 1
                            ? 'member__mainStatus-ac'
                            : statusId === 2
                            ? 'member__mainStatus-pause'
                            : statusId === 3
                            ? 'member__mainStatus-archive'
                            : ''
                        )}
                      >
                        {t(`userStatus.${statusKey}`)}
                      </span>
                    )}
                  </div>

                  <div className="member__mainCertification memberSpTitle" data-type="アカウント認証">
                    {row.verified_at ? dayjs(row.verified_at).format(DATE_FORMAT_TIME) : ''}
                  </div>
                </div>
                {statusId <= 3 && <AccountManagementActionCell user={row} />}
              </li>
            )
          })}

        {isLoading && <CircularProgress />}
      </ul>
      <Pagination
        totalPages={lastPage}
        page={currentPage}
        onPageChange={isLoading ? () => {} : handleChangePage}
        boundaryCount={1}
        siblingCount={2}
      />
    </>
  )
}
export default TableList

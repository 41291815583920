import React from 'react'
import { ThemeProvider } from '@mui/material/styles'
import 'typeface-roboto'

import Router from './router/Router'
import theme from './theme'
import 'assets/scss/style.scss'
import { EchoContextProvider, EchoInstance } from 'config/echo'
import { AlertContainer } from 'components'
import UserPingManager from 'manager/UserPingManager'

function App() {
  return (
    <ThemeProvider theme={theme}>
      <EchoContextProvider value={EchoInstance}>
        <Router />
        <UserPingManager />
      </EchoContextProvider>
      <AlertContainer />
    </ThemeProvider>
  )
}

export default App

export const USER_STATUSES = {
  active: 1,
  suspended: 2,
  archived: 3,
  pending: 4,
  locked: 5,
}

export const USER_TYPES = {
  admin: 1,
  hrParent: 2,
  hrChild: 3,
  ad1Parent: 4,
  ad1Child: 5,
  ad2Parent: 6,
  ad2Child: 7,
}

export const EMPLOYMENT_STATUS = {
  正社員: 'Regular employee',
  契約社員: 'Contract employee',
  派遣社員: 'Temporary Staff',
  業務委託: 'Outsourcing',
  アルバイト: 'Part-time job',
  その他: 'Others',
}

export const SELECTION_STATUSES = {
  recommend: 1,
  documentScreening: 2,
  officialJobOffer: 9,
  joined: 10,
  rejected: 11,
  declined: 12,
}

export const SELECTION_STEPS = {
  first: 3,
  second: 4,
  third: 5,
  fourth: 6,
  fifth: 7,
  final: 8,
}

export const SELECTION_STATUSES_LIST = {
  0: '',
  1: 'recommended',
  2: 'under_selection',
  9: 'unofficial_decision',
  10: 'joined_the_company',
}

export const FILTER_SIDE_STATUS = {
  unread: 'unread',
  recommended: 1,
  under_selection: 2,
  unofficial_decision: 9,
  joined_the_company: 10,
  decline_or_cancel: [11, 12],
}

export const PROPOSAL_SIDE_STATUS = {
  proposed: 1,
  appointment: 2,
  under_negotiation: 3,
  contracted: 4,
  rejected_cancelled: [5, 6],
}

export const SELECTION_STATUSES_OPTIONS = {
  'selection.sideMenu.recommended': 1,
  'selection.sideMenu.unread': 'unread',
  'selection.sideMenu.under_selection': 2,
  'selection.sideMenu.unofficial_decision': 9,
  'selection.sideMenu.joined_the_company': 10,
  'selection.sideMenu.decline': 12,
}

export const SELECTION_STEPS_OPTIONS = {
  'selectionManagementSearch.firstInterview': 3,
  'selectionManagementSearch.secondInterview': 4,
  'selectionManagementSearch.thirdInterview': 5,
  'selectionManagementSearch.fourthInterview': 6,
  'selectionManagementSearch.fifthInterview': 7,
  'selectionManagementSearch.lastInterview': 8,
}

export const SELECTION_STEPS_COLLECTION = [2, 3, 4, 5, 6, 7, 8]

export const PROPOSAL_STATUSES = {
  proposed: 1,
  appointment: 2,
  underNegotiation: 3,
  contracted: 4,
  rejected: 5,
  cancelled: 6,
}

export const ADTYPES = [USER_TYPES.ad1Parent, USER_TYPES.ad1Child, USER_TYPES.ad2Parent, USER_TYPES.ad2Child]

export const DATE_FORMAT = 'YYYY/MM/DD'
export const DATE_FORMAT_JAPANESE = 'YYYY年MM月'
export const COMPLETE_DATE_FORMAT_JAPANESE = 'YYYY年MM月DD日'
export const DATE_TIME_FORMAT = 'YYYY/MM/DD HH:mm:ss'
export const DATE_FORMAT_TIME = 'YYYY/MM/DD HH:mm'

export const IS_PROD = process.env.NODE_ENV === 'production'

export const INPUT_TYPES = {
  checked: 1,
  ranges: 2,
  freeInput: 3,
  radio: 4,
}

export const MODAL_SEARCH_TYPE = {
  industries: 'jobIndustry',
  occupations: 'availablePosition',
  workLocations: 'workLocations',
  workPoints: 'workingPoints',
  saveSearchCriteria: 'saveSearchCriteria',
}

export const MESSAGE_TYPE = {
  regular: 'regular',
  evaulation: 'evaulation',
  appointment: 'appointment',
  linkable: 'linkable',
  status: 'status',
  schedule: 'schedule',
}

export const COMPANY_STATUSES = {
  active: 'アクティブ',
  pause: '休止',
  archive: 'アーカイブ',
}

export const USER_STATUSES_LIST = {
  active: 'Active',
  pause: 'Suspended',
  archive: 'Archived',
  invite: 'Pending',
  expired: 'Locked',
}

export const USER_STATUSES_LIST_VALUES = {
  active: 1,
  suspended: 2,
  archived: 3,
  pending: 4,
  locked: 5,
}

export const CONTRACT_TYPES = {
  ad1: 'AD1',
  ad2: 'AD2',
  hr: 'HR',
}

export const MESSAGE_STATUS = {
  proposing: 'proposing',
  unread: 'unread',
  read: 'read',
  replied: 'replied',
}

export const ACCOUNT_TYPES = {
  ...CONTRACT_TYPES,
  both: 'AD1-2',
}

export const JOB_POSTING_STATUSES = {
  under_recruitment: '募集中',
  draft: '下書き',
  closed: '募集終了',
  archived: 'アーカイブ',
  limitReached: '募集上限に達しました',
}

export const ADMIN_EVALUATION_OPTIONS = [
  {
    id: 0,
    label: '未評価', // not yet evaluated
  },
  {
    id: 1,
    label: '評価済み', // mutually evaluated
  },
]

export const EVALUATION_MUTUAL_EVALUATION = {
  mutually_evaluated: 'complete', // 評価済み
  not_yet_evaluated: 'incomplete', // 未評価
}

export const EVALUATION_STATUSES = {
  notYetApproved: '未承認', //not yet approved/ new
  approved: '承認する', //approved
  disapproved: '非承認', //disapproved
}

export const EVALUATION_DISAPPROVAL_REASON = {
  policyViolation: 'ポリシー違反',
  contentsCannotConfirm: '事実確認ができない内容',
  falseAccusation: '誹謗中傷を含む',
  others: 'その他',
}

export const IPO_OPTIONS = {
  first_section_of_the_tokyo_stock_exchange: '東証一部',
  second_section_of_the_tokyo_stock_exchange: '東証二部',
  mothers: 'マザーズ',
  jasdaq: 'JASDAQ',
  unlisted: '非上場',
}
export const CONTRACT_OPTIONS = [
  {
    id: 'AD1',
    label: '求人紹介 (AD1)', // not yet evaluated
  },
  {
    id: 'AD2',
    label: '広告営業 (AD2)', // mutually evaluated
  },
]

export const USER_TYPE_KEYS = {
  AD1: 'AD1',
  AD2: 'AD2',
  HR: 'HR',
  ADMIN: 'ADMIN',
}

export const RESPONSE_TYPE = {
  proposing: 'proposing',
  noResponse: 'no_response',
  responded: 'responded',
  closed: 'closed',
}

export const USER_SETTINGS = {
  ENABLE_NOTIF_STATUS_CHANGE: 'enable_notif_status_change',
  ENABLE_NOTIF_NEW_MESSAGE: 'enable_notif_new_message',
}

export const DEFAULT_HOURS = [
  '00:00',
  '01:00',
  '02:00',
  '03:00',
  '04:00',
  '05:00',
  '06:00',
  '07:00',
  '08:00',
  '09:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00',
  '20:00',
  '21:00',
  '22:00',
  '23:00',
]

import React, { useEffect } from 'react'

import PropTypes from 'prop-types'
import { useLocation, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Controller, useForm, FormProvider, useController, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import findIndex from 'lodash/findIndex'
import isNil from 'lodash/isNil'

import { Label, Textarea, Button, Input, BlockEl } from 'components/atoms'
import { ErrorMessage, FormGroup, FormSupArea } from 'components/molecules'
import { createProposal, updateProposal } from 'services/referralProposals'
import { showNotification } from 'store/notification/actionCreators'
import { canPropose, getJobPostingsFavorite, getJobPostings, fetchJobPosting } from 'services/jobPostings'
import { InvalidFieldCount } from 'components'
import Modal from '../Modal'
import { makeFormGroupStateGetter, toMB } from 'utils/helper'
import cloneDeep from 'lodash/cloneDeep'

const defaultValues = { proposal: '', attachments: [] }

const fields = ['proposal', 'attachments']

const accepts = [
  'image/heic',
  'image/png',
  'image/jpeg',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
]

const MAX_ATTACHMENTS = 10

function mapFromStore(data) {
  return {
    id: data?.id,
    job_posting_id: data?.job_posting?.id || null,
    proposal: data?.proposal || defaultValues.proposal,
    attachments: data?.attachments || defaultValues.attachments,
  }
}

function mapToStore({ jobPostingId, formData, isEdit }) {
  return {
    job_posting_id: isEdit ? undefined : jobPostingId,
    id: isEdit ? formData.id : undefined,
    proposal: formData.proposal,
    attachments: formData.attachments,
  }
}

function AttachmentsField({ rules, showErrorNotif, fromFieldState }) {
  const { t } = useTranslation()
  const { setError } = useFormContext()

  const {
    field: { value, ...field },
    fieldState,
  } = useController({
    name: 'attachments',
    rules,
  })

  const { error } = fieldState

  const reinstateError = (errors) => {
    if (isNil(errors)) return

    setTimeout(() => {
      Object.keys(errors).forEach((key) => setError(`attachments.${key}`, errors[key]))
    }, 0)
  }

  const attachments = value.map((item, index) => ({
    index,
    name: (item?.name ?? item).split('/').pop(),
    remove: () => {
      field.onChange(
        value.slice().filter((_, i) => index !== i),
        { shouldValidate: true }
      )

      // Ignore if error is react hook form error
      if (error?.type !== 'validate') {
        const cachedError = cloneDeep(error || {})
        delete cachedError[index]
        const startingIndex = index + 1
        const newIndices = Object.keys(cachedError)
          .filter((key) => !isNil(cachedError[key]))
          .map((index) => ({
            prev: +index,
            curr: index >= startingIndex ? index - 1 : +index,
          }))

        reinstateError(newIndices.reduce((accum, { prev, curr }) => ({ ...accum, [curr]: cachedError[prev] }), {}))
      }
    },
  }))

  const showAdd = attachments.length < 10

  const handleFileSelect = (e) => {
    if (e.target.files.length) {
      // Exclude files that are already seleted
      const incoming = Array.from(e.target.files).filter((file) => findIndex(value, { name: file.name }) === -1)
      const filteredFiles = incoming.filter((item) => toMB(item.size) <= 5)
      const newFiles = value.concat(filteredFiles)

      if (filteredFiles.length !== incoming.length) {
        showErrorNotif(t('selection.modal.invalidAttachment'))
      }

      if (newFiles.length > MAX_ATTACHMENTS) {
        showErrorNotif(t('selection.modal.exceedAttachmentLimit'))
      }

      field.onChange(newFiles, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      })

      // Ignore if error is react hook form error
      if (error?.type === 'validate') {
        reinstateError(error)
      }
    }
  }

  const hasError = (index) => !!(error && error[index])
  const getError = (index) => error && error[index]?.message

  return (
    <FormGroup modifier="mgt2" {...fromFieldState(fieldState)} required>
      <BlockEl>{t('jobPosting.footer.proposalAttachment')}</BlockEl>
      <BlockEl className="form__groupFlex" blockElClass="form__group" modifier="mgt" noBlockEl>
        <Label bec="form__fileLabel" mini={false} modifier="plus" style={{ display: showAdd ? 'flex' : 'none' }}>
          {t('jobPosting.footer.proposalFileAttachment')}
          <Input
            {...field}
            accept={accepts}
            onChange={handleFileSelect}
            type="file"
            modifier="dpn"
            blockElClass="form__file"
            multiple
            forgetful
          />
        </Label>
      </BlockEl>
      <BlockEl blockElClass="form__group" modifier="mgt" noBlockEl>
        {attachments.map(({ index, remove, name }) => (
          <BlockEl
            component="p"
            key={index}
            bec="form__fileText"
            modifier={{ ib: true, invalid: hasError(index) }}
            title={getError(index)}
          >
            <span className="form__fileText-text"> {name}</span>
            <span className="form__fileTextDel" onClick={remove}></span>
          </BlockEl>
        ))}
      </BlockEl>
      <FormSupArea modifier="flex">
        <ErrorMessage name="attachments" />
      </FormSupArea>
    </FormGroup>
  )
}

AttachmentsField.propTypes = {
  rules: PropTypes.object.isRequired,
  showErrorNotif: PropTypes.func.isRequired,
  fromFieldState: PropTypes.func.isRequired,
}

function ProposalSuggestModal({ open, initialData, handleClose, jobPostingId }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const location = useLocation()
  const currentPage = location.pathname
  const isEdit = Number.isFinite(parseInt(initialData?.id))

  let { id } = useParams()

  const rules = {
    proposal: {
      required: t('validation.required'),
      maxLength: { value: 2000, message: t('validation.maxLength', { length: 2000 }) },
    },
    attachments: {
      required: t('validation.required'),
      validate: (val) => {
        return val.length <= MAX_ATTACHMENTS || (val.length ?? 0 ? t('validation.required') : true)
      },
    },
  }

  const methods = useForm({ defaultValues, mode: 'all' })
  const {
    formState: { isSubmitting },
    handleSubmit,
    reset,
    setError,
    getFieldState,
  } = methods

  useEffect(() => {
    if (open) {
      reset(mapFromStore(initialData), { keepDefaultValues: true })
    }
  }, [open, initialData])

  const showErrorNotif = (message) => {
    dispatch(showNotification(message, { type: 'danger' }))
  }

  const handleError = (response = {}) => {
    const { code, error, message: errorMsg } = response
    let message

    switch (code) {
      case 422: {
        const attachmentKeys = Object.keys(error).filter((key) => key.startsWith('attachments'))

        message = attachmentKeys.map((key) => error[key][0])[0] || ''

        attachmentKeys.map((key) => {
          const matches = key.match(/\d/)
          const index = matches && matches[0]

          if (index !== null) {
            setError(`attachments.${index}`, { message: error[key][0], type: 'custom' })
          }
        })

        if ('proposal' in error) {
          setError('proposal', { message: error.proposal[0] }, { shouldFocus: true })
        }
        break
      }
      default:
        message = error || errorMsg || message || 'Unknown Error'
    }

    showErrorNotif(message)
  }

  const onSubmit = async (data) => {
    const requestData = mapToStore({ jobPostingId, formData: data, isEdit })

    const res = await dispatch(isEdit ? updateProposal(requestData) : createProposal(requestData))

    if (res.error) {
      handleError(res.error)
      return
    }

    if (!isEdit) {
      dispatch(showNotification(t('jobPosting.footer.proposalTo'), { type: 'success', showButton: true }))
    }

    await dispatch(canPropose(jobPostingId))

    if (currentPage === '/favorite') {
      dispatch(getJobPostingsFavorite())
    }

    if (currentPage === '/job-postings/' + id + '/jobvacancies') {
      dispatch(fetchJobPosting(id))
    } else {
      dispatch(getJobPostings())
    }

    reset()
    handleClose()
  }

  const { fromFieldState } = makeFormGroupStateGetter(getFieldState)

  return (
    <>
      <Modal
        modifier="ofh pcClear"
        containerType="contSlideCont"
        containerModifier="w400 spUp"
        innerProps={{ modifier: 'rt' }}
        open={open}
        onClose={handleClose}
      >
        <Modal.Header>
          <Modal.TitleWrap>
            <Modal.Title modifier="mgr">{t('jobPosting.footer.suggestionTo')}</Modal.Title>
          </Modal.TitleWrap>
          <Modal.CloseButton onClick={handleClose}></Modal.CloseButton>
        </Modal.Header>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormProvider {...methods}>
            <Controller
              name="proposal"
              rules={rules.proposal}
              render={({ field, fieldState }) => (
                <FormGroup modifier="mgb0 mgt2" {...fromFieldState(fieldState)} required>
                  <Label>{t('jobPosting.footer.proposalContent')}</Label>

                  <Textarea {...field} className="form__group-mgt" />

                  <FormSupArea modifier="flex">
                    <ErrorMessage name="proposal" />
                    <FormSupArea.InputSup
                      modifier="end"
                      inputValue={field.value}
                      max={rules.proposal.maxLength.value}
                    />
                  </FormSupArea>
                </FormGroup>
              )}
            />

            <AttachmentsField
              fromFieldState={fromFieldState}
              rules={rules.attachments}
              showErrorNotif={showErrorNotif}
            />

            <BlockEl blockElClass="modal__searchFooter2">
              <InvalidFieldCount
                name={fields}
                render={({ invalid }) => (
                  <Button
                    modifier="w100"
                    type="submit"
                    disabled={isSubmitting || invalid}
                    variant="yellowDessable"
                    variantMod={{ active: !isSubmitting && !invalid }}
                  >
                    <span className="pc">{t('qAndA.replySubmitBtn')}</span>
                    <span className="sp">{t('jobPosting.footer.suggest')}</span>
                  </Button>
                )}
              />
            </BlockEl>
          </FormProvider>
        </form>
      </Modal>
    </>
  )
}

ProposalSuggestModal.propTypes = {
  open: PropTypes.bool,
  jobPostingId: PropTypes.any,
  handleClose: PropTypes.func,
  initialData: PropTypes.object,
}
export default ProposalSuggestModal

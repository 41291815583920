import React, { useState } from 'react'
import clsx from 'clsx'
import Slider from 'react-slick'
import { ReactComponent as SlideArrowPrev } from 'assets/img/slide-arrow-prev.svg'
import { ReactComponent as SlideArrowNext } from 'assets/img/slide-arrow-next.svg'
import PropTypes from 'prop-types'

const SlickSlider = ({ settings, children, itemsCount, rowCount }) => {
  const totalItemCount = itemsCount - rowCount
  const [slideIndex, setSlideIndex] = useState(0)

  const SliderPrevArrow = ({ onClick }) => (
    <span
      className={clsx('slick-prev', {
        ['slick-none']: slideIndex === 0,
      })}
      onClick={onClick}
    >
      <SlideArrowPrev />
    </span>
  )

  SliderPrevArrow.propTypes = {
    onClick: PropTypes.func,
  }

  const SliderNextArrow = ({ onClick }) => (
    <span
      className={clsx('slick-next', {
        ['slick-none']: slideIndex === totalItemCount,
      })}
      onClick={onClick}
    >
      <SlideArrowNext />
    </span>
  )

  SliderNextArrow.propTypes = {
    onClick: PropTypes.func,
  }

  const sliderSettings = {
    ...settings,
    prevArrow: <SliderPrevArrow />,
    nextArrow: <SliderNextArrow />,
    beforeChange: (current, next) => {
      setSlideIndex(next)
    },
  }

  return <Slider {...sliderSettings}>{children}</Slider>
}

SlickSlider.propTypes = {
  settings: PropTypes.object,
  children: PropTypes.any,
  itemsCount: PropTypes.number,
  rowCount: PropTypes.number,
}

export default SlickSlider

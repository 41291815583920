import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { EMPLOYMENT_STATUS } from 'utils/constants'

const CandidateBasicProfile = ({
  situation,
  gender,
  stateOrProvinceOfResidence,
  recentAnnualIncome,
  currentEmploymentStatus,
  desiredAnnualIncome,
  dateOfBirth,
  age,
}) => {
  const { t } = useTranslation()

  const splitDate = (dateOfBirth) => {
    return dateOfBirth.split('-')
  }

  const getCurrentEmploymentStatus = () => {
    // Regular, Contractual, etc...
    if (Object.values(EMPLOYMENT_STATUS).includes(currentEmploymentStatus))
      return t(`candidateSlideout.${currentEmploymentStatus.toLowerCase()}`)

    // Others
    return currentEmploymentStatus
  }

  return (
    <div className="candidate__modalTabContInner candidate__modalTabContInner-top">
      <p className="candidate__modalTabContTitle">{t('candidateSlideout.basicProfile')}</p>
      <table className="candidate__modalTabTable">
        <tbody>
          <tr className="candidate__modalTabTr">
            <th className="candidate__modalTabTh">{t('candidate.situation')}</th>
            <th className="candidate__modalTabTh">{t('candidate.gender')}</th>
          </tr>
          <tr className="candidate__modalTabTr">
            <td className="candidate__modalTabTd">{situation}</td>
            <td className="candidate__modalTabTd">{gender}</td>
          </tr>
          <tr className="candidate__modalTabTr">
            <th className="candidate__modalTabTh">{t('candidate.dateofBirth')}</th>
            <th className="candidate__modalTabTh">{t('candidate.residence')}</th>
          </tr>
          <tr className="candidate__modalTabTr">
            <td className="candidate__modalTabTd">
              {splitDate(dateOfBirth)[0]}
              {t('fieldLabels.year')}
              {splitDate(dateOfBirth)[1]}
              {t('fieldLabels.month')}
              {splitDate(dateOfBirth)[2]}
              {t('fieldLabels.day')}（{age}
              {t('suffixes.age')}）
            </td>
            <td className="candidate__modalTabTd">{stateOrProvinceOfResidence}</td>
          </tr>
          <tr className="candidate__modalTabTr">
            <th className="candidate__modalTabTh">{t('candidate.recentAnnualIncome')}</th>
            <th className="candidate__modalTabTh">{t('candidateSlideout.desiredAnnualIncome')}</th>
          </tr>
          <tr className="candidate__modalTabTr">
            <td className="candidate__modalTabTd">
              {recentAnnualIncome ? recentAnnualIncome.toLocaleString(undefined, { maximumFractionDigits: 2 }) : 0}
              {t('suffixes.yen')}
            </td>
            <td className="candidate__modalTabTd">
              {desiredAnnualIncome ? desiredAnnualIncome.toLocaleString(undefined, { maximumFractionDigits: 2 }) : 0}
              {t('suffixes.yen')}
            </td>
          </tr>
          <tr className="candidate__modalTabTr">
            <th className="candidate__modalTabTh">{t('candidate.currentEmployment')}</th>
          </tr>
          <tr className="candidate__modalTabTr">
            <td className="candidate__modalTabTd">{getCurrentEmploymentStatus()}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

CandidateBasicProfile.propTypes = {
  situation: PropTypes.string,
  gender: PropTypes.string,
  stateOrProvinceOfResidence: PropTypes.string,
  finalEducation: PropTypes.string,
  recentAnnualIncome: PropTypes.number,
  currentEmploymentStatus: PropTypes.string,
  desiredAnnualIncome: PropTypes.number,
  dateOfBirth: PropTypes.string,
  age: PropTypes.number,
}

export default CandidateBasicProfile

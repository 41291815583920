import * as ActionTypes from './actionTypes'

export const actionUpdateJobSearchParams = (patch) => {
  return {
    type: ActionTypes.UPDATE_JOB_SEARCH_PARAMS,
    payload: patch,
  }
}

//JobPosting Update
export function updateJobPostingRequest(id) {
  return {
    type: ActionTypes.UPDATE_JOB_POSTING_REQUEST,
    payload: { id },
  }
}

export function updateJobPostingSuccess(data) {
  return {
    type: ActionTypes.UPDATE_JOB_POSTING_SUCCESS,
    payload: { data },
  }
}

export function updateJobPostingFailure(error) {
  return {
    type: ActionTypes.UPDATE_JOB_POSTING_FAILURE,
    payload: { error },
  }
}

export const actionUpdateJobSearchFilters = (patch) => {
  return {
    type: ActionTypes.UPDATE_JOB_SEARCH_FILTERS,
    payload: patch,
  }
}

export const actionResetJobSearchFilters = (patch) => {
  return {
    type: ActionTypes.RESET_JOB_SEARCH_FILTERS,
    payload: patch,
  }
}
export const actionOpenProposalSlideOut = (initialData = null) => ({
  type: ActionTypes.OPEN_PROPOSAL_SLIDEOUT,
  payload: { initialData },
})

export const actionCloseProposalSlideOut = (initialData = null) => ({
  type: ActionTypes.CLOSE_PROPOSAL_SLIDEOUT,
  payload: { initialData },
})

export const actionTriggerJobSearch = () => ({ type: ActionTypes.TRIGGER_JOB_SEARCH })

export const actionUntriggerJobSearch = () => ({ type: ActionTypes.UNTRIGGER_JOB_SEARCH })

export const actionSetJobFilterSlideStatus = (status) => {
  return {
    type: ActionTypes.SET_JOB_FILTER_SLIDE_STATUS,
    payload: status,
  }
}

export const actionSetJobPostingFavoriteModal = (payload) => {
  return {
    type: ActionTypes.SET_JOB_POSTING_FAVORITE_MODAL,
    payload,
  }
}

export const actionSetBatchSelectionStatus = (payload = true) => {
  return {
    type: ActionTypes.SET_BATCH_SELECTION_STATUS,
    payload,
  }
}

export const setJobPostingFilter = (status) => {
  return {
    type: ActionTypes.SET_JOB_POSTING_FILTER,
    payload: status,
  }
}

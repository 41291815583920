import isEmpty from 'lodash/isEmpty'

import { createAsyncThunk } from 'store/helpers'
import { parseAxiosResponse } from 'utils/helper'
import Http from 'utils/Http'
import * as actions from 'store/referrals/actionCreators'
import { actionTriggerLastChanged } from 'store/referral-proposals/actionCreators'
import { markAllThreadMessagesAsRead } from './chatThreads'

const SUCCESS = 200

export const fetchReferralProposal = createAsyncThunk('FETCH_REFERRAL_PROPOSAL', async (id) =>
  parseAxiosResponse(Http.get(`common/referral_proposals/${id}`)).then((res) => res.data)
)

export const openSlideoutWithId = (id) => {
  return async (dispatch) => {
    let triggerLastChanged = false
    try {
      const { data } = await parseAxiosResponse(Http.get(`common/referral_proposals/${id}`))
      let item = data

      if (typeof data.chat_thread?.id !== 'undefined' && !data.read) {
        await dispatch(markAllThreadMessagesAsRead(data.chat_thread.id))
        triggerLastChanged = true
      }

      item = await parseAxiosResponse(Http.post(`common/referral_proposals/${id}/read`)).then((res) => res.data)

      dispatch(actionTriggerLastChanged(triggerLastChanged ? new Date() : null))

      dispatch({
        type: fetchReferralProposal.fulfilled,
        payload: item,
      })

      return { data }
    } catch (e) {
      return { error: e }
    }
  }
}

function _prepareProposalFormData({ attachments, ...body }) {
  const formData = new FormData()

  Object.keys(body).forEach((key) => typeof body[key] !== 'undefined' && formData.append(key, body[key]))

  if (typeof attachments !== 'undefined') {
    attachments.length === 0
      ? formData.append('attachments[]', null) // All attachments are deleted
      : attachments.map((attachment, index) => {
          const isFile = attachment instanceof File
          const key = isFile ? 'file' : 'path'

          formData.append(`attachments[${index}][${key}]`, attachment)
        })
  }

  formData.append('type', 'proposal')

  return formData
}

export const createProposal = createAsyncThunk('CREATE_PROPOSAL', (body) =>
  parseAxiosResponse(Http.post('ad2/referral_proposal', _prepareProposalFormData(body))).then((res) => res.data)
)

export const updateProposal = createAsyncThunk('UPDATE_PROPOSAL', ({ id, ...data }) => {
  const formData = _prepareProposalFormData(data)
  formData.append('_method', 'PUT')
  return parseAxiosResponse(Http.post(`common/referral_proposals/${id}`, formData))
})

export const updateReferralProposal = createAsyncThunk('UPDATE_REFERRAL_PROPOSAL', (id, updates) =>
  parseAxiosResponse(Http.put(`common/referral_proposals/${id}`, updates)).then((res) => res.data)
)

export const fetchSelectionStatusSteps = createAsyncThunk('FETCH_SELECTION_STATUS_STEPS', (statusId) =>
  parseAxiosResponse(Http.get(`/common/filters/selection_steps/${statusId}`))
)

export const fetchReferralProposalSteps = createAsyncThunk('FETCH_REFERRAL_PROPOSAL_STEPS', (id) =>
  parseAxiosResponse(Http.get(`/common/referral_proposals/${id}/steps`))
)

export const fetchReferralProposals = createAsyncThunk('FETCH_REFERRAL_PROPOSALS', (getState) => {
  const { ...params } = getState().referralProposals.params
  const arrays = ['with', 'statuses']

  arrays.forEach((key) => {
    if (isEmpty(params[key] || null)) {
      delete params[key]
      return
    }

    params[key] = params[key].join(',')
  })

  if ('unread' in params) {
    params.unread = params.unread ? 1 : 0
  }

  return parseAxiosResponse(Http.get(`/common/referral_proposals`, { params }))
})

export const fetchEditHistories = createAsyncThunk('FETCH_EDIT_HISTORIES', (id, created_date) => {
  return parseAxiosResponse(Http.get(`/common/applicants/${id}/edit_histories/`, { params: { date: created_date } }))
})

export function getReferralProposals() {
  return (dispatch) => {
    return Http.get(`/common/referralProposalsList`)
      .then((response) => {
        if (response.status === SUCCESS) {
          return dispatch(actions.getReferralListSuccess(response.data))
        }
      })
      .catch((error) => {
        return dispatch(actions.getReferralListFailure(error.response))
      })
  }
}

export const fetchApplicationStatuses = createAsyncThunk('FETCH_APPLICATION_STATUSES', () => {
  return parseAxiosResponse(Http.get(`/common/filters/application_status`))
})

export const fetchProposalStatuses = createAsyncThunk('FETCH_PROPOSAL_STATUSES', () => {
  return parseAxiosResponse(Http.get(`/common/filters/proposal_status`))
})

export const createReferralProposalUsers = createAsyncThunk(
  'CREATE_REFERRAL_PROPOSAL_USERS',
  (referralProposalId, userId) => {
    return parseAxiosResponse(Http.post(`/hr/referral_proposal/${referralProposalId}/${userId}`))
  }
)

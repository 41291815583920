import React from 'react'

import PropTypes from 'prop-types'

const BoxTitle = ({ title }) => <p className="selection__searchTitle">{title}</p>

BoxTitle.propTypes = {
  title: PropTypes.string.isRequired,
}

const BoxFreeWordSearch = ({ freeword_label, children }) => (
  <div className="selection__searchGroup">
    <label htmlFor="" className="selection__searchLabel">
      {freeword_label}
    </label>
    {children}
  </div>
)

BoxFreeWordSearch.propTypes = {
  freeword_label: PropTypes.string.isRequired,
  children: PropTypes.node,
}

const BoxDropdownArea = ({ children }) => <div className="selection__searchFlex">{children}</div>

BoxDropdownArea.propTypes = {
  children: PropTypes.node,
}

const BoxDropDown = ({ dropdown_label, children }) => (
  <div className="selection__searchGroup">
    <label htmlFor="" className="selection__searchLabel">
      {dropdown_label}
    </label>
    <div className="form__selectWrap selection__searchSelectWrap">{children}</div>
  </div>
)

BoxDropDown.propTypes = {
  dropdown_label: PropTypes.string.isRequired,
  children: PropTypes.node,
}

const BoxButtonArea = ({ children }) => <div className="selection__searchAreaBottom">{children}</div>

BoxButtonArea.propTypes = {
  children: PropTypes.node,
}

const BoxTop = ({ children }) => <div className="selection__searchAreaTop">{children}</div>

BoxTop.propTypes = {
  children: PropTypes.node,
}

const BoxSpHeader = ({ spTitle, handleSpHeaderCloseClick }) => (
  <div className="spHeader spHeader-inSec">
    <div className="spHeader__inner">
      <div className="spHeader__left">
        <p className="spHeader__title">{spTitle}</p>
      </div>
      <div className="spHeader__right">
        <p className="spHeader__close" id="searchSideClose" onClick={handleSpHeaderCloseClick}></p>
      </div>
    </div>
  </div>
)

BoxSpHeader.propTypes = {
  spTitle: PropTypes.string.isRequired,
  handleSpHeaderCloseClick: PropTypes.func,
}

const Box = ({ children }) => <div className="selection__searchArea">{children}</div>

Box.propTypes = {
  children: PropTypes.node,
}

Box.Title = BoxTitle
Box.FreeWordSearch = BoxFreeWordSearch
Box.DropdownArea = BoxDropdownArea
Box.DropDown = BoxDropDown
Box.ButtonArea = BoxButtonArea
Box.Top = BoxTop
Box.SpHeader = BoxSpHeader

export default Box

import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Box from './Box'
import { SlideModal, SvgIcon } from 'components'
import { useTranslation } from 'react-i18next'
import { DATE_FORMAT_TIME, EVALUATION_STATUSES, EVALUATION_MUTUAL_EVALUATION } from 'utils/constants'
import { updateEvaluation } from 'services/evaluation'
import { useDispatch, useSelector } from 'react-redux'
import { showNotification } from 'store/notification/actionCreators'
import { actionSetSlideoutItem } from 'store/evaluation/actionCreators'

function EvaluationCard({ evaluationItem }) {
  const [localItem, setLocalItem] = useState(null)
  const [openSub, setOpenSub] = useState(false)
  const item = useSelector((state) => state.evaluation.slideOut.item)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const actionDate = dayjs(evaluationItem.created_at).format(DATE_FORMAT_TIME)
  const evaluatorName = evaluationItem.evaluator_id.full_name
  const evaluatorCompany = evaluationItem.evaluator_id.company.company_name
  const evaluateeName = evaluationItem.evaluated_id.full_name
  const evaluateeCompany = evaluationItem.evaluated_id.company.company_name

  const open = item && item.id === evaluationItem.id

  const handleOpen = () => {
    dispatch(actionSetSlideoutItem(evaluationItem))
  }

  const handleClose = () => {
    dispatch(actionSetSlideoutItem(null))
  }

  const handleSubClose = () => {
    setOpenSub(false)
  }

  const updateEvaluationsStat = (evalStatus, reason) => {
    dispatch(
      updateEvaluation({
        id: item.id,
        status: evalStatus,
        reason_for_disapproval: reason ? reason : 'null',
      })
    )
  }

  const handleApproved = () => {
    updateEvaluationsStat(EVALUATION_STATUSES.approved)
    dispatch(showNotification(t('evaluation.status.approvedNotif'), { type: 'success' }))
  }

  const handleNonApproved = (reason) => () => {
    updateEvaluationsStat(EVALUATION_STATUSES.disapproved, reason)
    setOpenSub(false)
    dispatch(showNotification(t('evaluation.status.disapprovedNotif'), { type: 'success' }))
  }

  const handleRevertApproval = () => {
    updateEvaluationsStat(EVALUATION_STATUSES.notYetApproved)
  }

  const handleSubNonApproval = () => {
    setOpenSub(true)
  }

  useEffect(() => {
    if (open) {
      setLocalItem(item)
    }
  }, [open, item])

  return (
    <>
      <li
        className="adminEvaluation__mainLi selection__mainLi"
        data-modal="modal1"
        data-modalcont="cont1"
        data-id="v26g"
        onClick={handleOpen}
      >
        <Box>
          <Box.ActionDate date={actionDate} header={t('evaluation.listing.headings.actionDate')} />
          <Box.PersonnelDetails
            name={evaluatorName}
            company={evaluatorCompany}
            header={t('evaluation.listing.headings.valuer')}
          />
          <Box.PersonnelDetails
            name={evaluateeName}
            company={evaluateeCompany}
            header={t('evaluation.listing.headings.evaluatee')}
          />
          <Box.Evaluation header={t('evaluation.listing.headings.evaluation')}>
            {evaluationItem.like > evaluationItem.dislike ? (
              <>
                <SvgIcon.Happy className={'evaluation__leftEvaHeaderLinkImg evaluation__leftEvaHeaderLinkImg-1'} />
                {t('evaluation.iconText.like')}
              </>
            ) : (
              <>
                <SvgIcon.Sad className={'evaluation__leftEvaHeaderLinkImg evaluation__leftEvaHeaderLinkImg-2'} />
                {t('evaluation.iconText.dislike')}
              </>
            )}
          </Box.Evaluation>
          <Box.MutualEvaluation header={t('evaluation.listing.headings.mutualEvaluation')}>
            <span
              className={clsx({
                'adminEvaluation__mainMutual-no':
                  evaluationItem.mutual_evaluation === EVALUATION_MUTUAL_EVALUATION.not_yet_evaluated,
              })}
            >
              {t(`evaluation.mutualEvaluation.${evaluationItem.mutual_evaluation}`)}
            </span>
          </Box.MutualEvaluation>
          <Box.Status header={t('evaluation.listing.headings.approvalStatus')}>
            <span
              className={clsx({
                'adminEvaluation__mainApprovalStatus-no': evaluationItem.status !== EVALUATION_STATUSES.approved,
              })}
            >
              {evaluationItem.status}
            </span>
          </Box.Status>
          <Box.DetailBtn btnText={t('editHistory.seeDetails')} />
        </Box>
      </li>
      <SlideModal
        handleClose={handleClose}
        handleSubClose={handleSubClose}
        open={open}
        openSub={openSub}
        item={localItem}
        handleApproved={handleApproved}
        handleNonApproved={handleNonApproved}
        handleRevertApproval={handleRevertApproval}
        handleSubNonApproval={handleSubNonApproval}
      />
    </>
  )
}

EvaluationCard.propTypes = {
  evaluationItem: PropTypes.object.isRequired,
}

export default EvaluationCard

import React from 'react'
import { Modal } from 'components/molecules'
import PropTypes from 'prop-types'
import { ReactComponent as EditIcon } from 'assets/img/edit-icon2.svg'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import CandidateInformationTabsCard from '../../common/CandidateInformationTabsCard'
import { getApplicantDetails } from 'services/applicant'
import { useDispatch } from 'react-redux'

const CandidateInformationSidebar = ({ handleClose, open, row }) => {
  const applicant = row?.fullName
  const prefectureName = row?.prefecture?.prefecture
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const splitDate = (dateOfBirth) => {
    return dateOfBirth.split('-')
  }

  const handleClickEdit = () => {
    dispatch(getApplicantDetails(row.id)).then((response) => {
      if (response.type === 'GET_APPLICANT_DETAILS_SUCCESS') {
        manageData(response.payload.data.data)
      }
    })
  }

  const manageData = async (applicantData) => {
    let copyData = row
    localStorage.removeItem('candidate')
    copyData.day = splitDate(row?.birthday)[2]
    copyData.month = splitDate(row?.birthday)[1]
    copyData.year = splitDate(row?.birthday)[0]
    copyData.birthday = new Date(splitDate(row.birthday)[0], splitDate(row.birthday)[1], splitDate(row.birthday)[2])
    copyData.emergency_contact = row.contact_number ? 'contactNumber' : 'ad_person_incharge'
    copyData.ad_person_incharge = row.ad_person_incharge
    copyData.contact_number = row.contact_number
    copyData.prefecture = row?.prefecture.id
    copyData.prefectureName = prefectureName
    copyData.statusStr = row?.status
    copyData.birthdayCtr = 'success'
    copyData.fullNameCtr = 'success'
    copyData.kanaNameCtr = 'success'
    copyData.current_employment_statusCtr = 'success'
    copyData.current_employment_status = row?.current_employment_status
    copyData.gender = row?.gender

    const candidate = ((await JSON.parse(localStorage.getItem('candidate'))) ?? '{}') || {}
    localStorage.setItem('candidate', JSON.stringify({ ...candidate, ...copyData }))
    history.push({
      pathname: '/applicants/edit-applicant',
      state: {
        file_resume: { name: applicantData?.resume },
        file_cv: { name: applicantData?.work_history_cv },
        other_docs_pdf: applicantData?.other_docs_pdf,
        other_docs_url: applicantData?.other_docs_url,
        mode: 'edit',
      },
    })
  }

  return (
    <Modal
      modifier="ofh"
      containerType="contSlideCont"
      containerModifier="spUp full"
      innerProps={{ modifier: 'rt' }}
      onClose={handleClose}
      open={open}
    >
      <Modal.Header modifier="fixed">
        <div className="modal__contTitleWrap">
          <Modal.Title modifier="mgr">{t('candidateSlideout.modal.title', { applicant })}</Modal.Title>
          <Link to="#" onClick={() => handleClickEdit()} className="btn btn-iconL btn__textBtn">
            <span className="btn__btnIcon">
              <EditIcon />
            </span>
            <span className="pc">{t('common.edit')}</span>
          </Link>
        </div>
        <Modal.CloseButton onClick={handleClose} />
      </Modal.Header>
      <div className="modal__contMain modal__contMain-noh">
        <div className="modal__contMainInner modal__contMainInner-spmgT0">
          <CandidateInformationTabsCard open={open} row={row} />
        </div>
      </div>
    </Modal>
  )
}

CandidateInformationSidebar.propTypes = {
  handleClose: PropTypes.func,
  open: PropTypes.bool,
  row: PropTypes.object,
}

export default CandidateInformationSidebar

import { useEffect } from 'react'

import noop from 'lodash/noop'
import { useDispatch, useSelector } from 'react-redux'

import { bumpLastPing } from 'services/users'
import { selectAuthUser } from 'store/auth/selectors'

const TIMEOUT = 1000 * 15

function UserPingManager() {
  const user = useSelector(selectAuthUser)
  const dispatch = useDispatch()

  useEffect(() => {
    if (typeof user === 'undefined') return

    let timeoutId, abort

    const pingHandler = async () => {
      if (abort) abort()

      const promise = dispatch(bumpLastPing())
      abort = promise.abort
      promise.catch(noop)

      ping()
    }

    function ping() {
      timeoutId = setTimeout(pingHandler, TIMEOUT)
    }

    // Fire immediately
    setTimeout(() => pingHandler(), 2000)

    return () => {
      abort && abort()
      clearTimeout(timeoutId)
    }
  }, [user?.id])

  return null
}

export default UserPingManager

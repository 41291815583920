import Http from 'utils/Http'
import { showLoader, hideLoader } from 'store/loader/actionCreators'
import {
  actionCreateUser,
  actionUpdateUser,
  actionSearchUser,
  actionClearModalValues,
  actionSetSearchCriteria,
  actionSetModalValues,
} from 'store/users/actionCreators'
import { selectUserSearchParams } from 'store/users/selectors'
import { parseAxiosResponse } from 'utils/helper'
import { setUser } from 'store/auth/actionCreators'
import { createAsyncThunk } from '@reduxjs/toolkit'

export function inviteUser(userDetails) {
  return (dispatch) => {
    dispatch(showLoader())
    return Http.post('account_management', userDetails)
      .then((response) => {
        const data = response.data.data
        dispatch(actionCreateUser(data))

        return { data }
      })
      .catch((e) => {
        if (typeof e?.response === 'undefined') return new Error('Unknown Error')

        return { error: e.response.data }
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

export function readUser(userId) {
  return (dispatch) => {
    dispatch(showLoader())

    return Http.get('users/' + userId)
      .then((response) => {
        dispatch(actionSetModalValues(response.data.data))
      })
      .catch((error) => {
        console.log(error)
        // TODO Handle error throw a snackbar, alert, toast, or something
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

export function updateUser(userId, userDetails) {
  return (dispatch) => {
    return parseAxiosResponse(Http.put('account_management/' + userId, userDetails.params)).then((res) =>
      dispatch(actionUpdateUser(res.data))
    )
  }
}

export function updateProfile(userDetails) {
  const formData = new FormData()

  if (!(userDetails.avatar instanceof File)) {
    delete userDetails.avatar
  }

  for (const [key, value] of Object.entries(userDetails)) {
    formData.append(key, value)
  }

  formData.append('_method', 'PUT')

  return (dispatch) => {
    dispatch(showLoader())

    return parseAxiosResponse(
      Http.post('profile', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
    ).then(
      (data) => {
        dispatch(setUser(data))
        return { data }
      },
      (error) => ({ error })
    )
  }
}

export function searchUser() {
  return (dispatch, getState) => {
    dispatch(showLoader())

    return Http.get('account_management', { params: selectUserSearchParams(getState()) })
      .then((response) => {
        dispatch(actionSearchUser(response.data))
      })
      .catch((error) => {
        console.log(error)
        // TODO Handle error throw a snackbar, alert, toast, or something
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

export function changeSearchCriteria(patch) {
  return (dispatch) => {
    dispatch(actionSetSearchCriteria(patch))
  }
}

export function clearModalValues() {
  return (dispatch) => {
    dispatch(actionClearModalValues())
  }
}

/**
 * Bumps the current users last ping time
 *
 * @param {AbortController} abortCtrl
 * @returns
 */
export const bumpLastPing = createAsyncThunk('BUMP_LAST_PING', (_e, thunkApi) => {
  return parseAxiosResponse(Http.post(`/profile/ping`, {}, { signal: thunkApi.signal, silenceErrors: true }))
})

import React from 'react'
import PropTypes from 'prop-types'
import { SvgIcon } from 'components'
import { useIsMobile } from 'utils/hooks'

const Box = ({ children }) => {
  const isMobile = useIsMobile()

  return <div className={!isMobile ? 'adminEvaluation__mainLiInner' : ''}>{children}</div>
}
Box.propTypes = {
  children: PropTypes.node.isRequired,
}

const ActionDate = ({ date, header }) => {
  const isMobile = useIsMobile()
  return (
    <>
      {isMobile ? (
        <div className="adminEvaluation__mainBoxContainer adminEvaluation__pdb2">
          <div className="adminEvaluation__boxHeader">
            <span className="adminEvaluation__headerTitle">{header}</span>
          </div>
          <div className="adminEvaluation__boxDetails">
            <div className="adminEvaluation__mainDate">{date}</div>
          </div>
        </div>
      ) : (
        <div className="adminEvaluation__mainDate">{date}</div>
      )}
    </>
  )
}

ActionDate.propTypes = {
  date: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
}

const PersonnelDetails = ({ name, company, header }) => {
  const isMobile = useIsMobile()
  return (
    <>
      {isMobile ? (
        <div className="adminEvaluation__mainBoxContainer adminEvaluation__pdb2">
          <div className="adminEvaluation__boxHeader">
            <span className="adminEvaluation__headerTitle">{header}</span>
          </div>
          <div className="adminEvaluation__boxDetails">
            <span className="adminEvaluation__mainValuer-txt">{name}</span>
            <span className="adminEvaluation__mainValuer-gray">{company}</span>
          </div>
        </div>
      ) : (
        <div className="adminEvaluation__mainValuer">
          <span className="adminEvaluation__mainValuer-txt">{name}</span>
          <span className="adminEvaluation__mainValuer-gray">{company}</span>
        </div>
      )}
    </>
  )
}

PersonnelDetails.propTypes = {
  name: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
}

const Evaluation = ({ children, header }) => {
  const isMobile = useIsMobile()
  return (
    <>
      {isMobile ? (
        <div className="adminEvaluation__mainBoxContainer adminEvaluation__pdb2">
          <div className="adminEvaluation__boxHeader">
            <span className="adminEvaluation__headerTitle">{header}</span>
          </div>
          <div className="adminEvaluation__boxDetails">
            <div className="adminEvaluation__mainValue-good">{children}</div>
          </div>
        </div>
      ) : (
        <div className="adminEvaluation__mainValue">
          <span className="adminEvaluation__mainValue-good">{children}</span>
        </div>
      )}
    </>
  )
}

Evaluation.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.string.isRequired,
}

const MutualEvaluation = ({ children, header }) => {
  const isMobile = useIsMobile()
  return (
    <>
      {isMobile ? (
        <div className="adminEvaluation__mainBoxContainer adminEvaluation__pdb2">
          <div className="adminEvaluation__boxHeader">
            <span className="adminEvaluation__headerTitle">{header}</span>
          </div>
          <div className="adminEvaluation__boxDetails">
            <div className="adminEvaluation__mainMutual">{children}</div>
          </div>
        </div>
      ) : (
        <div className="adminEvaluation__mainMutual">{children}</div>
      )}
    </>
  )
}

MutualEvaluation.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.string.isRequired,
}

const Status = ({ children, header }) => {
  const isMobile = useIsMobile()
  return (
    <>
      {isMobile ? (
        <div className="adminEvaluation__mainBoxContainer adminEvaluation__pdb2">
          <div className="adminEvaluation__boxHeader">
            <span className="adminEvaluation__headerTitle">{header}</span>
          </div>
          <div className="adminEvaluation__boxDetails">
            <div className="adminEvaluation__mainApprovalStatus">{children}</div>
          </div>
        </div>
      ) : (
        <div className="adminEvaluation__mainApprovalStatus">{children}</div>
      )}
    </>
  )
}
Status.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.string.isRequired,
}

const DetailBtn = ({ btnText, header }) => {
  const isMobile = useIsMobile()
  return (
    <>
      {isMobile ? (
        <div className="adminEvaluation__mainBoxContainer adminEvaluation__pdb2">
          <div className="adminEvaluation__boxHeader">
            <span className="adminEvaluation__headerTitle">{header}</span>
          </div>
          <div className="adminEvaluation__boxDetails">
            <div className="adminEvaluation__mainDetailBtn">
              <span className="adminEvaluation__mainDetailBtn-txt">{btnText}</span>
              <SvgIcon.ArrowRight color={'#D7A600'} />
            </div>
          </div>
        </div>
      ) : (
        <div className="adminEvaluation__mainDetailBtn">
          <span className="adminEvaluation__mainDetailBtn-txt">{btnText}</span>
          <SvgIcon.ArrowRight color={'#D7A600'} />
        </div>
      )}
    </>
  )
}
DetailBtn.propTypes = {
  btnText: PropTypes.string.isRequired,
  header: PropTypes.string,
}

Box.ActionDate = ActionDate
Box.PersonnelDetails = PersonnelDetails
Box.Evaluation = Evaluation
Box.MutualEvaluation = MutualEvaluation
Box.Status = Status
Box.DetailBtn = DetailBtn
export default Box

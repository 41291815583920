import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useIsMobile } from 'utils/hooks'

const Box = ({ children }) => {
  const isMobile = useIsMobile()
  return <div className={`${!isMobile ? 'log__mainLiInner' : ''}`}>{children}</div>
}
Box.propTypes = {
  children: PropTypes.node,
}

const ActionDate = ({ date, header }) => {
  const isMobile = useIsMobile()
  return (
    <>
      {isMobile ? (
        <div className="log__mainBoxContainer log__pdb2">
          <div className="log__boxHeader">
            <span className="log__headerTitle">{header}</span>
          </div>
          <div className="log__boxDetails">
            <div className="log__mainDate">{date}</div>
          </div>
        </div>
      ) : (
        <div className="log__mainDate">{date}</div>
      )}
    </>
  )
}

ActionDate.propTypes = {
  date: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
}

const CompanyDetails = ({ name, id, label, header }) => {
  const isMobile = useIsMobile()

  return (
    <>
      {isMobile ? (
        <div className="log__mainBoxContainer log__pdb2">
          <div className="log__boxHeader">
            <span className="log__headerTitle">{header}</span>
          </div>
          <div className="log__boxDetails">
            <span className="log__mainAccount-txt">{name}</span>
            <span className="log__mainAccount-gray">
              {label}: {id}
            </span>
          </div>
        </div>
      ) : (
        <div className="log__mainCompanyName">
          <span className="log__mainCompanyName-txt">{name}</span>
          <span className="log__mainCompanyName-gray">
            {label}: {id}
          </span>
        </div>
      )}
    </>
  )
}

CompanyDetails.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
}

const AccountType = ({ accountType, header }) => {
  const isMobile = useIsMobile()
  return (
    <>
      {isMobile ? (
        <div className="log__mainBoxContainer log__pdb2">
          <div className="log__boxHeader">
            <span className="log__headerTitle">{header}</span>
          </div>
          <div className="log__boxDetails">
            <div className="log__mainCategory">{accountType}</div>
          </div>
        </div>
      ) : (
        <div className="log__mainCategory">{accountType}</div>
      )}
    </>
  )
}

AccountType.propTypes = {
  accountType: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
}

const AccountDetails = ({ accountName, accountLevel, label, header }) => {
  const isMobile = useIsMobile()

  return (
    <>
      {isMobile ? (
        <div className="log__mainBoxContainer log__pdb2">
          <div className="log__boxHeader">
            <span className="log__headerTitle">{header}</span>
          </div>
          <div className="log__boxDetails">
            <span className="log__mainAccount-txt">{accountName}</span>
            <span className="log__mainAccount-gray">
              {label}: {accountLevel}
            </span>
          </div>
        </div>
      ) : (
        <div className="log__mainAccount">
          <span className="log__mainAccount-txt">{accountName}</span>
          <span className="log__mainAccount-gray">
            {label}: {accountLevel}
          </span>
        </div>
      )}
    </>
  )
}

AccountDetails.propTypes = {
  accountName: PropTypes.string.isRequired,
  accountLevel: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
}

const Action = ({ action, header }) => {
  const isMobile = useIsMobile()
  return (
    <>
      {isMobile ? (
        <div className="log__mainBoxContainer log__pdb2">
          <div className="log__boxHeader">
            <span className="log__headerTitle">{header}</span>
          </div>
          <div className="log__boxDetails">
            <div className="log__mainContent">{action}</div>
          </div>
        </div>
      ) : (
        <div className="log__mainContent">{action}</div>
      )}
    </>
  )
}

Action.propTypes = {
  action: PropTypes.string,
  header: PropTypes.string.isRequired,
}

const JobDetails = ({ jobPostURL, jobPostTitle, jobPostID, label, header }) => {
  const isMobile = useIsMobile()

  return (
    <>
      {isMobile && jobPostURL !== null ? (
        <div className="log__mainBoxContainer log__pdb2">
          {jobPostURL !== null && (
            <div className="log__boxHeader">
              <span className="log__headerTitle">{header}</span>
            </div>
          )}
          {jobPostURL !== null && (
            <div className="log__boxDetails">
              <span className="log__mainRecruit-txt">
                <Link to={jobPostURL}>{jobPostTitle}</Link>
              </span>
              <span className="log__mainRecruit-gray2">
                {label}: {jobPostID}
              </span>
            </div>
          )}
        </div>
      ) : (
        <div className="log__mainRecruit">
          <span className="log__mainRecruit-txt">
            <Link to={jobPostURL}>{jobPostTitle}</Link>
          </span>
          {jobPostID && (
            <span className="log__mainRecruit-gray">
              {label}: {jobPostID}
            </span>
          )}
        </div>
      )}
    </>
  )
}

JobDetails.propTypes = {
  jobPostURL: PropTypes.string,
  jobPostTitle: PropTypes.string,
  jobPostID: PropTypes.string,
  label: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
}

Box.ActionDate = ActionDate
Box.CompanyDetails = CompanyDetails
Box.AccountType = AccountType
Box.AccountDetails = AccountDetails
Box.Action = Action
Box.JobDetails = JobDetails
export default Box

import React from 'react'
import PropTypes from 'prop-types'
import { BlockEl, Button } from 'components'
import { ReactComponent as ArrowRight } from 'assets/img/arrow-right.svg'

const TitleRow = ({ title, handleSeemoreClick, seeMoreFlag }) => (
  <BlockEl blockElClass="dashboard__title" modifier="wrap" noBlockEl>
    <p className="dashboard__title">{title}</p>
    {seeMoreFlag && (
      <Button blockElClass="dashboard__btn-link" component="a" onClick={handleSeemoreClick}>
        <span className="dashboard__btn-txt">もっと見る</span>
        <ArrowRight />
      </Button>
    )}
  </BlockEl>
)
TitleRow.propTypes = {
  title: PropTypes.string,
  handleSeemoreClick: PropTypes.func,
  seeMoreFlag: PropTypes.bool,
}

export default TitleRow

import * as ActionTypes from './actionTypes'

export function loadUserLogsRequest() {
  return {
    type: ActionTypes.LOAD_USER_LOGS_REQUEST,
  }
}

export function loadUserLogsSuccess(res) {
  return {
    type: ActionTypes.LOAD_USER_LOGS_SUCCESS,
    payload: res,
  }
}

export function loadUserLogsFailure(error) {
  return {
    type: ActionTypes.LOAD_USER_LOGS_FAILURE,
    payload: { error },
  }
}

export function patchSearchCriteria(patch) {
  return {
    type: ActionTypes.PATCH_USER_LOGS_SEARCH_CRITERIA,
    payload: patch,
  }
}

export const setUserLogsFilter = (status) => {
  return {
    type: ActionTypes.SET_USER_LOGS_FILTER,
    payload: status,
  }
}

import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import PlusIcon from 'assets/img/plus-icon.svg'
import { useTranslation } from 'react-i18next'
import { Pagination } from 'components/molecules'
import { Link as RouterLink } from 'react-router-dom'
import { BlockEl, Button } from 'components/atoms'
import PropTypes from 'prop-types'
import CandidateRecord from '../CandidateRecord'

const ApplicantsList = (props) => {
  const { handlePageChange } = props
  const [list, setList] = useState([])
  const { t } = useTranslation()
  const [applicantCount, setApplicantCount] = useState(0)
  const { applicants } = useSelector((state) => state?.applicant)
  const { total, currentPage, lastPage } = useSelector((state) => state?.applicant.pagination)

  useEffect(() => {
    setList(applicants?.data ? applicants?.data : applicants)
    setApplicantCount(total)
  }, [props, applicants])

  const addApplicantLocation = {
    pathname: '/applicants/add-applicant',
    state: { mode: 'add' },
  }

  return (
    <BlockEl blockElClass="candidate__main" id="candidateMain">
      <BlockEl blockElClass="candidate__mainHeader">
        <p className="candidate__headerTitle">
          {t('pageTitles.ApplicantCandidates')}
          <span className="candidate__headerTitle-num">{applicantCount}</span>
          <span className="candidate__headerTitle-small">{t('suffixes.name')}</span>
        </p>

        <Button component={RouterLink} to={addApplicantLocation} modifier="iconL" variant="shadow">
          <span className="btn__btnIcon">
            <img src={PlusIcon} alt="" className="modal__schedulePlusIcon" width="32" height="32" />
          </span>
          {t('buttonLabels.candidate')} <span className="pc">{t('buttonLabels.toAdd')}</span>
        </Button>
      </BlockEl>
      <BlockEl className="candidate__mainContWrap">
        <ul className="candidate__mainUl">
          <li className="candidate__mainLi candidate__mainLi-noBg">
            <BlockEl blockElClass="candidate__mainName" className="candidate" modifier="ais pdb8">
              {t('tableHeaders.candidateName')}
            </BlockEl>
            <BlockEl className="candidate__mainName2" blockElClass="candidate" modifier="pdb8">
              {t('tableHeaders.personIncharge')}
            </BlockEl>
            <BlockEl className="candidate__mainAge" blockElClass="candidate" modifier="pdb8">
              {t('tableHeaders.age')}
            </BlockEl>
            <BlockEl className="candidate__mainSituation" blockElClass="candidate" modifier="pdb8">
              {t('tableHeaders.status')}
            </BlockEl>
            <BlockEl className="candidate__mainApplications" blockElClass="candidate" modifier="pdb8">
              {t('tableHeaders.applicationCount')}
            </BlockEl>
            <BlockEl className="candidate__mainMemo" blockElClass="candidate" modifier="pdb8">
              {t('tableHeaders.memo')}
            </BlockEl>
          </li>
          {list?.map((row, index) => {
            return <CandidateRecord row={row} key={index} />
          })}
        </ul>
        <Pagination page={parseInt(currentPage)} totalPages={lastPage} onPageChange={handlePageChange} />
      </BlockEl>
    </BlockEl>
  )
}

ApplicantsList.propTypes = {
  result: PropTypes.object,
  handlePageChange: PropTypes.func,
}

export default ApplicantsList

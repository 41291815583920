import React, { useEffect } from 'react'
import OtherJobsBox from '../OtherJobsCard/Box'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { fetchOtherJobs } from 'services/otherJobs'
import { actionUpdateOtherJobsParams } from 'store/other-jobs/actionCreators'
import { Pagination } from 'components/molecules'
import { useHistory } from 'react-router-dom'

const OtherJobsItem = ({ item }) => {
  const { t } = useTranslation()
  const desiredPeriodOfEmployment = () => {
    if (item.desired_period_of_employment === 'year') return t('formValues.year')
    const date = new Date(item.desired_period_of_employment)
    return date.getFullYear() + t('dateSymbols.year') + date.getMonth() + t('dateSymbols.month')
  }

  return (
    <OtherJobsBox key={item.id}>
      <OtherJobsBox.Header>
        <OtherJobsBox.TitleArea title={item.job_title} />
      </OtherJobsBox.Header>

      <OtherJobsBox.PositionArea position={item.available_position?.name} />

      <OtherJobsBox.WorkPlaceEmploymentForm
        work_place={item.work_locations}
        employment_form={item.employment_status}
        work_place_txt={t('otherJobs.work_place')}
        employment_form_txt={t('otherJobs.employment_form')}
      />

      <OtherJobsBox.RecruitmentForm
        recruitment_date={desiredPeriodOfEmployment()}
        min_age={item.min_acceptable_age}
        max_age={item.max_acceptable_age}
        to_be_hired_count={item.number_of_positions}
        gender={item.gender}
        recruitment_date_txt={t('otherJobs.recruitment_date')}
        age_txt={t('otherJobs.age')}
        to_be_hired_count_txt={t('otherJobs.to_be_hired_count')}
        gender_txt={t('otherJobs.gender')}
      />
    </OtherJobsBox>
  )
}

OtherJobsItem.propTypes = {
  item: PropTypes.object,
}

const OtherJobsCard = ({ jobPostingId }) => {
  const dispatch = useDispatch()
  const items = useSelector((state) => state.otherJobs.items)
  const state = useSelector((state) => state.otherJobs.listState)
  const { currentPage, lastPage } = useSelector((state) => state.otherJobs.pagination)
  const history = useHistory()

  useEffect(() => {
    dispatch(actionUpdateOtherJobsParams({ id: jobPostingId, page: 1 }))
    dispatch(fetchOtherJobs())
  }, [jobPostingId])

  const handlePageChange = (page) => {
    if (state === 'pending') return

    dispatch(actionUpdateOtherJobsParams({ page }))
    dispatch(fetchOtherJobs())
  }

  const handleClick = (job_posting_id) => {
    history.push({ pathname: '/job-postings/' + job_posting_id })
  }

  return (
    <>
      {state === 'fulfilled' && (
        <ul className="otherjob__ul">
          {items.map((item) => (
            <li className="otherjob__li" key={item.id} value={item.id} onClick={() => handleClick(item.id)}>
              {' '}
              <OtherJobsItem item={item} />
            </li>
          ))}
        </ul>
      )}
      <div className="otherjob__pagination">
        <Pagination onPageChange={handlePageChange} page={currentPage} totalPages={lastPage} />
      </div>
    </>
  )
}

OtherJobsCard.propTypes = {
  jobPostingId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

export default OtherJobsCard

import React from 'react'
import SelectItem from './SelectItem'
import PropTypes from 'prop-types'
import { BlockEl, Checkbox } from 'components/atoms/index.js'
import FormGroupCheck from '../FormGroupCheck/FormGroupCheck.js'
import { components } from 'react-select'
import find from 'lodash/find'
function SearchDropdown({ selectOptions, searchDropdownSet: { items, reset } }) {
  const selectValue = items.map((item) => find(selectOptions, { id: item }))

  const Option = (props) => {
    return (
      <BlockEl style={{ height: '30px', background: 'none' }}>
        <components.Option {...props}>
          <FormGroupCheck.Item
            Input={Checkbox}
            inputProps={{
              onChange: () => null,
              checked: props.isSelected,
            }}
            type="square"
            label={props.label}
          />
        </components.Option>
      </BlockEl>
    )
  }

  const handleChange = (values) => {
    reset(values.map((value) => value.id))
  }

  return (
    <SelectItem
      options={selectOptions}
      isMulti
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      components={{ Option }}
      onChange={handleChange}
      allowSelectAll={true}
      value={selectValue}
    />
  )
}

SearchDropdown.propTypes = {
  onChange: PropTypes.func,
  isSelected: PropTypes.bool,
  label: PropTypes.string,
  data: PropTypes.any,
  value: PropTypes.any,
  options: PropTypes.any,
  selectOptions: PropTypes.any,
  searchDropdownSet: PropTypes.any,
}
export default SearchDropdown

import React from 'react'
import { useSelector } from 'react-redux'
import { FormGroupCheck } from 'components/molecules'
import { useFormContext } from 'react-hook-form'
import { Checkbox } from 'components/atoms'
import PropTypes from 'prop-types'
import { USER_STATUSES } from 'utils/constants'

const ConsultantInCharge = (props) => {
  const { onHandleChange, sideFilterDisplay, selectedAdInCharge, submitAdInChargeList } = props
  const { register } = useFormContext()
  const adInChargeListState = useSelector((state) => state.adInCharge.success)
  const users = useSelector((state) => state.adInCharge.adInchargelist?.data)
  const activeUsers = users?.filter((data) => data.full_name !== ' ' && data.status['id'] == USER_STATUSES.active)

  const isChecked = (value) => {
    return selectedAdInCharge && selectedAdInCharge.length > 0 && selectedAdInCharge.includes(value)
  }

  const selectedIsChecked = (value) => {
    return submitAdInChargeList && submitAdInChargeList.length > 0 && submitAdInChargeList.includes(value)
  }

  return (
    <FormGroupCheck blockElClass="">
      {adInChargeListState &&
        activeUsers.map((ad_incharge, index) => {
          if (sideFilterDisplay) {
            if (isChecked(ad_incharge?.id)) {
              return (
                <FormGroupCheck.Item
                  key={index}
                  Input={Checkbox}
                  className="candidate__conditonCheckItem"
                  inputProps={{
                    ...register('ad_person_incharge'),
                    value: ad_incharge?.id,
                    checked: selectedIsChecked(ad_incharge?.id),
                    onChange: (e) => onHandleChange(e),
                    className: 'form__checkItem2',
                  }}
                  type="square"
                  label={ad_incharge?.full_name}
                  labelProps={{ className: 'form__checkLabel2-mgl0' }}
                />
              )
            }
          } else {
            return (
              <FormGroupCheck.Item
                key={index}
                Input={Checkbox}
                inputProps={{
                  ...register('ad_person_incharge'),
                  value: ad_incharge?.id,
                  checked: isChecked(ad_incharge?.id),
                  onChange: onHandleChange,
                  className: 'form__checkItem2',
                }}
                modifier="w50"
                type="square"
                label={ad_incharge?.full_name}
              />
            )
          }
        })}
    </FormGroupCheck>
  )
}

ConsultantInCharge.propTypes = {
  onHandleChange: PropTypes.func,
  selectedAdInCharge: PropTypes.oneOfType([PropTypes.array, PropTypes.array]),
  submitAdInChargeList: PropTypes.oneOfType([PropTypes.array, PropTypes.array]),
  handleChecked: PropTypes.func,
  sideFilterDisplay: PropTypes.bool,
}

export default ConsultantInCharge

import React from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectJobPosting } from 'store/job-postings/selectors'
import { WorkLocations, NewlineText, BlockEl } from 'components'

function JobSummaryCard({ open, openDesc, onTitleClick }) {
  const jobPosting = useSelector(selectJobPosting)
  const { t: mainT } = useTranslation('translation')
  const t = (key, ...args) => mainT(`jobPosting.summary.${key}`, ...args)

  const concatIndustries = (jobPosting?.company?.industries || []).map(({ name }) => name).join('\n')

  return (
    <>
      <h2 className="evaluation__rightTitle">{t('title')}</h2>
      <BlockEl blockElClass="evaluation__rightTitleSp" modifier={openDesc ? 'open' : ''} onClick={onTitleClick}>
        {t('title')}
      </BlockEl>
      <BlockEl component={'dl'} blockElClass={'evaluation__rightDl'} modifier={open ? 'open' : ''}>
        <dt className="evaluation__rightDt">{t('occupation')}</dt>
        <dd className="evaluation__rightDd">{jobPosting?.available_position?.name}</dd>
        <dt className="evaluation__rightDt">{t('industry')}</dt>
        <dd className="evaluation__rightDd">
          <NewlineText text={concatIndustries} />
        </dd>
        {jobPosting?.job_posting_type === 'proposal' ? (
          <>
            <dt className="evaluation__rightDt">{t('advertisingBudget')}</dt>
            <dd className="evaluation__rightDd">
              {jobPosting?.advertising_budget === 'specify'
                ? `${jobPosting['min_advertising_budget']} ~ ${jobPosting['max_advertising_budget']} ${t('yen')}`
                : jobPosting['advertising_budget'] === 'consult' && t('consult')}
            </dd>
            <dt className="evaluation__rightDt">{t('workLocation')}</dt>
            <dd className="evaluation__rightDd">
              <WorkLocations workLocations={jobPosting?.work_locations} />
            </dd>
          </>
        ) : (
          <>
            <dt className="evaluation__rightDt">{t('annualIncome')}</dt>
            <dd className="evaluation__rightDd">
              {mainT('jobPosting.card.min_max_yen', {
                min: jobPosting?.min_estimated_annual_salary,
                max: jobPosting?.max_estimated_annual_salary,
              })}
            </dd>
            <dt className="evaluation__rightDt">{t('workLocation')}</dt>
            <dd className="evaluation__rightDd">
              <WorkLocations workLocations={jobPosting?.work_locations} />
            </dd>
            <dt className="evaluation__rightDt">{t('amountOfRemuneration')}</dt>
            <dd className="evaluation__rightDd">
              {/* See https://sprobeinc.backlog.com/view/HUUNO-342#comment-11750775 */}
              {/* {rew_cont_fee % 2 === 1 ? rew_cont_fee + '万' : '理論年収の' + rew_cont_fee} */}
            </dd>
          </>
        )}
      </BlockEl>
    </>
  )
}

JobSummaryCard.propTypes = {
  open: PropTypes.bool,
  openDesc: PropTypes.bool,
  onTitleClick: PropTypes.func,
}

export default JobSummaryCard

import React from 'react'
import PropTypes from 'prop-types'
import { USER_STATUSES } from 'utils/constants'
import EllipsisIcon from 'assets/img/threedot.svg'
import { BlockEl } from 'components/atoms'

const BoxNameHeader = ({ nameHeader }) => (
  <div className="adminMember__mainName adminMember__mainName-ais adminMember-pdb0">{nameHeader}</div>
)

BoxNameHeader.propTypes = {
  nameHeader: PropTypes.string.isRequired,
}

const BoxHeader = ({ classNameHeader, headerLabel }) => (
  <div className={`adminMember__${classNameHeader} adminMember-pdb0`}>{headerLabel}</div>
)

BoxHeader.propTypes = {
  classNameHeader: PropTypes.string.isRequired,
  headerLabel: PropTypes.string.isRequired,
}

const BoxCertificationHeader = ({ certificationHeader }) => (
  <div className="adminMember__mainCertification member-pdb0">{certificationHeader}</div>
)

BoxCertificationHeader.propTypes = {
  certificationHeader: PropTypes.string.isRequired,
}

const BoxItem = ({ classNameItem, item, headerLabel }) => (
  <div data-title={headerLabel} className={`adminMember__${classNameItem}`}>
    {item}
  </div>
)

BoxItem.propTypes = {
  classNameItem: PropTypes.string.isRequired,
  item: PropTypes.string.isRequired,
  headerLabel: PropTypes.string.isRequired,
}

const statusModMapping = {
  [USER_STATUSES.active]: 'ac',
  [USER_STATUSES.suspended]: 'pause',
  [USER_STATUSES.archived]: 'archive',
  [USER_STATUSES.pending]: 'invite',
}

const BoxStatus = ({ invitedBy, invitedBySuffix, status, statusId, isExpired, headerLabel }) => (
  <BlockEl
    data-title={headerLabel}
    bec="adminMember__mainStatus"
    modifier={{ col: statusId === USER_STATUSES.pending }}
  >
    <div>
      <BlockEl
        component="span"
        noBlockEl
        bec="adminMember__mainStatus"
        modifier={{
          [statusModMapping[statusId]]: statusId !== USER_STATUSES.pending,
          invite: !isExpired && statusId === USER_STATUSES.pending,
          expired: isExpired,
        }}
      >
        {status}
      </BlockEl>
      {statusId === USER_STATUSES.pending && (
        <span className="adminMember__mainStatus-gray">{invitedBy + invitedBySuffix}</span>
      )}
    </div>
  </BlockEl>
)

BoxStatus.propTypes = {
  invitedBySuffix: PropTypes.string.isRequired,
  invitedBy: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  isExpired: PropTypes.bool.isRequired,
  statusId: PropTypes.number.isRequired,
  headerLabel: PropTypes.string.isRequired,
}

const BoxPseudoLogin = ({ children }) => <div className="adminMember__mainLoginBtn member-pdb0">{children}</div>

BoxPseudoLogin.propTypes = {
  children: PropTypes.node,
}

const BoxUpdateStatus = ({ children }) => (
  <>
    <p className="adminMember__mainDot memberDot">
      <img src={EllipsisIcon} alt="" className="search__mainContFooterDotImg" width="20" height="8" />
    </p>
    {children}
    <div className="member__mainDotBg"></div>
  </>
)

BoxUpdateStatus.propTypes = {
  children: PropTypes.node,
}

const Box = ({ children }) => <div className="adminMember__mainLiInner">{children}</div>

Box.propTypes = {
  children: PropTypes.node,
}

Box.NameHeader = BoxNameHeader
Box.Header = BoxHeader
Box.Item = BoxItem
Box.Status = BoxStatus
Box.PseudoLogin = BoxPseudoLogin
Box.UpdateStatus = BoxUpdateStatus

export default Box

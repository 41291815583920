import React, { useState, useEffect } from 'react'
import { FormItemLabel, FormHeader, PrefectureModal, RecommendationFooter, Radio, ErrorMessage } from 'components'
import { FormGroupCheck, FormSupArea, FormGroup } from 'components/molecules'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useForm, useFieldArray, FormProvider } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'
import { getApplicants } from 'services/applicant'
import { useTranslation } from 'react-i18next'
import { Button, Input, DateText } from 'components/atoms'
import { COMPLETE_DATE_FORMAT_JAPANESE } from 'utils/constants'
import { makeFormGroupStateGetter } from 'utils/helper'
import { useCoWorkers } from 'utils/hooks'
import EmploymentStatus from './employmentStatus.js'
import EmergencyContact from './emergencyContact.js'
import ApplicantFullname from './applicantFullname.js'
import RecommendationInfo from './recommendationInfo.js'
import ApplicantDocuments from './applicantDocuments.js'
import dayjs from 'dayjs'

const defaultNew = {
  lastname: '',
  firstname: '',
  kana_lastname: '',
  kana_firstname: '',
  prefecture: '',
  month: '',
  day: '',
  year: '1995',
  gender: '',
  current_employment_status: '',
  recent_annual_salary: '0',
  desired_annual_salary: '0',
  contact_number: '',
  ad_person_incharge: '',
  resume: '',
  work_history_cv: '',
  testimonial: '',
  special_notes: '',
  emergency_contact: '',
}

const RecommendationForm = (props) => {
  const { id } = useParams()
  const { editmode, mode, loadedExisting, candidateSelected } = props
  const user = useSelector((state) => state.auth.user?.data)
  const { users: adInChargeList } = useCoWorkers()
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()

  const [formErrors, setFormErrors] = useState({})
  const [successPrefecture, setSuccessPrefecture] = useState('')
  const [successEmployment, setSuccessEmployment] = useState('')
  const [successGender, setSuccessGender] = useState('')
  const [successBirthday, setSuccessBirthday] = useState('')
  const [successAnnual, setSuccessAnnual] = useState('')
  const [successRecentAnnual, setSuccessRecentAnnual] = useState('')
  const [successContact, setSuccessContact] = useState('')
  const [successFullname, setSuccessFullname] = useState('')
  const [successKanaFullname, setSuccessKanaFullName] = useState('')
  const [successRecommendationInfo, setSuccessRecommendationInfo] = useState('')
  const [successResume, setSuccessResume] = useState('')
  const [successPdfWorkCv, setSuccessPdfWorkCv] = useState('')
  const [showPrefecture, setShowPrefecture] = useState(false)
  const [adPersonInCharge, setAdPersonInCharge] = useState({})
  const [contactNumber, setContactNumber] = useState('')
  const [showAdDropdown, setShowAdDropdown] = useState(false)
  const [showOthers, setShowOthers] = useState(false)
  const [showContactText, setShowContactText] = useState(false)
  const [selectedResume, setSelectedResume] = useState({})
  const [selectedWorkCv, setSelectedWorkCv] = useState({})
  const [selectedOtherPdf, setSelectedOtherPdf] = useState([])
  const [empStat, setEmpStat] = useState('')
  const [fieldState, setFieldState] = useState([])
  const [existingLoaded, setExistingLoaded] = useState(false)
  const adArea = document.getElementById('adArea')
  const adSearch = document.getElementById('adSearch')
  const [adcontactName] = useState('')
  const [applicantId, setApplicantId] = useState('')
  const [existingResume, setExistingResume] = useState('')
  const [existingWorkCv, setExistingWorkCv] = useState('')
  const [prefectureId, setPrefectureId] = useState('')
  const [jobPostingId, setJobPostingId] = useState('')
  const [applicants, setApplicants] = useState([])
  const [unfilled, setUnfilled] = useState(12)
  const [otherPdfString, setOtherPdfString] = useState('')

  const [data, setData] = useState(defaultNew)
  const methods = useForm({ defaultValues: defaultNew, mode: 'all' })
  const {
    register,
    handleSubmit,
    clearErrors,
    setError,
    reset,
    setValue,
    getValues,
    control,
    watch,
    getFieldState,
    formState: { errors, isValid, isSubmitting },
  } = methods

  const rules = {
    testimonial: {
      required: t('validation.required'),
      maxLength: { value: 3000, message: t('validation.maxLength', { length: 3000 }) },
    },
    special_notes: {
      maxLength: { value: 3000, message: t('validation.maxLength', { length: 3000 }) },
    },
    month: {
      required: t('hints.pleaseEnter'),
      maxLength: { value: 2, message: t('validation.maxLength', { length: 2 }) },
      min: { value: 1, message: t('validation.min', { field: t('fieldLabels.month'), min: 1 }) },
      max: { value: 12, message: t('validation.max', { field: t('fieldLabels.month'), max: 12 }) },
    },
    day: {
      required: t('hints.pleaseEnter'),
      maxLength: { value: 2, message: t('validation.maxLength', { length: 2 }) },
      min: { value: 1, message: t('validation.min', { field: t('fieldLabels.day'), min: 1 }) },
      max: { value: 31, message: t('validation.max', { field: t('fieldLabels.day'), max: 31 }) },
    },
    year: {
      maxLength: { value: 4, message: t('validation.maxLength', { length: 4 }) },
      min: {
        value: new Date().getFullYear() - 70,
        message: t('validation.min', { field: t('fieldLabels.year'), min: new Date().getFullYear() - 70 }),
      },
      max: {
        value: new Date().getFullYear() - 18,
        message: t('validation.max', { field: t('fieldLabels.year'), max: new Date().getFullYear() - 18 }),
      },
    },
  }

  const { byName } = makeFormGroupStateGetter(getFieldState)

  let { fields, append, remove, update } = useFieldArray({
    control,
    name: 'other_docs',
  })

  const checkFilled = (data) => {
    const year = +data?.year
    const month = +data?.month - 1
    const day = +data?.day

    const bDate = dayjs().year(year).month(month).date(day)

    bDate.year() === year && bDate.month() === month && bDate.date() === day
      ? setSuccessBirthday('success')
      : setSuccessBirthday('')

    data?.lastname && data?.firstname ? setSuccessFullname('success') : setSuccessFullname('')
    data?.kana_lastname && data?.kana_firstname ? setSuccessKanaFullName('success') : setSuccessKanaFullName('')
    data?.prefecture_name !== '選択してください' && data?.prefecture_name
      ? setSuccessPrefecture('success')
      : setSuccessPrefecture('')
    data?.gender ? setSuccessGender('success') : setSuccessGender('')
    data?.current_employment_status && data?.current_employment_status !== 'Others'
      ? setSuccessEmployment('success')
      : data?.current_employment_status === 'Others' && data?.other_status
      ? setSuccessEmployment('success')
      : setSuccessEmployment('')
    parseInt(data?.recent_annual_salary) > 0 ? setSuccessRecentAnnual('success') : setSuccessRecentAnnual('')
    parseInt(data?.desired_annual_salary) > 0 ? setSuccessAnnual('success') : setSuccessAnnual('')
    data?.resume ? setSuccessResume('success') : setSuccessResume('')
    data?.work_history_cv ? setSuccessPdfWorkCv('success') : setSuccessPdfWorkCv('')
    data?.testimonial && data?.testimonial.length <= 5000
      ? setSuccessRecommendationInfo('success')
      : setSuccessRecommendationInfo('')

    if (mode === 'preview_edit') {
      let storedData = JSON.parse(localStorage?.getItem('recommendation_data'))
      append(storedData?.fields)
      data?.existingResume && setExistingResume(data?.resume)
      data?.existingWorkCv && setExistingWorkCv(data?.work_history_cv)
      setApplicantId(data?.applicantId)
      data?.current_employment_status === 'Others' && setShowOthers(true)
      data?.contact_number && setShowContactText(true)
      data?.current_employment_status === 'Others' && data?.other_status && setSuccessEmployment('success')
    }
  }

  useEffect(() => {
    const emergencyContact = getValues('emergency_contact')
    if (
      (emergencyContact === 'contactNumber' && contactNumber) ||
      (emergencyContact === 'ad_person_incharge' && adPersonInCharge)
    ) {
      setSuccessContact('success')
    } else {
      setSuccessContact('')
    }
  }, [getValues().emergency_contact, contactNumber])

  useEffect(() => {
    if (mode === 'existing' && !existingLoaded) {
      append(fieldState)
      setExistingLoaded(true)
    }
  }, [fieldState])

  const setUnfilledCount = (data) => {
    const year = +data?.year
    const month = +data?.month - 1
    const day = +data?.day

    const bDate = dayjs().year(year).month(month).date(day)

    let counter = 0
    data.lastname !== '' && data.firstname !== '' && ++counter
    data.kana_lastname !== '' && data.kana_firstname !== '' && ++counter
    data.prefecture_name !== '' && ++counter
    bDate.year() === year && bDate.month() === month && bDate.date() === day && ++counter
    data.gender !== '' && ++counter
    data.current_employment_status !== undefined && ++counter
    data.desired_annual_salary !== '0' && ++counter
    data.recent_annual_salary !== '0' && ++counter
    data.work_history_cv !== '' && ++counter
    data.testimonial !== '' && data?.testimonial?.length <= 5000 && ++counter
    data.resume !== '' && ++counter
    if (
      (data.emergency_contact === 'ad_person_incharge' && data.ad_person_incharge !== '') ||
      (data.emergency_contact === 'contactNumber' && data.contact_number !== '')
    ) {
      ++counter
    }

    const count = 12 - parseInt(counter)
    setUnfilled(+count)
  }

  useEffect(() => {
    const subscription = watch((data) => {
      checkFilled(data)
      setUnfilledCount(data)
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [watch])

  const handleSelectedOtherPdfs = (index, state) => {
    var otherPdfs = selectedOtherPdf
    otherPdfs[index] = state
    setSelectedOtherPdf(otherPdfs)
  }

  const handleOnClose = () => {
    setShowPrefecture(false)
  }

  useEffect(() => {
    const birthday = new Date(data?.birthday)
    reset()
    setSelectedResume({})
    setSelectedWorkCv({})
    reset(
      {
        lastname: data?.lastname,
        firstname: data?.firstname,
        kana_lastname: data?.kana_lastname,
        kana_firstname: data?.kana_firstname,
        prefecture:
          mode === 'preview' || mode === 'preview_edit'
            ? data?.prefecture?.prefecture
            : mode === 'existing'
            ? data?.prefecture?.id
            : mode === 'preview_edit'
            ? data?.prefecture_name
            : null,
        prefecture_name:
          mode === 'existing' ? data?.prefecture?.prefecture : mode === 'preview_edit' ? data?.prefecture_name : '',
        gender: data?.gender,
        year: mode === 'existing' ? birthday.getFullYear() : data?.year,
        month: mode === 'existing' ? birthday.getMonth() + 1 : data?.month,
        day: mode === 'existing' ? ('0' + props?.applicant.birthday).slice(-2) : data?.day,
        current_employment_status:
          (mode === 'existing' || mode === 'preview_edit') && data?.current_employment_status
            ? data?.current_employment_status
            : '',
        other_status:
          (mode === 'existing' || mode === 'preview_edit') && data?.current_employment_status === 'Others'
            ? data?.other_status
            : '',
        recent_annual_salary: data?.recent_annual_salary,
        desired_annual_salary: data?.desired_annual_salary,
        emergency_contact:
          mode === 'preview_edit' && data?.ad_person_incharge !== ''
            ? 'ad_person_incharge'
            : mode === 'existing' && data?.ad_person_incharge !== ''
            ? 'ad_person_incharge'
            : '',
        contact_number: mode === 'existing' || mode === 'preview_edit' ? data?.contact_number : '',
        ad_person_incharge:
          mode === ' existing'
            ? data?.ad_person_incharge?.lastname +
              ' ' +
              data?.ad_person_incharge?.firstname +
              '/' +
              data?.ad_person_incharge?.contact_number
            : mode === 'preview_edit'
            ? data?.contactName
            : '',
        resume: data?.resume,
        work_history_cv: data?.work_history_cv,
        special_notes: mode === 'preview_edit' ? data?.special_notes : '',
        testimonial: mode === 'existing' || mode === 'preview_edit' ? data?.testimonial : '',
      },
      {
        keepDefaultValues: true,
        keepDirtyValues: false,
        keepValues: false,
      }
    )
    checkFilled(data)
    mode === 'existing' && setPrefectureId(data?.prefecture?.id)
    mode !== 'new' && data?.ad_person_incharge !== {} && setShowAdDropdown(true)
    mode === 'preview_edit' && setEmpStat(data?.current_employment_status)
    mode === 'preview_edit' && setSelectedResume(props?.files.file_resume)
    mode === 'preview_edit' && setSelectedWorkCv(props?.files.file_cv)
    mode === 'existing' && setOtherPdfString(data?.other_docs_pdf)
    data?.other_status ? setShowOthers(true) : setShowOthers(false)
  }, [data])

  useEffect(() => {
    adSearchEve()
    data.current_employment_status === t('candidate.other') && setShowOthers(true)
    setJobPostingId(id)
    dispatch(getApplicants()).then((response) => {
      if (response.type === 'GET_APPLICANTS_LIST_SUCCESS') {
        setApplicants(response.payload.data.data)
      }
    })
  }, [])

  // INIT
  useEffect(() => {
    let storedData = JSON.parse(localStorage.getItem('recommendation_data'))

    if (mode === 'new') {
      setAdPersonInCharge(user)
      setValue('ad_person_incharge', `${user.full_name}/${user.contact_number}`)
      // setValue('emergency_contact', 'ad_person_incharge', { shouldDirty: true })
    }

    if (mode === 'preview') {
      setData(props?.finalData)
    }

    if (mode === 'existing') {
      const adPerson = props?.applicant?.ad_person_incharge
      setData(props?.applicant)
      setApplicantId(props?.applicant?.id)
      setPrefectureId(props?.applicant?.prefecture?.id)
      setAdPersonInCharge(adPerson)
      data?.ad_person_incharge !== {} && setShowAdDropdown(true)
      setPrefectureId(data?.prefecture?.id)
      setValue('ad_person_incharge', `${adPerson?.full_name}/${adPerson?.contact_number}`)
    }

    if (mode === 'preview_edit') {
      setData(storedData)
      setPrefectureId(storedData?.prefecture)
      setSelectedResume(props?.files.file_resume)
      setSelectedWorkCv(props?.files.file_cv)
      setSelectedOtherPdf(props?.files.other_docs_pdf)
      setContactNumber(storedData.contact_number)
    }

    data?.prefecture !== '' ? setSuccessPrefecture('success') : setSuccessPrefecture('')
    setEmpStat(data?.current_employment_status)
    setExistingResume(data?.resume)
    setExistingWorkCv(data?.work_history_cv)

    try {
      let fieldlist = data?.other_docs_url ? JSON.parse(data?.other_docs_url) : []
      let pdfsList = data?.other_docs_pdf ? data?.other_docs_pdf?.split('\\n') : []
      if (pdfsList?.length > 0) {
        const otherDocsWithoutPdf = fieldlist.filter((item) => item.option === 'url')
        pdfsList.map((item) => {
          const objId = item?.split('_')[0]
          otherDocsWithoutPdf.push({
            id: objId,
            option: 'pdf',
            pdf: item,
            url: null,
          })
        })
        fieldlist = otherDocsWithoutPdf
      }
      setExistingLoaded(loadedExisting)
      setFieldState(fieldlist)
    } catch (err) {
      console.log('Error: ', err.message)
    }
  }, [props])

  const adSearchEve = () => {
    if (adArea) {
      const adAreaArea = adArea.getElementsByClassName('form__adAreaArea')
      const adGroupCheckAd = adArea.getElementsByClassName('form__groupCheckAd')
      adSearch.addEventListener('input', () => {
        if (adSearch.value !== '') {
          for (let i = 0; adAreaArea.length > i; i++) {
            adAreaArea[i].style.display = 'none'
          }
          for (let i = 0; adGroupCheckAd.length > i; i++) {
            adGroupCheckAd[i].style.display = 'none'
            if (adGroupCheckAd[i].getElementsByClassName('form__checkItem')[0].value.indexOf(adSearch.value) !== -1) {
              adGroupCheckAd[i].style.display = 'inline-block'
              adGroupCheckAd[i].parentNode.style.display = 'block'
            }
          }
        } else {
          for (let i = 0; adAreaArea.length > i; i++) {
            adAreaArea[i].style.display = 'block'
          }
          for (let i = 0; adGroupCheckAd.length > i; i++) {
            adGroupCheckAd[i].style.display = 'inline-block'
          }
        }
      })
    }
  }

  const handleShowPrefecture = () => {
    let val = showPrefecture ? false : true
    setShowPrefecture(val)
  }

  const handleSelectPrefecture = (e, label) => {
    const { value } = e.target
    setSuccessPrefecture('success')
    setShowPrefecture(false)
    setValue('prefecture_name', label)
    setPrefectureId(value)
    handleOnChange(e)
  }

  const handleShowAdDropdown = (event) => {
    const { value } = event.target

    const isShowAdDropdown = value === 'ad_person_incharge'

    setShowAdDropdown(isShowAdDropdown)
    setShowContactText(!isShowAdDropdown)
    handleOnChange(event)
  }

  const selectIncharge = (e, row) => {
    e.preventDefault()
    setValue('ad_person_incharge', `${row.full_name}/${row.contact_number}`)
    setValue('contact_number', '')
    // setSuccessContact('success')
    setAdPersonInCharge(row)
  }

  const handleOnChange = (e) => {
    const { name, value } = e.target
    setValue(name, value)
    name === 'current_employment_status' && setEmpStat(value)
    name === 'current_employment_status' &&
      value === t('candidate.other') &&
      setShowOthers(true) &&
      reset({ other_status: '' })
    name === 'current_employment_status' && value !== t('candidate.other') && setShowOthers(false)

    if (name === 'contact_number') {
      setContactNumber(value)
      setAdPersonInCharge(user)
    }
  }

  const handleInput = (e) => {
    if (!((e.keyCode > 95 && e.keyCode < 106) || (e.keyCode > 47 && e.keyCode < 58) || e.keyCode == 8)) {
      e.preventDefault()
    }
  }

  const handleClickAddPortfolio = () => {
    fields.length < 10 && append({ option: 'url', url: null, pdf: null })
  }

  const handleRemovePortfolio = (index) => {
    remove(index)
  }

  const handleRemoveFile = (index = 0, file) => {
    if (file === 'other pdf') {
      let list = []
      list = selectedOtherPdf
      let i = index
      list.splice(i, 1)
      setSelectedOtherPdf(list)
    } else if (file === 'resume') {
      setValue('resume', '')
      setSelectedResume('')
      setExistingResume('')
      setSuccessResume('')
    } else if (file === 'work_history_cv') {
      setValue('work_history_cv', '')
      setSelectedWorkCv('')
      setExistingWorkCv('')
      setSuccessPdfWorkCv('')
    }
  }

  const handleFileInput = (e) => {
    const { value, name } = e.target
    const file = e.target.files[0]
    const ext = value.split('.').pop()
    if (ext !== 'pdf') {
      setError(name, { type: 'custom', message: 'Expects a PDF file' })
      name === 'resume' && setSuccessResume('')
      name === 'work_history_cv' && setSuccessPdfWorkCv('')
      setValue(name, '')
    } else {
      clearErrors(name)
      name === 'resume' ? setSelectedResume(file) : null
      name === 'work_history_cv' ? setSelectedWorkCv(file) : null
      handleOnChange(e)
    }
    setFormErrors(errors)
  }

  const checkDuplicateApplicantDetails = (candidate) => {
    return applicants.find((obj) => {
      const birthday =
        candidate.year +
        '-' +
        (candidate.month.length < 2 ? '0' + candidate.month : candidate.month) +
        '-' +
        candidate.day
      return (
        obj.lastname === candidate.lastname &&
        obj.firstname === candidate.firstname &&
        obj.birthday === birthday &&
        obj.prefecture.id === Number(candidate.prefecture)
      )
    })
  }

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit(async (data) => {
          data.emergency_contact = data.contact_number ? 'contactNumber' : 'ad_person_incharge'
          data.applicantId = applicantId || 0
          data.ad_person_incharge = adPersonInCharge
          data.resume =
            mode === 'new'
              ? selectedResume?.name
              : mode === 'existing'
              ? selectedResume?.name
                ? selectedResume?.name
                : existingResume
              : mode === 'preview_edit'
              ? existingResume
                ? existingResume
                : selectedResume?.name
              : null
          data.work_history_cv =
            mode === 'new'
              ? selectedWorkCv?.name
              : mode === 'existing'
              ? selectedWorkCv?.name
                ? selectedWorkCv?.name
                : existingWorkCv
              : mode === 'preview_edit'
              ? selectedWorkCv?.name
                ? selectedWorkCv?.name
                : existingWorkCv
              : null
          data.fields = fields
          data.prefecture = prefectureId
          data.existingResume = existingResume
          data.existingWorkCv = existingWorkCv
          data.jobPostingId = jobPostingId
          data.otherPdfString = otherPdfString

          localStorage.setItem('recommendation_data', JSON.stringify(data))
          if (checkDuplicateApplicantDetails(data) && !candidateSelected) {
            props.handleShowDuplicateEntryError()
          } else {
            history.push({
              pathname: '/recommendation_confirmation',
              state: {
                file_resume: selectedResume,
                file_cv: selectedWorkCv,
                other_docs_pdf: selectedOtherPdf,
                candidateSelected: candidateSelected,
                applicant: props?.applicant,
              },
            })
          }
        })}
      >
        <main className="main recommendation">
          <div className="recommendation__inner">
            <div className="recommendation__header">
              <FormHeader
                label={editmode ? t('formTitles.newRecommendation') : t('formTitles.confirmRecommendation')}
              />
              {editmode ? (
                <Button
                  type="button"
                  className="btn btn__shadowBtn popModal"
                  data-modal="modal"
                  data-modalcont="modalContRecom1"
                  onClick={(e) => props.handleShowModal(e)}
                >
                  {t('buttonLabels.selectFromCandidates')}
                </Button>
              ) : null}
            </div>
            <ApplicantFullname
              applicant={data}
              finalData={props?.finalData}
              successFullname={successFullname}
              successKanaFullname={successKanaFullname}
              editmode={editmode}
              mode={mode}
              handleOnChange={handleOnChange}
              candidateSelected={candidateSelected}
            />
            <div
              className={
                editmode
                  ? `form__group form__group-0 form__group-mgb0 required ${successPrefecture}`
                  : `form__group form__group-0 form__group-mgb0`
              }
            >
              <label htmlFor="koyoukeitai" className="form__label form__label-mgt9 form__label-mini juusyoTitle">
                {t('fieldLabels.prefecture')}
              </label>
              {editmode && !candidateSelected ? (
                <>
                  <div className="form__adWrap">
                    <Input
                      className="form__adText form__adText-w240px"
                      type="text"
                      name="prefecture_name"
                      id="formAdText"
                      {...register('prefecture_name', { required: t('hints.pleaseEnter') })}
                      defaultValue={
                        mode === 'existing' ? props?.applicant.prefecture?.prefecture : t('common.pleaseSelect')
                      }
                      readOnly
                      onClick={handleShowPrefecture}
                    />

                    {showPrefecture ? (
                      <PrefectureModal
                        prefecture={props?.applicant.prefecture}
                        handleShowPrefecture={handleShowPrefecture}
                        handleSelectPrefecture={handleSelectPrefecture}
                        showPrefecture={showPrefecture}
                        prefectureId={+prefectureId}
                        handleOnChange={handleOnChange}
                        onClose={handleOnClose}
                      />
                    ) : null}
                  </div>
                  <div className="form__supArea form__supArea-flex">
                    <p className="form__alert">{errors.prefecture?.message}</p>
                  </div>
                </>
              ) : (
                <div className="form__groupFlex form__group-mgt form__groupMultiCheck">
                  <p className="recommendation__dt">
                    {candidateSelected ? props?.applicant.prefecture?.prefecture : props?.finalData.prefecture_name}
                  </p>
                </div>
              )}
            </div>
            <FormGroup
              modifier="mgb0"
              error={!!errors?.month || !!errors?.day || !!errors?.year}
              required={editmode}
              className={editmode ? successBirthday : ''}
            >
              <FormItemLabel label={t('fieldLabels.birthday')} />
              {editmode && !candidateSelected ? (
                <>
                  <div className="form__groupFlex form__group-mgt form__groupMultiCheck">
                    <input
                      type="number"
                      name="year"
                      {...register('year', rules.year)}
                      className="form__inputText form__inputText-w72px inputCheck boolCheckMulti"
                      min="1"
                      maxLength="4"
                    />
                    <p className="form__inputMiddleText">{t('fieldLabels.year')}</p>
                    <input
                      type="number"
                      {...register('month', rules.month)}
                      className="form__inputText form__inputText-w4em inputCheck boolCheckMulti"
                      min="1"
                      maxLength="2"
                    />
                    <p className="form__inputMiddleText">{t('fieldLabels.month')}</p>
                    <input
                      type="number"
                      {...register('day', rules.day)}
                      className="form__inputText form__inputText-w4em inputCheck boolCheckMulti"
                      min="1"
                      maxLength="2"
                    />
                    <p className="form__inputMiddleText">{t('fieldLabels.day')}</p>
                  </div>
                  <FormSupArea>
                    <ErrorMessage name="year" />
                    <ErrorMessage name="month" />
                    <ErrorMessage name="day" />
                  </FormSupArea>
                </>
              ) : (
                <div className="form__groupFlex form__group-mgt form__groupMultiCheck">
                  <p className="recommendation__dt">
                    {candidateSelected ? (
                      <DateText format={COMPLETE_DATE_FORMAT_JAPANESE} date={props?.applicant.birthday} />
                    ) : (
                      <>
                        {props?.finalData.year}年 {props?.finalData.month}月 {props?.finalData.day}日
                      </>
                    )}
                  </p>
                </div>
              )}
            </FormGroup>
            <FormGroup
              modifier="mgb0 mgt2"
              className={editmode ? successGender : ''}
              {...byName('gender')}
              required={editmode}
            >
              <FormItemLabel label={t('fieldLabels.gender')} />
              {editmode && !candidateSelected ? (
                <>
                  <div className="form__groupCheckArea">
                    <FormGroupCheck modifier="flex">
                      <FormGroupCheck.Item
                        label={t('formOptions.male')}
                        Input={Radio}
                        type="round"
                        inputProps={{
                          ...register('gender', { required: t('validation.required') }),
                          value: t('formOptions.male'),
                        }}
                      />
                      <FormGroupCheck.Item
                        label={t('formOptions.female')}
                        Input={Radio}
                        type="round"
                        inputProps={{
                          ...register('gender', { required: t('validation.required') }),
                          value: t('formOptions.female'),
                        }}
                      />
                      <FormGroupCheck.Item
                        label={t('formOptions.others')}
                        Input={Radio}
                        type="round"
                        inputProps={{ ...register('gender', { required: t('validation.required') }), value: 'others' }}
                      />
                    </FormGroupCheck>

                    <div className="form__supArea form__supArea-flex">
                      <p className="form__alert">{errors.gender?.message}</p>
                    </div>
                  </div>
                </>
              ) : (
                <div className="form__groupFlex form__group-mgt form__groupMultiCheck">
                  <p className="recommendation__dt">
                    {candidateSelected ? props?.applicant.gender : props?.finalData?.gender}
                  </p>
                </div>
              )}
            </FormGroup>
            <EmploymentStatus
              empStat={empStat}
              adcontactName={adcontactName}
              data={data}
              mode={mode}
              errors={errors}
              showOthers={showOthers}
              editmode={editmode}
              successEmployment={successEmployment}
              handleOnChange={handleOnChange}
              finalData={data}
              candidateSelected={candidateSelected}
            />
            <FormGroup
              modifier="mgb0 mgt2"
              className={editmode ? successRecentAnnual : ''}
              {...byName('recent_annual_salary')}
              required={editmode}
            >
              <FormItemLabel label={t('fieldLabels.recentAnnualSalary')} />
              {editmode && !candidateSelected ? (
                <>
                  <div className="form__groupFlex form__group-mgt">
                    <Input
                      type="number"
                      {...register('recent_annual_salary', { required: t('hints.pleaseEnter'), min: 0 })}
                      defaultValue={props?.applicant?.recent_annual_salary}
                      onKeyDown={handleInput}
                      onChange={(e) => handleOnChange(e)}
                      className="form__inputText form__inputText-w6em inputCheck boolCheck"
                      min="0"
                    />
                    <p className="form__inputAfterText">{t('suffixes.yen')}</p>
                  </div>
                  <div className="form__supArea form__supArea-flex">
                    <p className="form__alert">{errors.recentanuual_salary?.message}</p>
                  </div>
                </>
              ) : (
                <div className="form__groupFlex form__group-mgt form__groupMultiCheck">
                  <p className="recommendation__dt">
                    {candidateSelected ? props?.applicant.recent_annual_salary : props?.finalData?.recent_annual_salary}
                    {t('suffixes.yen')}
                  </p>
                </div>
              )}
            </FormGroup>
            <FormGroup
              modifier="mgb0 mgt2"
              className={editmode ? successAnnual : ''}
              {...byName('desired_annual_salary')}
              required={editmode}
            >
              <FormItemLabel label={t('fieldLabels.desiredAnnualIncome')} />
              {editmode && !candidateSelected ? (
                <>
                  <div className="form__groupFlex form__group-mgt">
                    <Input
                      type="number"
                      onKeyDown={handleInput}
                      {...register('desired_annual_salary', { required: t('validation.required'), min: 0 })}
                      defaultValue={props?.applicant?.desired_annual_salary}
                      onChange={(e) => handleOnChange(e)}
                      className="form__inputText form__inputText-w6em inputCheck boolCheck"
                      min="0"
                    />
                    <p className="form__inputAfterText">{t('suffixes.yen')}</p>
                  </div>
                  <div className="form__supArea form__supArea-flex">
                    <p className="form__alert">{errors.desired_annual_salary?.message}</p>
                  </div>
                </>
              ) : (
                <div className="form__groupFlex form__group-mgt form__groupMultiCheck">
                  <p className="recommendation__dt">
                    {candidateSelected
                      ? props?.applicant.desired_annual_salary
                      : props?.finalData?.desired_annual_salary}
                    {t('suffixes.yen')}
                  </p>
                </div>
              )}
            </FormGroup>

            <EmergencyContact
              successContact={successContact}
              adPersonInCharge={adPersonInCharge}
              adInchargeList={adInChargeList}
              editmode={editmode}
              handleShowAdDropdown={handleShowAdDropdown}
              showAdDropdown={showAdDropdown}
              showContactText={showContactText}
              handleOnChange={handleOnChange}
              selectIncharge={selectIncharge}
              candidateSelected={candidateSelected}
              data={data}
              errors={errors}
            />

            <ApplicantDocuments
              successResume={successResume}
              successPdfWorkCv={successPdfWorkCv}
              handleFileInput={handleFileInput}
              selectedResume={selectedResume}
              data={data}
              mode={mode}
              finalData={props.finalData}
              errors={errors}
              selectedWorkCv={selectedWorkCv}
              editmode={editmode}
              applicant={props.applicant}
              handleRemoveFile={handleRemoveFile}
              handleOnChange={handleOnChange}
              selectedOtherPdf={selectedOtherPdf}
              handleSelectedOtherPdfs={handleSelectedOtherPdfs}
              handleRemovePortfolio={handleRemovePortfolio}
              handleClickAddPortfolio={handleClickAddPortfolio}
              fields={fields}
              update={update}
              candidateSelected={candidateSelected}
            />
            <RecommendationInfo
              applicant={props.applicant}
              editmode={editmode}
              rules={rules}
              data={data}
              candidateSelected={candidateSelected}
              successRecommendationInfo={successRecommendationInfo}
              handleSelectPrefecture={handleSelectPrefecture}
              handleOnChange={handleOnChange}
              finalData={props?.finalData}
              default_new={defaultNew}
              errors={errors}
              methods={methods}
            />
          </div>
        </main>

        {editmode || mode === 'preview_edit' ? (
          <RecommendationFooter isSubmitting={isSubmitting} isValid={isValid} errors={formErrors} unfilled={unfilled} />
        ) : null}
      </form>
    </FormProvider>
  )
}

RecommendationForm.propTypes = {
  match: PropTypes.object,
  applicant: PropTypes.object,
  editmode: PropTypes.bool,
  mode: PropTypes.string,
  finalData: PropTypes.object,
  handleShowModal: PropTypes.func,
  location: PropTypes.object,
  files: PropTypes.object,
  handleShowDuplicateEntryError: PropTypes.func,
  loadedExisting: PropTypes.bool,
  candidateSelected: PropTypes.bool,
}

export default RecommendationForm

import React from 'react'
import PropTypes from 'prop-types'
import { BlockEl } from 'components/atoms'

function SearchSideGroup({ title, openDropdownCheck, handleOpenDropdownCheck, maxHeight, children, flex = false }) {
  return (
    <BlockEl blockElClass="search__sideSecGroup">
      <p
        className={`search__sideSecGroupTitle search__sideSecGroupTitle-${openDropdownCheck ? 'open' : ''}`}
        onClick={() => handleOpenDropdownCheck(!openDropdownCheck)}
      >
        {title}
        <span className="search__sideSecGroupTitleNum">
          <span className="search__sideSecGroupTitleNum-span">0</span>
        </span>
      </p>
      <BlockEl
        className="search__sideSecGroupInner"
        style={{ ...(openDropdownCheck ? { overflow: 'visible', maxHeight: maxHeight } : '') }}
      >
        <BlockEl bec="search__sideSecGroupCheckWrap" modifier={{ flex }}>
          {children}
        </BlockEl>
      </BlockEl>
    </BlockEl>
  )
}

SearchSideGroup.propTypes = {
  title: PropTypes.string,
  openDropdownCheck: PropTypes.bool,
  handleOpenDropdownCheck: PropTypes.func,
  maxHeight: PropTypes.string,
  children: PropTypes.any,
  flex: PropTypes.bool,
}

export default SearchSideGroup

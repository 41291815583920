import React from 'react'
import PropTypes from 'prop-types'
import { NewlineText, DateText, Button } from 'components'
import { useTranslation } from 'react-i18next'
import { DATE_FORMAT_JAPANESE } from 'utils/constants'

const JobPosting = ({ jobPost }) => {
  const { t } = useTranslation()
  const concatIndustries = (jobPost.company.industries || []).map(({ name }) => name).join('\n')

  return (
    <Button blockElClass="dashboard__job__item" component="a" href={`/job-postings/${jobPost.id}/jobvacancies`}>
      <div className="dashboard__job__item-top">
        <p className="dashboard__job__item-title">{jobPost.job_title}</p>
        <dl className="dashboard__job__item-dl">
          <dt>{t('jobPosting.card.desiredTimeToHire')}</dt>
          <dd>
            {jobPost.desired_period_of_employment === 'year' ? (
              t('formValues.year')
            ) : (
              <DateText format={DATE_FORMAT_JAPANESE} date={jobPost.desired_period_of_employment} />
            )}
          </dd>
          <dt>{t('jobPosting.numHires')}</dt>
          <dd>
            {jobPost.number_of_positions}
            {t('suffixes.name')}
          </dd>
        </dl>
        <ul className="dashboard__job__item-tag">
          {jobPost.working_points.map((workingPoint, index) => (
            <li key={index}>{workingPoint.name}</li>
          ))}
        </ul>
      </div>
      <div className="dashboard__job__item-bottom">
        <p className="dashboard__job__item-gyoshu">
          <NewlineText text={concatIndustries} />
        </p>
        <p className="dashboard__job__item-cname">{jobPost.company_name}</p>
      </div>
    </Button>
  )
}
JobPosting.propTypes = {
  jobPost: PropTypes.object,
}

export default JobPosting

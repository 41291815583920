import { createAsyncThunk } from '@reduxjs/toolkit'

import { parseAxiosResponse } from 'utils/helper'
import Http from 'utils/Http'

export const fetchUserSettings = createAsyncThunk('FETCH_USER_SETTINGS', (keys, thunkApi) => {
  return parseAxiosResponse(Http.get('/common/user_settings', { params: { keys }, signal: thunkApi.signal }))
})

export const updateUserSetting = createAsyncThunk('UPDATE_USER_SETTING', ({ key, value }) => {
  return parseAxiosResponse(Http.post(`/common/user_settings/${key}`, { value }))
})

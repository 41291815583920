import * as types from './actionTypes'

export const actionUpdateApplicantsParams = (patch) => {
  return {
    type: types.UPDATE_APPLICANTS_SEARCH_PARAMS,
    payload: patch,
  }
}

export function getFileRequest(applicantId) {
  return {
    type: types.GET_FILE_REQUEST,
    payload: { applicantId },
  }
}

export function getFileSuccess(applicantId, data) {
  return {
    type: types.GET_FILE_SUCCESS,
    payload: { applicantId, data },
  }
}

export function getFileFailure(applicantId, error) {
  return {
    type: types.GET_FILE_FAILURE,
    payload: { applicantId, error },
  }
}

//Applicants List
export function getApplicantsListRequest(fullname) {
  return {
    type: types.GET_APPLICANTS_LIST_REQUEST,
    payload: { fullname },
  }
}

export function getApplicantsListSuccess(data) {
  return {
    type: types.GET_APPLICANTS_LIST_SUCCESS,
    payload: { data },
  }
}

export function getApplicantsListFailure(error) {
  return {
    type: types.GET_APPLICANTS_LIST_FAILURE,
    payload: { error },
  }
}

//Applicant Details
export function getApplicantDetailsRequest(id) {
  return {
    type: types.GET_APPLICANT_DETAILS_REQUEST,
    payload: { id },
  }
}

export function getApplicantDetailsSuccess(data) {
  return {
    type: types.GET_APPLICANT_DETAILS_SUCCESS,
    payload: { data },
  }
}

export function getApplicantDetailsFailure(error) {
  return {
    type: types.GET_APPLICANT_DETAILS_FAILURE,
    payload: { error },
  }
}

//Applicant Create
export function createApplicantRequest(id) {
  return {
    type: types.CREATE_APPLICANT_REQUEST,
    payload: { id },
  }
}

export function createApplicantSuccess(data) {
  return {
    type: types.CREATE_APPLICANT_SUCCESS,
    payload: { data },
  }
}

export function createApplicantFailure(error) {
  return {
    type: types.CREATE_APPLICANT_FAILURE,
    payload: { error },
  }
}

//Applicant Update
export function updateApplicantRequest(id) {
  return {
    type: types.UPDATE_APPLICANT_REQUEST,
    payload: { id },
  }
}

export function updateApplicantSuccess(data) {
  return {
    type: types.UPDATE_APPLICANT_SUCCESS,
    payload: { data },
  }
}

export function updateApplicantFailure(error) {
  return {
    type: types.UPDATE_APPLICANT_FAILURE,
    payload: { error },
  }
}

export const setApplicantFilterModalStatus = (status) => {
  return {
    type: types.SET_APPLICANT_FILTER_MODAL_STATUS,
    payload: status,
  }
}

import React, { useState, useEffect, Fragment, memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import range from 'lodash/range'
import isEqual from 'lodash/isEqual'
import find from 'lodash/find'
import isFinite from 'lodash/isFinite'
import findIndex from 'lodash/findIndex'
import { useImmer } from 'use-immer'
import { useForm, useFormContext, FormProvider, Controller } from 'react-hook-form'
import {
  CustomSelect,
  FormGroup,
  FormGroupCheck,
  SearchConditionGroup,
  SearchSideGroup,
  FormInterlock,
  CheckListV2,
} from 'components/molecules'
import { Label, ButtonModal, Input, Radio, Checkbox, Button, BlockEl } from 'components/atoms'
import { calculateBEM, camelToSnakeCase, extractString, inAccountType, isHuunoAdmin } from 'utils/helper'
import * as options from './options'
import { ACCOUNT_TYPES, INPUT_TYPES, MODAL_SEARCH_TYPE } from 'utils/constants'
import JobPostingModal from '../JobPostingModal'
import clsx from 'clsx'

import { getIndustries } from 'services/industries'
import { getOccupations } from 'services/occupations'
import { getWorkPoints } from 'services/workPoints'
import { getPrefecturesList } from 'services/common'

import { actionAddJobFilterCriteria, actionSetEditMode } from 'store/job-filter/actionCreators'
import { fetchJobPostingFilter, updateJobPostingFilter } from 'services/jobFilter'
import {
  actionUpdateJobSearchFilters,
  actionResetJobSearchFilters,
  actionUpdateJobSearchParams,
  actionTriggerJobSearch,
  actionUntriggerJobSearch,
} from 'store/job-postings/actionCreators'
import { searchJobPostings } from 'services/jobPostings'
import SearchCriteriaModal from 'components/molecules/SearchCardContainer/SearchCriteriaModal'

import { showNotification } from 'store/notification/actionCreators'
import { getEducationalBackground } from 'services/educationalBackground'
import { selectEducationalBackgrounds } from 'store/educational-background/selectors'
import { getChineseLevel } from 'services/chineseLevel'
import { getEnglishLevel } from 'services/englishLevel'
import { selectChoice } from 'store/choices/selectors'
import { fetchChoices } from 'services/choices'
import SearchSideSection from './SearchSideSection'
import { selectAuthUser } from 'store/auth/selectors'
import PublicationMediumSection from './PublicationMediumSection'

const isNumber = (val) => isFinite(parseInt(val))

const knownKeys = [
  'keyword',
  'job_industry',
  'available_position',
  'work_locations',
  'min_estimated_annual_salary',
  'max_estimated_annual_salary',
  'annual_salary',
  'working_points',
  'characteristic_employment_status',
  'number_of_positions',
  'desired_period_of_employment',
  'detailed_employment_status',
  'number_of_employees',
  'type_of_work',
  'min_acceptable_age',
  'max_acceptable_age',
  'changed_job_count',
  'education_background',
  'assumed_gender',
  'english_level',
  'chinese_level',
  'posting_recruitment_media',
  'sending_scout_email',
  'advertising_budget',
  'year_established',
  'closing_month',
  'presence_absence_authorizer',
  'current_media',
  'past_publication_media',
  'media_of_interest',
  'post_period',
]

const filterKeys = (data) => {
  return Object.keys(data)
    .filter((key) => knownKeys.includes(key))
    .reduce((accum, key) => ({ ...accum, [key]: data[key] }), {})
}

const Checklist = ({ dropdown, searchType, index, onChange, isChecked }) => {
  const { t } = useTranslation()
  const getValue = (option) =>
    typeof option === 'object' && 'value' in option ? option.value : t(`jobPosting.search.${option}`)
  const getLabel = (option) =>
    typeof option === 'object' && 'label' in option ? option.label : t(`jobPosting.search.${option}`)

  return dropdown.options.map((option, idx) => (
    <FormGroupCheck.Item
      key={idx}
      Input={Checkbox}
      className={calculateBEM('search__conditonCheckItem', { w50: !!dropdown.w50 })}
      inputProps={{
        value: getValue(option),
        onChange: onChange,
        name: dropdown.name,
        checked: isChecked(dropdown.name, getValue(option)),
        ['data-index']: index,
        ['data-search']: searchType,
      }}
      label={getLabel(option)}
      type="square"
    />
  ))
}

Checklist.propTypes = {
  dropdown: PropTypes.object.isRequired,
  searchType: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  isChecked: PropTypes.func.isRequired,
}

const InputRadioJobCount = ({ activeNumberOfTimes, setActiveNumberOfTimes, onJobCountChange, onChangeCriteria }) => {
  const { register } = useFormContext()
  const { t } = useTranslation()
  const noOfTimes = [
    {
      id: 0,
      label: t('formValues.none'),
    },
  ]
  range(1, 7).map((num) => {
    noOfTimes.push({ id: num, label: num + t('suffixes.range') })
  })
  return (
    <FormGroupCheck>
      <FormGroupCheck.Item
        Input={Radio}
        inputProps={{
          className: 'formRadio boolCheck interlock',
          ...register('changed_job_count_opt'),
          checked: activeNumberOfTimes === '-1',
          value: '-1',
          onChange: () => {
            setActiveNumberOfTimes('-1')
            onJobCountChange('-1')
          },
        }}
        type="round"
        label={t('jobPosting.search.regardlessOfNoOfTimes')}
      />
      <FormGroupCheck.Item
        Input={Radio}
        inputProps={{
          className: 'formRadio boolCheck interlock',
          ...register('changed_job_count_opt'),
          checked: activeNumberOfTimes === '*',
          value: '*',
          onChange: () => {
            setActiveNumberOfTimes('*')
            onJobCountChange('*')
          },
        }}
        type="round"
        label={t('jobPosting.search.specifyNoOfTimes')}
      />
      <FormInterlock active={activeNumberOfTimes === '*'}>
        <Controller
          name="changed_job_count"
          render={({ field }) => (
            <CustomSelect
              value={field.value}
              options={noOfTimes}
              placeholder={t('common.pleaseSelect')}
              onChange={onChangeCriteria}
              selected={field.value}
            />
          )}
        />
      </FormInterlock>
    </FormGroupCheck>
  )
}

InputRadioJobCount.propTypes = {
  activeNumberOfTimes: PropTypes.string.isRequired,
  setActiveNumberOfTimes: PropTypes.func.isRequired,
  onJobCountChange: PropTypes.func.isRequired,
  onChangeCriteria: PropTypes.func.isRequired,
}

const InputAgeRange = ({ note, filterCriteria, onTextChange }) => {
  const { t } = useTranslation()
  const {
    register,
    formState: { errors },
  } = useFormContext()

  return (
    <FormInterlock active={true}>
      <div className="form__groupFlex form__group-mgt0 form__groupMultiCheck">
        <Input
          value={filterCriteria[camelToSnakeCase('min_acceptable_age')]}
          type="number"
          modifier="w6em"
          name="min_acceptable_age"
          min={1}
          {...register('min_acceptable_age', {
            min: { value: 1, message: t('validation.invalidInputNumber') },
            onChange: (e) => onTextChange(e),
          })}
        />
        <p className="form__inputMiddleText">～</p>
        <Input
          value={filterCriteria[camelToSnakeCase('max_acceptable_age')]}
          type="number"
          modifier="w6em"
          name="max_acceptable_age"
          min={1}
          {...register('max_acceptable_age', {
            min: { value: 1, message: t('validation.invalidInputNumber') },
            onChange: (e) => onTextChange(e),
          })}
        />
        <span className="form__inputAfterText">{t('jobPosting.search.underAgeOf')}</span>
      </div>
      {errors.min_acceptable_age && <Label modifier="error">{errors.min_acceptable_age.message}</Label>}
      {errors.max_acceptable_age && <Label modifier="error">{errors.max_acceptable_age.message}</Label>}
      <span className="form__sup">{note}</span>
    </FormInterlock>
  )
}

InputAgeRange.propTypes = {
  note: PropTypes.string.isRequired,
  filterCriteria: PropTypes.object.isRequired,
  onTextChange: PropTypes.func.isRequired,
}

const inputTypeComponents = {
  [INPUT_TYPES.checked]: Checklist,
  [INPUT_TYPES.ranges]: InputAgeRange,
  [INPUT_TYPES.radio]: InputRadioJobCount,
}

const DropDown = (props) => {
  const { filterCriteria, dropdown, onOpen, searchType, index, onCheckboxChange, ...innerProps } = props
  const filterOptions = filterCriteria[camelToSnakeCase(dropdown.name)]
  const isOpen = (filterOptions && filterOptions.length > 0) || dropdown.isOpen
  const Component = inputTypeComponents[dropdown.inputType]
  const componentProps = { filterCriteria, dropdown, searchType, index, onChange: onCheckboxChange, ...innerProps }

  return (
    <SearchSideGroup
      id={dropdown.name}
      title={dropdown.title}
      openDropdownCheck={isOpen}
      handleOpenDropdownCheck={() => onOpen(searchType, index)}
      maxHeight={dropdown.maxHeight}
      flex={dropdown.flex}
    >
      <Component {...componentProps} />
    </SearchSideGroup>
  )
}
DropDown.propTypes = {
  dropdown: PropTypes.object,
  index: PropTypes.number,
  searchType: PropTypes.string,
  filterCriteria: PropTypes.object.isRequired,
  onOpen: PropTypes.func.isRequired,
  onCheckboxChange: PropTypes.func,
  isChecked: PropTypes.func,
  onTextChange: PropTypes.func,
  activeNumberOfTimes: PropTypes.string,
  setActiveNumberOfTimes: PropTypes.func,
  onJobCountChange: PropTypes.func,
  onChangeCriteria: PropTypes.func,
  note: PropTypes.string,
}

function JobPostingSidebarSearch({ handleFilterCloseIconClick }) {
  const methods = useForm({ mode: 'all' })
  const {
    setValue,
    getValues,
    register,
    formState: { errors },
  } = methods
  const dispatch = useDispatch()
  const jobFilters = useSelector((state) => state.jobPostings.jobPostingSearch)
  const jobFilterOptions = useSelector((state) => state.jobFilter.items)
  const editMode = useSelector((state) => state.jobFilter.editMode)
  const filterCriterias = useSelector((state) => state.jobPostings.jobPostingSearch.filterCriterias)
  const params = useSelector((state) => state.jobPostings.params)
  const [filterCriteria, setFilterCriteria] = useState(filterCriterias)
  const modalFilterData = {
    [MODAL_SEARCH_TYPE.industries]: useSelector((state) => state.industries.items),
    [MODAL_SEARCH_TYPE.occupations]: useSelector((state) => state.occupations.items),
    [MODAL_SEARCH_TYPE.workLocations]: useSelector((state) => state.common.prefectureslist),
    [MODAL_SEARCH_TYPE.workPoints]: useSelector((state) => state.workpoints.items),
  }
  const user = useSelector(selectAuthUser)
  const isAD2 = inAccountType(user, ACCOUNT_TYPES.ad2)
  const { activeModal, activeFilterModal, filters } = useSelector((state) => state.jobPostings.jobPostingSearch)
  const educationalBackgrounds = useSelector(selectEducationalBackgrounds)
  const [enableUpdateBtn, setEnableUpdateBtn] = useState(false)
  const [isSubmitting, setisSubmitting] = useState(false)
  const chineseLevels = useSelector((state) => state.chineseLevel.items)
  const englishLevels = useSelector((state) => state.englishLevel.items)
  const postingRecruitmentMedia = useSelector((state) => selectChoice(state, 'posting_recruitment_media'))
  const sendingScoutEmail = useSelector((state) => selectChoice(state, 'sending_scout_email'))
  const [resetTime, setResetTime] = useState(null)

  const arrayableKeys = [
    'job_industry',
    'available_position',
    'work_locations',
    'working_points',
    'characteristic_employment_status',
    'number_of_positions',
    'desired_period_of_employment',
    'detailed_employment_status',
    'number_of_employees',
    'education_background',
    'english_level',
    'chinese_level',
    'posting_recruitment_media',
    'sending_scout_email',
    'type_of_work',
    'assumed_gender',
    'advertising_budget',
    'year_established',
    'closing_month',
    'current_media',
    'past_publication_media',
    'media_of_interest',
    'post_period',
  ]

  const { t } = useTranslation()
  const num_range = { min: 0, max: 0 }

  const postingRecruitmentMediaOptions =
    (postingRecruitmentMedia &&
      Object.keys(postingRecruitmentMedia).map((key) => ({
        value: key,
        label: postingRecruitmentMedia[key],
      }))) ||
    []

  const sendingScoutEmailOptions =
    (sendingScoutEmail &&
      Object.keys(sendingScoutEmail).map((key) => ({ value: key, label: sendingScoutEmail[key] }))) ||
    []
  const defaultSearch = {
    searchCriteriaOptions: {
      values: filterCriterias.search_criteria,
    },
    searchByBasicConditions: {
      values: {
        keyword: filterCriterias.keyword,
        min_estimated_annual_salary: filterCriterias.min_estimated_annual_salary,
        max_estimated_annual_salary: filterCriterias.max_estimated_annual_salary,
        specificAnnualSalary: filterCriterias.annual_salary,
        job_industry: filterCriterias.job_industry,
        available_position: filterCriterias.available_position,
        workLocations: filterCriterias.workLocations,
      },
    },
    searchByChar: [
      isAD2
        ? {
            name: 'postPeriod',
            title: t('jobPosting.search.postPeriod'),
            options: options.postPeriod.map((key) => ({ value: key, label: t(`jobPosting.search.${key}`) })),
            values: filterCriterias.post_period,
            isOpen: false,
            isDropdown: true,
            maxHeight: '192px',
            inputType: INPUT_TYPES.checked,
          }
        : {
            name: 'characteristicEmploymentStatus',
            title: t('jobPosting.search.employmentStatus'),
            options: options.employmentStatuses,
            values: filterCriterias.characteristic_employment_status,
            isOpen: false,
            isDropdown: true,
            maxHeight: '192px',
            inputType: INPUT_TYPES.checked,
          },
      {
        name: 'numberOfPositions',
        title: t('jobPosting.search.noOfHires'),
        options: options.noOfHires,
        values: filterCriterias.number_of_positions,
        isOpen: false,
        isDropdown: true,
        maxHeight: '328px',
        inputType: INPUT_TYPES.checked,
      },
      {
        name: 'desiredPeriodOfEmployment',
        title: t('jobPosting.search.desiredTimeToHire'),
        options: options.desiredTimeToHires.map((value) => ({ value, label: t(`jobPosting.search.${value}`) })),
        values: filterCriterias.desired_period_of_employment,
        isOpen: false,
        isDropdown: true,
        maxHeight: '260px',
        inputType: INPUT_TYPES.checked,
      },
    ],
    searchByDetailedConditions: [
      {
        name: 'detailedEmploymentStatus',
        title: t('jobPosting.search.employmentStatus'),
        options: options.employmentStatuses,
        values: filterCriterias.detailed_employment_status,
        isOpen: false,
        isDropdown: true,
        maxHeight: '192px',
        inputType: INPUT_TYPES.checked,
      },
      {
        name: 'numberOfEmployees',
        title: t('jobPosting.search.companySize'),
        options: options.companySizes,
        values: filterCriterias.number_of_employees,
        isOpen: false,
        isDropdown: true,
        maxHeight: '260px',
        inputType: INPUT_TYPES.checked,
      },
      {
        name: 'typeOfWork',
        title: t('jobPosting.search.workingStyle'),
        options: options.workingStyles,
        values: filterCriterias.type_of_work,
        isOpen: false,
        isDropdown: true,
        maxHeight: '328px',
        inputType: INPUT_TYPES.checked,
      },
      {
        name: 'assumedAge',
        title: t('jobPosting.search.assumedAge'),
        options: num_range,
        values: {
          min_acceptable_age: filterCriterias.min_acceptable_age,
          max_acceptable_age: filterCriterias.max_acceptable_age,
        },
        isOpen: false,
        isDropdown: true,
        maxHeight: '328px',
        inputType: INPUT_TYPES.ranges,
        notes: t('jobPosting.search.ageRangeNote', { min: 18, max: 70 }),
      },
      {
        name: 'changeJobCount',
        title: t('jobPosting.search.noOfJobChanges'),
        options: options.noOfJobChanges,
        values: filterCriterias.changed_job_count,
        isOpen: false,
        isDropdown: true,
        maxHeight: '328px',
        inputType: INPUT_TYPES.radio,
      },
      {
        name: 'educationBackground',
        title: t('jobPosting.search.educationalBackground'),
        options: educationalBackgrounds.map((item) => ({ value: item.id, label: item.name })),
        values: filterCriterias.education_background,
        isOpen: false,
        isDropdown: true,
        maxHeight: '362px',
        inputType: INPUT_TYPES.checked,
      },
      {
        name: 'assumedGender',
        title: t('jobPosting.search.assumedGender'),
        options: options.assumedGenders,
        values: filterCriterias.assumed_gender,
        isOpen: false,
        isDropdown: true,
        maxHeight: '192px',
        inputType: INPUT_TYPES.checked,
      },
      ...(isAD2
        ? [
            {
              name: 'yearEstablished',
              title: t('jobPosting.search.yearsEstablished'),
              options: options.yearEstablished.map((value) => ({ value, label: t(`jobPosting.search.${value}`) })),
              values: filterCriterias.year_established,
              isOpen: false,
              isDropdown: true,
              maxHeight: '226px',
              inputType: INPUT_TYPES.checked,
            },
            {
              name: 'closingMonth',
              title: t('userProfile.basicInformation.companyClosingMonthLabel'),
              options: range(1, 13).map((value) => ({
                value,
                label: t(`corporateInfo.closingMonthVal`, { months: value }),
              })),
              values: filterCriterias.year_established,
              isOpen: false,
              isDropdown: true,
              maxHeight: '226px',
              inputType: INPUT_TYPES.checked,
              flex: true,
              w50: true,
            },
          ]
        : [
            {
              name: 'englishLevel',
              title: t('jobPosting.search.englishLevel'),
              options: englishLevels.map((item) => ({ value: item.id, label: item.name })),
              values: filterCriterias.english_level,
              isOpen: false,
              isDropdown: true,
              maxHeight: '226px',
              inputType: INPUT_TYPES.checked,
            },
            {
              name: 'chineseLevel',
              title: t('jobPosting.search.chineseLevel'),
              options: chineseLevels.map((item) => ({ value: item.id, label: item.name })),
              values: filterCriterias.chinese_level,
              isOpen: false,
              isDropdown: true,
              maxHeight: '226px',
              inputType: INPUT_TYPES.checked,
            },
          ]),
    ],
    searchByPublicInformationConditions: [
      {
        name: 'postingRecruitmentMedia',
        title: t('jobPosting.search.postingOnMedia'),
        options: postingRecruitmentMediaOptions,
        values: filterCriterias.posting_recruitment_media,
        isOpen: false,
        isDropdown: true,
        maxHeight: '264px',
        inputType: INPUT_TYPES.checked,
      },
      {
        name: 'sendingScoutEmail',
        title: t('jobPosting.search.sendScoutEmail'),
        options: sendingScoutEmailOptions,
        values: filterCriterias.sending_scout_email,
        isOpen: false,
        isDropdown: true,
        maxHeight: '264px',
        inputType: INPUT_TYPES.checked,
      },
    ],
    modalSearch: {
      saveSearchCriteria: {
        name: 'saveSearchCriteria',
        title: t('jobPosting.search.modal.saveCriteriaTitle'),
        values: '',
        isTabListSearch: false,
        isTabCheckListSearch: false,
        maxLength: 100,
        note: t('jobPosting.search.modal.saveCriteriaNote'),
      },
      [MODAL_SEARCH_TYPE.industries]: {
        name: MODAL_SEARCH_TYPE.industries,
        title: t('jobPosting.search.modal.selectIndustry'),
        isTabListSearch: true,
        isTabCheckListSearch: false,
      },
      [MODAL_SEARCH_TYPE.occupations]: {
        name: MODAL_SEARCH_TYPE.occupations,
        title: t('jobPosting.search.modal.selectJobType'),
        isTabListSearch: true,
        isTabCheckListSearch: false,
      },
      [MODAL_SEARCH_TYPE.workLocations]: {
        name: MODAL_SEARCH_TYPE.workLocations,
        title: t('jobPosting.search.modal.selectWorkLocation'),
        isTabListSearch: true,
        isTabCheckListSearch: true,
      },
      [MODAL_SEARCH_TYPE.workPoints]: {
        name: MODAL_SEARCH_TYPE.workPoints,
        title: t('jobPosting.search.modal.selectWorkingStyle'),
        isTabListSearch: true,
        isTabCheckListSearch: false,
      },
    },
  }
  const [searchByCharacteristics, setSearchByCharacteristics] = useImmer(defaultSearch)
  // Use to determine what radio to check and interlock to show for planned annual income input.
  // 0 - show min and max
  // 1 - show exact input
  // null - hide both
  const [activeEstimateAnnualIncome, setActiveEstimateAnnualIncome] = useState(null)
  const [activeNumberOfTimes, setActiveNumberOfTimes] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [modalData, setModalData] = useState({})
  const [activeListKey, setActiveListKey] = useState(0)
  const [criteriaOption, setCriteriaOption] = useState('')
  const [openCriteriaModal, setOpenCriteriaModal] = useState(false)
  const isCriteriaSelected = useSelector((state) => state?.jobPostings?.jobPostingSearch?.triggerSearch)
  const jobSearchStatus = ['under_recruitment']
  const authUser = useSelector((state) => state.auth?.user?.data)
  const [isAdmin, setIsAdmin] = useState(false)

  useEffect(() => {
    dispatch(getIndustries())
    dispatch(getOccupations())
    dispatch(getWorkPoints())
    dispatch(getChineseLevel())
    dispatch(getEnglishLevel())
    dispatch(getEducationalBackground())
    dispatch(getPrefecturesList())
    dispatch(fetchChoices({ choices: ['posting_recruitment_media', 'sending_scout_email'] }))
    dispatch(fetchJobPostingFilter())
    dispatch(actionUpdateJobSearchParams({ status: jobSearchStatus }))
    setIsAdmin(isHuunoAdmin(authUser))
    if (!isHuunoAdmin(authUser)) {
      resetSearchFilter()
    }
  }, [])

  useEffect(() => {
    if (
      educationalBackgrounds.length > 0 ||
      chineseLevels.length > 0 ||
      englishLevels.length > 0 ||
      postingRecruitmentMedia ||
      sendingScoutEmail
    ) {
      setSearchByCharacteristics(defaultSearch)
    }
  }, [educationalBackgrounds, chineseLevels, englishLevels, postingRecruitmentMedia, sendingScoutEmail])

  useEffect(() => {
    if (editMode) {
      const selectedCriteria = jobFilterOptions.filter(
        (item) => item.search_criteria === filterCriteria.search_criteria
      )[0]

      const parsedFilterCriteria = decodeEncodeFilterCriteriaArrayableKeys(filterCriteria, false)

      setEnableUpdateBtn(isEqual(selectedCriteria, parsedFilterCriteria))
    }
  }, [filterCriteria])

  const handleCriteriaModalEditBtnClick = (newSelected) => {
    setOpenCriteriaModal(false)
    handleSavedSearchCriteriaChange(newSelected.savedSearchCriteria)
    dispatch(actionTriggerJobSearch())
    dispatch(actionSetEditMode(true))
  }

  useEffect(() => {
    return () => {
      setOpenModal(false)
    }
  }, [])

  const searchModal = (searchType) => {
    const searchKey = searchByCharacteristics.modalSearch[searchType]
    dispatch(actionUpdateJobSearchFilters({ activeModal: searchKey.name }))
    setModalData(searchKey)
    setActiveListKey(0)
    setOpenModal(true)
    if (MODAL_SEARCH_TYPE.saveSearchCriteria === searchType) {
      dispatch(actionAddJobFilterCriteria({ ...filterCriteria }))
    }
  }

  const handleModalClose = () => {
    setOpenModal(false)
  }

  const handleActiveLists = (index) => {
    setActiveListKey(index)
  }

  const handleOpenDropdown = (key, index) => {
    setSearchByCharacteristics((search) => {
      search[key][index].isOpen = !search[key][index].isOpen
    })
  }

  const populateModalFilter = (criterias) => {
    const modalFilters = Object.keys(MODAL_SEARCH_TYPE)
      .filter((key) => key !== MODAL_SEARCH_TYPE.saveSearchCriteria)
      .map((item) => MODAL_SEARCH_TYPE[item])
    var category_ids = ''
    const filters = modalFilters.reduce(
      (modal, curr) => ({ ...modal, [curr]: JSON.parse(criterias[camelToSnakeCase(curr)] ?? '[]') || {} }),
      {}
    )
    const activeFilterModal = modalFilters.reduce((modal, curr) => {
      var filtersIds = []
      if (criterias[camelToSnakeCase(curr)] !== undefined && criterias[camelToSnakeCase(curr)] !== '[]') {
        var ids = []
        if (curr === 'workLocations') {
          ids = JSON.parse(criterias[camelToSnakeCase(curr)])
        } else {
          category_ids = Object.keys(JSON.parse(criterias[camelToSnakeCase(curr)]))
          category_ids = category_ids.map((item) => parseInt(item))
          const id = JSON.parse(criterias[camelToSnakeCase(curr)])
          const flatten = (obj) => Object.values(obj).flat()
          ids = flatten(id)
        }
        filtersIds = ids.map((item) => parseInt(item))
      }

      let filterSelected = {}
      if (curr !== MODAL_SEARCH_TYPE.workLocations) {
        const modalList = modalFilterData[curr].filter((category) => category_ids.includes(category.id))
        filterSelected = modalList
      } else {
        const prefectureList = modalFilterData[curr].filter((item) => filtersIds.includes(item.id))
        filterSelected = formatPrefecture(prefectureList)
      }

      return { ...modal, [curr]: filterSelected }
    }, {})
    dispatch(actionUpdateJobSearchFilters({ filters, activeFilterModal: activeFilterModal }))
  }

  const decodeEncodeFilterCriteriaArrayableKeys = (filterCriteria, parse = true) => {
    const mapFilterCriteria = Object.assign({}, filterCriteria)
    arrayableKeys.forEach((key) => {
      mapFilterCriteria[key] = parse ? JSON.parse(filterCriteria[key] || '[]') : JSON.stringify(filterCriteria[key])
    })
    return mapFilterCriteria
  }

  const popAlert = (message, type = 'success') => {
    dispatch(showNotification(message, { type: type, autoClose: 2000, modifier: 'btm' }))
  }

  const handleUpdateJobFilter = async () => {
    setisSubmitting(true)
    const filter = find(jobFilterOptions, { search_criteria: criteriaOption })
    const response = await dispatch(updateJobPostingFilter({ id: filter.id, ...filterCriteria }))

    if (response.error) {
      const error = response.error
      let msg = 'Unknown error'

      if (error.code === 422) {
        msg = extractString(error.error)
      } else if (typeof error.error === 'string') {
        msg = error.error
      }

      popAlert(msg, 'danger')
    } else {
      dispatch(fetchJobPostingFilter())
      popAlert(t('jobPosting.filter.updateSuccessMessage'))
      resetSearchFilter()
    }

    setisSubmitting(false)
  }

  const handleEditCancelClick = () => {
    dispatch(actionSetEditMode(false))
    setOpenCriteriaModal(true)
    resetSearchFilter()
  }

  const determineActiveAnnualIncomeFilter = (filter) => {
    // Show min-max inputs
    if (filter.min_estimated_annual_salary !== '' && filter.max_estimated_annual_salary !== '') {
      return 0
    } else if (filter.annual_salary !== '') {
      // Show exact planned annual income input
      return 1
    } else {
      return null
    }
  }
  const determineActiveNumberOfTimesFilter = (filter) => {
    const changeJobCount = filter.changed_job_count
    if (changeJobCount === -1) return '-1'

    if (!Number.isNaN(parseInt(changeJobCount))) return '*'

    return ''
  }

  const convertFilterNullValues = (filter) => {
    filter.keyword = filter?.keyword ?? ''
    filter.annual_salary = filter.annual_salary ?? ''
    filter.max_acceptable_age = filter.max_acceptable_age ?? ''
    filter.max_estimated_annual_salary = filter.max_estimated_annual_salary ?? ''
    filter.min_acceptable_age = filter.min_acceptable_age ?? ''
    filter.min_estimated_annual_salary = filter.min_estimated_annual_salary ?? ''
  }

  const setSearchGroupOpen = (name, val) => {
    const arrayConditions = ['searchByChar', 'searchByDetailedConditions', 'searchByPublicInformationConditions']

    setSearchByCharacteristics((draft) => {
      let i = 0,
        index = -1,
        key = null

      while (i < arrayConditions.length) {
        const currKey = arrayConditions[i]
        const currIndex = findIndex(searchByCharacteristics[currKey], { name })

        if (currIndex !== -1) {
          index = currIndex
          key = currKey
          break
        }
        i++
      }

      if (index !== -1) {
        draft[key][index].isOpen = val
      }
    })
  }

  /**
   * Calculate the isOpen state for those items that could not be derived from its value.
   *
   * @param {*} filter
   */
  const calculateOpenSearchGroup = (filter) => {
    setSearchGroupOpen('assumedAge', isNumber(filter.max_acceptable_age) || isNumber(filter.min_acceptable_age))
    setSearchGroupOpen('changeJobCount', determineActiveNumberOfTimesFilter(filter) !== '')
  }

  const handleSavedSearchCriteriaChange = (newSelected) => {
    const newFilterCriteria = filterKeys(jobFilterOptions.find((item) => item.search_criteria === newSelected))
    const parsedFilterCriteria = decodeEncodeFilterCriteriaArrayableKeys(newFilterCriteria)
    convertFilterNullValues(parsedFilterCriteria)
    populateModalFilter(newFilterCriteria)
    setCriteriaOption(newSelected)
    setActiveEstimateAnnualIncome(determineActiveAnnualIncomeFilter(parsedFilterCriteria))
    setActiveNumberOfTimes(determineActiveNumberOfTimesFilter(parsedFilterCriteria))
    setValue('changed_job_count', parsedFilterCriteria.changed_job_count)
    setValue('max_acceptable_age', parsedFilterCriteria.max_acceptable_age)
    setValue('min_acceptable_age', parsedFilterCriteria.min_acceptable_age)
    setFilterCriteria(parsedFilterCriteria)
    calculateOpenSearchGroup(parsedFilterCriteria)
    setResetTime(new Date().toString())
  }

  const handleChangeSearchCriteria = (newSelected) => {
    const filter = Object.assign({}, filterCriteria)
    filter['changed_job_count'] = newSelected
    setFilterCriteria(filter)
    setValue('changed_job_count', newSelected)
  }

  const onTextChange = (e) => {
    const filter = Object.assign({}, filterCriteria)
    const inputName = camelToSnakeCase(e.target.name)
    filter[inputName] = e.target.value
    setFilterCriteria(filter)
  }

  const resetEstinmatedSalaryInput = () => {
    const filter = Object.assign({}, filterCriteria)

    if (activeEstimateAnnualIncome === 1 || activeEstimateAnnualIncome === 0) {
      filter['min_estimated_annual_salary'] = ''
      filter['max_estimated_annual_salary'] = ''
      filter['annual_salary'] = ''
    }
    setFilterCriteria(filter)
  }

  const handleCheckedOptions = (e) => {
    const filters = Object.assign({}, filterCriteria)
    const name = camelToSnakeCase(e.target.name)
    const value = e.target.value
    let filterCheckList = {}

    if (e.target.checked) {
      filterCheckList[name] = [...filters[name], value]
    } else {
      filterCheckList[name] = filters[name].filter((item) => item !== value)
    }

    filterCheckList = { ...filters, ...filterCheckList }
    setFilterCriteria(filterCheckList)
  }

  const isChecked = (name, value) => {
    const target = filterCriteria[camelToSnakeCase(name)]
    return target.includes(value) || target.includes(value.toString())
  }

  const handleCheckPrefectureModalOptions = (e) => {
    const value = parseInt(e.target.value)
    const search = camelToSnakeCase(e.target.dataset.search)
    const filterCriterias = Object.assign({}, filterCriteria)
    const checkedPrefectures = Array.isArray(filterCriterias[search])
      ? filterCriterias[search]
      : JSON.parse(filterCriterias[search])

    let checkedPrefecturesList = checkedPrefectures
    if (e.target.checked && !checkedPrefectures.includes(value)) {
      checkedPrefecturesList = [...checkedPrefectures, value]
    } else {
      checkedPrefecturesList = checkedPrefectures.filter((item) => item !== value)
    }

    filterCriterias[search] = checkedPrefecturesList
    setFilterCriteria(filterCriterias)
  }

  const handleCheckModalOptions = (e) => {
    const filterCriterias = Object.assign({}, filterCriteria)
    const search = camelToSnakeCase(e.target.dataset.search)
    const index = e.target.dataset.filter
    const value = parseInt(e.target.value)
    const selectedCriterias = { ...filterCriterias[search] }

    const checkedFilters = Array.isArray(selectedCriterias[index])
      ? selectedCriterias[index]
      : JSON.parse(selectedCriterias[index])

    let checkedFiltersList = checkedFilters
    if (e.target.checked) {
      checkedFiltersList = [...checkedFilters, value]
      selectedCriterias[index] = checkedFiltersList
      filterCriterias[search] = selectedCriterias
      dispatch(actionUpdateJobSearchFilters({ filterCriterias }))
    } else {
      checkedFiltersList = checkedFilters.filter((item) => item !== value)
      selectedCriterias[index] = checkedFiltersList
      filterCriterias[search] = selectedCriterias
    }

    setFilterCriteria(filterCriterias)
  }

  const checkFilter = (item) => {
    if (filters[item]) {
      if (item !== MODAL_SEARCH_TYPE.workLocations) {
        return Object.keys(filters[item]).length > 0
      } else {
        return filters[item].length > 0
      }
    }
  }

  const formatPrefecture = (prefectureList) => {
    let prefectureFilter = prefectureList.reduce((acc, curr) => {
      let item = acc.find((item) => item.name === curr.category)
      if (item) {
        item.children.push({
          id: curr.id,
          category: curr.category,
          name: curr.prefecture,
        })
      } else {
        acc.push({
          id: curr.id,
          name: curr.category,
          children: [
            {
              id: curr.id,
              category: curr.category,
              name: curr.prefecture,
            },
          ],
        })
      }
      return acc
    }, [])
    return prefectureFilter
  }

  const handleSaveSearchCriteria = (criteria) => {
    setCriteriaOption(criteria)
  }

  const handleSubmitSearchModal = () => {
    const { filters } = jobFilters
    const filterCriterias = Object.assign({}, filterCriteria)
    let activeFilterModal = Object.assign({}, jobFilters.activeFilterModal)
    const filtersIds = Object.keys(filters[activeModal]).map((item) => parseInt(item))
    let filterSelected = {}
    if (activeModal !== MODAL_SEARCH_TYPE.workLocations) {
      filterSelected = modalFilterData[activeModal].filter((item) => filtersIds.includes(item.id))
    } else {
      const selectedPrefectures = Array.isArray(filters[activeModal])
        ? filters[activeModal]
        : JSON.parse(filters[activeModal])
      const prefectureList = modalFilterData[activeModal].filter((item) => selectedPrefectures.includes(item.id))
      filterSelected = formatPrefecture(prefectureList)
    }
    activeFilterModal[activeModal] = { ...activeFilterModal[activeModal], ...filterSelected }
    filterCriterias[camelToSnakeCase(activeModal)] = filters[activeModal]
    setFilterCriteria(filterCriterias)
    dispatch(actionUpdateJobSearchFilters({ activeFilterModal }))
  }

  const isCheckedModalFilter = (item, filterKey, id) => {
    if (item !== MODAL_SEARCH_TYPE.workLocations) {
      const flatten = (obj) => Object.values(obj).flat()
      var ids = []
      if (typeof filterCriteria[camelToSnakeCase(item)] !== 'object') {
        ids = flatten(JSON.parse(filterCriteria[camelToSnakeCase(item)]))
      } else {
        ids = flatten(filterCriteria[camelToSnakeCase(item)])
      }

      return ids.includes(id)
    } else {
      return filterCriteria[camelToSnakeCase(item)].includes(id)
    }
  }

  const setSearch = (key, value) => {
    setSearchByCharacteristics((draft) => {
      draft[key] = value
    })
  }

  function onClear(typeName) {
    let defaultFilter = JSON.parse(JSON.stringify(jobFilters))
    defaultFilter.activeModal = ''
    defaultFilter.filters[typeName] = []
    dispatch(actionUpdateJobSearchFilters(defaultFilter))
    setSearchByCharacteristics(defaultSearch)
  }

  const ChecklistModal = ({ filterList, item, filterKey }) => {
    return filterList.map((option) => (
      <FormGroupCheck.Item
        key={option.name}
        Input={Checkbox}
        inputProps={{
          value: option.id,
          name: option.name,
          onChange:
            item !== MODAL_SEARCH_TYPE.workLocations ? handleCheckModalOptions : handleCheckPrefectureModalOptions,
          defaultChecked: isCheckedModalFilter(item, filterKey, option.id),
          ['data-filter']: filterKey,
          ['data-search']: item,
        }}
        label={option.name}
        type="square"
      />
    ))
  }

  ChecklistModal.propTypes = {
    children: PropTypes.object,
    item: PropTypes.string,
    key: PropTypes.number,
  }

  const getChildren = (key, item, child) => {
    var filterList = []
    const flatten = (obj) => Object.values(obj).flat()
    if (typeof filters[item] !== 'object') {
      filterList = flatten(JSON.parse(filters[item]))
    } else {
      filterList = flatten(filters[item])
    }
    const children = child?.filter((item) => filterList.includes(item.id))
    return children
  }

  const ModalFiltersChecklist = ({ item }) => {
    return (
      <div className="search__conditonCheckArea">
        <div className="search__conditonCheckItemWrap" style={{ display: 'block' }}>
          {Object.keys(activeFilterModal[item]).map((key) => {
            let children = 0
            if (item === MODAL_SEARCH_TYPE.workPoints) {
              children = getChildren(key, item, activeFilterModal[item][key]?.descendants)
            } else if (item === MODAL_SEARCH_TYPE.workLocations) {
              children = activeFilterModal[item][key].children
            } else {
              children = getChildren(key, item, activeFilterModal[item][key].children)
            }

            return (
              children?.length > 0 && (
                <Fragment key={key}>
                  <h3 className="search__conditonCheckTitle">{activeFilterModal[item][key].name}</h3>
                  <ChecklistModal item={item} filterKey={activeFilterModal[item][key].id} filterList={children} />
                </Fragment>
              )
            )
          })}
        </div>
      </div>
    )
  }

  ModalFiltersChecklist.propTypes = {
    item: PropTypes.string,
  }

  const renderModalFilters = (item) => {
    return activeFilterModal[item] && <ModalFiltersChecklist item={item} />
  }

  const handleJobCountChange = (value) => {
    setValue('changed_job_count_opt', value, { shouldDirty: true })
    setValue('changed_job_count', value === '-1' && '-1')
    const filter = Object.assign({}, filterCriteria)
    filter['changed_job_count'] = getValues('changed_job_count')
    setFilterCriteria(filter)
    setSearch('searchCriteriaOptions', { values: 0 })
  }

  const resetSearchFilter = () => {
    setSearchByCharacteristics(defaultSearch)
    dispatch(actionResetJobSearchFilters())
    setFilterCriteria(filterCriterias)
    setActiveEstimateAnnualIncome(determineActiveAnnualIncomeFilter(filterCriterias))
    setActiveNumberOfTimes(determineActiveNumberOfTimesFilter(filterCriterias))
    dispatch(actionSetEditMode(false))
    dispatch(actionUpdateJobSearchParams({ page: 1 }))
    dispatch(actionTriggerJobSearch())
    setResetTime(new Date().toString())
  }

  const searchFilter = () => {
    const searchFilter = filterCriteria

    const flatten = (obj) => Object.values(obj).flat()
    const newkeys = { industries: 'job_industry', occupations: 'available_position' }
    const searchKeys = Object.keys(searchFilter)
    const updatedKeys = {}
    searchKeys.forEach((key) => {
      const newKey = newkeys[key] || key
      updatedKeys[newKey] =
        searchFilter[key] && typeof searchFilter[key] === 'object' ? flatten(searchFilter[key]) : searchFilter[key]
    })

    const keys = [
      'available_position',
      'working_points',
      'job_industry',
      'education_background',
      'english_level',
      'chinese_level',
    ]
    keys.forEach((key) => {
      updatedKeys[key] = updatedKeys[key].length > 0 ? flatten(updatedKeys[key]) : ''
    })
    const otherKeys = [
      'desired_period_of_employment',
      'number_of_positions',
      'sending_scout_email',
      'posting_recruitment_media',
      'number_of_employees',
    ]
    otherKeys.forEach(
      (key) =>
        (updatedKeys[key] =
          updatedKeys[key].length > 0
            ? typeof updatedKeys[key] === 'string'
              ? JSON.parse(updatedKeys[key])
              : updatedKeys[key]
            : '')
    )

    const filteredParams = filterKeys(updatedKeys)

    dispatch(searchJobPostings({ ...params, ...filteredParams, status: jobSearchStatus }))
  }

  const { searchByChar, searchByDetailedConditions, searchByPublicInformationConditions } = searchByCharacteristics
  const mapCriterias = jobFilterOptions.map((item) => {
    return {
      id: item.search_criteria,
      label: item.search_criteria,
    }
  })

  const industry = Object.keys(filterCriteria.job_industry).map((res) => filterCriteria.job_industry[res][0] ?? null)
  const occupation = Object.keys(filterCriteria.available_position).map(
    (res) => filterCriteria.available_position[res][0] ?? null
  )

  const disabled =
    (filterCriteria.keyword || '').trim().length === 0 &&
    filterCriteria.annual_salary === '' &&
    (filterCriteria.job_industry.length === 0 || industry.find((res) => res > 0) === undefined) &&
    (filterCriteria.available_position.length === 0 || occupation.find((res) => res > 0) === undefined) &&
    (filterCriteria.min_estimated_annual_salary === '' || filterCriteria.max_estimated_annual_salary === '') &&
    [
      'work_locations',
      'working_points',
      'characteristic_employment_status',
      'number_of_positions',
      'desired_period_of_employment',
      'detailed_employment_status',
      'number_of_employees',
      'type_of_work',
      'education_background',
      'assumed_gender',
      'english_level',
      'chinese_level',
      'posting_recruitment_media',
      'sending_scout_email',
      'advertising_budget',
      'year_established',
      'closing_month',
      'current_media',
      'past_publication_media',
      'media_of_interest',
      'post_period',
    ].every((key) => (filterCriteria[key] || []).length === 0) &&
    (filterCriteria.min_acceptable_age === '' || filterCriteria.max_acceptable_age === '') &&
    (filterCriteria.changed_job_count === null || filterCriteria.changed_job_count === false) &&
    filterCriteria.presence_absence_authorizer === null

  const handleSelectCriteria = (searchCriteria) => {
    handleSavedSearchCriteriaChange(searchCriteria)
    dispatch(actionTriggerJobSearch())
  }

  //trigger search after render with applied changes
  useEffect(() => {
    if (isCriteriaSelected) {
      searchFilter()
      dispatch(actionUntriggerJobSearch())
    }
  }, [isCriteriaSelected])

  const handleSearch = () => {
    dispatch(actionUpdateJobSearchParams({ page: 1 }))
    dispatch(actionTriggerJobSearch())
    handleFilterCloseIconClick()
  }

  const handleAdvertisingBudgetChange = (values) => {
    const filter = Object.assign({}, filterCriteria)
    filter['advertising_budget'] = values
    setFilterCriteria(filter)
  }

  return (
    <FormProvider {...methods}>
      <div className="search__sideSec">
        <FormGroup modifier="mgb0 mgt0">
          <div className="search__groupFlex">
            <Label blockElClass="form_label search__label3">{t('jobPosting.search.applySaveCriteria')}</Label>
            <BlockEl
              onClick={() => !editMode && setOpenCriteriaModal(true)}
              bec="search__conditonBtn"
              modifier={{ 'search__conditonBtn-gray': editMode }}
            >
              {t('searchCriteria.SearchConditionList')}
            </BlockEl>
          </div>
          <CustomSelect
            options={mapCriterias}
            placeholder={mapCriterias.length > 0 ? t('common.pleaseSelect') : t('searchCriteria.noSavedSearch')}
            onChange={handleSavedSearchCriteriaChange}
            selected={criteriaOption}
            disabled={editMode || mapCriterias.length < 1}
          />
        </FormGroup>

        <ButtonModal
          className={clsx('search__keepBtn', {
            'search__conditonBtn-gray': editMode,
            'search__keepBtn-disabled': disabled,
          })}
          id="keepBtn"
          data-modal="modal"
          data-modalcont="modalCont1"
          onClick={() => !editMode && !disabled && searchModal('saveSearchCriteria')}
        >
          {t('jobPosting.search.saveCurrentSearchCriteria')}
        </ButtonModal>
      </div>
      <SearchSideSection title={t('jobPosting.search.basicCondition')}>
        <FormGroup className={'form__group-mgb0 form__group-mgt0'}>
          <Label className="search__label2" modifier={''}>
            {t('jobPosting.search.freewordJobId')}
          </Label>
          <Input
            type="text"
            className={'form__group-mgt0 form__inputText-w100'}
            {...register('keyword', {
              maxLength: { value: 100, message: t('maxLength') },
            })}
            data-search="searchByBasicConditions"
            value={filterCriteria.keyword}
            onChange={(e) => onTextChange(e)}
          />
        </FormGroup>
        {options.basicConditions.map((item) => (
          <div className="search__conditon" key={item}>
            <SearchConditionGroup label={t(`jobPosting.search.${item}`)} handleModalOpen={() => searchModal(item)} />
            {checkFilter(item) && renderModalFilters(item)}
          </div>
        ))}
        {isAD2 ? (
          <FormGroup modifier="mgb0">
            <p className="search__sideSecTitle2">{t('jobVacancies.basicInformation.advertisingBudget')}</p>
            <CheckListV2
              selected={filterCriteria.advertising_budget}
              items={options.advertisingBudget.map((value) => ({ value, label: t(`jobPosting.search.${value}`) }))}
              onChange={handleAdvertisingBudgetChange}
            />
          </FormGroup>
        ) : (
          <FormGroup modifier="mgb0">
            <Label className="search__sideSecTitle2" mini={false}>
              {t('jobPosting.search.estimatedAnnualIncome')}
            </Label>
            <div className="form__groupCheckArea">
              <FormGroupCheck>
                <FormGroupCheck.Item
                  Input={Radio}
                  inputProps={{
                    className: 'formRadio boolCheck interlock',
                    checked: activeEstimateAnnualIncome === 0,
                    onChange: () => {
                      setActiveEstimateAnnualIncome(0)
                      resetEstinmatedSalaryInput()
                    },
                  }}
                  type="round"
                  label={t('jobPosting.search.specifyMinMaxIncome')}
                />
                <FormInterlock active={activeEstimateAnnualIncome === 0}>
                  <div className="form__groupFlex form__group-mgt">
                    <Input
                      type="number"
                      className={'form__inputText form__inputText-w6em'}
                      name="minEstimatedAnnualSalary"
                      data-search="searchByBasicConditions"
                      value={filterCriteria[camelToSnakeCase('minEstimatedAnnualSalary')]}
                      min={1}
                      {...register('minEstimatedAnnualSalary', {
                        min: { value: 1, message: t('validation.invalidInputNumber') },
                        onChange: (e) => onTextChange(e),
                      })}
                    />
                    <p className="form__inputMiddleText">～</p>
                    <Input
                      type="number"
                      className={'form__inputText form__inputText-w6em'}
                      name="maxEstimatedAnnualSalary"
                      data-search="searchByBasicConditions"
                      value={filterCriteria[camelToSnakeCase('maxEstimatedAnnualSalary')]}
                      min={1}
                      {...register('maxEstimatedAnnualSalary', {
                        min: { value: 1, message: t('validation.invalidInputNumber') },
                        onChange: (e) => onTextChange(e),
                      })}
                    />
                    <p className="form__inputAfterText">{t('jobPosting.search.tenThousanYen')}</p>
                  </div>
                  {errors.minEstimatedAnnualSalary && (
                    <Label modifier="error">{errors.minEstimatedAnnualSalary.message}</Label>
                  )}
                  <br />
                  {errors.maxEstimatedAnnualSalary && (
                    <Label modifier="error">{errors.maxEstimatedAnnualSalary.message}</Label>
                  )}
                </FormInterlock>
                <FormGroupCheck.Item
                  Input={Radio}
                  inputProps={{
                    className: 'formRadio boolCheck interlock',
                    checked: activeEstimateAnnualIncome === 1,
                    onChange: () => {
                      setActiveEstimateAnnualIncome(1)
                      resetEstinmatedSalaryInput()
                    },
                  }}
                  type="round"
                  label={t('jobPosting.search.specifyAnnualIncome')}
                />
                <FormInterlock active={activeEstimateAnnualIncome === 1}>
                  <div className="form__groupFlex form__group-mgt">
                    <Input
                      type="number"
                      className={'form__inputText form__inputText-w6em'}
                      name="annualSalary"
                      data-search="searchByBasicConditions"
                      value={filterCriteria[camelToSnakeCase('annualSalary')]}
                      onChange={onTextChange}
                      min={1}
                      {...register('annualSalary', {
                        min: { value: 1, message: t('validation.invalidInputNumber') },
                        onChange: (e) => onTextChange(e),
                      })}
                    />
                    <p className="form__inputAfterText">{t('jobPosting.search.tenThousanYen')}</p>
                  </div>
                  {errors.annualSalary && <Label modifier="error">{errors.annualSalary.message}</Label>}
                </FormInterlock>
              </FormGroupCheck>
            </div>
          </FormGroup>
        )}
      </SearchSideSection>
      {isAD2 && (
        <PublicationMediumSection
          filterCriteria={filterCriteria}
          setFilterCriteria={setFilterCriteria}
          resetToState={resetTime}
        />
      )}
      <SearchSideSection title={t('jobPosting.search.searchByCharacteristics')}>
        {!isAD2 && (
          <div className="search__conditon search__conditon-mgb">
            <SearchConditionGroup
              label={t('jobPosting.search.pointOfWorkingStyle')}
              handleModalOpen={() => searchModal(MODAL_SEARCH_TYPE.workPoints)}
            />
            {checkFilter(MODAL_SEARCH_TYPE.workPoints) && renderModalFilters(MODAL_SEARCH_TYPE.workPoints)}
          </div>
        )}
        {searchByChar.map((dropdown, index) => (
          <DropDown
            activeNumberOfTimes={activeNumberOfTimes}
            setActiveNumberOfTimes={setActiveNumberOfTimes}
            onJobCountChange={handleJobCountChange}
            onChangeCriteria={handleChangeSearchCriteria}
            onTextChange={onTextChange}
            isChecked={isChecked}
            onCheckboxChange={handleCheckedOptions}
            onOpen={handleOpenDropdown}
            filterCriteria={filterCriteria}
            dropdown={dropdown}
            key={dropdown.name}
            index={index}
            onJobChangeCountChange={handleChangeSearchCriteria}
            searchType="searchByChar"
          />
        ))}
      </SearchSideSection>
      <SearchSideSection title={t('jobPosting.search.searchByDetailedConditions')}>
        {searchByDetailedConditions.map((dropdown, index) => (
          <DropDown
            activeNumberOfTimes={activeNumberOfTimes}
            setActiveNumberOfTimes={setActiveNumberOfTimes}
            onJobCountChange={handleJobCountChange}
            onChangeCriteria={handleChangeSearchCriteria}
            onTextChange={onTextChange}
            isChecked={isChecked}
            onCheckboxChange={handleCheckedOptions}
            onOpen={handleOpenDropdown}
            filterCriteria={filterCriteria}
            dropdown={dropdown}
            key={dropdown.name}
            index={index}
            note={dropdown.notes}
            onJobChangeCountChange={handleChangeSearchCriteria}
            searchType="searchByDetailedConditions"
          />
        ))}
      </SearchSideSection>
      {!isAD2 && (
        <SearchSideSection title={t('jobPosting.search.publicInformation')}>
          {searchByPublicInformationConditions.map((dropdown, index) => (
            <DropDown
              activeNumberOfTimes={activeNumberOfTimes}
              setActiveNumberOfTimes={setActiveNumberOfTimes}
              onJobCountChange={handleJobCountChange}
              onChangeCriteria={handleChangeSearchCriteria}
              onTextChange={onTextChange}
              isChecked={isChecked}
              onCheckboxChange={handleCheckedOptions}
              onOpen={handleOpenDropdown}
              filterCriteria={filterCriteria}
              dropdown={dropdown}
              key={dropdown.name}
              index={index}
              onJobChangeCountChange={handleChangeSearchCriteria}
              searchType="searchByPublicInformationConditions"
            />
          ))}
        </SearchSideSection>
      )}
      <div className="search__sideBtnArea">
        {!editMode ? (
          <>
            <Button modifier="fs14" variant="text" onClick={resetSearchFilter}>
              {isAdmin ? t('common.cancel') : t('jobPosting.search.clearSearchConditions')}
            </Button>
            <Button modifier="fs14" variant="shadow" onClick={handleSearch}>
              {isAdmin ? t('common.register') : t('jobPosting.search.searchFor')}
            </Button>
          </>
        ) : (
          <>
            <Button modifier="fs14" variant="text" onClick={handleEditCancelClick} disabled={isSubmitting}>
              {t('common.cancel')}
            </Button>
            <Button
              modifier="fs14"
              variant={isSubmitting || enableUpdateBtn ? 'shadowDissable' : 'shadow'}
              disabled={isSubmitting || enableUpdateBtn}
              onClick={handleUpdateJobFilter}
            >
              {t('common.change')}
            </Button>
          </>
        )}
        <JobPostingModal
          onClear={(e) => onClear(e)}
          onClose={handleModalClose}
          onClickList={handleActiveLists}
          open={openModal}
          modalData={modalData}
          activeListKey={activeListKey}
          searchByCharacteristics={searchByCharacteristics}
          onSubmitSearch={handleSubmitSearchModal}
          onSaveSearchCriteria={handleSaveSearchCriteria}
        />
      </div>

      <SearchCriteriaModal
        open={openCriteriaModal}
        onSelectCriteria={handleSelectCriteria}
        onCloseCriteriaModal={() => setOpenCriteriaModal(false)}
        onEditClick={handleCriteriaModalEditBtnClick}
        handleFilterCloseIconClick={handleFilterCloseIconClick}
      />
    </FormProvider>
  )
}

JobPostingSidebarSearch.propTypes = {
  handleFilterCloseIconClick: PropTypes.func,
  item: PropTypes.object,
}

export default memo(JobPostingSidebarSearch, isEqual)

import React from 'react'
import PropTypes from 'prop-types'
import { JobPostingCard, SlickSlider } from 'components'

const JobPostingSlider = ({ items }) => {
  const sliderSettings = {
    autoplay: false,
    infinite: false,
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    variableWidth: false,
    draggable: true,
    responsive: [{ breakpoint: 834, settings: { centerMode: false, slidesToShow: 1 } }],
    className: 'dashboard__job',
  }

  const SlideItem = ({ item }) => {
    return <JobPostingCard jobPost={item} />
  }

  SlideItem.propTypes = {
    item: PropTypes.any.isRequired,
  }

  return (
    <SlickSlider settings={sliderSettings} itemsCount={items.length} rowCount={3}>
      {items?.map((item, index) => (
        <SlideItem key={index} item={item} />
      ))}
    </SlickSlider>
  )
}
JobPostingSlider.propTypes = {
  items: PropTypes.array,
}

export default JobPostingSlider

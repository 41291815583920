import React from 'react'
import { useSelector } from 'react-redux'
import { FormGroupCheck } from 'components/molecules'
import { useFormContext } from 'react-hook-form'
import { Checkbox } from 'components/atoms'
import PropTypes from 'prop-types'

const ApplicantsStatus = (props) => {
  const { rules } = props
  const { register } = useFormContext()
  const applicantsStatusState = useSelector((state) => state.applicantsStatus.state)
  const items = useSelector((state) => state.applicantsStatus.items)

  return (
    <FormGroupCheck blockElClass="candidate__sideSecGroupCheckWrap">
      {applicantsStatusState === 'fulfilled' &&
        items?.map((applicant_status, index) => (
          <FormGroupCheck.Item
            key={index}
            Input={Checkbox}
            className="candidate__conditonCheckItem"
            inputProps={{ ...register('status', rules), value: applicant_status?.id, className: 'form__checkItem2' }}
            type="square"
            label={applicant_status?.name}
            labelProps={{ className: 'form__checkLabel2-mgl0' }}
          />
        ))}
    </FormGroupCheck>
  )
}

ApplicantsStatus.propTypes = {
  rules: PropTypes.object,
}

export default ApplicantsStatus

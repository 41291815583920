import React from 'react'
import { useTranslation } from 'react-i18next'
import TrainSvg from 'assets/img/train-icon.svg'
import { BlockEl } from 'components/atoms'

function WorkPlaceCard(params) {
  const { t } = useTranslation('translation', { keyPrefix: 'jobVacancies.workPlaceCard' })
  const { zipcode, address, nearestStation } = params

  return (
    <BlockEl blockElClass="jobvacancies__ddBox">
      <p className="jobvacancies__ddBoxText">
        {t('zipcode')} {zipcode}
      </p>
      <p className="jobvacancies__ddBoxText">{address}</p>
      <dl className="jobvacancies__ddBoxDl">
        <dt className="jobvacancies__ddBoxDt">
          <img src={TrainSvg} alt="" className="jobvacancies__ddBoxDtImg" width="15" height="17" />
          {t('nearest')}
        </dt>
        <dd className="jobvacancies__ddBoxDd">{nearestStation}</dd>
      </dl>
    </BlockEl>
  )
}

export default WorkPlaceCard

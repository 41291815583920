import produce from 'immer'
import { fetchUserSettings, updateUserSetting } from 'services/userSettings'
import { userSettingAdapter } from './userSettingAdapter'

const initialState = userSettingAdapter.getInitialState({
  loading: false,
  error: null,
  request: [],
})

const reducer = produce((draft, { type, payload, meta }) => {
  switch (type) {
    case fetchUserSettings.pending.type:
      draft.error = null
      draft.loading = true
      break
    case fetchUserSettings.fulfilled.type:
      draft.loading = false
      userSettingAdapter.setAll(draft, payload.data)
      break
    case fetchUserSettings.rejected.type:
      draft.loading = false
      break
    case updateUserSetting.pending.type:
      console.log({ meta })
      break
    case updateUserSetting.fulfilled.type:
      userSettingAdapter.upsertOne(draft, payload.data)
      break
    default:
      return draft
  }
}, initialState)

export default reducer

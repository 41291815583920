import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const Activity = ({ stats }) => {
  const { t } = useTranslation()

  return (
    <div className="dashboard__activity">
      <div className="dashboard__title-wrap">
        <p className="dashboard__title">{t('dashboard.activity.title')}</p>
      </div>
      <div className="dashboard__status">
        {stats.map(({ label, value }) => (
          <dl className="dashboard__status-dl" key={label + value}>
            <dt>{label}</dt>
            <dd>
              <span className="dashboard__status-num">{value}</span>
              <span className="dashboard__status-unit">{t('suffixes.subject')}</span>
            </dd>
          </dl>
        ))}
      </div>
    </div>
  )
}

Activity.propTypes = {
  stats: PropTypes.array.isRequired,
}

export default Activity

import * as ActionTypes from './actionTypes'

export function loadCompanySelectRequest() {
  return {
    type: ActionTypes.LOAD_COMPANY_SELECT_REQUEST,
  }
}

export function loadCompanySelectSuccess(data) {
  return {
    type: ActionTypes.LOAD_COMPANY_SELECT_SUCCESS,
    payload: data,
  }
}

export function loadCompanySelectFailure(error) {
  return {
    type: ActionTypes.LOAD_COMPANY_SELECT_FAILURE,
    payload: error,
  }
}

//Company Update
export function updateCompanyRequest(id) {
  return {
    type: ActionTypes.UPDATE_COMPANY_REQUEST,
    payload: { id },
  }
}

export function updateCompanySuccess(data) {
  return {
    type: ActionTypes.UPDATE_COMPANY_SUCCESS,
    payload: data,
  }
}

export function updateCompanyFailure(error) {
  return {
    type: ActionTypes.UPDATE_COMPANY_FAILURE,
    payload: { error },
  }
}

//Company Update List
export const actionUpdateCompanyListParams = (patch) => {
  return {
    type: ActionTypes.UPDATE_COMPANY_LIST_PROPOSALS,
    payload: patch,
  }
}

export const actionSyncCompanyCreate = (payload) => ({
  type: ActionTypes.SYNC_COMPANY_USER_FORM,
  payload,
})

export const setCompanyMgmtFilter = (status) => {
  return {
    type: ActionTypes.SET_COMPANY_MANAGEMENT_FILTER,
    payload: status,
  }
}

export const setCompanyAdminMgmtFilter = (status) => {
  return {
    type: ActionTypes.SET_COMPANY_ADMIN_MANAGEMENT_FILTER,
    payload: status,
  }
}

// export const actionSetJobPostingProposalForm = (payload) => ({
//   type: ActionTypes.UPDATE_COMPANY_LIST_PROPOSALS,
//   payload,
// })

// export function createCompanySuccess(data) {
//   return {
//     type: ActionTypes.CREATE_COMPANY_USER_FORM,
//     payload: data,
//   }
// }

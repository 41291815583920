import produce from 'immer'
import * as ActionTypes from './actionTypes'
import {
  fetchJobPosting,
  getJobPostings,
  getJobPostingsFavorite,
  createJobPostings,
  searchJobPostings,
  addToFavorites,
  canPropose,
  fetchForEditProposal,
} from 'services/jobPostings'
import { MODAL_SEARCH_TYPE } from 'utils/constants'
import { AUTH_SIGNOUT } from 'store/auth/actionTypes'

const initialState = {
  items: [],
  jobPosting: null,
  state: 'pending',
  listState: 'pending',
  params: {
    limit: 10,
    page: 1,
    sort: 'desc',
    sortBy: 'created_at',
  },
  pdfUrl: '',
  jobPostingSearch: {
    filters: {
      [MODAL_SEARCH_TYPE.industries]: [],
      [MODAL_SEARCH_TYPE.occupations]: [],
      [MODAL_SEARCH_TYPE.workLocations]: [],
      [MODAL_SEARCH_TYPE.workPoints]: [],
    },
    activeModal: '',
    activeFilterModal: {},
    triggerSearch: false,
    filterCriterias: {
      search_criteria: '',
      keyword: '',
      job_industry: [],
      available_position: [],
      work_locations: [],
      min_estimated_annual_salary: '',
      max_estimated_annual_salary: '',
      annual_salary: '',
      working_points: [],
      characteristic_employment_status: [],
      number_of_positions: [],
      desired_period_of_employment: [],
      detailed_employment_status: [],
      number_of_employees: [],
      type_of_work: [],
      min_acceptable_age: '',
      max_acceptable_age: '',
      changed_job_count: null,
      education_background: [],
      assumed_gender: [],
      english_level: [],
      chinese_level: [],
      japanese_level: [],
      posting_recruitment_media: [],
      sending_scout_email: [],
      advertising_budget: [],
      year_established: [],
      closing_month: [],
      presence_absence_authorizer: null,
      current_media: [],
      past_publication_media: [],
      media_of_interest: [],
      post_period: [],
    },
  },
  pagination: {
    total: 0,
    currentPage: 1,
    lastPage: 1,
  },
  proposalSlideOut: {
    open: false,
    initialData: null,
  },
  controls: {
    filterModal: false,
    filterJobPostingModal: false,
    favoriteModal: {
      isOpen: false,
      selected: null,
    },
    batchSelectionStatus: false,
  },
}

export default produce((draft, { type, payload, meta }) => {
  switch (type) {
    case ActionTypes.UPDATE_JOB_SEARCH_PARAMS:
      draft.params = { ...draft.params, ...payload }
      break
    case ActionTypes.UPDATE_JOB_SEARCH_FILTERS:
      draft.jobPostingSearch = { ...draft.jobPostingSearch, ...payload }
      break
    case ActionTypes.RESET_JOB_SEARCH_FILTERS:
      draft.jobPostingSearch = { ...draft.jobPostingSearch, ...initialState.jobPostingSearch }
      break
    case fetchJobPosting.pending:
      draft.state = 'pending'
      break
    case fetchJobPosting.fulfilled:
      draft.state = 'fulfilled'
      draft.jobPosting = Object.assign(draft.jobPosting || {}, payload.data)
      break
    case fetchJobPosting.failure:
      draft.state = 'failure'
      break
    case getJobPostings.pending:
      draft.listState = 'pending'
      break
    case getJobPostings.fulfilled:
      draft.listState = 'fulfilled'
      draft.items = payload.data
      draft.pagination = {
        total: +payload.meta.total,
        lastPage: +payload.meta.lastPage,
        currentPage: +payload.meta.currentPage,
      }
      break
    case getJobPostingsFavorite.pending:
      draft.listState = 'pending'
      break
    case getJobPostingsFavorite.fulfilled:
      draft.listState = 'fulfilled'
      draft.items = payload.data
      draft.pagination = {
        total: +payload.meta.total,
        lastPage: +payload.meta.lastPage,
        currentPage: +payload.meta.currentPage,
      }
      break
    case getJobPostings.failure:
      draft.listState = 'failure'
      break
    case getJobPostingsFavorite.failure:
      draft.listState = 'failure'
      break

    //update job posting
    case ActionTypes.UPDATE_JOB_POSTING_REQUEST:
      draft.details = null
      draft.pending = true
      draft.success = false
      break
    case ActionTypes.UPDATE_JOB_POSTING_SUCCESS:
      draft.details = payload.data
      draft.pending = false
      draft.success = true
      break
    case ActionTypes.UPDATE_JOB_POSTING_FAILURE:
      draft.details = null
      draft.pending = false
      draft.success = false
      draft.error = payload.error
      break
    case ActionTypes.TRIGGER_JOB_SEARCH:
      draft.jobPostingSearch.triggerSearch = true
      break
    case ActionTypes.UNTRIGGER_JOB_SEARCH:
      draft.jobPostingSearch.triggerSearch = false
      break
    case createJobPostings.fulfilled:
      draft.state = 'fulfilled'
      break
    case createJobPostings.pending:
      draft.state = 'pending'
      break
    case createJobPostings.failure:
      draft.state = 'failure'
      break
    case searchJobPostings.fulfilled:
      draft.listState = 'fulfilled'
      draft.items = payload.data
      draft.pagination = {
        total: +payload.meta.total,
        lastPage: +payload.meta.lastPage,
        currentPage: +payload.meta.currentPage,
      }
      break
    case searchJobPostings.pending:
      draft.listState = 'pending'
      break
    case searchJobPostings.failure:
      draft.listState = 'failure'
      break
    case addToFavorites.fulfilled: {
      const index = draft.items.findIndex((items) => items.id === payload.data.job_posting_id)
      if (index !== -1) {
        draft.items[index].is_bookmarked = meta.args[1] === 0
      }
      break
    }
    case canPropose.pending:
      draft.jobPosting.can_propose = false
      break
    case canPropose.fulfilled:
      draft.jobPosting.can_propose = payload.data
      break

    case canPropose.failure:
      draft.jobPosting.can_propose = false
      break
    case ActionTypes.OPEN_PROPOSAL_SLIDEOUT:
      draft.proposalSlideOut.open = true
      draft.proposalSlideOut.initialData = payload.initialData
      break
    case ActionTypes.CLOSE_PROPOSAL_SLIDEOUT:
      draft.proposalSlideOut.open = false
      draft.proposalSlideOut.initialData = payload.initialData
      break
    case fetchForEditProposal.fulfilled:
      draft.proposalSlideOut.open = true
      draft.proposalSlideOut.initialData = payload.data
      break
    case ActionTypes.SET_JOB_FILTER_SLIDE_STATUS:
      draft.controls.filterModal = payload
      break
    case ActionTypes.SET_JOB_POSTING_FAVORITE_MODAL:
      draft.controls.favoriteModal = payload
      break
    case ActionTypes.SET_BATCH_SELECTION_STATUS:
      draft.controls.batchSelectionStatus = payload
      break
    case ActionTypes.SET_JOB_POSTING_FILTER:
      draft.controls.filterJobPostingModal = payload
      break
    case AUTH_SIGNOUT:
      return initialState
    default:
      return draft
  }
}, initialState)

import React from 'react'
import PropTypes from 'prop-types'
import { default as ReactSelect } from 'react-select'
import { t } from 'i18next'
import { BlockEl } from 'components/atoms'

const customStyles = {
  control: () => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    background: '#F5F4EF',
    borderRadius: '4px',
    border: '1px solid transparent',
    width: '100%',
    boxSizing: 'border-box',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.014em',
    color: '#8A8883',
  }),
  option: () => ({}),
}

const SelectItem = (props) => {
  if (props.allowSelectAll) {
    return (
      <BlockEl blockElClass="modal__searchInputArea">
        <ReactSelect
          {...props}
          styles={customStyles}
          placeholder={t('newJobPostProposal.step1.searchFor')}
          options={props.options}
          onChange={props.onChange}
          getOptionValue={(item) => item.id}
          getOptionLabel={(option) => option.name}
        />
      </BlockEl>
    )
  }

  return <ReactSelect {...props} />
}

SelectItem.propTypes = {
  options: PropTypes.array,
  value: PropTypes.any,
  label: PropTypes.any,
  onChange: PropTypes.func,
  allowSelectAll: PropTypes.bool,
  allOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
}

export default SelectItem

import React, { useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import partition from 'lodash/partition'

import PersonImg from 'assets/img/person-img.png'
import CheckIcon from 'assets/img/check-icon-main.svg'
import { selectRefPropSlideoutItem } from 'store/referral-proposals/selectors'
import { BlockEl, NewlineText } from 'components/atoms'
import { fetchUserProfile } from 'services/userLogs'
import { selectUserById } from 'store/users/reducer'
import { selectAuthUser } from 'store/auth/selectors'
import { inAccountType } from 'utils/helper'

function CompanyInfo({ company }) {
  const { t } = useTranslation()
  return (
    <dl className="selectionModal__orgInfoDl">
      <dt className="selectionModal__orgInfoDt">{t('selection.modal.companyName')}</dt>
      <dd className="selectionModal__orgInfoDd">{company.company_name}</dd>
      <dt className="selectionModal__orgInfoDt">{t('suggestion.modal.hqAddress')}</dt>
      <dd className="selectionModal__orgInfoDd">{company.address}</dd>
      <dt className="selectionModal__orgInfoDt">{t('common.industry')}</dt>
      <dd className="selectionModal__orgInfoDd">{company.industry?.name}</dd>
      <dt className="selectionModal__orgInfoDt">{t('corporateInfo.companyHP')}</dt>
      <dd className="selectionModal__orgInfoDd selectionModal__orgInfoDd-link">{company.website_url}</dd>
      <dt className="selectionModal__orgInfoDt">{t('common.ipo')}</dt>
      <dd className="selectionModal__orgInfoDd">{company.ipo}</dd>
      <dt className="selectionModal__orgInfoDt">{t('corporateInfo.representativeName')}</dt>
      <dd className="selectionModal__orgInfoDd">{company.last_name + ' ' + company.first_name}</dd>
      <dt className="selectionModal__orgInfoDt">{t('corporateInfo.yrOfEstablishment')}</dt>
      <dd className="selectionModal__orgInfoDd">
        {t('corporateInfo.yrOfEstablishmentSuffix', { year: company.year_established })}
      </dd>
      <dt className="selectionModal__orgInfoDt">{t('corporateInfo.closingMonth')}</dt>
      <dd className="selectionModal__orgInfoDd">
        {t('corporateInfo.closingMonthVal', { months: company.account_settlement_month })}
      </dd>
      <dt className="selectionModal__orgInfoDt">{t('corporateInfo.numOfEmployees')}</dt>
      <dd className="selectionModal__orgInfoDd">
        {t('corporateInfo.numOfEmployeesCount', { count: company.employee_count })}
      </dd>
      <dt className="selectionModal__orgInfoDt">{t('corporateInfo.companyProfile')}</dt>
      <dd className="selectionModal__orgInfoDd">
        <NewlineText text={company.description} />
      </dd>
      <dt className="selectionModal__orgInfoDt">{t('corporateInfo.businessContent')}</dt>
      <dd className="selectionModal__orgInfoDd">
        <NewlineText text={company.overview} />
      </dd>
    </dl>
  )
}

CompanyInfo.propTypes = {
  company: PropTypes.object,
}

function SubBlock({ title, items }) {
  return (
    <div className="selectionModal__subsBlock">
      <div className="selectionModal__subsTitle">{title}</div>
      <ul className="selectionModal__subsUl">
        {items.map((item, index) => (
          <li className="selectionModal__subsLi" key={index}>
            <img className="selectionModal__subsCheckIcon" src={CheckIcon} alt="" width="24" height="24" />
            <span className="selectionModal__subsLiText">{item}</span>
          </li>
        ))}
      </ul>
    </div>
  )
}

SubBlock.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.string),
}

const getName = (item) => item.name

function SuggestionModalADCompanyInfoTab({ active }) {
  const { t } = useTranslation()
  const item = useSelector(selectRefPropSlideoutItem)
  const user = useSelector(selectAuthUser)
  const referrerId = item.referrer?.id
  const referrer = useSelector((state) => selectUserById(state, referrerId) ?? {})
  const dispatch = useDispatch()
  const isAD2 = inAccountType(user, 'AD2')
  const company = (isAD2 ? item.job_posting?.company : referrer.company) ?? {}
  const [bestIndustries, specializedIndustries] = partition(referrer.industries || [], ['most_good', true])
  const [bestOccupations, specializedOccupations] = partition(referrer.occupations || [], ['most_good', true])

  useEffect(() => {
    if (referrerId) {
      dispatch(fetchUserProfile(referrerId))
    }
  }, [item.id, referrerId])

  const blocks = [
    {
      title: t('suggestion.modal.bestIndustry'),
      items: bestIndustries.map(getName),
    },
    {
      title: t('suggestion.modal.specialIndustry'),
      items: specializedIndustries.map(getName),
    },
    {
      title: t('suggestion.modal.bestOccupation'),
      items: bestOccupations.map(getName),
    },
    {
      title: t('suggestion.modal.specialOccupation'),
      items: specializedOccupations.map(getName),
    },
  ]

  return (
    <BlockEl bec="selectionModal__tabCont" modifier={{ active }}>
      <div className="selectionModal__info">
        {!isAD2 && (
          <>
            <div className="selectionModal__infoHeader">
              <div className="selectionModal__infoTitle">{t('common.profile')}</div>
              <div className="selectionModal__infoPersonDetail">
                <div className="selectionModal__infoPersonImg">
                  <img src={referrer.avatar || PersonImg} alt="" />
                </div>
                <div className="selectionModal__infoPersonText">
                  <div className="selectionModal__infoPersonName">{referrer.full_name}</div>
                  <div className="selectionModal__infoPersonOrg">{company?.company_name}</div>
                </div>
              </div>
            </div>

            <div className="selectionModal__subs">
              {blocks.map((block, index) => (
                <SubBlock key={index} {...block} />
              ))}
            </div>

            <div className="selectionModal__comment">
              <NewlineText
                text={referrer.self_introduction}
                renderLine={({ line }) => <p className="selectionModal__commentP">{line}</p>}
                noBreak
              />
            </div>
          </>
        )}

        <BlockEl bec="selectionModal__orgInfo" modifier={{ mt0: isAD2 }}>
          <div className="selectionModal__orgInfoTitle">
            {isAD2 ? t('jobPosting.tabs.corporateInfo') : t('suggestion.modal.companyInfo')}
          </div>
          <CompanyInfo company={company} />
        </BlockEl>
      </div>
    </BlockEl>
  )
}

SuggestionModalADCompanyInfoTab.propTypes = {
  active: PropTypes.bool.isRequired,
}

export default SuggestionModalADCompanyInfoTab

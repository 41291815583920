import React from 'react'

import PropTypes from 'prop-types'

import { BlockEl } from 'components/atoms'

const Header = React.forwardRef(({ children, modifier }) => {
  return (
    <BlockEl blockElClass="header" modifier={modifier}>
      {children}
    </BlockEl>
  )
})

Header.displayName = 'Header'

Header.propTypes = {
  children: PropTypes.node,
  modifier: PropTypes.string,
}

export default Header

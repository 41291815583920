import React from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormItemLabel, OtherUrlPdfDialog } from 'components'
import { BlockEl, Input } from 'components/atoms'
import { FormGroup } from 'components/molecules'
import { getFileNameFromURl, makeFormGroupStateGetter, replaceFileName } from 'utils/helper'
import LinkPreview from 'components/molecules/LinkPreview'

const ApplicantDocuments = (props) => {
  const { t } = useTranslation()
  const {
    successResume,
    successPdfWorkCv,
    handleFileInput,
    selectedResume,
    data,
    mode,
    selectedWorkCv,
    editmode,
    applicant,
    handleOnChange,
    handleRemoveFile,
    selectedOtherPdf,
    handleSelectedOtherPdfs,
    handleRemovePortfolio,
    handleClickAddPortfolio,
    fields,
    update,
    candidateSelected,
  } = props
  var Router = require('react-router')
  var Link = Router.Link
  const {
    register,
    formState: { errors },
    getFieldState,
  } = useForm()
  const { byName } = makeFormGroupStateGetter(getFieldState)

  const removeHash = (string) => {
    return string?.replace(/^[^_]+(?=_)_/, '')
  }

  return (
    <>
      <FormGroup
        modifier="mgb0 mgt2"
        className={editmode ? successResume : ''}
        {...byName('resume')}
        required={editmode}
      >
        <FormItemLabel label={t('fieldLabels.resume')} />
        {editmode && !candidateSelected ? (
          <>
            <BlockEl blockElClass="form__groupFlex form__group-mgt">
              {successResume !== 'success' ? (
                <label className="form__fileLabel form__fileLabel-plus" htmlFor="file1">
                  {t('hints.enterPDF')}
                  <Input
                    type="file"
                    {...register('resume', { required: t('validation.required') })}
                    name="resume"
                    accept="application/pdf"
                    onChange={handleFileInput}
                    id="file1"
                    className="form__file form__file-dpn inputFile boolCheck"
                  />
                </label>
              ) : null}
              {successResume === 'success' && selectedResume !== '' && (
                <p className="form__fileText">
                  <BlockEl component={'span'} blockElClass="form__fileText-text">
                    {mode === 'new'
                      ? selectedResume?.name
                      : mode === 'preview'
                      ? selectedResume?.name || data?.resume
                      : mode === 'existing'
                      ? selectedResume?.name
                        ? selectedResume?.name
                        : applicant?.resume
                      : mode === 'preview_edit'
                      ? selectedResume?.name
                        ? selectedResume?.name
                        : data?.existingResume
                        ? removeHash(data?.existingResume)
                        : data?.resume
                      : ''}
                  </BlockEl>
                  <BlockEl
                    component={'span'}
                    blockElClass="form__fileTextDel"
                    onClick={() => handleRemoveFile(0, 'resume')}
                  ></BlockEl>
                </p>
              )}
            </BlockEl>
            <BlockEl blockElClass="form__supArea form__supArea" modifier={'flex'}>
              <p className="form__alert">{t('hints.upload5M')}</p>
              <p className="form__alert">{errors.resume?.message}</p>
            </BlockEl>
          </>
        ) : (
          <BlockEl blockElClass="recommendation__dd">
            <a
              href={`applicant/resume/${
                candidateSelected
                  ? props?.data?.resume
                  : props?.finalData?.resume
                  ? candidateSelected
                    ? props?.data?.resume
                    : props?.finalData?.resume
                  : data?.existingResume
                  ? data?.existingResume
                  : ''
              }`}
              className="recommendation__ddFile recommendation__ddFile-pdf"
              target="_blank"
              rel="noreferrer"
            >
              {candidateSelected
                ? getFileNameFromURl(props?.data?.resume_url) === props?.data?.resume
                  ? replaceFileName(props?.data.resume)
                  : replaceFileName(data?.existingResume)
                : props?.finalData.resume
                ? candidateSelected
                  ? removeHash(props?.data.resume)
                  : replaceFileName(props?.finalData.resume)
                : removeHash(data?.existingResume)
                ? removeHash(data?.existingResume)
                : ''}
            </a>
          </BlockEl>
        )}
      </FormGroup>
      <FormGroup
        modifier="mgb0 mgt2"
        className={editmode ? successPdfWorkCv : ''}
        {...byName('work_history_cv')}
        required={editmode}
      >
        <FormItemLabel label={t('fieldLabels.cv')} />
        {editmode && !candidateSelected ? (
          <>
            <BlockEl blockElClass="form__groupFlex form__group" modifier={'mgt'}>
              {successPdfWorkCv !== 'success' ? (
                <label className="form__fileLabel form__fileLabel-plus" htmlFor="file2">
                  {t('hints.enterPDF')}
                  <Input
                    type="file"
                    {...register('work_history_cv', { required: t('validation.required') })}
                    name="work_history_cv"
                    accept="application/pdf"
                    onChange={handleFileInput}
                    id="file2"
                    className="form__file form__file-dpn inputFile boolCheck"
                  />
                </label>
              ) : null}
              {successPdfWorkCv === 'success' && (
                <p className="form__fileText">
                  <BlockEl component={'span'} blockElClass="form__fileText-text">
                    {mode === 'new'
                      ? selectedWorkCv?.name
                      : mode === 'existing'
                      ? selectedWorkCv?.name
                        ? removeHash(selectedWorkCv?.name)
                        : removeHash(applicant?.work_history_cv)
                      : mode === 'preview_edit'
                      ? selectedWorkCv?.name
                        ? selectedWorkCv?.name
                        : data?.existingWorkCv
                        ? removeHash(data?.existingWorkCv)
                        : removeHash(data?.work_history_cv)
                      : ''}
                  </BlockEl>
                  <BlockEl
                    component={'span'}
                    blockElClass="form__fileTextDel"
                    onClick={() => handleRemoveFile(0, 'work_history_cv')}
                  ></BlockEl>
                </p>
              )}
            </BlockEl>
            <BlockEl blockElClass="form__supArea form__supArea" modifier={'flex'}>
              <p className="form__alert">{t('hints.upload5M')}</p>
              <p className="form__alert">{errors.work_history_cv?.message}</p>
            </BlockEl>
          </>
        ) : (
          <BlockEl blockElClass="recommendation__dd">
            {selectedWorkCv?.name ? (
              <Link
                to={{
                  pathname: `applicant/cv/${
                    props?.finalData?.work_history_cv
                      ? props?.finalData?.work_history_cv
                      : data?.existingWorkCv
                      ? data?.existingWorkCv
                      : ''
                  }`,
                  query: {
                    work_history_cv: selectedWorkCv,
                  },
                }}
              >
                {props?.finalData?.work_history_cv
                  ? props?.finalData?.work_history_cv
                  : data?.existingWorkCv
                  ? data?.existingWorkCv
                  : ''}
              </Link>
            ) : (
              <a
                href={`applicant/cv/${
                  candidateSelected
                    ? props?.data.work_history_cv
                    : props?.finalData.work_history_cv
                    ? candidateSelected
                      ? props?.data.work_history_cv
                      : props?.finalData.work_history_cv
                    : data?.existingWorkCv
                    ? data?.existingWorkCv
                    : ''
                }`}
                className="recommendation__ddFile recommendation__ddFile-pdf"
                target="_blank"
                rel="noreferrer"
              >
                {candidateSelected
                  ? getFileNameFromURl(props?.data.work_history_cv_url) === props?.data.work_history_cv
                    ? replaceFileName(props?.data.work_history_cv)
                    : replaceFileName(data?.existingWorkCv)
                  : props?.finalData.work_history_cv
                  ? candidateSelected
                    ? props?.data.work_history_cv
                    : getFileNameFromURl(props?.data.work_history_cv_url) === props?.data.work_history_cv
                    ? replaceFileName(props?.data.work_history_cv)
                    : replaceFileName(data?.existingWorkCv)
                  : data?.existingWorkCv
                  ? data?.existingWorkCv
                  : ''}
              </a>
            )}
          </BlockEl>
        )}
      </FormGroup>
      <FormItemLabel label="ポートフォリオ" />
      {editmode ? (
        mode === 'existing' || (mode === 'preview_edit' && candidateSelected) ? (
          <>
            {fields.map((field, index) => {
              if (field?.option === 'url') {
                return (
                  <BlockEl blockElClass="candidate__modalTabContOg">
                    <LinkPreview key={index} url={field?.url} isForForms={false} />
                  </BlockEl>
                )
              } else {
                return (
                  <BlockEl blockElClass="candidate__modalTabContPortArea">
                    <a href="#" className="recommendation__ddFile recommendation__ddFile-pdf" target="_blank">
                      {removeHash(field?.pdf?.replace('C:\\fakepath\\', ''))}
                    </a>
                  </BlockEl>
                )
              }
            })}
          </>
        ) : (
          <>
            {fields.map((field, index) => {
              return (
                <OtherUrlPdfDialog
                  key={field.id}
                  index={index}
                  field={field}
                  finalData={data}
                  handleOnChange={handleOnChange}
                  handleRemoveFile={handleRemoveFile}
                  update={update}
                  selectedOtherPdf={selectedOtherPdf}
                  handleSelectedOtherPdfs={handleSelectedOtherPdfs}
                  handleRemovePortfolio={handleRemovePortfolio}
                />
              )
            })}
          </>
        )
      ) : (
        data?.other_docs?.map((otherDocs, index) => {
          let docs = otherDocs.option
          return (
            <BlockEl blockElClass="recommendation__dd" key={index}>
              {docs === 'pdf' ? (
                <a
                  href={`applicant/other_docs/${otherDocs?.pdf?.replace('C:\\fakepath\\', '')}`}
                  className="recommendation__ddFile recommendation__ddFile-pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  {removeHash(otherDocs?.pdf?.replace('C:\\fakepath\\', ''))}
                </a>
              ) : docs === 'url' ? (
                <>
                  <a
                    href={otherDocs.url}
                    className="recommendation__ddFile recommendation__ddFile-pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {otherDocs.url}
                  </a>
                </>
              ) : null}
            </BlockEl>
          )
        })
      )}
      {mode === 'new' || (mode === 'preview_edit' && !candidateSelected)
        ? fields.length < 10 && (
            <p className="form__portfolioPlus" id="portfolioPlus" onClick={handleClickAddPortfolio}>
              {t('hints.addPortfolio')}
            </p>
          )
        : null}
    </>
  )
}

ApplicantDocuments.propTypes = {
  successResume: PropTypes.string,
  successPdfWorkCv: PropTypes.string,
  handleFileInput: PropTypes.func,
  selectedResume: PropTypes.object,
  data: PropTypes.object,
  update: PropTypes.func,
  mode: PropTypes.string,
  finalData: PropTypes.object,
  selectedWorkCv: PropTypes.object,
  editmode: PropTypes.bool,
  applicant: PropTypes.object,
  handleRemoveFile: PropTypes.func,
  handleOnChange: PropTypes.func,
  fields: PropTypes.array,
  selectedOtherPdf: PropTypes.array,
  handleSelectedOtherPdfs: PropTypes.func,
  handleRemovePortfolio: PropTypes.func,
  handleClickAddPortfolio: PropTypes.func,
  candidateSelected: PropTypes.bool,
}

export default ApplicantDocuments

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { generatePath, Link, useHistory, useLocation } from 'react-router-dom'
import qs from 'query-string'
import clsx from 'clsx'
import { selectJobPosting } from 'store/job-postings/selectors'
import { ReactComponent as QuestionIcon } from 'assets/img/q-icon.svg'
import { ReactComponent as PrintIcon } from 'assets/img/print-card.svg'
import { ReactComponent as BookmarkIcon } from 'assets/img/add-to-favorites.svg'
import { ReactComponent as DownloadIcon } from 'assets/img/download-card-icon.svg'
import { Button } from 'components/atoms'
import ProposalSuggestModal from 'components/molecules/JobVacancySection/ProposalSuggestModal'
import { addToFavorites, fetchForEditProposal } from 'services/jobPostings'
import QuestionAndAnswerFormModal from '../QuestionAndAnswerFormModal'
import { fetchQuestions } from 'services/questionAndAnswers'
import { actionEditQuestion } from 'store/question-and-answers/actionCreators'
import { fetchTopics } from 'services/topics'
import { handleDownload } from 'services/download'
import { actionCloseProposalSlideOut, actionOpenProposalSlideOut } from 'store/job-postings/actionCreators'
import omit from 'lodash/omit'
import { selectAuthUser } from 'store/auth/selectors'
import { isSuspended } from 'utils/helper'

function Buttons(props) {
  const jobPosting = useSelector(selectJobPosting)
  const { t } = useTranslation()
  const {
    jobPosting: { id },
    qAndAModalOpen,
    setqAndAModalOpen,
  } = props
  const history = useHistory()
  const dispatch = useDispatch()
  const location = useLocation()
  const { open: openSlideOut, initialData } = useSelector((state) => state.jobPostings.proposalSlideOut)
  const loggedUser = useSelector(selectAuthUser)
  const isUserSuspended = isSuspended(loggedUser)

  useEffect(() => {
    const { editProposal } = new qs.parse(location.search)

    if (Number.isFinite(parseInt(editProposal))) {
      dispatch(fetchForEditProposal(editProposal))
    }
  }, [location.search])

  const editQuestion = useSelector((state) => state.qAndA.editQuestion)

  const isBookmarked = jobPosting?.is_bookmarked

  const handleClickSaveBookmark = (id) => () => {
    isBookmarked ? dispatch(addToFavorites(id, 1)) : dispatch(addToFavorites(id, 0))
  }

  if (jobPosting === null) return <></>

  const handleShowModal = () => {
    dispatch(actionOpenProposalSlideOut())
  }

  const isEditing = !!editQuestion

  const handleAddBtnClick = () => {
    if (isUserSuspended) return
    dispatch(fetchTopics())
    setqAndAModalOpen(true)
  }

  const handleSuccess = () => {
    setqAndAModalOpen(false)
    if (!isEditing) dispatch(fetchQuestions())
    if (isEditing) dispatch(actionEditQuestion(null))

    history.push({
      pathname: generatePath('/job-postings/:id/:tab?', { id, tab: 'qanda' }),
    })
  }

  const handleModalClose = () => {
    if (isEditing) {
      dispatch(actionEditQuestion(null))
    }
    setqAndAModalOpen(false)
  }

  const handleProposalSuggestClose = () => {
    dispatch(actionCloseProposalSlideOut())
    const query = qs.parse()

    history.replace({
      search: qs.stringify(omit(query, ['editProposal'])),
    })
  }

  const handleDownloadClick = async (e) => {
    dispatch(handleDownload(id, t))
    e.preventDefault()
  }

  const handlePrintClick = (e) => {
    if (isUserSuspended) e.preventDefault()
  }

  const handleRecommendationClick = () => {
    history.push(`/job-posting/recommendation/${id}`)
  }

  const can_Propose = !!jobPosting?.can_propose

  return (
    <ul className="footer__ul">
      <li className="footer__li footer__li-mr12">
        <Link
          to={isUserSuspended ? '/' : `/job_postings/pdf/${id}`}
          className={clsx('btn btn-iconL btn__textBtn', {
            'btn__textBtn-disabled': isUserSuspended,
          })}
          onClick={handlePrintClick}
          target="_blank"
          disabled={isUserSuspended}
        >
          <span className="btn__btnIcon">
            <PrintIcon />
          </span>
          {t('jobPosting.footer.print')}
        </Link>
      </li>
      <li>
        <Button
          modifier="iconL textBtn"
          variant="text"
          className="footer__li footer__li-mr12"
          onClick={handleAddBtnClick}
          disabled={isUserSuspended}
        >
          <span className="btn__btnIcon">
            <QuestionIcon />
          </span>
          {t('jobPosting.footer.question')}
        </Button>

        <QuestionAndAnswerFormModal
          onSuccess={handleSuccess}
          onClose={handleModalClose}
          open={qAndAModalOpen}
          isEditing={!!editQuestion}
          initialFormValue={editQuestion}
          jobPostingId={id}
        />
      </li>
      <li className="footer__li footer__li-mr24">
        <Button modifier="iconL" variant="outline" onClick={handleDownloadClick} disabled={isUserSuspended}>
          <span className="btn__btnIcon">
            <DownloadIcon />
          </span>
          {t('jobPosting.card.download')}
        </Button>
      </li>
      <li className="footer__li footer__li-mr16">
        <Link to="#" className="btn btn-iconL btn__outlineBtn" onClick={handleClickSaveBookmark(id)}>
          <span className="btn__btnIcon">
            <BookmarkIcon />
          </span>
          {isBookmarked ? t('jobPosting.card.unFavorites') : t('jobPosting.footer.saveAsBookmark')}
        </Link>
      </li>
      <li className="footer__li">
        {jobPosting?.job_posting_type === 'proposal' ? (
          <>
            <Button
              modifier="w100"
              onClick={handleShowModal}
              variant="yellowDessable"
              variantMod={{ active: can_Propose }}
              disabled={!can_Propose || isUserSuspended}
              type="submit"
            >
              {can_Propose ? t('jobPosting.footer.suggestionTo') : t('jobPosting.footer.alreadyPropose')}
            </Button>
            <ProposalSuggestModal
              open={openSlideOut}
              initialData={initialData}
              handleClose={handleProposalSuggestClose}
              jobPostingId={id}
            />
          </>
        ) : (
          <Button
            variant={isUserSuspended ? 'shadowDissable' : 'shadow'}
            disabled={isUserSuspended}
            onClick={handleRecommendationClick}
          >
            {t('jobPosting.footer.recommendationTo')}
          </Button>
        )}
      </li>
    </ul>
  )
}

Buttons.propTypes = {
  jobPosting: PropTypes.object,
  qAndAModalOpen: PropTypes.bool,
  setqAndAModalOpen: PropTypes.func,
}

export default Buttons

import React from 'react'
import AccountInvitationManagementBox from './Box'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { BlockEl, Button } from 'components/atoms'
import dayjs from 'dayjs'
import { DATE_FORMAT_TIME, USER_STATUSES } from 'utils/constants'
import { signInUserViaUserID } from 'services/auth'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { objectFlip } from 'utils/helper'

const flippedStatus = objectFlip(USER_STATUSES)

const AccountInvitationManagementHeaders = () => {
  const { t } = useTranslation()

  return (
    <li className="adminMember__mainLi adminMember__mainLi-gray">
      <div className="adminMember__mainLiInner">
        <AccountInvitationManagementBox.NameHeader nameHeader={t('accountList.userList.name')} />
        <AccountInvitationManagementBox.Header
          classNameHeader="mainJobMail"
          headerLabel={t('accountList.userList.emailAddress')}
        />
        <AccountInvitationManagementBox.Header
          classNameHeader="mainAuthority"
          headerLabel={t('accountList.userList.authority')}
        />
        <AccountInvitationManagementBox.Header
          classNameHeader="mainStatus"
          headerLabel={t('accountList.userList.status')}
        />
        <AccountInvitationManagementBox.Header
          classNameHeader="mainCertification"
          headerLabel={t('accountList.userList.accountVerification')}
        />
        <AccountInvitationManagementBox.Header classNameHeader="mainLoginBtn" headerLabel="" />
      </div>
    </li>
  )
}

const AccountInvitationManagementItem = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()
  const { item, handleUserStatusOnClick, openUserStatus, handleUserOnClick, clickedUser } = props
  const statusId = item.status.id
  const isUserStatusPending = statusId === USER_STATUSES.pending
  const isExpired = isUserStatusPending && item.invitation_has_expired
  const fullName = item.full_name !== ' ' ? item.full_name : '---'
  const verifiedAt = item.verified_at ? dayjs(item.verified_at).format(DATE_FORMAT_TIME) : '---'
  const handlePseudoLoginClick = async () => {
    if (isUserStatusPending) return
    await dispatch(signInUserViaUserID(item.id)).then(() => history.push('/'))
  }
  const statusKey = isUserStatusPending && isExpired ? 'locked' : flippedStatus[statusId]
  const statusText = t(`userStatus.${statusKey}`)

  const UpdateStatusBtn = ({ updateStatusModal, handleUserStatusOnClick, item }) => {
    const isClicked = clickedUser?.id === item.id

    return (
      <p
        className="member__mainDotText popModal"
        style={{ display: openUserStatus && isClicked ? 'flex' : 'none' }}
        data-modal="modal"
        data-modalcont="modalCont1"
        onClick={handleUserStatusOnClick()}
      >
        {updateStatusModal}
      </p>
    )
  }

  UpdateStatusBtn.propTypes = {
    updateStatusModal: PropTypes.string,
    handleUserStatusOnClick: PropTypes.func,
    item: PropTypes.any,
  }

  return (
    <>
      <AccountInvitationManagementBox>
        <AccountInvitationManagementBox.Item
          classNameItem="mainName"
          item={fullName}
          headerLabel={t('accountList.userList.name')}
        />
        <AccountInvitationManagementBox.Item
          classNameItem="mainJobMail"
          item={item.email}
          headerLabel={t('accountList.userList.emailAddress')}
        />
        <AccountInvitationManagementBox.Item
          classNameItem="mainAuthority"
          item={item.groups[0]?.is_parent ? t('userPermission.parent') : t('userPermission.child')}
          headerLabel={t('accountList.userList.authority')}
        />
        <AccountInvitationManagementBox.Status
          invitedBy={item?.invited_by?.full_name ?? ''}
          invitedBySuffix={t('accountList.userList.invitedBySuffix')}
          statusId={statusId}
          isExpired={isExpired}
          status={statusText}
          headerLabel={t('accountList.userList.status')}
        />
        <AccountInvitationManagementBox.Item
          classNameItem="mainCertification"
          item={verifiedAt}
          headerLabel={t('accountList.userList.accountVerification')}
        />
        <AccountInvitationManagementBox.PseudoLogin>
          <Button
            variant={isUserStatusPending ? 'shadowDissable' : 'shadow'}
            onClick={handlePseudoLoginClick}
            disabled={isUserStatusPending}
          >
            {t('accountList.userList.pseudoLogin')}
          </Button>
        </AccountInvitationManagementBox.PseudoLogin>
      </AccountInvitationManagementBox>
      {!isUserStatusPending && (
        <div className="adminMember__mainDotArea pc" onClick={handleUserOnClick(item)}>
          <AccountInvitationManagementBox.UpdateStatus>
            <UpdateStatusBtn
              updateStatusModal={t('accountList.userList.updateStatusButton')}
              handleUserStatusOnClick={handleUserStatusOnClick}
              item={item}
            />
          </AccountInvitationManagementBox.UpdateStatus>
        </div>
      )}
    </>
  )
}

AccountInvitationManagementItem.propTypes = {
  item: PropTypes.object,
  handleUserStatusOnClick: PropTypes.func,
  handleUserOnClick: PropTypes.func,
  openUserStatus: PropTypes.bool,
  clickedUser: PropTypes.object,
}

const AccountInvitationManagementCard = (props) => {
  const { users, handleUserStatusOnClick, handleUserOnClick, openUserStatus, clickedUser } = props

  return (
    <>
      <ul className="adminMember__mainUl">
        <AccountInvitationManagementHeaders />
        {users.map((user) => (
          <BlockEl component="li" blockElClass="adminMember__mainLi" key={user.id}>
            <AccountInvitationManagementItem
              item={user}
              handleUserStatusOnClick={handleUserStatusOnClick}
              openUserStatus={openUserStatus}
              handleUserOnClick={handleUserOnClick}
              clickedUser={clickedUser}
            />
          </BlockEl>
        ))}
      </ul>
    </>
  )
}

AccountInvitationManagementCard.propTypes = {
  users: PropTypes.array.isRequired,
  handleUserStatusOnClick: PropTypes.func,
  openUserStatus: PropTypes.bool,
  handleUserOnClick: PropTypes.func,
  clickedUser: PropTypes.object,
}

export default AccountInvitationManagementCard

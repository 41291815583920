import some from 'lodash/some'
import isUndefined from 'lodash/isUndefined'
import { selectAuthId } from 'store/auth/selectors'

export function selectUserSearchParams(state) {
  return state.users.search
}

export function selectHasOtherParent(state) {
  const authId = selectAuthId(state)
  return !isUndefined(
    state.users.list.find((user) => {
      const isNotAuth = user.id !== authId
      const isParent = some(user.groups, ['is_parent', true])

      return isNotAuth && isParent
    })
  )
}

export const selectFilterModal = (state) => state.users.filterModal

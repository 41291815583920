import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { BlockEl } from 'components/atoms'

const SearchArea = (props) => {
  useEffect(() => {
    // nameSearchイベント bv
    const nameSearch = document.getElementById('nameSearch')
    if (nameSearch) {
      const recommendationModalLi = document.getElementsByClassName('recommendation__modalLi')
      nameSearch.addEventListener('input', (e) => {
        for (let i = 0; recommendationModalLi.length > i; i++) {
          recommendationModalLi[i].style.display = 'none'

          if (recommendationModalLi[i].textContent.toLowerCase().indexOf(e.currentTarget.value.toLowerCase()) !== -1) {
            recommendationModalLi[i].style.display = 'flex'
          }
        }
      })
    }
  }, [])

  return (
    <BlockEl blockElClass="recommendation__modalSearchArea">
      <p className="recommendation__modalSearchText">{props.label}</p>
      <input
        type="text"
        name=""
        id="nameSearch"
        placeholder={props.placeholder}
        className="recommendation__modalSearch"
      />
      <p className="recommendation__modalClose modalClose" onClick={() => props.handleCloseModal()}></p>
    </BlockEl>
  )
}

SearchArea.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  handleCloseModal: PropTypes.func,
}

export default SearchArea

import React from 'react'
import PropTypes from 'prop-types'
import Box from './Box'
import { useSelector } from 'react-redux'
import { selectAuthUser } from 'store/auth/selectors'
import { isSuspended } from 'utils/helper'

function JobManagementItem({ jobPost }) {
  const loggedUser = useSelector(selectAuthUser)
  const jobTitle = jobPost.job_title === null ? '' : jobPost.job_title

  const truncateCharCount = (text, length) => {
    if (text.length > length) {
      return text.substr(0, length) + '...'
    }
    return text
  }

  return (
    <>
      <Box.MainInfo.Index>
        <Box.MainInfo.Tag tag={jobPost.job_posting_type} />
        <Box.MainInfo.Content content={truncateCharCount(jobTitle, 37)} />
        <Box.MainInfo.StatusArea>
          <Box.MainInfo.Status status={jobPost.status} />
          <Box.MainInfo.EditDate editDate={jobPost.updated_at} />
        </Box.MainInfo.StatusArea>
      </Box.MainInfo.Index>
      <Box.Type type={jobPost.pv} />
      <Box.Num num={jobPost.number_of_positions} />
      <Box.StatusNum num={jobPost.inprogress_count} />
      <Box.ProgressNum num={jobPost.not_yet_started_count} />
      <Box.Empty.Index>
        <Box.Empty.Link href={`/job-postings/${jobPost.id}`} />
        <Box.Empty.OptionArea jobPostId={jobPost.id} item={jobPost} disableOption={isSuspended(loggedUser)} />
      </Box.Empty.Index>
    </>
  )
}

JobManagementItem.propTypes = {
  jobPost: PropTypes.object,
}

export default JobManagementItem

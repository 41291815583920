import React, { useMemo, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import Select from 'react-select'

import { ReactComponent as ArrowIcon } from 'assets/img/arrow3.svg'
import { Button, Input } from 'components/atoms'
import { PROPOSAL_SIDE_STATUS } from 'utils/constants'
import SearchSuggestionManagementBox from './Box'
import { selectAuthUser } from 'store/auth/selectors'
import { accountType } from 'utils/helper'
import { useCoWorkers, useIsMobile } from 'utils/hooks'

const hasName = (user) => (user.full_name || '').trim().length > 0

const components = {
  DropdownIndicator: () => <ArrowIcon />,
}

const SelectionManagementSearchArea = ({ onSubmit, loading, handleSpHeaderCloseClick }) => {
  const { t } = useTranslation()

  const [keyword, setKeyWord] = useState('')
  const [statuses, setStatuses] = useState([])
  const [selectedUser, setSelectedUser] = useState()
  const userType = useSelector(selectAuthUser)
  const account_type_AD2 = accountType(userType) === 'AD2'
  const { users } = useCoWorkers()
  const isMobile = useIsMobile()

  const statusOptions = Object.keys(PROPOSAL_SIDE_STATUS).map((key) => ({
    id: key,
    label: t(`suggestion.${key}`),
    value: PROPOSAL_SIDE_STATUS[key],
  }))

  const handleClearBtn = () => {
    setKeyWord('')
    setStatuses([])
  }

  const handleStatusChange = (e) => {
    setStatuses(e)
  }

  const handleUserChange = (e) => {
    setSelectedUser(e)
  }

  const handleKeywordChange = (e) => {
    setKeyWord(e.target.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    onSubmit({ keyword, statuses, proposer: selectedUser?.id ? selectedUser.id : null })
  }

  const userList = useMemo(
    () => users.filter(hasName).map((item) => ({ id: item.id, label: item.full_name, vallue: item.id })),
    [users]
  )

  return (
    <form onSubmit={handleSubmit}>
      <SearchSuggestionManagementBox>
        {isMobile && (
          <SearchSuggestionManagementBox.SpHeader
            spTitle={t('selectionManagementSearch.spTitle')}
            handleSpHeaderCloseClick={handleSpHeaderCloseClick}
          />
        )}
        <SearchSuggestionManagementBox.Top>
          <SearchSuggestionManagementBox.Title title={t('formLabels.search')} />
          <SearchSuggestionManagementBox.DropdownArea>
            <SearchSuggestionManagementBox.FreeWordSearch
              freeword_label={t('selectionManagementSearch.searchFreeWord')}
            >
              <Input type="text" bec="selection__searchInput" onChange={handleKeywordChange} value={keyword} />
            </SearchSuggestionManagementBox.FreeWordSearch>
            {account_type_AD2 && (
              <SearchSuggestionManagementBox.DropDown dropdown_label={t('common.loginUsername')}>
                <Select
                  className="selection__searchSelect selection__multiple_select"
                  options={userList}
                  onChange={handleUserChange}
                  components={components}
                  value={selectedUser}
                  getOptionValue={(option) => option.id}
                  placeholder={t('hints.pleaseSpecify')}
                  isClearable={true}
                />
              </SearchSuggestionManagementBox.DropDown>
            )}
            <SearchSuggestionManagementBox.DropDown dropdown_label={t('common.status')}>
              <Select
                isMulti
                className="selection__searchSelect selection__multiple_select"
                options={statusOptions}
                noOptionsMessage={() => t('common.dropDownNoOption')}
                onChange={handleStatusChange}
                components={components}
                value={statuses}
                getOptionValue={(option) => option.id}
                placeholder={t('hints.pleaseSpecify')}
              />
            </SearchSuggestionManagementBox.DropDown>
          </SearchSuggestionManagementBox.DropdownArea>
        </SearchSuggestionManagementBox.Top>

        <SearchSuggestionManagementBox.ButtonArea>
          <Button bec="selection__searchBtn1" onClick={handleClearBtn} disabled={loading}>
            {t('selectionManagementSearch.clearSearchBtn')}
          </Button>
          <Button bec="selection__searchBtn2" onClick={handleSpHeaderCloseClick} type="submit" disabled={loading}>
            {t('selectionManagementSearch.searchBtn')}
          </Button>
        </SearchSuggestionManagementBox.ButtonArea>
      </SearchSuggestionManagementBox>
    </form>
  )
}

SelectionManagementSearchArea.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  handleSpHeaderCloseClick: PropTypes.func.isRequired,
}

export default SelectionManagementSearchArea

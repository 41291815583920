export const LOAD_COMPANY_SELECT_REQUEST = 'LOAD_COMPANY_SELECT_REQUEST'
export const LOAD_COMPANY_SELECT_SUCCESS = 'LOAD_COMPANY_SELECT_SUCCESS'
export const LOAD_COMPANY_SELECT_FAILURE = 'LOAD_COMPANY_SELECT_FAILURE'
export const UPDATE_COMPANY_REQUEST = 'UPDATE_COMPANY_REQUEST'
export const UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS'
export const UPDATE_COMPANY_FAILURE = 'UPDATE_COMPANY_FAILURE'
export const UPDATE_COMPANY_LIST_PROPOSALS = 'UPDATE_COMPANY_LIST_PROPOSALS'
export const GET_COMPANY_DETAILS_REQUEST = 'GET_COMPANY_DETAILS_REQUEST'
export const GET_COMPANY_DETAILS_SUCCESS = 'GET_COMPANY_DETAILS_SUCCESS'
export const GET_COMPANY_DETAILS_FAILURE = 'GET_COMPANY_DETAILS_FAILURE'
export const SYNC_COMPANY_USER_FORM = 'SYNC_COMPANY_USER_FORM'
export const SET_COMPANY_MANAGEMENT_FILTER = 'SET_COMPANY_MANAGEMENT_FILTER'
export const SET_COMPANY_ADMIN_MANAGEMENT_FILTER = 'SET_COMPANY_ADMIN_MANAGEMENT_FILTER'

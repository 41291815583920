import React from 'react'
import CalendarIcon from 'assets/img/calendar-icon.svg'
import Box from '../CompanyManagementSearchArea/Box'
import SearchBox from '../../common/SelectionManagementSearchArea/Box'
import Flatpickr from 'react-flatpickr'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { CustomSelect } from 'components'
import { ACCOUNT_TYPES, DATE_FORMAT } from 'utils/constants'
import { useIsMobile } from 'utils/hooks'

function UserLogSearchArea({
  keyword,
  dateTo,
  dateFrom,
  accountType,
  onKeywordChange,
  onDateFromChange,
  onDateToChange,
  onAccountTypeChange,
  onFormSubmit,
  onClearFilterClick,
  handleSpHeaderCloseClick,
}) {
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const handleKeywordChange = (e) => onKeywordChange(e.target.value)
  const accountTypes = [
    {
      id: ACCOUNT_TYPES.ad1,
      label: ACCOUNT_TYPES.ad1,
    },
    {
      id: ACCOUNT_TYPES.ad2,
      label: ACCOUNT_TYPES.ad2,
    },
    {
      id: ACCOUNT_TYPES.hr,
      label: ACCOUNT_TYPES.hr,
    },
    {
      id: 'both',
      label: ACCOUNT_TYPES.both,
    },
  ]

  return (
    <form action="" className="selection__innerForm searchSide" onSubmit={onFormSubmit}>
      <Box>
        {isMobile && (
          <Box.SpHeader
            spTitle={t('jobManagement.searchArea.title')}
            handleSpHeaderCloseClick={handleSpHeaderCloseClick}
          />
        )}
        <Box.Top>
          <Box.Title title={t('jobManagement.searchArea.title')} />
          <Box.DropdownArea>
            <Box.FreeWordSearch freewordLabel={t('userLogs.searchArea.companyKeyword')}>
              <input
                value={keyword}
                type="text"
                className="form__inputText form__inputText-w320px form__inputText-sp100"
                onChange={handleKeywordChange}
              />
            </Box.FreeWordSearch>
            <div className="selection__searchGroup">
              <div className="form__customSelectTitle">{t('userLogs.searchArea.actionDate')}</div>
              <div className="calendar__group">
                <div className="calendar__icon">
                  <img src={CalendarIcon} alt="calendar" />
                </div>
                <div className="form__customSelectArea">
                  <div className="form__customSelectWrap">
                    <Flatpickr
                      value={dateFrom}
                      onChange={(date) => {
                        const dateFrom = dayjs(date).isValid() ? dayjs(date).format(DATE_FORMAT) : ''
                        onDateFromChange(dateFrom)
                      }}
                      render={({ defaultValue, ...props }, ref) => {
                        return (
                          <input
                            {...props}
                            defaultValue={defaultValue}
                            ref={ref}
                            className="form__customSelect"
                            placeholder={t('common.pleaseSelect')}
                            readOnly
                          />
                        )
                      }}
                    />
                  </div>
                </div>
                <div className="calendar__connectTxt">〜</div>
                <div className="form__customSelectArea">
                  <div className="form__customSelectWrap">
                    <Flatpickr
                      value={dateTo}
                      onChange={(date) => {
                        const dateTo = dayjs(date).isValid() ? dayjs(date).format(DATE_FORMAT) : ''
                        onDateToChange(dateTo)
                      }}
                      render={({ defaultValue, ...props }, ref) => {
                        return (
                          <input
                            {...props}
                            defaultValue={defaultValue}
                            ref={ref}
                            className="form__customSelect"
                            placeholder={t('common.pleaseSelect')}
                            readOnly
                          />
                        )
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <SearchBox.DropDown dropdown_label={t('userProfile.basicInformation.accountTypeLabel')}>
              <CustomSelect
                placeholder={t('jobManagement.searchArea.jobTypeSelectPlaceholder')}
                options={accountTypes}
                selected={accountType}
                onChange={onAccountTypeChange}
              />
            </SearchBox.DropDown>
          </Box.DropdownArea>
        </Box.Top>

        <Box.ButtonArea>
          <button className="selection__searchBtn1 f_to_yellow transition_main" onClick={onClearFilterClick}>
            {t('buttonLabels.clearSearch')}
          </button>
          <button className="selection__searchBtn2" onClick={handleSpHeaderCloseClick}>
            {t('jobManagement.searchArea.searchButton')}
          </button>
        </Box.ButtonArea>
      </Box>
    </form>
  )
}

UserLogSearchArea.propTypes = {
  keyword: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  dateFrom: PropTypes.string,
  dateTo: PropTypes.string,
  accountType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onKeywordChange: PropTypes.func,
  onDateFromChange: PropTypes.func,
  onDateToChange: PropTypes.func,
  onAccountTypeChange: PropTypes.func,
  onFormSubmit: PropTypes.func,
  onClearFilterClick: PropTypes.func,
  handleSpHeaderCloseClick: PropTypes.func,
}

export default UserLogSearchArea

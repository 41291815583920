import React from 'react'
import { useTranslation } from 'react-i18next'
import { Input } from 'components/atoms'
import { useFormContext } from 'react-hook-form'
import PropTypes from 'prop-types'
import { FormGroup } from 'components/molecules'
import OtherDocsDialog from './otherDocsDialog'
import { FormItemLabel } from 'components'
import { getFileNameFromURl, replaceFileName } from 'utils/helper'

const CandidateDocument = (props) => {
  const { t } = useTranslation()
  const {
    handleFileInput,
    handleRemoveFile,
    handleSelectedOtherPdfs,
    handleRemovePortfolio,
    handleClickAddPortfolio,
    selectedOtherPdf,
    selectedResume,
    selectedWorkCv,
    fields,
    update,
    mode,
    successResume,
    successPdfWorkCv,
  } = props
  const {
    formState: { errors, dirtyFields },
    register,
  } = useFormContext()

  let applicantData =
    mode == 'edit' || mode == 'add-confirm' ? (JSON.parse(localStorage.getItem('candidate')) ?? '{}') || {} : {}

  return (
    <>
      <FormGroup
        success={!errors?.resume && dirtyFields?.resume}
        error={!!errors?.resume}
        modifier="mgb0 mgt2"
        required
      >
        <div className="form__group form__group-mgb0 form__group-mgt0 form__group-spMgb16 required">
          <label htmlFor="" className="form__label form__label-mgt9 form__label-mini">
            {t('fieldLabels.resume')}
          </label>
          <div className="form__groupFlex form__group-mgt">
            {successResume !== 'success' ? (
              <label className="form__fileLabel form__fileLabel-plus" htmlFor="file1">
                {t('hints.enterPDF')}
                <Input
                  type="file"
                  {...register('resume')}
                  name="resume"
                  accept="application/pdf"
                  onChange={handleFileInput}
                  id="file1"
                  className="form__file form__file-dpn inputFile boolCheck"
                />
              </label>
            ) : null}
            {successResume === 'success' && typeof selectedResume?.name !== 'undefined' && (
              <p className="form__fileText">
                <span className="form__fileText-text">
                  {mode === 'add'
                    ? selectedResume?.name
                    : mode === 'edit' || mode === 'add-confirm'
                    ? getFileNameFromURl(applicantData?.resume_url) === selectedResume?.name
                      ? replaceFileName(selectedResume?.name)
                      : selectedResume?.name
                    : ''}
                </span>
                <span className="form__fileTextDel" onClick={() => handleRemoveFile(0, 'resume')}></span>
              </p>
            )}
          </div>
        </div>
      </FormGroup>
      <FormGroup
        success={!errors?.work_history_cv && dirtyFields?.work_history_cv}
        error={!!errors?.work_history_cv}
        modifier="mgb0 mgt2"
        required
      >
        <div className="form__group form__group-mgb0 form__group-mgt0 form__group-spMgb16 required">
          <label htmlFor="" className="form__label form__label-mgt9 form__label-mini">
            {t('fieldLabels.cv')}
          </label>
          <div className="form__groupFlex form__group-mgt">
            {successPdfWorkCv !== 'success' ? (
              <label className="form__fileLabel form__fileLabel-plus" htmlFor="file2">
                {t('hints.enterPDF')}
                <Input
                  type="file"
                  {...register('work_history_cv')}
                  name="work_history_cv"
                  accept="application/pdf"
                  onChange={handleFileInput}
                  id="file2"
                  className="form__file form__file-dpn inputFile boolCheck"
                />
              </label>
            ) : null}
            {successPdfWorkCv === 'success' && typeof selectedWorkCv?.name !== 'undefined' ? (
              <p className="form__fileText">
                <span className="form__fileText-text">
                  {mode === 'add'
                    ? selectedWorkCv?.name
                    : mode === 'edit' || mode === 'add-confirm'
                    ? getFileNameFromURl(applicantData?.work_history_cv_url) === selectedWorkCv?.name
                      ? replaceFileName(selectedWorkCv?.name)
                      : selectedWorkCv?.name
                    : ''}
                </span>
                <span className="form__fileTextDel" onClick={() => handleRemoveFile(0, 'work_history_cv')}></span>
              </p>
            ) : null}
          </div>
        </div>
      </FormGroup>

      <FormGroup modifier="mgb0 mgt2" required={false}>
        <FormItemLabel label={t('candidate.otherDocs')} />
        {fields.map((field, index) => {
          return (
            <OtherDocsDialog
              key={field.id}
              index={index}
              field={field}
              handleRemoveFile={handleRemoveFile}
              update={update}
              selectedOtherPdf={selectedOtherPdf}
              handleSelectedOtherPdfs={handleSelectedOtherPdfs}
              handleRemovePortfolio={handleRemovePortfolio}
              fieldCtr={fields.length}
            />
          )
        })}
        {fields.length < 10 && (
          <p className="form__portfolioPlus" id="portfolioPlus" onClick={handleClickAddPortfolio}>
            {t('hints.addPortfolio')}
          </p>
        )}
      </FormGroup>
    </>
  )
}

CandidateDocument.propTypes = {
  fields: PropTypes.array,
  update: PropTypes.func,
  selectedWorkCv: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  selectedResume: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  selectedOtherPdf: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  handleFileInput: PropTypes.func,
  handleSelectedOtherPdfs: PropTypes.func,
  handleRemovePortfolio: PropTypes.func,
  handleClickAddPortfolio: PropTypes.func,
  handleRemoveFile: PropTypes.func,
  mode: PropTypes.string,
  successResume: PropTypes.string,
  successPdfWorkCv: PropTypes.string,
  data: PropTypes.object,
}
export default CandidateDocument

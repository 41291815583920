import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectUserSearchParams } from 'store/users/selectors'
import { changeSearchCriteria, searchUser } from 'services/users'
import AccountPermissionSelect from '../AccountPermissionSelect'
import UserStatusSelect from '../UserStatusSelect'
import { useTranslation } from 'react-i18next'
import { Button } from 'components/atoms'
import { actionSetFilterModal } from 'store/users/actionCreators'

function AccountManagementListFilter() {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { keyword, permissions, statuses } = useSelector(selectUserSearchParams)
  const [statusSelect, setStatusSelect] = useState(statuses[0] ?? null)
  const [permissionSelect, setPermissionSelect] = useState(permissions[0] ?? '')
  const [keywordSelect, setKeywordSelect] = useState(keyword)

  const handleSearch = (e) => {
    e.preventDefault()
    dispatch(searchUser())
  }

  const handleKeywordChange = (e) => {
    const inputValue = e.target.value
    dispatch(changeSearchCriteria({ keyword: inputValue }))
    setKeywordSelect(inputValue)
  }

  const handlePermissionChange = (id) => {
    dispatch(changeSearchCriteria({ permissions: [id] }))
    setPermissionSelect(id)
  }

  const handleStatusChange = (id) => {
    dispatch(changeSearchCriteria({ statuses: [id] }))
    setStatusSelect(id)
  }

  const handleSpCloseClick = () => dispatch(actionSetFilterModal(false))

  const handleFormReset = () => {
    setStatusSelect('')
    setPermissionSelect('')
    setKeywordSelect('')
    dispatch(changeSearchCriteria({ statuses: [] }))
    dispatch(changeSearchCriteria({ permissions: [] }))
    dispatch(changeSearchCriteria({ keyword: '' }))
  }

  return (
    <form onSubmit={handleSearch}>
      <div className="selection__searchArea">
        <div className="spHeader spHeader-inSec">
          <div className="spHeader__inner">
            <div className="spHeader__left">
              <p className="spHeader__title">{t('accountManagement.searchButton')}</p>
            </div>
            <div className="spHeader__right">
              <p className="spHeader__close" id="searchSideClose" onClick={handleSpCloseClick}></p>
            </div>
          </div>
        </div>
        <div className="selection__searchAreaTop">
          <p className="selection__searchTitle">{t('userAccount.title')}</p>
          <div className="selection__searchFlex">
            <div className="selection__searchGroup">
              <div className="form__customSelectArea form__customSelectArea-2">
                <div className="form__customSelectTitle">{t('userAccount.keyword')}</div>
                <input
                  type="text"
                  value={keywordSelect}
                  onChange={handleKeywordChange}
                  className="form__inputText form__inputText-w320px form__inputText-sp100"
                />
              </div>
            </div>

            <div className="selection__searchGroup">
              <div className="form__customSelectArea-2">
                <div className="form__customSelectTitle">{t('userAccount.filterByStatus')}</div>
                <div className="form__customSelectArea">
                  <div className="form__customSelectWrap">
                    <UserStatusSelect
                      onChange={handleStatusChange}
                      value={statusSelect}
                      formControlProps={{ fullWidth: true }}
                    />
                  </div>
                  <div className="form__customSelectBack"></div>
                </div>
              </div>
            </div>

            <div className="selection__searchGroup">
              <div className="form__customSelectArea-2">
                <div className="form__customSelectTitle">{t('userAccount.filterByPermission')}</div>
                <div className="form__customSelectArea">
                  <div className="form__customSelectWrap">
                    <AccountPermissionSelect onChange={handlePermissionChange} value={permissionSelect} />
                    <div className="form__customSelectBack"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="selection__searchAreaBottom">
          <Button className="selection__searchBtn1" blockElClass="" onClick={handleFormReset}>
            {t('userAccount.clearFilter')}
          </Button>
          <Button className="selection__searchBtn2" onClick={handleSpCloseClick} blockElClass="" type="submit">
            {t('userAccount.searchBtn')}
          </Button>
        </div>
      </div>
    </form>
  )
}
export default AccountManagementListFilter

import React, { useMemo, useState } from 'react'

import PropTypes from 'prop-types'
import find from 'lodash/find'
import get from 'lodash/get'
import noop from 'lodash/noop'
import merge from 'lodash/merge'
import omit from 'lodash/omit'

import { BlockEl } from 'components/atoms'

const ensureObject = (option) => {
  const isObject = typeof option === 'object'
  const id = isObject ? option.id : option
  const label = isObject ? option.label : option
  const optionObj = isObject ? option : null

  return { id, label, optionObj }
}

const SelectItem = ({ option, ...props }) => <li {...omit(props, 'id')}>{option.label}</li>
const IDProp = PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])

SelectItem.propTypes = {
  label: PropTypes.string,
  option: PropTypes.oneOfType([
    PropTypes.shape({
      id: IDProp,
      label: PropTypes.string.isRequired,
    }),
    IDProp,
  ]),
  onClick: PropTypes.func,
}

const SelectBack = ({ expanded, ...props }) => <div {...props} style={{ display: expanded ? 'block' : 'none' }}></div>

SelectBack.propTypes = {
  expanded: PropTypes.bool,
}

function CustomSelect(props) {
  const {
    id,
    placeholder,
    modifier,
    inputModifier,
    options,
    components: propComponents,
    classes: propClasses,
    selected,
    onChange,
    disabled = false,
  } = props
  const [expanded, setExpanded] = useState(false)
  const components = merge(
    {},
    {
      selectArea: 'div',
      selectWrap: 'div',
      input: 'input',
      selectBack: SelectBack,
      selectList: 'ul',
      selectItem: SelectItem,
    },
    propComponents
  )

  const classes = merge(
    {},
    {
      selectArea: 'form__customSelectArea',
      selectWrap: 'form__customSelectWrap',
      input: 'form__customSelect',
      selectBack: 'form__customSelectBack',
      selectList: 'form__customSelectList',
      selectItem: 'form__customSelectItem',
    },
    propClasses
  )

  const handleClick = () => {
    if (!disabled) setExpanded(true)
  }

  const handleSelect = (value) => {
    onChange(value)
    setExpanded(false)
  }

  const handleClose = () => {
    setExpanded(false)
  }

  const selectedText = useMemo(
    () => get(find(options.map(ensureObject), { id: selected }), 'label', ''),
    [selected, options]
  )

  return (
    <BlockEl blockElClass={classes.selectArea} component={components.selectArea} modifier={modifier}>
      <BlockEl blockElClass={classes.selectWrap} component={components.selectWrap} onClick={handleClick}>
        <BlockEl
          blockElClass={classes.input}
          component={components.input}
          modifier={inputModifier}
          type="text"
          id={id}
          value={selectedText}
          placeholder={placeholder}
          disabled
        />
      </BlockEl>
      <BlockEl
        blockElClass={classes.selectBack}
        component={components.selectBack}
        expanded={expanded}
        onClick={handleClose}
      />
      <BlockEl
        blockElClass={classes.selectList}
        component={components.selectList}
        modifier={{ active: expanded && !disabled }}
      >
        {options.map((item, key) => (
          <BlockEl
            key={key}
            blockElClass={classes.selectItem}
            component={components.selectItem}
            option={ensureObject(item)}
            onClick={() => handleSelect(item.id)}
          />
        ))}
      </BlockEl>
    </BlockEl>
  )
}

const ComponentsProp = PropTypes.shape({
  selectArea: PropTypes.elementType,
  selectWrap: PropTypes.elementType,
  input: PropTypes.elementType,
  selectBack: PropTypes.elementType,
  selectList: PropTypes.elementType,
  selectItem: PropTypes.elementType,
})

const ClassesProp = PropTypes.shape({
  selectArea: PropTypes.string,
  selectWrap: PropTypes.string,
  input: PropTypes.string,
  selectBack: PropTypes.string,
  selectList: PropTypes.string,
  selectItem: PropTypes.string,
})

CustomSelect.propTypes = {
  id: PropTypes.string,
  placeholder: PropTypes.string,
  modifier: PropTypes.string,
  inputModifier: PropTypes.string,
  options: PropTypes.arrayOf(SelectItem.propTypes.option).isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  selected: IDProp,
  components: ComponentsProp,
  classes: ClassesProp,
}

CustomSelect.defaultProps = {
  inputModifier: 'w100',
  onChange: noop,
  disabled: false,
}

export default CustomSelect

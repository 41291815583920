import * as types from './actionTypes'

export function actionGetEvaluationList(payload) {
  return {
    type: types.GET_EVALUATION_LIST,
    payload: payload,
  }
}

export function actionSetSearchCriteria(payload) {
  return {
    type: types.SET_SEARCH_CRITERIA,
    payload: payload,
  }
}

export function actionSetSlideoutItem(data) {
  return {
    type: types.SET_EVALUATION_SLIDEOUT_ITEM,
    payload: { data },
  }
}

export function setEvaluationListFilter(data) {
  return {
    type: types.SET_EVALUATION_LIST_FILTER,
    payload: { data },
  }
}

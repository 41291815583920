import React from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { useTranslation } from 'react-i18next'
import { DATE_FORMAT } from 'utils/constants'
import { SlickSlider } from 'components'

const RecentInterviews = ({ items }) => {
  const { t } = useTranslation()
  const sliderSettings = {
    autoplay: false,
    infinite: false,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 834,
        settings: {
          centerMode: true,
          slidesToShow: 1,
        },
      },
    ],
    className: 'dashboard__mensetsu',
  }

  const SlideItem = ({ item }) => {
    return (
      <div className="dashboard__mensetsu__box">
        <div className="dashboard__mensetsu__title" hidden>
          <p className="dashboard__mensetsu__title-date">{dayjs(item?.updated_at).format(DATE_FORMAT)}</p>
          <p className="dashboard__mensetsu__title-num">4</p>
          <p className="dashboard__mensetsu__title-unit">件</p>
        </div>

        <div className="dashboard__mensetsu__status">
          <dl className="dashboard__mensetsu__status-dl">
            <dt>{t('dashboard.recentInterviews.company')}</dt>
            <dd>
              <p>{item?.job_posting.company_name}</p>
            </dd>
          </dl>
          <dl className="dashboard__mensetsu__status-dl">
            <dt>{t('dashboard.recentInterviews.referrer')}</dt>
            <dd>
              <p>{item?.job_applicant.fullName}</p>
            </dd>
          </dl>
          <dl className="dashboard__mensetsu__status-dl">
            <dt>{t('dashboard.recentInterviews.selectionStep')}</dt>
            <dd>
              <p>{item?.application_status.status}</p>
            </dd>
          </dl>
        </div>
      </div>
    )
  }
  SlideItem.propTypes = {
    item: PropTypes.any.isRequired,
  }

  return (
    <>
      <div className="dashboard__title-wrap">
        <p className="dashboard__title">{t('dashboard.recentInterviews.title')}</p>
      </div>
      <SlickSlider settings={sliderSettings} itemsCount={items.length} rowCount={1}>
        {items?.map((item, index) => (
          <SlideItem key={index} item={item} />
        ))}
      </SlickSlider>
    </>
  )
}

RecentInterviews.propTypes = {
  items: PropTypes.array,
}

export default RecentInterviews

import React from 'react'
import Modal from '../Modal'
import PropTypes from 'prop-types'
import CloseIcon from 'assets/img/white-batu.svg'
import PenIconYellow from 'assets/img/pen-icon-yellow.svg'
import { BlockEl, Button } from 'components'
import { useTranslation } from 'react-i18next'
import { ReactComponent as GarbageIcon } from 'assets/img/garbage-icon3.svg'

const SearchCriteriaItemModal = ({ searchCriteria, open, onClose, onEdit, onDelete }) => {
  const { t } = useTranslation()

  return (
    <Modal
      modifier="ofh"
      containerType=""
      containerClass="modal__fullScreenCont modal__fullScreenCont-jce modalCont"
      onClose={onClose}
      open={open}
    >
      <BlockEl blockElClass="modal__fullScreenContInner">
        <Button
          blockElClass="search"
          variant="spModal"
          variantMod="w"
          type="button"
          onClick={() => onEdit(searchCriteria)}
        >
          <span className="btn__btnIcon">
            <img src={PenIconYellow} alt="" width="20" height="20" />
          </span>
          &ensp;{t('common.edit')}
        </Button>
        <Button
          blockElClass="search"
          variant="spModal"
          variantMod="wr"
          type="button"
          onClick={() => onDelete(searchCriteria.id)}
        >
          <span className="btn__btnIcon">
            <GarbageIcon />
          </span>
          &ensp;{t('common.delete')}
        </Button>
        <Button
          className="modalClose onlyModalClose withoutScroll"
          blockElClass="search"
          variant="spModal"
          variantMod="close"
          type="button"
          onClick={onClose}
        >
          <span className="btn__btnIcon">
            <img src={CloseIcon} alt="" width="12" height="12" />
          </span>
          &ensp;{t('candidateSlideout.close')}
        </Button>
      </BlockEl>
    </Modal>
  )
}

SearchCriteriaItemModal.propTypes = {
  searchCriteria: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
}

export default SearchCriteriaItemModal

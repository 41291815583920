import React, { useRef, useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import useResizeObserver from '@react-hook/resize-observer'
import { NavLink, useParams, useLocation } from 'react-router-dom'
import find from 'lodash/find'
import { useAuth } from 'utils/hooks'
import FilterHeader from './FilterHeader'

import NonAdminLogo from 'assets/img/logo.svg'
import CloseIcon from 'assets/img/close.svg'
import AdminLogo from 'assets/img/logo-admin.svg'
import HamburgerIcon from 'assets/img/hunb-img.svg'
import { NavMenuButton } from 'components/atoms'
import { CustomSelect, Header } from 'components/molecules'
import { selectAuthUser } from 'store/auth/selectors'
import { accountType, isHuunoAdmin, calculateModifiers } from 'utils/helper'
import { actionSetHeaderSize } from 'store/app/actionCreators'
import { actionSetJobFilterSlideStatus, setJobPostingFilter } from 'store/job-postings/actionCreators'
import { setApplicantFilterModalStatus } from 'store/applicant/actionCreators'
import { setReferralFilter, setProposalFilter } from 'store/referral-proposals/actionCreators'
import { USER_STATUSES } from 'utils/constants'
import { setCompanyMgmtFilter, setCompanyAdminMgmtFilter } from 'store/companies/actionCreator'
import { setUserLogsFilter } from 'store/user-logs/actionCreator'
import { setEvaluationListFilter } from 'store/evaluation/actionCreators'
import clsx from 'clsx'

const customSelectClasses = {
  selectArea: 'header__customSelectArea',
  selectWrap: 'header__customSelectWrap',
  input: 'header__customSelect',
  selectBack: 'header__customSelectBack',
  selectList: 'header__customSelectList header__customSelectList-right',
  selectItem: 'header__customSelectItem',
}

const Topbar = (props) => {
  const { userType } = props
  const dispatch = useDispatch()
  const authUser = useSelector(selectAuthUser)
  const isAdmin = isHuunoAdmin(authUser)
  const { t } = useTranslation()
  const { user, signOut } = useAuth()
  const account_type = accountType(authUser)
  const userStatus = authUser.status.id
  const { id } = useParams()
  //const history = useHistory()
  const profileURL = '/profile'
  const location = useLocation()
  const [isProfile, setIsProfile] = useState(false)
  const [navbarOpen, setNavbarOpen] = useState(false)

  const pages = [
    {
      title: t('header.navbar.home'),
      href: '/',
      access: ['ad1Parent', 'ad1Child', 'ad2Parent', 'ad2Child', 'hrParent', 'hrChild'],
      exact: true,
    },
    {
      title: account_type === 'HR' ? t('header.navbar.jobPostings') : t('header.navbar.jobSearch'),
      href: account_type === 'HR' ? '/job-management' : '/jobSearch',
      access: ['ad1Parent', 'ad1Child', 'ad2Parent', 'ad2Child', 'hrParent', 'hrChild'],
      exact: true,
    },
    {
      title: t('header.navbar.candidates'),
      href: '/applicants',
      access: ['ad1Parent', 'ad1Child'],
      restrictStatus: [USER_STATUSES.suspended],
      exact: true,
    },
    {
      title: account_type === 'HR' ? t('header.navbar.selection') : t('header.navbar.recommendation'),
      href: '/selection-management',
      access: ['ad1Parent', 'ad1Child', 'hrParent', 'hrChild'],
      restrictStatus: [USER_STATUSES.suspended],
      exact: true,
    },
    {
      title: t('header.navbar.proposal'),
      href: '/suggestion-management',
      access: ['ad2Parent', 'ad2Child', 'hrParent', 'hrChild'],
      restrictStatus: [USER_STATUSES.suspended],
      exact: true,
    },
    {
      title: t('header.navbar.question'),
      href: '/questions',
      access: ['ad1Parent', 'ad1Child', 'ad2Parent', 'ad2Child', 'hrParent', 'hrChild'],
      restrictStatus: [USER_STATUSES.suspended],
    },
    {
      title: t('header.navbar.favorite'),
      href: '/favorite',
      access: ['ad1Parent', 'ad1Child', 'ad2Parent', 'ad2Child'],
    },

    // TODO: update href links once admin pages are finilized
    {
      title: t('header.navbar.companyAccount'),
      href: '/admin/company-management',
      access: ['admin'],
      exact: true,
    },
    {
      title: t('header.navbar.log'),
      href: '/admin/log',
      access: ['admin'],
    },
    {
      title: t('header.navbar.evaluation'),
      href: '/admin/evaluation',
      access: ['admin'],
    },
    {
      title: t('header.navbar.specialFeature'),
      href: '/admin/article',
      access: ['admin'],
    },
  ]

  const subPages = [
    {
      id: '/user-settings',
      href: '/user-settings',
      label: t('userSetting.dropdownText'),
      access: ['*'],
    },
    {
      id: '/about-us',
      href: '/about-us',
      label: t('header.dropdown.companyInformation'),
      access: ['ad1Parent', 'ad1Child', 'ad2Parent', 'ad2Child', 'hrParent', 'hrChild'],
    },
    {
      id: isAdmin ? '/admin/users/account-management' : '/users',
      href: isAdmin ? '/admin/users/account-management' : '/users',
      label: t('header.dropdown.accountManagement'),
      access: ['ad1Parent', 'ad2Parent', 'hrParent'],
    },
    {
      id: '/profile',
      href: '/profile',
      label: t('header.dropdown.profileManagement'),
      access: ['ad1Parent', 'ad1Child', 'ad2Parent', 'ad2Child', 'hrParent', 'hrChild'],
    },
    {
      id: 'logout',
      label: t('header.dropdown.logout'),
      onClick: signOut,
      access: ['*'],
    },
  ]

  const handleSubMenuClick = (itemId) => {
    const item = find(subPages, { id: itemId })

    if (!item) return

    if (item.onClick) {
      item.onClick()
      return
    }
  }

  const userPages = () => {
    // Filter by userType
    const filterByUserType = pages.filter((page) => page.access.includes(userType))

    // Filter by userStatus
    return filterByUserType.filter((page) => !page.restrictStatus?.includes(userStatus))
  }

  const theSubPages = subPages.filter((page) => page.access.includes(userType) || page.access.includes('*'))

  const headerRef = useRef(null)
  useResizeObserver(headerRef, (e) => {
    const size = e.borderBoxSize[0]
    dispatch(
      actionSetHeaderSize({
        blockSize: size.blockSize,
        inlineSize: size.inlineSize,
      })
    )
  })

  const logo = isAdmin ? AdminLogo : NonAdminLogo

  const isJobSearchPage = location.pathname === '/jobSearch'
  const isApplicantPage = location.pathname === '/applicants'
  const isSelectionPage = location.pathname === '/selection-management'
  const isSuggestionPage = location.pathname === '/suggestion-management'
  const isJobMgmtPage = location.pathname === '/job-management'
  const isCompanymgmtPage = location.pathname === '/admin/company-management'
  const isAdminCompanyDetailsPage = location.pathname.includes('/admin/account-management')
  const isAdminLogPage = location.pathname === '/admin/log'
  const isEvaluationMgmtPage = location.pathname === '/admin/evaluation'

  useEffect(() => {
    const currentPage = location.pathname.replaceAll('/' + id, '')
    if (currentPage === profileURL) {
      if (typeof id !== 'undefined') {
        if (+id !== authUser.id) {
          setIsProfile(false)
        } else {
          setIsProfile(true)
        }
      } else {
        setIsProfile(true)
      }
    }
  }, [location.pathname])

  const handleNavbarIconClick = () => setNavbarOpen(!navbarOpen)

  const handleNavbarClose = () => setNavbarOpen(false)

  const jobSearchFilterClick = (e) => {
    e.preventDefault()
    dispatch(actionSetJobFilterSlideStatus(true))
  }

  const applicantFilterClick = (e) => {
    e.preventDefault()
    dispatch(setApplicantFilterModalStatus(true))
  }

  const selectionFilterClick = (e) => {
    e.preventDefault()
    dispatch(setReferralFilter(true))
  }

  const suggestionFilterClick = (e) => {
    e.preventDefault()
    dispatch(setProposalFilter(true))
  }

  const jobPostingFilterClick = (e) => {
    e.preventDefault()
    dispatch(setJobPostingFilter(true))
  }

  const companyMgmtFilterClick = (e) => {
    e.preventDefault()
    dispatch(setCompanyMgmtFilter(true))
  }

  const companyAdminMgmtFilterClick = (e) => {
    e.preventDefault()
    dispatch(setCompanyAdminMgmtFilter(true))
  }

  const adminLogFilterClick = (e) => {
    e.preventDefault()
    dispatch(setUserLogsFilter(true))
  }

  const evaluationFilterClick = (e) => {
    e.preventDefault()
    dispatch(setEvaluationListFilter(true))
  }

  return (
    <>
      <Header ref={headerRef}>
        <div className="header__logoArea">
          <h1 className="header__logoWrap pc">
            <img src={logo} alt="HuuNo" className="header__logo" width="72" height="25"></img>
          </h1>
          <div className="header__humbArea" id="headerHumb" onClick={handleNavbarIconClick}>
            <img src={HamburgerIcon} alt="" className="header__humb" width="17" height="12" />
          </div>
          <nav id="headerNav" className={clsx('header__nav', calculateModifiers('header__nav', { open: navbarOpen }))}>
            <div className="header__navBgClose sp" id="headerBgClose" onClick={handleNavbarClose}></div>
            <div className="header__navInner" id="headerNavInner">
              <div className="header__navHeader sp sp-flex">
                <p className="header__logoWrap sp">
                  <img src={NonAdminLogo} alt="HuuNo" className="header__logo" width="72" height="25" />
                </p>
                <div className="header__navClose" id="navClose" onClick={handleNavbarClose}>
                  <img src={CloseIcon} alt="" className="header__navCloseIcon" width="12" height="12" />
                </div>
              </div>
              <ul className="header__navUl">
                {userPages().map((page) => (
                  <NavMenuButton key={page.title} {...page} />
                ))}
              </ul>
              <ul className="header__navUl header__navUl-none sp">
                {theSubPages.map(
                  (subPage) => subPage.id !== 'logout' && <NavMenuButton key={subPage.id} {...subPage} />
                )}
                <li className="header__navLi" key={'logout'} onClick={signOut}>
                  <span className="header__customSelectItemLink">{t('header.dropdown.logout')}</span>
                </li>
              </ul>
            </div>
          </nav>
        </div>
        <div className="header__rightArea header__rightArea-aic pc">
          <CustomSelect
            classes={{
              ...customSelectClasses,
              input: isProfile ? 'header__customSelect header__customSelect-profileClicked' : 'header__customSelect',
            }}
            components={{
              input: ({ className, onClick }) => (
                <p className={className} onClick={onClick}>
                  {user?.full_name}
                </p>
              ),
              selectItem: ({ option, ...props }) => (
                <li {...props}>
                  {option.id === 'logout' ? (
                    <span className="header__customSelectItemLink">{option.label}</span>
                  ) : (
                    <NavLink to={option.id} className="header__customSelectItemLink">
                      {option.label}
                    </NavLink>
                  )}
                </li>
              ),
            }}
            options={theSubPages}
            onChange={handleSubMenuClick}
          />
        </div>
      </Header>
      {isJobSearchPage && <FilterHeader title={t('header.navbar.jobSearch')} onFilter={jobSearchFilterClick} />}
      {isApplicantPage && (
        <FilterHeader title={t('header.subbar.jobSeekerManagement')} onFilter={applicantFilterClick} />
      )}
      {isSelectionPage && (
        <FilterHeader title={t('header.subbar.selectionManagement')} onFilter={selectionFilterClick} />
      )}
      {isSuggestionPage && (
        <FilterHeader title={t('header.subbar.proposalManagement')} onFilter={suggestionFilterClick} />
      )}
      {isJobMgmtPage && <FilterHeader title={t('header.subbar.proposalManagement')} onFilter={jobPostingFilterClick} />}
      {isCompanymgmtPage && (
        <FilterHeader title={t('header.subbar.proposalManagement')} onFilter={companyMgmtFilterClick} />
      )}
      {isAdminCompanyDetailsPage && (
        <FilterHeader title={t('companyList.search.title')} onFilter={companyAdminMgmtFilterClick} />
      )}
      {isAdminLogPage && <FilterHeader title={t('companyList.search.title')} onFilter={adminLogFilterClick} />}
      {isEvaluationMgmtPage && <FilterHeader title={t('companyList.search.title')} onFilter={evaluationFilterClick} />}
    </>
  )
}

Topbar.propTypes = {
  userType: PropTypes.string,
  user: PropTypes.object,
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
}

export default Topbar

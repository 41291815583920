import React, { useEffect, useState } from 'react'
import { Button } from 'components'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { selectFavoriteModalControls } from 'store/job-postings/selectors'
import { showNotification } from 'store/notification/actionCreators'
import { addToFavorites } from 'services/jobPostings'
import { actionSetJobPostingFavoriteModal } from 'store/job-postings/actionCreators'
import PropTypes from 'prop-types'
import Modal from 'components/molecules/Modal'
import CloseIcon from 'assets/img/white-batu.svg'
import StarIcon from 'assets/img/star-icon.svg'

const JobPostingFavoriteModal = ({ callback }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { isOpen, selected } = useSelector(selectFavoriteModalControls)
  const [bookmarked, setBookmarked] = useState(false)

  useEffect(() => {
    if (selected) setBookmarked(selected.is_bookmarked)
  }, [selected])

  const handleClose = () => {
    dispatch(
      actionSetJobPostingFavoriteModal({
        isOpen: false,
        selected: null,
      })
    )
  }

  const handleFavoriteClick = (e) => {
    e.stopPropagation()
    const isBookmarked = selected.is_bookmarked
    dispatch(addToFavorites(selected.id, isBookmarked ? 1 : 0)).then(() => {
      dispatch(
        showNotification(t(`jobPosting.card.${isBookmarked ? 'unfavoriteRemovedTextNotif' : 'addedToFavorites'}`), {
          type: 'success',
        })
      )
      setBookmarked(!bookmarked)
    })

    if (callback !== undefined) {
      callback()
      handleClose()
    }
  }

  return (
    <Modal
      modifier="ofh"
      containerType=""
      containerClass="modal__fullScreenCont modal__fullScreenCont-jce modalCont"
      onClose={handleClose}
      open={isOpen}
    >
      <div className="modal__fullScreenContInner">
        <Button blockElClass="search" variant="spModal" variantMod="w" type="button" onClick={handleFavoriteClick}>
          <span className="btn__btnIcon">
            <img src={StarIcon} alt="" width="12" height="12" />
          </span>
          &ensp;{bookmarked ? t('jobPosting.card.unFavorites') : t('jobPosting.card.addToFavorites')}
        </Button>
        <Button
          className="modalClose"
          blockElClass="search"
          variant="spModal"
          variantMod="close"
          type="button"
          onClick={handleClose}
        >
          <span className="btn__btnIcon">
            <img src={CloseIcon} alt="" width="12" height="12" />
          </span>
          {t('candidateSlideout.close')}
        </Button>
      </div>
    </Modal>
  )
}

JobPostingFavoriteModal.propTypes = {
  callback: PropTypes.func,
}

export default JobPostingFavoriteModal

import React, { useEffect, useRef, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import EditIcon from 'assets/img/pen-icon-yellow.svg'
import DraftIcon from 'assets/img/pen-icon-draft-yelow.svg'
import CopyIcon from 'assets/img/copy-icon.svg'
import EllipsisIcon from 'assets/img/threedot.svg'
import BlankIconBlackIcon from 'assets/img/blank-icon-black.svg'
import CloseWhiteIcon from 'assets/img/close-white.svg'
import { BlockEl } from 'components/atoms'
import { CustomSelect } from 'components/molecules'
import { useTranslation } from 'react-i18next'
import { copyJobPosting, updateJobPosting } from 'services/jobPostings'
import { useDispatch } from 'react-redux'
import { showNotification } from 'store/notification/actionCreators'
import { JOB_POSTING_STATUSES } from 'utils/constants'
import { useHistory } from 'react-router-dom'
import { useIsMobile } from 'utils/hooks'

const BoxMainInfo = ({ children }) => (
  <div className="selection__mainInfo selection__mainInfo-wordBreak">{children}</div>
)

BoxMainInfo.propTypes = {
  children: PropTypes.node,
}

const BoxMainInfoTag = ({ tag }) => {
  const { t } = useTranslation()
  return (
    <div className="selection__mainInfoTag">
      {tag === 'referral' ? t('jobManagement.listing.item.referralTag') : t('jobManagement.listing.item.proposalTag')}
    </div>
  )
}

BoxMainInfoTag.propTypes = {
  tag: PropTypes.string.isRequired,
}

const BoxMainInfoContent = ({ content }) => <div className="selection__mainInfoContent">{content}</div>

BoxMainInfoContent.propTypes = {
  content: PropTypes.string.isRequired,
}

const BoxMainInfoStatusArea = ({ children }) => <div className="selection__mainInfoStatusArea">{children}</div>

BoxMainInfoStatusArea.propTypes = {
  children: PropTypes.node,
}

const BoxMainInfoStatus = ({ status }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'jobPosting.status' })

  return (
    <BlockEl
      component="p"
      bec="evaluation__rightStatus"
      modifier={{
        underRecruitement: status === t('underRecruit'),
        closed: status === t('closed'),
        draft: status === t('draft'),
        archive: status === t('archive'),
        max: status === t('max'),
      }}
    >
      {status}
    </BlockEl>
  )
}

BoxMainInfoStatus.propTypes = {
  status: PropTypes.string.isRequired,
}

const BoxMainInfoEditDate = ({ editDate }) => {
  const { t } = useTranslation()
  const date = new Date(editDate)
  const formatted_date = date.toLocaleDateString()
  return (
    <div className="selection__mainInfoEditDate">
      {t('jobManagement.listing.item.editDateLabel')}: {formatted_date}
    </div>
  )
}

BoxMainInfoEditDate.propTypes = {
  editDate: PropTypes.string.isRequired,
}

const BoxType = ({ type }) => (
  <div className="selection__mainType-hide" data-type="PV数">
    {type}
  </div>
)

BoxType.propTypes = {
  type: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

const BoxNum = ({ num }) => (
  <div className="selection__mainNum" data-type="採用予定数">
    {num}
  </div>
)

BoxNum.propTypes = {
  num: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

const BoxStatusNum = ({ num }) => (
  <div className="selection__mainStatusNum" data-type="進行中">
    {num}
  </div>
)

BoxStatusNum.propTypes = {
  num: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

const BoxProgressNum = ({ num }) => (
  <div className="selection__mainProgressNum" data-type="進行中">
    {num}
  </div>
)

BoxProgressNum.propTypes = {
  num: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

const BoxEmpty = ({ children }) => (
  <div className="selection__mainEmpty">
    <div className="selection__mainEmptyFlex">{children}</div>
  </div>
)

BoxEmpty.propTypes = {
  children: PropTypes.node,
}

const BoxEmptyLink = ({ href }) => (
  <a className="selection__mainEmptyLink" href={href} target="_blank" rel="noreferrer">
    <img src={BlankIconBlackIcon} alt="" />
  </a>
)

BoxEmptyLink.propTypes = {
  href: PropTypes.string.isRequired,
}

const BoxEmptyOptionArea = ({ jobPostId, item, disableOption }) => {
  const [show, setShow] = useState(false)
  const dispatch = useDispatch()
  const { t } = useTranslation('translation', { keyPrefix: 'jobManagement.jobStatusLabel' })
  const history = useHistory()
  const ref = useRef()
  const isMobile = useIsMobile()
  const [optionFilter, setOptionFilter] = useState([])

  const [disabled, setDisabled] = useState(false)

  const options = [
    {
      id: 'resumeRecruitment',
      label: t('resumeRecruitment'),
      icon: CopyIcon,
      statuses: [JOB_POSTING_STATUSES.closed],
      modifier: 'copy',
    },
    {
      id: 'startRecruitment',
      label: t('startRecruitment'),
      icon: '',
      statuses: [JOB_POSTING_STATUSES.draft],
      modifier: '',
    },
    {
      id: 'under_recruitment',
      label: t('underRecruitment'),
      icon: EditIcon,
      statuses: [
        JOB_POSTING_STATUSES.under_recruitment,
        JOB_POSTING_STATUSES.closed,
        JOB_POSTING_STATUSES.draft,
        JOB_POSTING_STATUSES.limitReached,
      ],
      modifier: 'edit',
    },
    {
      id: 'draft',
      label: t('draft'),
      icon: DraftIcon,
      statuses: [
        JOB_POSTING_STATUSES.under_recruitment,
        JOB_POSTING_STATUSES.archived,
        JOB_POSTING_STATUSES.limitReached,
      ],
      modifier: 'draft',
    },
    {
      id: 'copy',
      label: t('copy'),
      icon: CopyIcon,
      statuses: [JOB_POSTING_STATUSES.under_recruitment, JOB_POSTING_STATUSES.draft, JOB_POSTING_STATUSES.limitReached],
      modifier: 'copy',
    },
    {
      id: 'closed',
      label: t('recruitmentSuspended'),
      icon: '',
      statuses: [JOB_POSTING_STATUSES.under_recruitment, JOB_POSTING_STATUSES.limitReached],
      modifier: 'stop',
    },
    {
      id: 'archived',
      label: t('archived'),
      icon: '',
      statuses: [
        JOB_POSTING_STATUSES.under_recruitment,
        JOB_POSTING_STATUSES.closed,
        JOB_POSTING_STATUSES.draft,
        JOB_POSTING_STATUSES.limitReached,
      ],
      modifier: 'archive',
    },
  ]

  const initalOptionFilter = options.filter((option) => option.statuses?.includes(item.status))

  useEffect(() => {
    if (isMobile) {
      const closeOption = {
        id: 'closeMenu',
        label: t('closeMenu'),
        icon: CloseWhiteIcon,
        statuses: [],
        modifier: 'closeMenu',
      }
      setOptionFilter([...initalOptionFilter, closeOption])
    } else {
      setOptionFilter(initalOptionFilter)
    }
  }, [isMobile])

  const updateStatuses = (status) => {
    dispatch(updateJobPosting(jobPostId, { _method: 'PUT', status: status })).then(({ payload, type }) => {
      if (type === 'UPDATE_JOB_POSTING_FAILURE')
        dispatch(showNotification(payload.error.error, { type: 'danger', modifier: 'btm' }))

      setTimeout(() => location.reload(), 1000)
    })
  }

  const handleChangeStatus = (label) => () => {
    switch (label) {
      case 'under_recruitment':
        localStorage.setItem(
          item.job_posting_type === 'referral' ? 'job_posting' : 'job_post_proposal',
          JSON.stringify(item)
        )
        history.push({
          pathname: item.job_posting_type === 'referral' ? '/new-job-post/step1/' : '/new-job-post-proposal/step1/',
          state: { job_posting_data: item, multipleStep: true },
        })
        return
      case 'draft':
        updateStatuses(JOB_POSTING_STATUSES.draft)
        break
      case 'copy':
        dispatch(copyJobPosting(jobPostId)).then(({ error }) => {
          if (!error) dispatch(showNotification(t('copyAsDraft'), { type: 'success' }))
          else dispatch(showNotification(error.error, { type: 'danger', modifier: 'btm' }))
          setTimeout(() => location.reload(), 1000)
        })
        break
      case 'closed':
        updateStatuses(JOB_POSTING_STATUSES.closed)
        break
      case 'archived':
        updateStatuses(JOB_POSTING_STATUSES.archived)
        break
      case 'closeMenu':
        handleOpen()
        break
      default:
        updateStatuses(JOB_POSTING_STATUSES.under_recruitment)
        break
    }

    setDisabled(true)
  }

  const handleOpen = () => {
    if (disableOption) return
    setShow(!show)
  }

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (show && ref.current && !ref.current.contains(e.target)) {
        setShow(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [show])

  return (
    <div onClick={handleOpen} ref={ref}>
      <CustomSelect
        options={optionFilter}
        classes={{
          selectArea: 'selection__mainEmptyOptionArea',
          selectWrap: 'selection__mainEmptyOptionIcon optionsEvent',
          selectList: 'selection__mainEmptyOptionsUl',
        }}
        components={{
          input: () => {
            return <img src={EllipsisIcon} alt="" />
          },
          selectBack: () => <></>,
          selectList: ({ children }) => {
            const adjustYCoordinate = useCallback((node) => {
              if (node === null) return
              const body = document.body

              // Raise the node up if it causes an extra scroll height to the body
              if (body.clientHeight < body.scrollHeight) {
                node.style.top = `-${node.clientHeight}px`
              }
            }, [])

            return (
              <BlockEl
                blockElClass={`selection__mainEmptyOptions ${show ? 'show' : ''}`}
                componentRef={adjustYCoordinate}
              >
                <BlockEl component="ul" blockElClass="selection__mainEmptyOptionsUl">
                  {children}
                </BlockEl>
              </BlockEl>
            )
          },
          selectItem: ({ option }) => (
            <BlockEl
              component="li"
              blockElClass="selection__mainEmptyOptionsLi"
              modifier={
                typeof option?.optionObj === 'object' && option?.optionObj?.modifier !== ''
                  ? option?.optionObj?.modifier
                  : 'action'
              }
              onClick={handleChangeStatus(disabled === false ? option.id : '')}
              key={option.id}
            >
              {typeof option?.optionObj === 'object' && option?.optionObj?.icon !== '' && (
                <span className="selection__mainEmptyOptionsLiIcon sp">
                  <img src={option?.optionObj?.icon} alt="" />
                </span>
              )}
              {option.label}
            </BlockEl>
          ),
        }}
        modifier="show"
      />
    </div>
  )
}

BoxEmptyOptionArea.propTypes = {
  jobPostId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  item: PropTypes.any,
  disableOption: PropTypes.bool,
}

const Box = {
  MainInfo: {
    Index: BoxMainInfo,
    Tag: BoxMainInfoTag,
    Content: BoxMainInfoContent,
    StatusArea: BoxMainInfoStatusArea,
    Status: BoxMainInfoStatus,
    EditDate: BoxMainInfoEditDate,
  },
  Type: BoxType,
  Num: BoxNum,
  StatusNum: BoxStatusNum,
  ProgressNum: BoxProgressNum,
  Empty: {
    Index: BoxEmpty,
    Link: BoxEmptyLink,
    OptionArea: BoxEmptyOptionArea,
  },
}

export default Box

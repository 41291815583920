import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { BlockEl } from 'components/atoms'

function SearchConditionGroup({ label, handleModalOpen, selectedItemsCount = 0 }) {
  const { t } = useTranslation()
  return (
    <>
      <BlockEl blockElClass="search__conditonTitleArea">
        <p className="search__conditonTitle">{label}</p>
        <p
          className="search__conditonBtn popModal"
          onClick={handleModalOpen}
          data-modal="modal"
          data-modalcont="modalCont3"
        >
          {t('jobPosting.search.select')}
        </p>
      </BlockEl>
      <BlockEl
        blockElClass="search__conditonTitleAreaSP popModal"
        id="searchIndustrySp"
        data-modal="modal"
        data-modalcont="modalCont3sp"
        onClick={handleModalOpen}
      >
        <p className="search__conditonTitle">{label}</p>
        <BlockEl blockElClass="search__modalTitleNum">
          <span className="search__modalTitleNum-span" style={{ display: selectedItemsCount > 0 ? 'flex' : 'none' }}>
            {selectedItemsCount}
          </span>
        </BlockEl>
      </BlockEl>
    </>
  )
}

SearchConditionGroup.propTypes = {
  label: PropTypes.string,
  handleModalOpen: PropTypes.func,
  selectedItemsCount: PropTypes.string,
}

export default SearchConditionGroup

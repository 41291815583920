import { actionSetSearchCriteria, actionGetEvaluationList } from 'store/evaluation/actionCreators'
import Http from 'utils/Http'
import { showLoader, hideLoader } from 'store/loader/actionCreators'
import { createAsyncThunk } from 'store/helpers'
import { parseAxiosResponse } from 'utils/helper'

export function searchEvaluation(jobPostingid) {
  return (dispatch, getState) => {
    dispatch(showLoader())

    return Http.get('common/evaluations/list/' + jobPostingid, { params: getState().evaluation.params })
      .then((response) => {
        dispatch(actionGetEvaluationList(response.data))
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

export function searchEvaluationByJobPostingCompanyId(jobPostingId) {
  return (dispatch, getState) => {
    dispatch(showLoader())

    return Http.get(`common/evaluations/${jobPostingId}/company/list`, { params: getState().evaluation.params })
      .then((response) => {
        dispatch(actionGetEvaluationList(response.data))
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

export function changeSearchCriteria(keyword, page, limit, sort, sortBy, job_evaluation_filter) {
  return (dispatch) => {
    dispatch(actionSetSearchCriteria({ keyword, page, limit, sort, sortBy, job_evaluation_filter }))
  }
}

export const createEvaluation = createAsyncThunk('CREATE_EVALUATION', (data) =>
  parseAxiosResponse(Http.post('common/evaluations', data))
)

export function getUserEvaluations(userId, data) {
  return (dispatch) => {
    dispatch(showLoader())
    return Http.get('common/user_evaluations/' + userId, {
      params: { ...data },
    })
      .then((response) => {
        dispatch(actionGetEvaluationList(response.data))
      })
      .finally(() => {
        dispatch(hideLoader())
      })
  }
}

export const fetchEvaluations = createAsyncThunk('FETCH_EVALUATIONS_SLIDEOUT', (params) =>
  parseAxiosResponse(Http.get(`admin/evaluations/`, { params }))
)

export const updateEvaluation = createAsyncThunk('UPDATE_EVALUATION', ({ id, ...params }) =>
  parseAxiosResponse(Http.post(`admin/evaluations/${id}`, params))
)

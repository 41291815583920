import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Modal from 'components/molecules/Modal'
import CloseIcon from 'assets/img/white-batu.svg'
import { ReactComponent as StarIcon } from 'assets/img/star-icon.svg'
import { ReactComponent as QuestionIcon } from 'assets/img/question-icon.svg'
import { Button } from 'components'
import { useTranslation } from 'react-i18next'
import { showNotification } from 'store/notification/actionCreators'
import { useDispatch, useSelector } from 'react-redux'
import { addToFavorites } from 'services/jobPostings'
import { selectJobPosting } from 'store/job-postings/selectors'

const JobDetailsSpModal = ({ open, onClose, onQuestionBtnClick }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const jobPosting = useSelector(selectJobPosting)
  const [bookmarked, setBookmarked] = useState(false)

  useEffect(() => {
    if (jobPosting) setBookmarked(jobPosting.is_bookmarked)
  }, [jobPosting])

  const handleClickSaveBookmark = () => {
    dispatch(addToFavorites(jobPosting.id, bookmarked ? 1 : 0)).then(() => {
      dispatch(
        showNotification(t(`jobPosting.card.${bookmarked ? 'unfavoriteRemovedTextNotif' : 'addedToFavorites'}`), {
          type: 'success',
        })
      )
      setBookmarked(!bookmarked)
    })
  }

  return (
    <Modal
      containerType=""
      containerClass="modal__fullScreenCont modal__fullScreenCont-jce modalCont"
      onClose={onClose}
      open={open}
    >
      <div className="modal__fullScreenContInner">
        <Button blockElClass="search" variant="spModal" variantMod="w" type="button" onClick={handleClickSaveBookmark}>
          <span className="btn__btnIcon">
            <StarIcon />
          </span>
          {bookmarked ? t('jobPosting.card.unFavorites') : t('jobPosting.card.addToFavorites')}
        </Button>
        <Button blockElClass="search" variant="spModal" variantMod="w" type="button" onClick={onQuestionBtnClick}>
          <span className="btn__btnIcon">
            <QuestionIcon />
          </span>
          {t('qAndA.modalTitle')}
        </Button>
        <Button
          className="modalClose"
          blockElClass="search"
          variant="spModal"
          variantMod="close"
          type="button"
          onClick={onClose}
        >
          <span className="btn__btnIcon">
            <img src={CloseIcon} alt="" width="12" height="12" />
          </span>
          {t('candidateSlideout.close')}
        </Button>
      </div>
    </Modal>
  )
}

JobDetailsSpModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onQuestionBtnClick: PropTypes.func.isRequired,
}

export default JobDetailsSpModal

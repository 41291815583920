import React from 'react'
import Modal from 'components/molecules/Modal'
import CloseIcon from 'assets/img/white-batu.svg'
import PropTypes from 'prop-types'
import { Button } from 'components'
import { useTranslation } from 'react-i18next'

const AccountManagementActionSpModal = ({ open, onClose, handleModalStatus, handleModalPermission, isUserPending }) => {
  const { t } = useTranslation()
  const handleClose = () => onClose(!open)

  return (
    <Modal
      containerType=""
      containerClass="modal__fullScreenCont modal__fullScreenCont-jce modalCont"
      onClose={handleClose}
      open={open}
    >
      <div className="modal__fullScreenContInner">
        {!isUserPending && (
          <Button blockElClass="search" variant="spModal" variantMod="w" type="button" onClick={handleModalStatus}>
            {t('userAccount.changeStatusLbl')}
          </Button>
        )}
        <Button blockElClass="search" variant="spModal" variantMod="w" type="button" onClick={handleModalPermission}>
          {t('userAccount.permission')}
        </Button>
        <Button
          className="modalClose"
          blockElClass="search"
          variant="spModal"
          variantMod="close"
          type="button"
          onClick={handleClose}
        >
          <span className="btn__btnIcon">
            <img src={CloseIcon} alt="" width="12" height="12" />
          </span>
          {t('candidateSlideout.close')}
        </Button>
      </div>
    </Modal>
  )
}

AccountManagementActionSpModal.propTypes = {
  open: PropTypes.bool.isRequired,
  isUserPending: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleModalStatus: PropTypes.func.isRequired,
  handleModalPermission: PropTypes.func.isRequired,
}

export default AccountManagementActionSpModal

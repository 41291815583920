import React, { useState, useEffect } from 'react'
import { FormGroup, FormGroupCheck, FormInterlock, FormSupArea } from 'components/molecules'
import { useTranslation } from 'react-i18next'
import { useForm, FormProvider, useFieldArray } from 'react-hook-form'
import { Input, Radio, Textarea } from 'components/atoms'
import { PrefectureModal } from 'components'
import { useDispatch, useSelector } from 'react-redux'
import PersonInCharge from './personInCharge'
import { getAdInChargeList } from 'services/adIncharge'
import CandidateDocument from './candidateDocument'
import Footer from './footer'
import { getApplicants } from 'services/applicant'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import dayjs from 'dayjs'

const AddEditForm = (props) => {
  const { mode, files } = props

  const initialValue = {
    firstname: '',
    lastname: '',
    kana_firstname: '',
    kana_lastname: '',
    fullNameCtr: '',
    kanaNameCtr: '',
    gender: '',
    birthday: '',
    birthdayCtr: '',
    year: '',
    day: '',
    month: '',
    prefecture: '',
    prefectureName: '',
    address: '',
    recent_annual_salary: '',
    desired_annual_salary: '',
    other_docs_url: '',
    work_history_cv: '',
    resume: '',
    work_location: '',
    contact_number: '',
    contactName: '',
    ad_person_incharge: '',
    current_employment_status: '',
    current_employment_statusCtr: '',
    status: '',
    statusStr: '',
    other_docs_pdf: '',
    file_cv: '',
    file_resume: '',
    file_portfolio: '',
    url_portfolio: '',
    testimonial: '',
    other_status: '',
  }

  const methods = useForm({ defaultValues: initialValue, mode: 'all' })
  const user = useSelector((state) => state.auth.user?.data)

  const {
    formState: { errors, dirtyFields },
    handleSubmit,
    watch,
    register,
    setValue,
    reset,
    resetField,
    control,
    getValues,
  } = methods

  let { fields, append, remove, update } = useFieldArray({
    control,
    name: 'other_docs',
  })

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const [adInchargeList, setAdInchargeList] = useState([])
  const [applicants, setApplicants] = useState([])
  const special_note = watch('testimonial')
  const [showInterlock, setShowInterlock] = useState(false)
  const [prefectureId, setPrefectureId] = useState('')
  const [showPrefecture, setShowPrefecture] = useState('')
  const [prefectureName, setPrefectureName] = useState('')
  const [showInchargeDropdown, setShowInchargeDropdown] = useState(false)
  const [adPersonInCharge, setAdPersonInCharge] = useState({})
  const [contactNumber, setContactNumber] = useState('')
  const [initted, setInitted] = useState(false)
  const [selectedResume, setSelectedResume] = useState({})
  const [selectedWorkCv, setSelectedWorkCv] = useState({})
  const [selectedOtherPdf, setSelectedOtherPdf] = useState([])
  const [successResume, setSuccessResume] = useState('')
  const [successPdfWorkCv, setSuccessPdfWorkCv] = useState('')
  const [statusStr, setStatusStr] = useState('')
  const [errorBirthdayInput, setErrorBirthdayInput] = useState(false)
  const [errorBirthdayMessage, setErrorBirthdayMessage] = useState('')
  const [otherPdfString, setOtherPdfString] = useState('')
  const [showAdList, setShowAdList] = useState(false)
  const [showContactNumber, setShowContactNumber] = useState(false)

  useEffect(() => {
    dispatch(getAdInChargeList()).then((response) => {
      if (response.type === 'GET_AD_INCHARGE_LIST_SUCCESS') {
        setAdInchargeList(response.payload.data.data)
      }
    })
    dispatch(getApplicants()).then((response) => {
      if (response.type === 'GET_APPLICANTS_LIST_SUCCESS') {
        setApplicants(response.payload.data.data)
      }
    })
  }, [])

  useEffect(() => {
    if (initted) return

    let data =
      mode == 'edit' || mode == 'add-confirm' ? (JSON.parse(localStorage.getItem('candidate')) ?? '{}') || {} : {}

    const newReset = {
      firstname: data?.firstname ?? '',
      lastname: data?.lastname ?? '',
      fullNameCtr: data?.fullNameCtr ?? '',
      kana_firstname: data?.kana_firstname ?? '',
      kana_lastname: data?.kana_lastname ?? '',
      kanaNameCtr: data?.kanaNameCtr ?? '',
      gender: data?.gender ?? '',
      year: data?.year ?? '',
      month: data?.month ?? '',
      day: data?.day ?? '',
      birthdayCtr: data?.birthdayCtr ?? '',
      prefecture: data?.prefectureName ?? '',
      current_employment_status: data?.current_employment_status ?? '',
      current_employment_statusCtr: data?.current_employment_statusCtr ?? '',
      other_status: data?.other_status ?? '',
      desired_annual_salary: data?.desired_annual_salary ?? '',
      recent_annual_salary: data?.recent_annual_salary ?? '',
      status: data?.status?.toString() ?? '',
      statusStr: data.statusStr ?? '',
      emergency_contact: data?.emergency_contact ?? 'ad_person_incharge',
      ad_person_incharge: data?.ad_person_incharge,
      contact_number: data?.contact_number ?? '',
      resume: data?.resume ? data?.resume?.replace(/^[^_]+(?=_)_/, '') : '',
      work_history_cv: data?.work_history_cv ? data?.work_history_cv?.replace(/^[^_]+(?=_)_/, '') : '',
      testimonial: data?.testimonial ?? '',
    }

    if (isEmpty(data) && !isEmpty(user)) {
      setSuccessResume('')
      setSuccessPdfWorkCv('')
      setValue('ad_person_incharge', user?.full_name + ' / ' + user?.contact_number, {
        shouldDirty: true,
        shouldValidate: true,
      })
      setAdPersonInCharge(user)
      return
    }

    if (!isEmpty(data) && isEmpty(files)) return
    setInitted(true)
    reset(newReset, {
      keepDefaultValues: true,
      keepDirtyValues: false,
      keepValues: false,
    })

    if (mode == 'edit') {
      setOtherPdfString(data?.other_docs_pdf)
      data.emergency_contact === 'ad_person_incharge' ? setShowAdList(true) : setShowContactNumber(true)
      setValue('emergency_contact', { shouldDirty: true })
      setContactNumber(data?.contact_number)
    }

    setAdPersonInCharge(data?.ad_person_incharge)
    setSelectedResume(files?.file_resume)
    setSuccessResume('success')
    setSelectedWorkCv(files?.file_cv)
    setSuccessPdfWorkCv('success')
    setPrefectureName(data?.prefectureName ?? '')
    setPrefectureId(data?.prefecture ?? '')
    setStatusStr(data?.statusStr)
    setStatusValue(data?.status)

    if (data?.current_employment_status === t('candidate.other')) {
      setCurrentEmploymentStatus(data.other_status)
    } else {
      setCurrentEmploymentStatus(data.current_employment_status)
    }

    if (data?.other_docs) {
      append(data?.other_docs)
    }

    if (isEmpty(data?.other_docs) && files?.other_docs_url !== 'undefined' && !isEmpty(files?.other_docs_url)) {
      let otherUrl = JSON.parse(files?.other_docs_url)
      for (let x = 0; x < otherUrl.length; x++) {
        if (otherUrl[x].option === 'pdf' && !isEmpty(otherUrl[x].pdf)) {
          append({
            option: 'pdf',
            url: null,
            pdf: otherUrl[x].pdf,
          })
        } else {
          append({
            option: 'url',
            url: otherUrl[x].url,
            pdf: null,
          })
        }
      }
    }
  }, [initted, files])

  const setCurrentEmploymentStatus = (status) => {
    switch (status) {
      case 'Regular employee':
      case t('candidate.fulltime'):
        setValue('current_employment_status', t('candidate.fulltime'), { shouldDirty: true, shouldValidate: true })
        setValue('other_status', '', { shouldDirty: true, shouldValidate: true })
        setShowInterlock(false)
        break

      case 'Contract employee':
      case t('candidate.contractEmployee'):
        setValue('current_employment_status', t('candidate.contractEmployee'), {
          shouldDirty: true,
          shouldValidate: true,
        })
        setValue('other_status', '', { shouldDirty: true, shouldValidate: true })
        setShowInterlock(false)
        break

      case 'Temporary Staff':
      case t('candidate.temporary'):
        setValue('current_employment_status', t('candidate.temporary'), { shouldDirty: true, shouldValidate: true })
        setValue('other_status', '', { shouldDirty: true, shouldValidate: true })
        setShowInterlock(false)
        break

      case 'Outsourcing':
      case t('candidate.subContracting'):
        setValue('current_employment_status', t('candidate.subContracting'), {
          shouldDirty: true,
          shouldValidate: true,
        })
        setValue('other_status', '', { shouldDirty: true, shouldValidate: true })
        setShowInterlock(false)
        break

      case 'Part-time job':
      case t('candidate.partTime'):
        setValue('current_employment_status', t('candidate.partTime'), {
          shouldDirty: true,
          shouldValidate: true,
        })
        setValue('other_status', '', { shouldDirty: true, shouldValidate: true })
        setShowInterlock(false)
        break

      case t('candidate.other'):
        setValue('current_employment_status', t('candidate.other'), { shouldDirty: true, shouldValidate: true })
        setValue('other_status', status, { shouldDirty: true, shouldValidate: true })
        setShowInterlock(true)
        break

      default:
        setValue('current_employment_status', t('candidate.other'), { shouldDirty: true, shouldValidate: true })
        setValue('other_status', status, { shouldDirty: true, shouldValidate: true })
        setShowInterlock(true)
        break
    }
  }

  const setStatusValue = (value) => {
    switch (value) {
      case t('candidate.beforeSup'):
        setStatusStr(t('candidate.beforeSup'))
        setValue('status', '1', { shouldDirty: true, shouldValidate: true })
        break

      case '1':
        setStatusStr(t('candidate.beforeSup'))
        break

      case t('candidate.underSup'):
        setStatusStr(t('candidate.underSup'))
        setValue('status', '2', { shouldDirty: true, shouldValidate: true })
        break

      case '2':
        setStatusStr(t('candidate.underSup'))
        break

      case t('candidate.notApplicable'):
        setStatusStr(t('candidate.notApplicable'))
        setValue('status', '3', { shouldDirty: true, shouldValidate: true })
        break

      case '3':
        setStatusStr(t('candidate.notApplicable'))
        break

      case t('candidate.timeLag'):
        setStatusStr(t('candidate.timeLag'))
        setValue('status', '4', { shouldDirty: true, shouldValidate: true })
        break

      case '4':
        setStatusStr(t('candidate.timeLag'))
        break

      case t('candidate.EndSup'):
        setStatusStr(t('candidate.EndSup'))
        setValue('status', '5', { shouldDirty: true, shouldValidate: true })
        break

      case '5':
        setStatusStr(t('candidate.EndSup'))
        break

      default:
        setStatusStr('')
        setValue('status', '', { shouldDirty: true, shouldValidate: true })
        break
    }
  }

  const handleStatus = (e) => {
    const { value } = e.target

    setValue('status', value, { shouldDirty: true, shouldValidate: true })

    switch (value) {
      case '1':
        setStatusStr(t('candidate.beforeSup'))
        break

      case '2':
        setStatusStr(t('candidate.underSup'))
        break

      case '3':
        setStatusStr(t('candidate.notApplicable'))
        break

      case '4':
        setStatusStr(t('candidate.timeLag'))
        break

      case '5':
        setStatusStr(t('candidate.EndSup'))
        break

      default:
        setStatusStr('')
        setValue('status', '', { shouldDirty: true, shouldValidate: true })
        break
    }
  }

  const handleSelectPrefecture = (e, label) => {
    const { value } = e.target
    setShowPrefecture(false)
    setValue('prefecture', label, { shouldDirty: true, shouldValidate: true })
    setPrefectureName(label)
    setPrefectureId(value)
  }

  const handleOnClose = () => {
    setShowPrefecture(false)
    setShowInchargeDropdown(false)
  }

  const handleShowPrefecture = () => {
    setShowPrefecture(!showPrefecture)
  }

  const selectIncharge = (e, adPerson) => {
    e.preventDefault()
    setValue('ad_person_incharge', `${adPerson.full_name}/${adPerson.contact_number}`, {
      shouldDirty: true,
      shouldValidate: true,
    })
    setAdPersonInCharge(adPerson)
    setShowInchargeDropdown(false)
  }

  const handleShowAdPerInChargeDropdown = () => {
    setShowInchargeDropdown(!showInchargeDropdown)
  }

  const handleEmployeeStatus = (e) => {
    const { value } = e.target
    if (value === t('candidate.other')) {
      setValue('current_employment_status', t('candidate.other'), { shouldDirty: true, shouldValidate: true })
      setValue('other_status', '', { shouldDirty: true, shouldValidate: true })
      setValue('current_employment_statusCtr', '', { shouldDirty: true, shouldValidate: true })
      setShowInterlock(true)
    } else {
      setValue('current_employment_status', value, { shouldDirty: true, shouldValidate: true })
      setValue('other_status', t('candidate.other'), { shouldDirty: true, shouldValidate: true })
      setValue('current_employment_statusCtr', 'success', { shouldDirty: true, shouldValidate: true })
      setShowInterlock(false)
    }
  }

  const handleInput = (e) => {
    if (!((e.keyCode > 95 && e.keyCode < 106) || (e.keyCode > 47 && e.keyCode < 58) || e.keyCode == 8)) {
      e.preventDefault()
    }
  }

  const handleAdInChargeSelectChange = (e) => {
    const { value } = e.target
    const isAdInCharge = value === 'ad_person_incharge'

    if ((showContactNumber && !isAdInCharge) || (showAdList && isAdInCharge)) return

    setShowAdList(isAdInCharge)
    setShowContactNumber(!isAdInCharge)
    setContactNumber(isAdInCharge ? '' : contactNumber)
  }

  const handleContactNumberChange = (event) => {
    const { value } = event.target
    setContactNumber(value)
    setAdPersonInCharge(user)
    setValue('emergency_contact', 'contactNumber', { shouldDirty: true })
  }

  useEffect(() => {
    const emergencyContact = getValues('emergency_contact')
    if (
      (emergencyContact === 'contactNumber' && !contactNumber) ||
      (emergencyContact === 'ad_person_incharge' && !adPersonInCharge)
    ) {
      // Clean
      resetField('emergency_contact', {
        defaultValue: '',
      })
    }
  }, [getValues().emergency_contact, contactNumber])

  const handleSubmitData = (data) => {
    data.birthday = new Date(data?.year, data?.month, data?.day)
    data.ad_person_incharge = adPersonInCharge
    data.prefecture = prefectureId
    data.prefectureName = prefectureName
    data.resume = selectedResume?.name
    data.work_history_cv = selectedWorkCv?.name
    data.fields = fields
    data.statusStr = statusStr
    data.otherPdfString = otherPdfString

    if (contactNumber) {
      data.contact_number = contactNumber
    }

    if ((mode == 'add' || mode == 'add-confirm') && checkDuplicateApplicant(data)) {
      props.handleShowDuplicateEntryError()
    } else {
      const candidate = JSON.parse(localStorage.getItem('candidate') ?? '{}') || {}
      localStorage.setItem('candidate', JSON.stringify({ ...candidate, ...data }))
      history.push({
        pathname: '/applicants/confirmation',
        state: { file_resume: selectedResume, file_cv: selectedWorkCv, other_docs_pdf: selectedOtherPdf, mode: mode },
      })
    }
  }

  const checkDuplicateApplicant = (candidate) => {
    return applicants.find((obj) => {
      const birthday =
        candidate.year +
        '-' +
        (candidate.month.length < 2 ? '0' + candidate.month : candidate.month) +
        '-' +
        candidate.day

      return (
        obj.lastname === candidate.lastname &&
        obj.firstname === candidate.firstname &&
        obj.birthday === birthday &&
        obj.prefecture.id === Number(candidate.prefecture)
      )
    })
  }

  const handleFileInput = (e) => {
    const { value, name } = e.target
    const filesDoc = e.target.files[0]
    const ext = value.split('.').pop()

    if (ext === 'pdf') {
      if (name === 'resume') {
        setSuccessResume('success')
        setSelectedResume(filesDoc)
        setValue('resume', filesDoc, { shouldDirty: true, shouldValidate: true })
      }
      if (name === 'work_history_cv') {
        setSuccessPdfWorkCv('success')
        setSelectedWorkCv(filesDoc)
        setValue('work_history_cv', filesDoc, { shouldDirty: true, shouldValidate: true })
      }
    }
  }

  const handleRemoveFile = (index = 0, file) => {
    if (file === 'other pdf') {
      let list = selectedOtherPdf
      list.splice(index, 1)
      setSelectedOtherPdf(list)
    } else if (file === 'resume') {
      setValue('resume', '')
      setSelectedResume(null)
      setSuccessResume('')
      setValue('resume', '', { shouldDirty: true, shouldValidate: true })
    } else if (file === 'work_history_cv') {
      setValue('work_history_cv', '')
      setSelectedWorkCv(null)
      setSuccessPdfWorkCv('')
      setValue('work_history_cv', '', { shouldDirty: true, shouldValidate: true })
    }
  }

  const handleClickAddPortfolio = () => {
    fields.length < 10 && append({ option: 'url', url: null, pdf: null })
  }

  const handleRemovePortfolio = (index) => {
    remove(index)
  }

  const handleSelectedOtherPdfs = (index, state) => {
    let otherPdfs = selectedOtherPdf
    otherPdfs[index] = state
    setSelectedOtherPdf(otherPdfs)
  }

  const checkFullname = (e) => {
    const { name, value } = e.target
    setValue(name, value)
    if (getValues('firstname') && getValues('lastname')) {
      setValue('fullNameCtr', 'success', { shouldDirty: true, shouldValidate: true })
    } else {
      setValue('fullNameCtr', '', { shouldDirty: true, shouldValidate: true })
    }
  }

  const checkKanaName = (e) => {
    const { name, value } = e.target
    setValue(name, value)

    if (getValues('kana_firstname') && getValues('kana_lastname')) {
      setValue('kanaNameCtr', 'success', { shouldDirty: true, shouldValidate: true })
    } else {
      setValue('kanaNameCtr', '', { shouldDirty: true, shouldValidate: true })
    }
  }

  const checkBirthday = (e) => {
    const { name, value } = e.target
    setValue(name, value)
    let result = ''

    if (
      getValues('year').length > 3 &&
      getValues('year') >= new Date().getFullYear() - 70 &&
      getValues('year') <= new Date().getFullYear() - 18 &&
      getValues('month') >= 1 &&
      getValues('month') <= 12 &&
      getValues('day') >= 1 &&
      getValues('day') <= 31
    ) {
      const year = +getValues('year')
      const month = +getValues('month') - 1
      const day = +getValues('day')

      const bDate = dayjs().year(year).month(month).date(day)

      if (bDate.year() === year && bDate.month() === month && bDate.date() === day) {
        setErrorBirthdayInput(false)
        result = 'success'
      } else {
        setErrorBirthdayInput(true)
        result = ''
      }
      setErrorBirthdayMessage('')
    } else {
      setErrorBirthdayInput(true)
      result = ''
      if (getValues('year') >= new Date().getFullYear() - 70 && getValues('year') <= new Date().getFullYear() - 18) {
        setErrorBirthdayMessage('')
      } else {
        setErrorBirthdayMessage(t('candidate.yearErrorMessage'))
      }
    }
    setValue('birthdayCtr', result, { shouldDirty: true, shouldValidate: true })
  }

  const checkEmpStats = (e) => {
    const { name, value } = e.target
    setValue(name, value)

    if (getValues('other_status')) {
      setValue('current_employment_statusCtr', 'success', { shouldDirty: true, shouldValidate: true })
    } else {
      setValue('current_employment_statusCtr', '', { shouldDirty: true, shouldValidate: true })
    }
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleSubmitData)}>
        <main className="main recommendation">
          <div className="recommendation__inner">
            <div className="recommendation__header recommendation__header-mgb32">
              <h1 className="recommendation__title">{t('candidate.title')}</h1>
            </div>

            {/* 氏名 */}
            <FormGroup
              success={!errors?.fullNameCtr && dirtyFields?.fullNameCtr}
              error={!!errors?.fullNameCtr && !!errors?.fullNameCtr}
              modifier="mgb0 mgt2"
              required
            >
              <div className="form__group form__group-mgb0 form__group-spMgb16 required">
                <label htmlFor="" className="form__label form__label-mini">
                  {t('candidate.fullname')}
                </label>
                <div className="form__groupFlex form__group-mgt form__groupMultiCheck">
                  <Input
                    type="text"
                    className="form__inputText form__inputText-w96px form__inputText-mgr inputCheck boolCheckMulti"
                    {...register('lastname')}
                    onChange={(e) => checkFullname(e)}
                  />
                  <Input
                    type="text"
                    className="form__inputText form__inputText-w96px inputCheck boolCheckMulti"
                    {...register('firstname')}
                    onChange={(e) => checkFullname(e)}
                  />
                </div>
              </div>
            </FormGroup>

            {/* フリガナ */}
            <FormGroup
              success={!errors?.kanaNameCtr && dirtyFields?.kanaNameCtr}
              error={!!errors?.kanaNameCtr && !!errors?.kanaNameCtr}
              modifier="mgb0 mgt2"
              required
            >
              <div className="form__group form__group-mgb0 form__group-spMgb16 required">
                <label htmlFor="" className="form__label form__label-mini">
                  {t('candidate.furigana')}
                </label>
                <div className="form__groupFlex form__group-mgt form__groupMultiCheck">
                  <Input
                    type="text"
                    className="form__inputText form__inputText-w128px form__inputText-mgr inputCheck boolCheckMulti"
                    {...register('kana_lastname')}
                    onChange={(e) => checkKanaName(e)}
                  />
                  <Input
                    type="text"
                    className="form__inputText form__inputText-w128px inputCheck boolCheckMulti"
                    {...register('kana_firstname')}
                    onChange={(e) => checkKanaName(e)}
                  />
                </div>
              </div>
            </FormGroup>

            {/* 生年月日 */}
            <FormGroup
              success={!errors?.birthdayCtr && dirtyFields?.birthdayCtr && !errorBirthdayInput}
              error={errorBirthdayInput}
              modifier="mgb0 mgt2"
              required
            >
              <div className="form__group form__group-mgb0 form__group-spMgb16 required">
                <label htmlFor="" className="form__label form__label-mini">
                  {t('candidate.dateofBirth')}
                </label>
                <div className="form__groupFlex form__group-mgt form__groupMultiCheck">
                  <Input
                    type="number"
                    className="form__inputText form__inputText-w72px inputCheck boolCheckMulti"
                    {...register('year', {
                      min: new Date().getFullYear() - 70,
                      max: new Date().getFullYear() - 18,
                      maxLength: 4,
                      minLength: 4,
                    })}
                    onChange={(e) => checkBirthday(e)}
                  />
                  <p className="form__inputMiddleText">{t('candidate.year')}</p>

                  <Input
                    type="number"
                    className="form__inputText form__inputText-w4em inputCheck boolCheckMulti"
                    {...register('month', {
                      maxLength: 2,
                      minLength: 1,
                      min: 1,
                      max: 12,
                    })}
                    onChange={(e) => checkBirthday(e)}
                  />
                  <p className="form__inputMiddleText">{t('candidate.month')}</p>
                  <Input
                    type="number"
                    className="form__inputText form__inputText-w4em inputCheck boolCheckMulti"
                    {...register('day', {
                      maxLength: 2,
                      minLength: 1,
                      min: 1,
                      max: 31,
                    })}
                    onChange={(e) => checkBirthday(e)}
                  />
                  <p className="form__inputMiddleText">{t('candidate.day')}</p>
                </div>
                <FormSupArea>
                  <p className="form__alert">{errorBirthdayMessage}</p>
                </FormSupArea>
              </div>
            </FormGroup>

            {/* 居住の都道府県 */}
            <FormGroup
              success={!errors?.prefecture && dirtyFields?.prefecture}
              error={!!errors?.prefecture}
              modifier="mgb0 mgt2"
              required
            >
              <div className="form__group form__group-0 form__group-mgb0 form__group-spMgb16 required cloneJuusyo">
                <label htmlFor="koyoukeitai" className="form__label form__label-mgt9 form__label-mini juusyoTitle">
                  {t('fieldLabels.prefecture')}
                </label>
                <div className="form__adWrap">
                  <Input
                    className="form__adText form__adText-w240px"
                    type="text"
                    name="prefecture"
                    id="formAdText"
                    placeholder={t('candidate.formAdtext')}
                    {...register('prefecture')}
                    readOnly
                    onClick={handleShowPrefecture}
                  />
                  {showPrefecture ? (
                    <PrefectureModal
                      handleShowPrefecture={handleShowPrefecture}
                      handleSelectPrefecture={handleSelectPrefecture}
                      showPrefecture={showPrefecture}
                      prefectureId={+prefectureId}
                      onClose={handleOnClose}
                    />
                  ) : null}
                </div>
              </div>
            </FormGroup>

            {/* 性別 */}
            <FormGroup
              success={!errors?.gender && dirtyFields?.gender}
              error={!!errors?.gender}
              modifier="mgb0 mgt2"
              required
            >
              <div className="form__group form__group-mgb0 form__group-mgt0 required">
                <label htmlFor="" className="form__label form__label-mgt9 form__label-mini">
                  {t('candidate.gender')}
                </label>
                <FormGroupCheck className="form__groupCheckArea">
                  <FormGroupCheck.Item
                    label={t('candidate.male')}
                    Input={Radio}
                    type="round"
                    inputProps={{ ...register('gender'), value: t('candidate.male') }}
                    className="form__groupCheck"
                  />
                  <FormGroupCheck.Item
                    label={t('candidate.female')}
                    Input={Radio}
                    type="round"
                    inputProps={{ ...register('gender'), value: t('candidate.female') }}
                    className="form__groupCheck"
                  />
                  <FormGroupCheck.Item
                    label={t('candidate.others')}
                    Input={Radio}
                    type="round"
                    inputProps={{ ...register('gender'), value: t('candidate.others') }}
                    className="form__groupCheck"
                  />
                </FormGroupCheck>
              </div>
            </FormGroup>

            {/* 現在の雇用形態 */}
            <FormGroup
              success={!errors?.current_employment_statusCtr && dirtyFields?.current_employment_statusCtr}
              error={!!errors?.current_employment_statusCtr}
              modifier="mgb0 mgt2"
              required
            >
              <div className="form__group form__group-mgb0 form__group-spMgb16 required">
                <label htmlFor="" className="form__label form__label-mgt9 form__label-mini">
                  {t('candidate.currentEmployment')}
                </label>
                <div className="form__groupCheckArea">
                  <FormGroupCheck modifier="flex">
                    <FormGroupCheck.Item
                      label={t('candidate.fulltime')}
                      Input={Radio}
                      type="round"
                      className="form__groupCheckItem-w50b"
                      inputProps={{
                        ...register('current_employment_status'),
                        onChange: handleEmployeeStatus,
                        value: t('candidate.fulltime'),
                      }}
                    />
                    <FormGroupCheck.Item
                      label={t('candidate.contractEmployee')}
                      Input={Radio}
                      type="round"
                      className="form__groupCheckItem-w50b2"
                      inputProps={{
                        ...register('current_employment_status'),
                        onChange: handleEmployeeStatus,
                        value: t('candidate.contractEmployee'),
                      }}
                    />
                    <FormGroupCheck.Item
                      label={t('candidate.temporary')}
                      Input={Radio}
                      type="round"
                      className="form__groupCheckItem-w50b"
                      inputProps={{
                        ...register('current_employment_status'),
                        onChange: handleEmployeeStatus,
                        value: t('candidate.temporary'),
                      }}
                    />

                    <FormGroupCheck.Item
                      label={t('candidate.subContracting')}
                      Input={Radio}
                      type="round"
                      className="form__groupCheckItem-w50b2"
                      inputProps={{
                        ...register('current_employment_status'),
                        onChange: handleEmployeeStatus,
                        value: t('candidate.subContracting'),
                      }}
                    />
                    <FormGroupCheck.Item
                      label={t('candidate.partTime')}
                      Input={Radio}
                      type="round"
                      className="form__groupCheckItem-w50b"
                      inputProps={{
                        ...register('current_employment_status'),
                        onChange: handleEmployeeStatus,
                        value: t('candidate.partTime'),
                      }}
                    />
                    <div className="form__groupCheckItem form__groupCheckItem-w50b2">
                      <FormGroupCheck.Item
                        label={t('candidate.other')}
                        Input={Radio}
                        type="round"
                        inputProps={{
                          ...register('current_employment_status'),
                          onChange: handleEmployeeStatus,
                          value: t('candidate.other'),
                        }}
                      />
                      <FormInterlock className="form__interlock" active={showInterlock}>
                        <div className="form__groupFlex form__group-mgt">
                          <Input type="text" {...register('other_status')} onChange={(e) => checkEmpStats(e)} />
                        </div>
                      </FormInterlock>
                    </div>
                  </FormGroupCheck>
                </div>
              </div>
            </FormGroup>

            {/* 直近の年収 */}
            <FormGroup
              success={!errors?.recent_annual_salary && dirtyFields?.recent_annual_salary}
              error={!!errors?.recent_annual_salary}
              modifier="mgb0 mgt2"
              required
            >
              <div className="form__group form__group-mgb0 form__group-mgt0 form__group-spMgb16 required">
                <label htmlFor="" className="form__label form__label-mgt9 form__label-mini">
                  {t('candidate.recentAnnualIncome')}
                </label>
                <div className="form__groupFlex form__group-mgt">
                  <Input
                    type="number"
                    {...register('recent_annual_salary', { min: 1 })}
                    onKeyDown={handleInput}
                    className="form__inputText form__inputText-w6em inputCheck boolCheck"
                  />
                  <p className="form__inputAfterText">{t('candidate.recentIncomelbl')}</p>
                </div>
              </div>
            </FormGroup>

            {/* 希望の年収 */}
            <FormGroup
              success={!errors?.desired_annual_salary && dirtyFields?.desired_annual_salary}
              error={!!errors?.desired_annual_salary}
              modifier="mgb0 mgt2"
              required
            >
              <div className="form__group form__group-mgb0 form__group-mgt0 form__group-spMgb16 required">
                <label htmlFor="" className="form__label form__label-mgt9 form__label-mini">
                  {t('candidate.desriedAnnualIncome')}
                </label>
                <div className="form__groupFlex form__group-mgt">
                  <Input
                    type="number"
                    {...register('desired_annual_salary', { min: 1 })}
                    onKeyDown={handleInput}
                    className="form__inputText form__inputText-w6em inputCheck boolCheck"
                  />
                  <p className="form__inputAfterText">{t('candidate.desiredAnnuallbl')}</p>
                </div>
              </div>
            </FormGroup>

            {/* 状況 */}
            <FormGroup
              success={!errors?.status && dirtyFields?.status}
              error={!!errors?.status}
              modifier="mgb0 mgt2"
              required
            >
              <div className="form__group form__group-mgb0 form__group-mgt0 form__group-spMgb16 required">
                <label htmlFor="" className="form__label form__label-mgt9 form__label-mini">
                  {t('candidate.situation')}
                </label>
                <div className="form__groupCheckArea">
                  <FormGroupCheck className="form__groupCheckArea">
                    <FormGroupCheck.Item
                      label={t('candidate.beforeSup')}
                      Input={Radio}
                      type="round"
                      inputProps={{ ...register('status'), value: '1', onChange: handleStatus }}
                      className="form__groupCheck"
                    />
                    <FormGroupCheck.Item
                      label={t('candidate.underSup')}
                      Input={Radio}
                      type="round"
                      inputProps={{ ...register('status'), value: '2', onChange: handleStatus }}
                      className="form__groupCheck"
                    />
                    <FormGroupCheck.Item
                      label={t('candidate.notApplicable')}
                      Input={Radio}
                      type="round"
                      inputProps={{ ...register('status'), value: '3', onChange: handleStatus }}
                      className="form__groupCheck"
                    />
                    <FormGroupCheck.Item
                      label={t('candidate.timeLag')}
                      Input={Radio}
                      type="round"
                      inputProps={{ ...register('status'), value: '4', onChange: handleStatus }}
                      className="form__groupCheck"
                    />
                    <FormGroupCheck.Item
                      label={t('candidate.EndSup')}
                      Input={Radio}
                      type="round"
                      inputProps={{ ...register('status'), value: '5', onChange: handleStatus }}
                      className="form__groupCheck"
                    />
                  </FormGroupCheck>
                </div>
              </div>
            </FormGroup>

            {/* 担当コンサルタント */}
            {mode !== 'edit' ? (
              <FormGroup
                success={!errors?.ad_person_incharge && dirtyFields?.ad_person_incharge}
                error={!!errors?.ad_person_incharge}
                modifier="mgb0 mgt2"
                required
              >
                <div className="form__group form__group-mgb0 form__group-mgt0 form__group-spMgb16 required">
                  <label htmlFor="" className="form__label form__label-mgt9 form__label-mini">
                    担当コンサ
                  </label>
                  <div className="form__groupCheckArea">
                    <div className="form__groupFlex form__group-mgt">
                      <div className="form__dropWrap">
                        <div className="form__dropInputWrap">
                          <Input
                            type="text"
                            {...register('ad_person_incharge')}
                            value={`${adPersonInCharge.full_name}/${adPersonInCharge.contact_number}`}
                            placeholder={t('hints.selectAD')}
                            className="form__dropText form__dropText-w280px inputCheck boolCheck"
                            id="formTelText"
                            readOnly
                            onClick={handleShowAdPerInChargeDropdown}
                          />
                        </div>

                        {showInchargeDropdown ? (
                          <PersonInCharge
                            showPersonIncharge={showInchargeDropdown}
                            handleSelectPerInCharge={selectIncharge}
                            handleOnClose={handleOnClose}
                            adInchargeList={adInchargeList}
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </FormGroup>
            ) : (
              <FormGroup
                success={!errors?.emergency_contact && dirtyFields.emergency_contact}
                error={errors?.emergency_contact}
                modifier="mgb0 mgt2"
                required
              >
                <div className="form__group form__group-mgb0 form__group-mgt0 form__group-spMgb16 required">
                  <label htmlFor="" className="form__label form__label-mgt9 form__label-mini">
                    担当コンサルタント
                  </label>
                  <div className="form__groupCheckArea">
                    <div className="form__groupCheck">
                      <input
                        type="radio"
                        {...register('emergency_contact')}
                        id="emergency-contact1"
                        checked={showAdList}
                        data-interlock="interlock2"
                        className="form__checkItem formRadio boolCheck interlock"
                        value="ad_person_incharge"
                        onClick={(e) => handleAdInChargeSelectChange(e)}
                      />
                      <label htmlFor="emergency-contact1" className="form__checkLabel">
                        {t('hints.selectFromList')}
                      </label>
                    </div>
                    <FormInterlock active={showAdList}>
                      <div className="form__groupFlex form__group-mgt">
                        <div className="form__dropWrap">
                          <div className="form__dropInputWrap">
                            <Input
                              type="text"
                              {...register('ad_person_incharge')}
                              value={`${adPersonInCharge.full_name}/${adPersonInCharge.contact_number}`}
                              placeholder={t('hints.selectAD')}
                              className="form__dropText form__dropText-w280px inputCheck boolCheck"
                              id="formTelText"
                              readOnly
                              onClick={handleShowAdPerInChargeDropdown}
                            />
                          </div>

                          {showInchargeDropdown ? (
                            <PersonInCharge
                              showPersonIncharge={showInchargeDropdown}
                              handleSelectPerInCharge={selectIncharge}
                              handleOnClose={handleOnClose}
                              adInchargeList={adInchargeList}
                            />
                          ) : null}
                        </div>
                      </div>
                    </FormInterlock>
                    <div className="form__groupCheck">
                      <input
                        type="radio"
                        {...register('emergency_contact')}
                        id="emergency-contact2"
                        data-interlock="interlock3"
                        className="form__checkItem formRadio boolCheck interlock"
                        value="contactNumber"
                        checked={showContactNumber}
                        onClick={(e) => handleAdInChargeSelectChange(e)}
                      />
                      <label htmlFor="emergency-contact2" className="form__checkLabel">
                        {t('hints.enterDirectly')}
                      </label>
                    </div>
                    <FormInterlock active={showContactNumber}>
                      <div className="form__groupFlex form__group-mgt">
                        <input
                          type="text"
                          {...register('contact_number')}
                          name="contact_number"
                          value={contactNumber}
                          onChange={handleContactNumberChange}
                          className="form__inputText inputCheck boolCheck"
                        />
                      </div>
                      <div className="form__supArea form__supArea-flex">
                        <p className="form__alert">{t('hints.enter2')}</p>
                      </div>
                    </FormInterlock>
                  </div>
                </div>
              </FormGroup>
            )}

            <CandidateDocument
              handleRemoveFile={handleRemoveFile}
              handleFileInput={handleFileInput}
              handleSelectedOtherPdfs={handleSelectedOtherPdfs}
              handleRemovePortfolio={handleRemovePortfolio}
              handleClickAddPortfolio={handleClickAddPortfolio}
              selectedWorkCv={selectedWorkCv}
              selectedOtherPdf={selectedOtherPdf}
              selectedResume={selectedResume}
              successResume={successResume}
              successPdfWorkCv={successPdfWorkCv}
              fields={fields}
              update={update}
              mode={mode}
            />

            <FormGroup
              success={!errors?.testimonial && dirtyFields?.testimonial}
              error={!!errors?.testimonial}
              modifier="mgb0 mgt2"
              required
            >
              <div className="form__group form__group-mgb0 form__group-mgt2 required">
                <label htmlFor="" className="form__label form__label-mgt9 form__label-mini">
                  {t('candidate.note')}
                </label>
                <div className="form__textAreaWrap form__textAreaWrap-w600">
                  <Textarea
                    cols="30"
                    rows="10"
                    className="form__inputTextArea form__group-mgt inputCalc boolCheck"
                    {...register('testimonial', {
                      maxLength: { value: 3000, message: t('validation.maxLength') },
                    })}
                  />
                  <FormSupArea>
                    <FormSupArea.InputSup modifier="end" inputValue={special_note} max={3000} />
                  </FormSupArea>
                </div>
              </div>
            </FormGroup>
          </div>
        </main>
        <Footer mode={mode} />
      </form>
    </FormProvider>
  )
}

AddEditForm.propTypes = {
  handleShowDuplicateEntryError: PropTypes.func,
  mode: PropTypes.string,
  files: PropTypes.object,
  location: PropTypes.object,
}
export default AddEditForm

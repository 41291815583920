import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Controller } from 'react-hook-form'

const PublishDateTime = ({ control, dateTime, time, onPublishDateChange, date, onPublishTimeChange, defaultTime }) => {
  const { t } = useTranslation()
  return (
    <>
      <p className="fArticle__input__publishSetting-title">{t('featuredArticle.form.publishDate')}</p>
      <Controller
        control={control}
        name="publish_date"
        render={({ field }) => (
          <select
            {...field}
            className="fArticle__input__publishSetting-select"
            value={date}
            onChange={onPublishDateChange}
          >
            {dateTime.map(({ date }) => (
              <option key={date} value={date}>
                {date}
              </option>
            ))}
          </select>
        )}
      />
      <Controller
        control={control}
        name="publish_time"
        render={({ field }) => (
          <select
            {...field}
            className="fArticle__input__publishSetting-select"
            value={defaultTime}
            onChange={onPublishTimeChange}
          >
            {time?.length &&
              time?.map((time) => (
                <option key={time} value={time}>
                  {time}
                </option>
              ))}
          </select>
        )}
      />
    </>
  )
}

PublishDateTime.propTypes = {
  control: PropTypes.any,
  dateTime: PropTypes.array,
  time: PropTypes.array,
  onPublishDateChange: PropTypes.func,
  onPublishTimeChange: PropTypes.func,
  date: PropTypes.string,
  defaultTime: PropTypes.string,
}

export default PublishDateTime

import React from 'react'
import SelectionManagementBox from '../SelectionManagementCard/Box'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { createReferralProposalUsers } from 'services/referralProposals'
import PropTypes from 'prop-types'
import { BlockEl } from 'components/atoms'
import { selectAuthUser } from 'store/auth/selectors'
import { useSituationMark } from 'utils/hooks'
import { inAccountType } from 'utils/helper'
import { ACCOUNT_TYPES } from 'utils/constants'

const SelectionManagementHeaders = () => {
  const { t } = useTranslation()

  return (
    <li className="selection__mainLi selection__mainLi-gray">
      <SelectionManagementBox.NameHeader name_header={t('selectionManagement.name_header')} />
      <SelectionManagementBox.JobInfoHeader job_info_header={t('selectionManagement.job_info_header')} />
      <SelectionManagementBox.CompanyHeader company_header={t('selectionManagement.company_header')} />
      <SelectionManagementBox.StepHeader step_header={t('selectionManagement.step_header')} />
      <SelectionManagementBox.SituationHeader situation_header={t('selectionManagement.situation_header')} />
    </li>
  )
}

const SelectionManagementItem = ({ item }) => {
  const { t } = useTranslation()
  const { message, color } = useSituationMark(item)
  const name = item?.job_applicant?.lastname + ' ' + item?.job_applicant?.firstname
  const date = new Date(item?.latest_status_transaction?.created_at)
  const formatted_date = date.toLocaleDateString() + ' ' + t('daysOfTheWeek.' + date.getDay())

  if (item.type === 'proposal') return <></>

  return (
    <SelectionManagementBox>
      <SelectionManagementBox.Name name={name} />
      <SelectionManagementBox.JobInfo
        job={item?.job_posting?.job_title}
        info={item?.job_posting?.available_position?.name}
      />
      <SelectionManagementBox.Company company={item?.job_posting?.company_name} />
      <SelectionManagementBox.Step step={item?.application_status?.status} date={formatted_date} />
      <SelectionManagementBox.Situation situation={message} situationColor={color} />
    </SelectionManagementBox>
  )
}

SelectionManagementItem.propTypes = {
  item: PropTypes.object,
}

const SelectionManagementCard = ({ items, state, onSelectionItemClick }) => {
  const user = useSelector(selectAuthUser)
  const dispatch = useDispatch()
  const isHR = inAccountType(user, ACCOUNT_TYPES.hr)

  const handleClick = (id) => {
    if (isHR) {
      dispatch(createReferralProposalUsers(id, user.id))
    }

    onSelectionItemClick(id)
  }

  return (
    <>
      {state === 'fulfilled' && (
        <ul className="selection__mainUl">
          <SelectionManagementHeaders />
          {items.map((item) => (
            <BlockEl
              component="li"
              blockElClass="selection__mainLi"
              modifier={item.read ? 'gray' : 'white'}
              onClick={() => handleClick(item.id)}
              key={item.id}
            >
              <SelectionManagementItem item={item} />
            </BlockEl>
          ))}
        </ul>
      )}
    </>
  )
}

SelectionManagementCard.propTypes = {
  items: PropTypes.array.isRequired,
  state: PropTypes.string.isRequired,
  handleClose: PropTypes.func,
  setProposalIsUpdated: PropTypes.func,
  onSelectionItemClick: PropTypes.func,
}

export default SelectionManagementCard

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import PropTypes from 'prop-types'
import clsx from 'clsx'
import Buttons from './Buttons'
import qs from 'query-string'

import { useLocation, useHistory } from 'react-router-dom'
import { selectJobPosting } from 'store/job-postings/selectors'
import { canPropose, fetchForEditProposal } from 'services/jobPostings'
import { selectAuthUser } from 'store/auth/selectors'
import { accountType, inAccountType, isSuspended } from 'utils/helper'
import { USER_TYPE_KEYS } from 'utils/constants'
import { Button } from 'components'
import { actionOpenProposalSlideOut } from 'store/job-postings/actionCreators'
import { useIsMobile } from 'utils/hooks'

function JobDetailFooter(props) {
  const { t } = useTranslation()
  const history = useHistory()
  const jobPosting = useSelector(selectJobPosting)
  const { openedJobDetailFooter, qAndAModalOpen, setqAndAModalOpen } = props
  const dispatch = useDispatch()
  const location = useLocation()
  const user = useSelector(selectAuthUser)
  const account_type_AD2 = accountType(user) === 'AD2'
  const isProposal = jobPosting?.job_posting_type === 'proposal'
  const couldPropose = !!jobPosting?.can_propose
  const isUserSuspended = isSuspended(user)
  const isMobile = useIsMobile()

  useEffect(() => {
    if (jobPosting?.id && account_type_AD2) {
      dispatch(canPropose(jobPosting?.id))
    }
  }, [jobPosting?.id])

  useEffect(() => {
    const { editProposal } = new qs.parse(location.search)

    if (Number.isFinite(parseInt(editProposal))) {
      dispatch(fetchForEditProposal(editProposal))
    }
  }, [location.search])

  if (jobPosting === null) return <></>

  const isHuunoAdmin = inAccountType(user, USER_TYPE_KEYS.ADMIN)

  const handleFixBtnClick = () =>
    isProposal ? dispatch(actionOpenProposalSlideOut()) : history.push(`/job-posting/recommendation/${jobPosting.id}`)

  return (
    <>
      <footer
        className={clsx({
          ['footer footer-flex pc']: !openedJobDetailFooter,
        })}
      >
        {!openedJobDetailFooter && (
          <div className="footer__leftCont">
            <p className="footer__corp">{jobPosting.company_name}</p>
            <p className="footer__corp2">{jobPosting.job_title}</p>
          </div>
        )}

        {!isHuunoAdmin && (
          <Buttons jobPosting={jobPosting} qAndAModalOpen={qAndAModalOpen} setqAndAModalOpen={setqAndAModalOpen} />
        )}
      </footer>
      {isMobile && (
        <Button
          component="a"
          variant="shadow"
          className="evaluation__fixBtn"
          variantMod="blue"
          onClick={handleFixBtnClick}
          disabled={(!couldPropose && isProposal) || isUserSuspended}
        >
          {isProposal
            ? couldPropose
              ? t('jobPosting.footer.suggestionTo')
              : t('jobPosting.footer.alreadyPropose')
            : t('jobPosting.footer.recommendationTo')}
        </Button>
      )}
    </>
  )
}

JobDetailFooter.propTypes = {
  id: PropTypes.any,
  openedJobDetailFooter: PropTypes.any,
  qAndAModalOpen: PropTypes.bool,
  setqAndAModalOpen: PropTypes.func,
}

export default JobDetailFooter

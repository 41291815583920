import Http from 'utils/Http'
import { createAsyncThunk } from 'store/helpers'
import { parseAxiosResponse } from 'utils/helper'
import * as actions from 'store/applicant/actionCreators'

const SUCCESS = 200

export const getApplicantsList = createAsyncThunk('GET_APPLICANTS', (getState) => {
  const { ...params } = getState().applicant.params
  return parseAxiosResponse(Http.get(`/ad1`, { params }))
})

export function getApplicantsListByCompanyId(companyId) {
  const params = {
    limit: -1,
    company_id: companyId,
  }

  return async () => await parseAxiosResponse(Http.get(`/ad1`, { params }))
}

export function getApplicantDetails(applicant_id) {
  return (dispatch) => {
    dispatch(actions.getApplicantDetailsRequest())
    return Http.get(`/ad1/${applicant_id}`)
      .then((response) => {
        if (response.status === SUCCESS) {
          return dispatch(actions.getApplicantDetailsSuccess(response.data))
        }
      })
      .catch((error) => {
        return dispatch(actions.getApplicantDetailsFailure(error.response.data.error))
      })
  }
}

export function getApplicantFile(applicantId) {
  return (dispatch) => {
    dispatch(actions.getFileRequest(applicantId))
    return Http.get(`common/applicant_file/${applicantId}`)
      .then((response) => {
        if (response.status === SUCCESS) {
          return dispatch(actions.getFileSuccess(applicantId, response.data))
        }
      })
      .catch((error) => {
        return dispatch(actions.getFileFailure(applicantId, error.response.data.error))
      })
  }
}

export const fetchApplicant = createAsyncThunk('FETCH_APPLICANT', async (id) =>
  parseAxiosResponse(Http.get(`common/applicants/${id}`)).then(({ data }) => data)
)

export function getApplicants() {
  return (dispatch) => {
    dispatch(actions.getApplicantsListRequest())
    return Http.get(`/ad1/applicantslist`)
      .then((response) => {
        if (response.status === SUCCESS) {
          return dispatch(actions.getApplicantsListSuccess(response.data))
        }
      })
      .catch((error) => {
        return dispatch(actions.getApplicantsListFailure(error.response.data.error))
      })
  }
}

export function createApplicant(formData) {
  return (dispatch) => {
    dispatch(actions.createApplicantRequest())
    var data = new FormData()
    data.append('firstname', formData.firstname)
    data.append('lastname', formData.lastname)
    data.append('kana_firstname', formData.kana_firstname)
    data.append('kana_lastname', formData.kana_lastname)
    data.append('gender', formData.gender)
    data.append('birthday', formData.birthday)
    data.append('prefecture', formData.prefecture)
    data.append('recent_annual_salary', formData.recent_annual_salary)
    data.append('desired_annual_salary', formData.desired_annual_salary)
    data.append('other_docs_url', JSON.stringify(formData?.other_docs_url))
    data.append('current_employment_status', formData.current_employment_status)
    data.append('status', formData.status ? formData.status : 0)
    data.append('testimonial', formData?.testimonial)
    data.append('ad_person_incharge', formData?.ad_person_incharge.id)
    data.append('contact_number', formData.contact_number)

    if (formData?.isCreateRecommendation) {
      data.append('isCreateRecommendation', formData.isCreateRecommendation)
      data.append('job_posting_id', formData.job_posting_id)
      data.append('special_notes', formData.special_notes)
    }

    for (var n = 0; n < formData?.other_docs_pdf.length; n++) {
      if (formData.other_docs_pdf[n]) {
        data.append('other_docs_pdf[]', formData.other_docs_pdf[n])
      }
    }

    data.append('current_employment_status', formData.current_employment_status)

    if (formData.file_resume) {
      data.append('file_resume', formData.file_resume)
    }
    if (formData.file_cv) {
      data.append('file_cv', formData.file_cv)
    }
    return Http.post('/ad1/', data)
      .then((response) => {
        if (response.status === SUCCESS) {
          return dispatch(actions.createApplicantSuccess(response.data))
        }
      })
      .catch((error) => {
        return dispatch(actions.createApplicantFailure(error))
      })
  }
}

export function updateApplicant(id, formData) {
  return (dispatch) => {
    var data = new FormData()
    data.append('firstname', formData.firstname)
    data.append('lastname', formData.lastname)
    data.append('kana_firstname', formData.kana_firstname)
    data.append('kana_lastname', formData.kana_lastname)
    data.append('gender', formData.gender)
    data.append('birthday', formData.birthday)
    data.append('prefecture', formData.prefecture)
    data.append('testimonial', formData?.testimonial)
    data.append('recent_annual_salary', formData.recent_annual_salary)
    data.append('desired_annual_salary', formData.desired_annual_salary)
    data.append('other_docs_url', JSON.stringify(formData?.other_docs_url))
    data.append('other_docs_pdf', JSON.stringify(formData?.other_docs_pdf))
    data.append('ad_person_incharge', formData.ad_person_incharge.id ?? parseInt(formData.ad_person_incharge))

    if (formData?.contact_number) {
      data.append('contact_number', formData?.contact_number)
    }

    if (formData?.status) {
      data.append('status', formData.status)
    }
    data.append('current_employment_status', formData.current_employment_status)
    if (formData.file_resume && formData.file_resume instanceof File) {
      data.append('file_resume', formData.file_resume)
    }
    if (formData?.action) {
      data.append('action', formData.action)
    }

    if (formData?.pdfToDelete) {
      data.append('pdf_to_delete', formData.pdfToDelete)
    }

    if (formData.file_cv && formData.file_cv instanceof File) {
      data.append('file_cv', formData.file_cv)
    }
    if (formData?.isCreateRecommendation) {
      data.append('isCreateRecommendation', formData.isCreateRecommendation)
      data.append('job_posting_id', formData.job_posting_id)
      data.append('special_notes', formData.special_notes)
    }

    for (var n = 0; n < formData?.other_pdfs?.length; n++) {
      if (formData.other_pdfs[n]) {
        data.append('other_pdfs[]', formData.other_pdfs[n])
      }
    }

    dispatch(actions.updateApplicantRequest())
    return Http.post(`/ad1/${id}`, data)
      .then((response) => {
        if (response.status === SUCCESS) {
          return dispatch(actions.updateApplicantSuccess(response.data))
        }
      })
      .catch((error) => {
        return dispatch(actions.updateApplicantFailure(error.response.data.error))
      })
  }
}

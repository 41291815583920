import React from 'react'
import PropTypes from 'prop-types'
import EllipsisIcon from 'assets/img/threedot.svg'
import { useHistory } from 'react-router-dom'

const JobDetailsSpHeader = ({ title, onOptionClick }) => {
  const history = useHistory()
  const handleTitleClick = (e) => {
    e.preventDefault()
    history.goBack()
  }

  return (
    <div className="spHeader spHeader-inSec">
      <div className="spHeader__inner">
        <div className="spHeader__left">
          <a className="spHeader__title spHeader__title-link" href="/" onClick={handleTitleClick}>
            {title}
          </a>
        </div>
        <div className="spHeader__right">
          <p
            className="search__mainContFooterDot popModal"
            data-modal="modal"
            data-modalcont="modalCont6sp"
            onClick={onOptionClick}
          >
            <img src={EllipsisIcon} alt="" className="search__mainContFooterDotImg" width="20" height="8" />
          </p>
        </div>
      </div>
    </div>
  )
}

JobDetailsSpHeader.propTypes = {
  title: PropTypes.string.isRequired,
  onOptionClick: PropTypes.func.isRequired,
}

export default JobDetailsSpHeader

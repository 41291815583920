//import Dialog from '@mui/material/Dialog'
import { Modal } from 'components/molecules'
import PropTypes from 'prop-types'
import React from 'react'
import InviteUserForm from '../InviteUserForm'

function InviteUserDialog(props) {
  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      containerType="contA"
      containerClass="member__modal member__modal-sppdb"
    >
      <InviteUserForm isOpen={props.open} onCancel={props.onClose} onInviteSuccess={props.onInviteSuccess} />
    </Modal>
  )
}

InviteUserDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onInviteSuccess: PropTypes.func,
}

export default InviteUserDialog
